import parse from "html-react-parser";

const AlertHandler = ({ assistant }) => {
  if (assistant.is_full_configured) {
    return null;
  }

  const i18nKeyAlert = !assistant.already_populated_bases
    ? "empty_knowledge_bases_html"
    : "not_implemented_html";

  return (
    <div className="alert alert-warning text-body mt-2">
      <div className="d-flex align-items-center">
        <i className="ti ti-exclamation-circle text-warning icon me-2" />

        <div>
          {parse(I18n.t(`views.leadster_ai.page.home.pending_alerts.${i18nKeyAlert}`))}
        </div>
      </div>
    </div>
  );
};

export default AlertHandler;
