import React from 'react';

import OptimizationImage from '../OptimizationImage';

import { Link, Header, MobileContainer } from './styles';

const Default = ({ link, percentage, image }) => {
  return (
    <Header
      className="card d-flex flex-row justify-content-center align-items-center mb-0"
      style={{ padding: '15px', borderRadius: '8px', height: '40px' }}>

      <Link href={link}>
        <OptimizationImage width={35} img={image} />
      </Link>

      <Link href={link}>
        <span className="ms-2 d-none d-md-block">
          <span className="text-dark">
            {I18n.t("customer_success.optimization_header.optimization")}
          </span> <span className="optimization-percentage">{percentage}</span>
        </span>
      </Link>
    </Header>
  );
}

const Mobile = ({ link, image }) => {
  return (
    <MobileContainer href={link}>
      <OptimizationImage width={80} img={image} />
    </MobileContainer>
  );
}

const OptimizationHeader = ({ link, percentage, image }) => {
  const isMobile = window.innerWidth <= 600;

  return (
    <div>
      {!isMobile && (<Default link={link} percentage={percentage} image={image} />)}
      {isMobile && (<Mobile link={link} image={image} />)}
    </div>
  );
};

export default OptimizationHeader;
