import styled from "styled-components";

export const ListGroupContainer = styled.div`
  max-height: 220px;
  overflow-y: auto;

  .list-group-item {
    border-width: 0 0 1px;

    &:nth-of-type(odd) {
      background: var(--tblr-white);
    }

    &:nth-of-type(even) {
      background: var(--tblr-light);
    }
  }
`;
