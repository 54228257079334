import React, { useState } from "react";

import dayjs from "@/lib/dayjs";

const Filters = ({
  leadsCount,
  defaultScriptVersionHash,
  allowedScriptVersions,
  onChangeScriptVersionHash,
  defaultChannel,
  onChangeChannel
}) => {
  const [scriptVersionHash, setScriptVersionHash] = useState(defaultScriptVersionHash);
  const [channel, setChannel] = useState(defaultChannel);

  const handleChangeScriptVersionHash = (hash) => {
    setScriptVersionHash(hash);
    onChangeScriptVersionHash(hash);
  };

  const handleChangeChannel = (channel) => {
    setChannel(channel);
    onChangeChannel(channel);
  };

  return (
    <div className="row">
      <div className="col-lg mb-3">
        <label className="form-label">
          {I18n.t("views.analytics.total_leads")}
        </label>

        <div className='fw-bold fs-1'>
          {leadsCount}
        </div>
      </div>

      <div className="col-lg mb-3">
        <label className="form-label">{I18n.t("views.analytics.flow_version")}</label>

        <select
          className="form-control form-select"
          onChange={(e) => handleChangeScriptVersionHash(e.target.value)}
          value={scriptVersionHash}
        >
          {!allowedScriptVersions.length && (
            <option value="">{I18n.t("views.analytics.no_version_found")}</option>
          )}

          {allowedScriptVersions.map((version) => {
            const fromDate = dayjs(version.version_date).format(I18n.t("js.formats.default.date_time"))
            const untilDate = version.end_version_date
              ? dayjs(version.end_version_date).format(I18n.t("js.formats.default.date_time"))
              : I18n.t("views.analytics.now");

            return (<option key={version.version_hash} value={version.version_hash}>
              {`${I18n.t("views.analytics.version_from", { date: fromDate })}`}
              {" "}-{" "}
              {`${I18n.t("views.analytics.until", { date: untilDate })}`}
            </option>)
          })}
        </select>
      </div>

      <div className="col-lg mb-3">
        <label className="form-label">{I18n.t("views.analytics.source_channel")}</label>

        <select
          className="form-control form-select"
          onChange={(e) => handleChangeChannel(e.target.value || null)}
          value={channel || ""}
        >
          <option value="">{I18n.t("views.analytics.source_options.all_channels")}</option>
          <option value="facebook">{I18n.t("views.analytics.source_options.meta")}</option>
          <option value="google">{I18n.t("views.analytics.source_options.google")}</option>
          <option value="linkedin">{I18n.t("views.analytics.source_options.linkedin")}</option>
          <option value="not_identified">{I18n.t("views.analytics.source_options.not_identified")}</option>
        </select>
      </div>
    </div>
  );
};

export default Filters;
