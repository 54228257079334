import Modal from "react-bootstrap/Modal";

const UrlsUsed = ({ isLoaded, urlsUsed }) => {
  if (isLoaded || !urlsUsed) return <></>;

  return (
    <Modal.Body className="fw-bold py-3 bg-success text-white border-0">
      <i className="icon ti ti-check me-2 fs-2" />{" "}
      {I18n.t("views.leadster_ai.page.add_content.add_links.already_added")}
    </Modal.Body>
  );
};

export default UrlsUsed;
