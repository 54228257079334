import axios from 'axios';

export const simplePut = async (uri, params={}) => {
  await axios.put(uri, {
    authenticity_token: $('[name="csrf-token"]')[0].content,
    ...params,
  });
}
export const notificationClick = async (notification, displayType) => {
  await simplePut("notifications/click", { id: notification.id } );
  MixpanelService.track("notificantion_click", {
    template: notification.template,
    type: notification.type,
    displayType
  });
  location.href=notification.link
}

export const setModalOpening = (notificationId) => {
  simplePut('notifications/modal_open', { id: notificationId } );
}

export const setListNotificationsOpening = (dateTime) => {
  simplePut('notifications/list_open/', { datetime: dateTime });
};
