import React from "react";
import PropTypes from "prop-types";

const QualificationIcon = ({ score }) => {
  return (
    <>
      {score === null && (
        <img src="/images/lead_score/fire_off_deny.svg" width="17" />
      )}

      {score === 0 && (
        <img src="/images/lead_score/fire_off.svg" width="17" />
      )}

      {score >= 1 &&
        new Array(score).fill(
          <img src="/images/lead_score/fire_on.svg" width="17" className="me-1" />
        )}
    </>
  );
};

QualificationIcon.propTypes = {
  score: PropTypes.number,
};

export default QualificationIcon;
