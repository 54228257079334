import React, { useState } from "react";

import AvatarImages from "@/components/scripts/AvatarImages";
import ScriptAvatarPicker from "@/components/scripts/ScriptAvatarPicker";

const FormNewTestAb = ({ title, avatar, script, platform }) => {
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const [newTitle, setNewTitle] = useState(title);

  const handleChangeAvatar = (avatar) => {
    setSelectedAvatar(avatar);
  };

  return (
    <>
      <div className="mb-3">
        <input type="hidden" name="script_id" value={script} />
        <input type="hidden" name="platform" value={platform} />
        <label className="form-label required">
          {I18n.t("views.test_ab.new_test.avatar_photo.test_name")}
        </label>
        <input
          id="name"
          type="text"
          name="name"
          className="form-control form-control-md"
          placeholder={I18n.t("views.test_ab.new_test.avatar_photo.name_placeholder")}
        />
      </div>

      <div className="row text-center">
        <div className="col m-3">
          <span className="text-center fw-bold">{title}</span>
        </div>
      </div>

      <div className="row text-center">
        <div className="col m-3">
          <span
            className="avatar avatar-rounded avatar-md"
            style={{ backgroundImage: `url(${avatar})` }}
          >
            <span className="badge bg-success"></span>
          </span>
        </div>
      </div>

      <div className="row text-center">
        <div className="col mb-4 mt-3">
          <img width="64" src="https://cdn.leadster.com.br/leadster/app/image/arrow_down.png" />
        </div>
      </div>

      <div className="card mb-3">
        <div className="card-body text-center">
          <label className="form-label">
            {I18n.t("views.test_ab.new_test.avatar_photo.avatar_name")}
          </label>
          <input
            id="title"
            type="text"
            name="title"
            className="form-control form-control-md"
            placeholder={I18n.t("views.test_ab.new_test.avatar_photo.avatar_placeholder")}
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
        </div>
      </div>

      <div className="card mb-3">
        <div className="card-body text-center">
          <label className="form-label">
            {I18n.t("views.test_ab.new_test.avatar_photo.new_avatar")}
          </label>
          <div className="d-flex">
            <ScriptAvatarPicker
              onSuccess={(avatar) => handleChangeAvatar(avatar)}
            />
            <AvatarImages
              onChange={handleChangeAvatar}
              selectedAvatar={selectedAvatar}
            />
          </div>
        </div>

        <input id="avatar" name="avatar" type="hidden" value={selectedAvatar} />
      </div>
    </>
  );
};

export default FormNewTestAb;
