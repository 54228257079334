import styled from "styled-components";

export const Container = styled.div`
  .form-colorinput.custom-color {
    .form-colorinput-color {
      width: 4.5rem;
      text-align: right;
      display: flex;
      justify-content: end;
      align-items: center;
      padding: 0px 5px;

      &.checked:before {
        opacity: 1;
        left: -1.2rem;
      }
    }
  }

  .cancel-preview-chat {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
