import React, { useEffect } from "react";
import PropTypes from "prop-types";

import useActiveTab from "./hooks/useActiveTab";
import useScriptAnalyticsStore from './store';

import NavigationTabs from "./NavigationTabs";
import Conversion from "./TabContent/Conversion";
import QuestionQualification from "./TabContent/QuestionQualification";
import WhatsAppRoulette from "./TabContent/WhatsAppRoulette";

import { TabContainer } from "./styles";

const ScriptAnalytics = ({ scriptId, currentScriptVersionHash }) => {
  const [activeTab, setActiveTab, tabIsLoaded] = useActiveTab("conversion");

  const {
    scriptId: storeScriptId,
    setData: setStorageData,
  } = useScriptAnalyticsStore((state) => state);

  useEffect(() => {
    setStorageData({ scriptId, currentScriptVersionHash });
  }, [scriptId, currentScriptVersionHash])

  if (!storeScriptId) {
    return <></>;
  }

  return (
    <>
      <NavigationTabs activeTab={activeTab} setActiveTab={setActiveTab} />

      <div className="mt-4">
        <TabContainer active={activeTab == "conversion"}>
          {tabIsLoaded("conversion") && <Conversion />}
        </TabContainer>

        <TabContainer active={activeTab == "question_qualification"}>
          {tabIsLoaded("question_qualification") && <QuestionQualification />}
        </TabContainer>

        <TabContainer active={activeTab == "whatsapp"}>
          {tabIsLoaded("whatsapp") && <WhatsAppRoulette />}
        </TabContainer>
      </div>
    </>
  );
};

ScriptAnalytics.propTypes = {
  scriptId: PropTypes.number.isRequired,
  currentScriptVersionHash: PropTypes.string.isRequired,
};

export default ScriptAnalytics;
