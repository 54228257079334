import { can } from "@/lib/user-permission"

import dayjs from "@/lib/dayjs";
import parse from "html-react-parser";

const SumarizationInfo = ({ lastUpdated }) => {
  const hasPermission = can({ action: "manage", subject: "leadster_ai_plugin" });

  return (
    <div className="fs-4 fw-medium mb-3">
      {lastUpdated && parse(I18n.t("views.leadster_ai.page.analytics.dashboard.graph.updated", {
        date: dayjs(lastUpdated).format(I18n.t("js.formats.default.date")),
      }))}

      {(!lastUpdated && hasPermission) ? I18n.t("views.leadster_ai.page.analytics.dashboard.graph.processing") : null}

      {!hasPermission && (
        <div className="alert alert-warning mt-2">
          <div className="d-flex">
            <i className="ti ti-alert-triangle icon alert-icon" />
            <div className="text-body">
              {parse(I18n.t("views.leadster_ai.page.analytics.dashboard.graph.without_permission_html"))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SumarizationInfo;
