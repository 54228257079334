import { useState } from "react";
import useCustomComponents from "./hooks/useCustomComponents";

import CreatableSelect from "react-select/creatable";

import parse from "html-react-parser";

import customStyles from "./custom-styles";

const I18N_SCOPE = "views.manage_flows.conditional_block.creatable_select";
const MAX_OPTION_LENGTH = 200;

const MultipleOptionsInput = ({ values, allowMultiValues, onUpdateValue, loadDefaultOptions }) => {
  const { MultiValueContainer, Option } = useCustomComponents(values, allowMultiValues);

  const [defaultOptions, setDefaultOptions] = useState([]);

  const isOptionDisabled = allowMultiValues ? false : !!values.length
  const parsedValues = _.map(values, (item) => ({ label: item, value: item }));

  const onChange = (newValue) => {
    onUpdateValue(_.map(newValue, "value"));
  };

  const onInputChange = (inputValue) => {
    return inputValue.length <= MAX_OPTION_LENGTH
      ? inputValue
      : inputValue.substring(0, MAX_OPTION_LENGTH);
  };

  const handleLoadOptions = () => {
    const options = loadDefaultOptions().map((option) => {
      return { value: option, label: option };
    });

    setDefaultOptions(options);
  };

  return (
    <CreatableSelect
      value={parsedValues}
      options={defaultOptions}
      styles={customStyles}
      searchable
      isMulti
      isOptionDisabled={() => isOptionDisabled}
      isClearable={false}
      components={{ MultiValueContainer, Option }}
      onChange={onChange}
      onInputChange={onInputChange}
      onFocus={handleLoadOptions}
      placeholder={I18n.t(`${I18N_SCOPE}.placeholder`)}
      noOptionsMessage={() => {
        if (isOptionDisabled) {
          return parse(I18n.t(`${I18N_SCOPE}.one_option_allowed_operator_html`));
        }

        return I18n.t(`${I18N_SCOPE}.no_options`);
      }}
      formatCreateLabel={(value) => {
        if (isOptionDisabled) {
          return parse(I18n.t(`${I18N_SCOPE}.one_option_allowed_operator_html`));
        }

        return parse(I18n.t(`${I18N_SCOPE}.format_create_label`, { value }));
      }}
    />
  );
};

export default MultipleOptionsInput;
