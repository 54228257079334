import React from "react";

import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";

const Step1 = props => {
  // State Machine
  const { action, state } = useStateMachine(updateAction);

  // Form
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      allowed_events: state.data?.allowed_events
    }
  });
  const integrationType = watch("allowed_events");
  const onSubmit = data => {
    action(data);
    MixpanelService.track('integrations_second_step');
    props.history.push("./step3");
  };

  // Register Custom Fields
  React.useEffect(() => {
    register("name.allowed_events");
  }, [register]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            {I18n.t("views.integrations.step01.title")}
          </h3>
        </div>

        <div className="card-body text-center">
          <div className="row row-cards row-deck">
            <div className="col-md-6">
              <div className={`card ${integrationType === "lead:finish:full" ? 'card-active' : ''}`}>
                <div className="card-body text-center">
                  <i
                    className="ti ti-user-check mb-3"
                    style={{ fontSize: "24px", display: "block" }}
                  />
                  <div className="h3 text-uppercase fw-medium">
                    {I18n.t("views.integrations.step01.new_complete_lead.title")}
                  </div>

                  <p className="mb-3">
                    {I18n.t("views.integrations.step01.new_complete_lead.description")}
                  </p>
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      onClick={() => setValue("allowed_events", "lead:finish:full")}
                      disabled={integrationType === "lead:finish:full"}
                    >
                      {I18n.t("shared.actions.choose")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className={`card ${integrationType === "lead:finish:partial" ? 'card-active' : ''}`}>
                <div className="card-body text-center">
                  <i
                    className="ti ti-user-minus mb-3"
                    style={{ fontSize: "24px", display: "block" }}
                  />
                  <div className="h3 text-uppercase fw-medium">
                    {I18n.t("views.integrations.step01.new_incomplete_lead.title")}
                  </div>

                  <p className="mb-3">
                    {I18n.t("views.integrations.step01.new_incomplete_lead.description")}
                  </p>

                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      onClick={() => setValue("allowed_events", "lead:finish:partial")}
                      disabled={integrationType === "lead:finish:partial"}
                    >
                      {I18n.t("shared.actions.choose")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className={`card ${(integrationType === "*" || integrationType === "lead:finish") ? 'card-active' : ''}`}>
                <div className="card-body text-center">
                  <i
                    className="ti ti-user-plus mb-3"
                    style={{ fontSize: "24px", display: "block" }}
                  />
                  <div className="h3 text-uppercase fw-medium">
                    {I18n.t("views.integrations.step01.new_lead.title")}
                  </div>

                  <p className="mb-3">
                    {I18n.t("views.integrations.step01.new_lead.description")}
                  </p>

                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      onClick={() => setValue("allowed_events", "*")}
                      disabled={
                        integrationType === "*" ||
                        integrationType === "lead:finish"
                      }
                    >
                      {I18n.t("shared.actions.choose")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer bg-white text-end">
          <div className="d-flex">
            <button
              className="btn btn-primary ms-auto"
              type="submit"
              disabled={typeof integrationType === "undefined"}
            >
              {I18n.t("shared.actions.next")}
              <i className="ti ti-arrow-right ms-1"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default withRouter(Step1);
