import PropTypes from "prop-types";
import parse from "html-react-parser";

const RowError = ({ error }) => {
  return(
    <div className={"general-error"}>
      {parse(error.message_html)}

      <a className="btn btn-primary mt-3" href={error.help_url} rel={"noopener"}>
        {error.button_label}
      </a>
    </div>
  );
};

RowError.propTypes = {
  error: PropTypes.object.isRequired,
};

export default RowError;
