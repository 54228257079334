import Modal from "react-bootstrap/Modal";

import useCurrentUserStore from "@/store/currentUserStore";

import parse from "html-react-parser";

const TotalCharacters = ({ isLoaded, totalCharacters, characterLimit }) => {
  const { locale: userLocale } = useCurrentUserStore((state) => state.currentUser);
  if (isLoaded) return <></>;

  return (
    <Modal.Body className="d-flex justify-content-between fw-bold py-3 border-0" style={{ backgroundColor: "#CCE2FB" }}>
      {parse(
        I18n.t("views.leadster_ai.page.add_content.add_links.total_characters", {
          total: totalCharacters?.toLocaleString(userLocale, { minimumFractionDigits: 0 }),
          limit: characterLimit?.toLocaleString(userLocale, { minimumFractionDigits: 0 }),
        })
      )}
    </Modal.Body>
  );
};

export default TotalCharacters;
