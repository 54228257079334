import React from "react";

import useCurrentUserStore from "@/store/currentUserStore";

import parse from "html-react-parser";
import dayjs from "@/lib/dayjs";

const PaidTrialingAlert = () => {
  const { freeTrialDays, freeTrialEndAt } = useCurrentUserStore((state) => state.currentUser.plugins.calendarSchedule);

  return (
    <div className="alert alert-primary mb-3">
      <div className="d-flex align-items-center">
        <div>
          <i className="ti ti-info-circle icon alert-icon fs-1" />
        </div>

        <div>
          <div className="alert-title mb-2">
            {I18n.t("views.manage_flows.scheduler_block.meeting_scheduling")}
            <span className="badge bg-success-lt ms-2">
             {I18n.t("views.manage_flows.scheduler_block.free_trial_ongoing")}
            </span>
          </div>

          <p className="mb-1 text-muted">
            {parse(
              I18n.t("views.manage_flows.scheduler_block.paid_trialing_feature_information_html", {
                trial_days: freeTrialDays,
                trial_end_at: dayjs(freeTrialEndAt).format(I18n.t("js.formats.default.date")),
              })
            )}
          </p>

          <a
            className='d-block'
            href={I18n.t("shared.helpscout.google_calendar_scheduling")}
            target="_blank"
          >
            {I18n.t("views.manage_flows.scheduler_block.learn_more")} {" "}
            <i className="ti ti-external-link"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default PaidTrialingAlert;
