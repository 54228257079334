import React, { useState, useEffect } from "react";
import ScriptCardBlock from "../ScriptCardBlock";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';

const MarketingEmails = () => {
  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);
  const desktopContent = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.desktopContent);

  const convoScript = desktopContent;
  const [emails, setEmails] = useState(!!convoScript.to ? convoScript.to : ["teste@email.com"]);

  const setScriptValue = (key, value) => {
    dispatch({
      type: "SET_DESKTOP_AND_MOBILE_CONVOSCRIPT_VALUE",
      payload: { key, value },
    });
  };

  useEffect(() => {
    selectizeEmailField("#email_to", emails.join(","), (value) =>
      setScriptValue("to", value)
    );
  }, [emails]);

  return (
    <ScriptCardBlock
      title={I18n.t("views.manage_flows.script_editor.lead_receipt_emails")}
      icon="ti ti-mail"
      description={I18n.t("views.manage_flows.script_editor.enter_the_emails_you_would_like_to_receive_your_leads_in")}
    >
      <div>
        <label className="form-label">{I18n.t("shared.actions.email")}</label>

        <select id="email_to" multiple>
          {emails.map((email, index) => {
            return (
              <option value={email} key={`${email}${index}`}>
                {email}
              </option>
            );
          })}
        </select>
      </div>
    </ScriptCardBlock>
  );
};

export default MarketingEmails;
