import React, { useState, forwardRef } from 'react'
import PropTypes from "prop-types";

import ReactPortal from '@/components/ReactPortal';
import HelpPopover from "@/components/shared/HelpPopover";

import { selectAllLabels } from "@/components/scripts/ScriptEditor/scriptFieldLabels";
import { removeItemFromArray } from "../ScriptEditorParts/script-utils";

const MAX_INTRODUCTION_TEXT_LENGTH = 200;

const SettingsModal = forwardRef((
  {
    hasPermission,
    introductionText,
    setIntroductionText,
    label,
    setLabel,
    updateDefinition
  }, modalRef) => {
    const [tmpLabel, setTmpLabel] = useState(label);
    const [tmpIntroductionText, setTmpIntroductionText] = useState(introductionText || "");
    const [labelAlreadyExist, setLabelAlreadyExist] = useState(false);

    const saveSettings = () => {
      if (!hasPermission) return;
      if (isInvalidName()) return;

      const newName = tmpLabel.trim();

      setTmpLabel(newName);
      setLabel(newName);
      setIntroductionText(introductionText);

      updateDefinition({
        label: newName,
        introduction_text: tmpIntroductionText
      });

      $(modalRef.current).modal("hide");
    };

    const handleChangeIntroductionText = (e) => {
      if (!hasPermission) return;

      const inputValue = e.target.value;
      if (inputValue.length > MAX_INTRODUCTION_TEXT_LENGTH) return;

      setTmpIntroductionText(inputValue);
    }

    const handleUpdateLabel = (newLabel) => {
      if (!hasPermission) return;

      const replacedValue = newLabel.replace(/[^:\w\s]/gi, "");
      const allLabels = selectAllLabels();
      const previewLabels = removeItemFromArray(allLabels, label);

      setTmpLabel(replacedValue);
      setLabelAlreadyExist(previewLabels.includes(replacedValue.trim()));
    };

    const cancelAndClose = () => {
      setTmpLabel(label);
      setTmpIntroductionText(introductionText);
      setLabelAlreadyExist(false);

      $(modalRef.current).modal("hide");
    };

    const isEmptyLabel = () => {
      return tmpLabel.trim() === '';
    };

    const isInvalidName = () => {
      return isEmptyLabel() || labelAlreadyExist;
    };

    return (
      <ReactPortal>
        <div ref={modalRef} className="modal modal-blur fade" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="btn-close" onClick={cancelAndClose} />

                <h5 className="modal-title">
                  {I18n.t("views.manage_flows.scheduler_block.modal.settings")}
                </h5>
              </div>

              <div className="modal-body py-3">
                <div className="mb-3">
                  <label className="form-label fw-medium">
                    {I18n.t("views.manage_flows.script_editor.field_name")}
                    <HelpPopover
                      className="ms-2"
                      content={I18n.t("views.manage_flows.script_editor.this_field_does_not_accept_accents")}
                    />
                  </label>

                  <input
                    type="text"
                    className={`form-control ${isInvalidName() ? "is-invalid" : ""}`}
                    onChange={(e) => handleUpdateLabel(e.target.value)}
                    value={tmpLabel}
                    maxLength="50"
                    placeholder={I18n.t("views.manage_flows.script_editor.name_of_this_field")}
                  />

                  <div className="invalid-feedback">
                    {isEmptyLabel() && I18n.t("views.manage_flows.script_editor.field_name_cannot_be_blank")}
                    {labelAlreadyExist && I18n.t("views.manage_flows.script_editor.another_field_with_this_name_already_exists")}
                  </div>
                </div>

                <div>
                  <label className="form-label fw-medium">
                    {I18n.t("views.manage_flows.scheduler_block.modal.intro_text")}
                    <span className="form-label-description">{tmpIntroductionText.length} / {MAX_INTRODUCTION_TEXT_LENGTH}</span>
                  </label>

                  <textarea
                    className="form-control"
                    onChange={handleChangeIntroductionText}
                    value={tmpIntroductionText}
                    maxLength="255"
                    placeholder={I18n.t("views.manage_flows.scheduler_block.modal.intro_text_before_schedule")}
                  />

                  <small className="form-hint">{I18n.t("views.manage_flows.scheduler_block.modal.leave_blank")}</small>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn me-auto"
                  onClick={() => $(modalRef.current).modal("hide")}
                >
                  {I18n.t("shared.actions.cancel")}
                </button>

                <button
                  type="button"
                  className="btn btn-primary btn-rounded"
                  disabled={isInvalidName()}
                  onClick={saveSettings}
                >
                  {I18n.t("shared.actions.save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactPortal>
    )
  }
);

SettingsModal.propTypes = {
  label: PropTypes.string.isRequired,
  introductionText: PropTypes.string,
  hasPermission: PropTypes.bool.isRequired,
  setLabel: PropTypes.func.isRequired,
  updateDefinition: PropTypes.func.isRequired,
};

export default SettingsModal;
