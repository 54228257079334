import styled from 'styled-components';

export const Component = styled.div`
  padding: 10px;
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`

export const Avatar = styled.img`
  float: left;
  padding: 0;
  width: 3rem;
  height: 3rem;
  margin: 15px;
  border: 1px solid rgba(0,0,0,.1);
`

export const Date = styled.div`
  line-height: 1.5;
  font-size: 11px;
  color: #999;
`

export const Header = styled.div`
  position:inline-flex;
  flex-direction: row;
  align-items: center;
`

export const Body = styled.span`
  font-size: 13px;
  line-height: 1.5;
  padding-top: 12px;
  margin-bottom: 0px;
  display: inline;
`

export const Title = styled.label`
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 5px;
`

export const Decoration = styled.div`
  width: 3px;
  height: 100px;
  float: left;
`
