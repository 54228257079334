import React, { useState, useCallback, useEffect } from 'react';

import axios from "@/lib/axios";

const PrivacyPolicyInput = ({ scriptId, privacyPolicyUrl }) => {
  const [updateUrl] = useState(`/scripts/${scriptId}/privacy_policy_url`);
  const [link, setLink] = useState(privacyPolicyUrl || '');
  const [inputErrorClass, setInputErrorClass] = useState('');
  const [messageError] = useState(I18n.t("views.manage_flows.privacy_policy.link_provided_is_not_a_valid_url"));
  const [showError, setShowError] = useState(false);
  const [showTooltipError, setShowTooltipError] = useState(false);

  const disableErrorInput = useCallback(() => {
    setShowError(false);
    setInputErrorClass('');
  }, []);

  const enableErrorInput = useCallback(() => {
    setInputErrorClass('is-invalid');
    setShowError(true);
  }, []);

  const isValidOption = useCallback((string) => {
    return string === '' || isValidUrl(string);
  }, []);

  useEffect(() => {
    window.initJqueryTooltip();
  });

  useEffect(() => {
    setShowTooltipError(!isValidOption(link) || showError);
  }, [link, showError]);

  const isValidUrl = useCallback((string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return ['http:', 'https:'].includes(url.protocol);
  }, []);

  const updateLink = useCallback(async (value) => {
    disableErrorInput();
    setLink(value);

    if (isValidOption(value)) {
      try {
        await axios.patch(updateUrl, { url: value });
        window.neuroleadDefaultScript.privacyPolicyUrl = value;
        window.neurolead.reload();
      } catch (_) {
        enableErrorInput();
      }
    }
  }, []);

  return (
    <>
      <label className='form-label'>
        {I18n.t("views.manage_flows.privacy_policy.title")}

        {showTooltipError && (
          <span data-bs-toggle="tooltip" title={messageError}>
            <i className="ti ti-alert-triangle text-danger icon"></i>
          </span>
        )}
      </label>

      <input
        type="text"
        className={`form-control input-lg ${inputErrorClass}`}
        placeholder={I18n.t("views.manage_flows.privacy_policy.your_companys_privacy_policy")}
        value={link}
        onChange={(e) => updateLink(e.target.value)}
      />

      {showError && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          { messageError }
        </div>
      )}
    </>
  )
};

export default PrivacyPolicyInput;
