import { useState, useEffect, useRef } from "react";
import useUpdateEffect from "@/hooks/useUpdateEffect";

import AlertHandler from "./AlertHandler";
import AdvancedSettings from "./AdvancedSettings";
import SellersSelector from "./SellersSelector";
import SettingsModal from "./SettingsModal";
import AvailabilitySchedule from "@/components/scripts/AvailabilitySchedule";
import CloneBlockButton from "@/components/scripts/ScriptEditorParts/CloneBlockButton";
import DeleteButton from "@/components/scripts/ScriptEditorParts/DeleteButton";
import PermissionsGate from "@/components/permissions/PermissionsGate";
import HelpPopover from "@/components/shared/HelpPopover";
import RichTextEditor from "@/components/shared/RichTextEditor";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from "use-context-selector";
import { can } from "@/lib/user-permission";

import axios from "@/lib/axios";
import parse from "html-react-parser";

const PERMISSION_SCOPE = Object.freeze({
  action: "manage",
  subject: "calendar_schedule_plugin",
});

const ScriptBlockScheduler = (props) => {
  const hasPermission = can(PERMISSION_SCOPE);
  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);

  const [selectedRepresentativesIds, setSelectedRepresentativesIds] = useState(props.definition.representatives);
  const [subject, setSubject] = useState(props.definition.subject);
  const [emailField, setEmailField] = useState(props.definition.email_field);
  const [durationTime, setDurationTime] = useState(props.definition.duration_time);
  const [useCustomSchedule, setUseCustomSchedule] = useState(!!props.definition.use_custom_schedule);
  const [description, setDescription] = useState(props.definition.description);
  const [label, setLabel] = useState(props.definition.label);
  const [introductionText, setIntroductionText] = useState(props.definition.introduction_text);

  const modalSettingsRef = useRef();

  const scriptEmailFields = [];
  props.mapAttributesName(props.getFullTree(), scriptEmailFields, "email");
  props.mapAttributesName(props.getFullTree(), scriptEmailFields, "email_corp");

  useUpdateEffect(() => {
    updateDefinition({
      label,
      subject,
      description,
      representatives: selectedRepresentativesIds,
      email_field: emailField,
      duration_time: durationTime,
      use_custom_schedule: useCustomSchedule,
      introduction_text: introductionText,
    });
  }, [
    label,
    subject,
    description,
    selectedRepresentativesIds,
    emailField,
    durationTime,
    useCustomSchedule,
    introductionText,
  ]);

  useEffect(() => {
    findRepresentatives();
  }, [hasPermission]);

  useEffect(() => {
    if (emailField && !scriptEmailFields.includes(emailField)) {
      setEmailField("");
    }
  }, [scriptEmailFields]);

  const updateDefinition = (newDefinition) => {
    if (!hasPermission) return;

    const updatedDefinition = Object.assign(props.definition, newDefinition);

    props.setDefinition(updatedDefinition);
  };

  const showSettingsModal = () => {
    $(modalSettingsRef.current).modal("show");
  };

  const findRepresentatives = () => {
    axios.get("/representatives/list-of-connected").then((response) => {
      const representatives = response.data.representatives;
      const alreadyConnected = response.data.already_connected;

      dispatch({ type: "SET_REPRESENTATIVES", payload: representatives });
      dispatch({ type: "SET_ALLOW_CONNECT_GOOGLE", payload: !alreadyConnected });
    });
  };

  const handleChangeDescription = (description, editor) => {
    const maxDescriptionSize = 1000;
    const descriptionRawValue = editor.getContent({ format: "text" });

    if (descriptionRawValue.length <= maxDescriptionSize) {
      setDescription(description);
    }
  };

  return (
    <>
      <AlertHandler hasPermission={hasPermission} onDelete={props.onDelete} />

      <PermissionsGate scope={PERMISSION_SCOPE} disablePointerEvents>
        <div>
          <div className="row gx-2 mb-3">
            <div className="col">
              <div className="input-group input-group-flat">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{I18n.t("views.manage_flows.script_editor.click_here_to_edit_settings")}</Tooltip>}
                >
                  <button type="button" className="btn btn-success pe-1" onClick={showSettingsModal}>
                    <i className="ti ti-calendar-event me-2" />
                    {label}
                    <i className="ti ti-dots-vertical ms-2" />
                  </button>
                </OverlayTrigger>

                <input
                  type="text"
                  className="form-control"
                  value={subject}
                  maxLength={255}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder={I18n.t("views.manage_flows.scheduler_block.placeholder_subject")}
                />

                <SettingsModal
                  label={label}
                  introductionText={introductionText}
                  setIntroductionText={setIntroductionText}
                  setLabel={setLabel}
                  updateDefinition={updateDefinition}
                  hasPermission={hasPermission}
                  ref={modalSettingsRef}
                />
              </div>
            </div>

            <div className="col-auto">
              <CloneBlockButton onDuplicate={props.onDuplicate} enabled={hasPermission} />
            </div>

            <div className="col-auto">
              <DeleteButton onDelete={props.onDelete} permissionScope={PERMISSION_SCOPE} />
            </div>
          </div>

          <div>
            <SellersSelector
              selectedRepresentativesIds={selectedRepresentativesIds}
              updateDefinition={updateDefinition}
              findRepresentatives={findRepresentatives}
              setSelectedRepresentativesIds={setSelectedRepresentativesIds}
            />

            <div className="row g-3 mb-3">
              <div className="col-12 col-md-6 form-group">
                <div className="form-label">
                  {I18n.t("views.manage_flows.scheduler_block.lead_email_label")}
                  <HelpPopover
                    className="ms-2"
                    content={I18n.t("views.manage_flows.scheduler_block.lead_email_hint")}
                    placement="top"
                  />
                </div>

                <select
                  className="form-select"
                  value={emailField}
                  onChange={(e) => setEmailField(e.target.value)}
                >
                  <option value="">
                    {I18n.t(`views.manage_flows.scheduler_block.${scriptEmailFields.length ? "not_selected" : "empty_lead_email"}`)}
                  </option>

                  {scriptEmailFields.map((fieldName) => {
                    return <option value={fieldName} key={fieldName}>{fieldName}</option>;
                  })}
                </select>
              </div>

              <div className="form-group col-md-6">
                <div className="form-label">
                  {I18n.t("views.manage_flows.scheduler_block.duration_label")}
                  <HelpPopover
                    className="ms-2"
                    content={I18n.t("views.manage_flows.scheduler_block.duration_hint")}
                    placement="top"
                  />
                </div>

                <select
                  className="form-select"
                  value={durationTime}
                  onChange={(e) => setDurationTime(e.target.value)}
                >
                  {[15, 30, 45, 60, 90, 120, 150, 180].map((minutes) => {
                    return (
                      <option value={minutes} key={minutes}>
                        {I18n.t("views.manage_flows.scheduler_block.minutes", { count: minutes })}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="form-group mb-3">
              <div className="form-label">
                {I18n.t("views.manage_flows.scheduler_block.availability_label")}
              </div>

              <div className="form-selectgroup form-selectgroup-boxes d-flex">
                <label className="form-selectgroup-item flex-fill">
                  <input
                    type="radio"
                    className="form-selectgroup-input"
                    checked={!useCustomSchedule}
                    onChange={(e) => setUseCustomSchedule(!e.target.checked)}
                  />

                  <div className="form-selectgroup-label d-flex align-items-center p-3">
                    <div className="me-3">
                      <span className="form-selectgroup-check"></span>
                    </div>

                    <div>
                      {parse(I18n.t("views.manage_flows.scheduler_block.use_existing_schedule"))}
                      <div className="text-muted">
                        {I18n.t("views.manage_flows.scheduler_block.use_existing_schedule_hint")}
                      </div>
                    </div>
                  </div>
                </label>

                <label className="form-selectgroup-item flex-fill">
                  <input
                    type="radio"
                    className="form-selectgroup-input"
                    checked={useCustomSchedule}
                    onChange={(e) => setUseCustomSchedule(e.target.checked)}
                  />

                  <div className="form-selectgroup-label d-flex align-items-center p-3">
                    <div className="me-3">
                      <span className="form-selectgroup-check"></span>
                    </div>
                    <div>
                      {parse(I18n.t("views.manage_flows.scheduler_block.use_custom_schedule"))}
                      <div className="text-muted">
                        {I18n.t("views.manage_flows.scheduler_block.use_custom_schedule_hint")}
                      </div>
                    </div>
                  </div>
                </label>
              </div>

              {useCustomSchedule && (
                <AvailabilitySchedule
                  initialSchedules={props.definition.schedules || []}
                  setDefinition={(schedules) => updateDefinition({ schedules })}
                />
              )}
            </div>

            <div className="form-group mb-3">
              <div className="form-label">
                {I18n.t("views.manage_flows.scheduler_block.description_label")}
                <HelpPopover
                  className="ms-2"
                  content={I18n.t("views.manage_flows.scheduler_block.description_hint")}
                  placement="top"
                />
              </div>

              <RichTextEditor
                value={description}
                disabled={!hasPermission}
                inline={false}
                init={{
                  height: 150,
                  menubar: false,
                  placeholder: I18n.t("views.manage_flows.scheduler_block.description_placeholder"),
                  toolbar: "bold italic underline",
                  force_br_newlines: false,
                  forced_root_block: "div",
                }}
                onEditorChange={handleChangeDescription}
              />
            </div>

            <AdvancedSettings
              definition={props.definition}
              updateDefinition={updateDefinition}
            />
          </div>
        </div>
      </PermissionsGate>
    </>
  );
};

export default ScriptBlockScheduler;
