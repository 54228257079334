import styled from "styled-components";

export const MainTable = styled.table.attrs({
  className: "table table-bordered table-vcenter",
})`
  thead {
    tr {
      border: none;
    }

    th {
      background-color: var(--tblr-white);
      color: var(--tblr-body-color);
      text-transform: none;
      border-left: 1px solid rgba(126, 136, 150, 0.16);
      border-right: 1px solid rgba(126, 136, 150, 0.16);
      font-size: 0.775rem !important;

      ${({ isEmptyResult }) => isEmptyResult &&`
        &:first-of-type{
          border-top-left-radius: 8px;
          border: none;
        }
      `}

      ${({ isEmptyResult }) => !isEmptyResult &&`
        &:first-of-type {
          background-color: var(--tblr-body-bg) !important;
          border: none !important;
        }

        &:nth-child(2) {
          border-top-left-radius: 8px;
          border: none;
        }
      `}

      &:last-child {
        border-top-right-radius: 8px;
        border: none;
      }
    }
  }

  tbody {
    background-color: var(--tblr-white);

    tr {
      &.empty-table-row td {
        border: none;

        &.lead {
          background-color: var(--tblr-white) !important;
          height: 250px;
        }

        p {
          font-size: 14px;
          margin: 0;
        }

        .domain-fix i {
          color: var(--tblr-link-hover-color);
        }
      }

      &:not(.empty-table-row) {
        &:nth-child(odd) {
          background-color: var(--tblr-body-bg);
        }

        &:first-of-type {
          border: 1px solid rgba(126, 136, 150, 0.16);
        }

        &:nth-child(1) td:first-child {
          background-color: #61adfd !important;
        }

        &:nth-child(2) td:first-child {
          background-color: #92c5fc !important;
        }

        &:nth-child(3) td:first-child {
          background-color: #c3defb !important;
        }

        &:nth-child(4) td:first-child {
          background-color: #dbeafb !important;
        }

        &:nth-child(5) td:first-child {
          background-color: #eff7fe !important;
        }
      }

      td.truncate-url {
        white-space: unset;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 285px;
        overflow-wrap: break-word;
        font-size: 0.9rem;
      }
    }
  }
`;
