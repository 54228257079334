import { useEffect, useState } from "react";
import useCurrentUserStore from "@/store/currentUserStore";
import useAlertDefault from "@/components/shared/AlertDefault/hooks/useAlertDefault";

import { captureException } from "@sentry/react";
import { buildWhatsAppLink } from "@/utils/whatsapp_helpers";

import axios from "@/lib/axios";

const useLogic = () => {
  const { firstName } = useCurrentUserStore((state) => state.currentUser);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { showAlert } = useAlertDefault((state) => state);

  const getDatas = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get("/upsell/plans");

      if (!data?.second_plan_suggestion.plans.length || !data?.second_plan_suggestion.plans.length) {
        return dispatchMessageError();
      }

      setData(data);

    } catch (err) {
      captureException(err);
      dispatchMessageError();
    };
    setIsLoading(false);
  };

  const dispatchMessageError = () => {
    showAlert({
      icon: "ERROR",
      title: I18n.t("views.upsell.stepper.step_one.alert_errors.title"),
      description: I18n.t("views.upsell.stepper.step_one.alert_errors.description_html"),
      buttons: [
        {
          text: I18n.t("views.upsell.stepper.step_one.alert_errors.btn_talk_whatsapp"),
          action: () => {
            window.open(buildWhatsAppLink({
              number: '41991074307',
              message: I18n.t("views.upsell.stepper.step_two.validates.alert_3.message", {
                user_name: firstName
              }),
              mobileDevice: navigator.userAgentData !== undefined && navigator.userAgentData.mobile
            }))
          }
        }
      ],
      closeButton: {
        text: I18n.t("shared.actions.cancel"),
      }
    });
  }

  useEffect(() => {
    getDatas();
  }, []);

  return {
    data: {
      isLoading,
      currentPlan: data?.current_plan,
      thirdPlan: data?.second_plan_suggestion,
      secondPlan: data?.first_plans_suggestion,
      overdueInvoice: data?.overdue_invoice > 0,
    },
    methods: {
      dispatchMessageError
    }
  };
};

export default useLogic;
