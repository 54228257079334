import React, {useState, useEffect} from 'react';
import {
  MobileBackgroud,
  Component,
  Header,
  Icon,
  Body,
  Footer,
  Exit,
  CallToActionBotton,
} from './styles';

import htmlParser from 'html-react-parser';

import {notificationClick, setModalOpening} from '../notification_events';

const NotificationModal = ({notification}) => {
  const [showBody, setShowBody] = useState(false);
  const [showSelf, setShowSelf] = useState(true);
  const [heartSelection, setHeartSelection] = useState(false);
  const [dislikeSelection, setDislikeSelection] = useState(false);

  const togleHeart = (notification) => {
    setHeartSelection(!heartSelection);
    MixpanelService.track("notificantion_like", {
      template: notification.template,
      type: notification.type,
    });
  }

  const togleDislike = (notification) => {
    setDislikeSelection(!dislikeSelection);
    MixpanelService.track("notificantion_dislike", {
      template: notification.template,
      type: notification.type,
    });
  }

  useEffect(() => {
    let timer = setTimeout(() => {
      setShowBody(true);
      setModalOpening(notification.id);
      MixpanelService.track("notificantion_modal_opening", {
        template: notification.template,
        type: notification.type,
      });
    }, 2000);

    return (() => clearTimeout(timer));
  }, [] );

  return (
    <div>
      {showSelf && (
        <MobileBackgroud onClick={
          () => setShowSelf(false)
        }></MobileBackgroud>
      )}
      <Component style={{display: (showSelf ? "block" : "none")}}>
        <Header>
          <div style={{alignItems: "center", float: "left"}}>
            <Icon className="rounded-circle" src={notification.icon}></Icon>
            <div style={{display: "inline-block"}} >
              <div style={{fontSize: "18px"}}>{notification.title}</div>
              <div style={{fontSize: "13px"}}>{notification.createdAt}</div>
            </div>
          </div>

          <Exit
            src='/images/notification/white_exit.svg'
            style={{display: (showBody ? "block" : "none")}}
            onClick={() => setShowSelf(false)}
          ></Exit>
        </Header>

        <Body style={{ display: (showBody ? "block" : "none") }}>
          {htmlParser(notification.modalText)}
          <CallToActionBotton
            className="text-uppercase"
            onClick={() => notificationClick(notification, "modal")}
          >
            {notification.callToAction}<img style={{
              height: "12px",
              marginLeft: "5px",
              marginbottom: "4px",
            }} src='/images/notification/botton_arrow.svg' />
          </CallToActionBotton>
        </Body>

        <Footer style={{ display: (showBody ? "block" : "none") }}>
          <strong>Gostou da dica?</strong>
          <img
            style={{paddingLeft:"20px" ,height: "20px"}}
            src={heartSelection ? '/images/notification/blue_heart.svg' : '/images/notification/gray_heart.svg'}
            onClick={() => togleHeart(notification)}
          />
          <img
            style={{paddingLeft:"10px", height: "20px"}}
            src={dislikeSelection ? '/images/notification/blue_dislike.svg' : '/images/notification/gray_dislike.svg'}
            onClick={() => togleDislike(notification)}
          />
        </Footer>
      </Component>
    </div>
  );
}

export default NotificationModal;
