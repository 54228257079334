import React from "react";

const RowEmpty = () => {
  return(
    <tr className="empty-table-row">
      <td colSpan={5}>
        <div className={"domain-fix"}>
          <p>
            <i className="fa fa-pencil-square-o"/> {I18n.t("views.page_rank.rank_table.row_empty.correct_url")}
            <a href="/users/edit#tab-company"> {I18n.t("views.page_rank.rank_table.row_empty.alter_now")}</a>
          </p>
        </div>
      </td>
    </tr>
  );
}

export default RowEmpty;
