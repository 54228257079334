import parse from "html-react-parser";

const NoActivationAlert = () => {
  return (
    <div className="alert alert-warning mb-3">
      <div className="d-flex align-items-center">
        <i className="ti ti-exclamation-circle icon alert-icon" />

        <div className="text-body">
          {parse(I18n.t("views.leadster_ai.page.activate_assistant.no_activation_alert_html"))}
        </div>
      </div>
    </div>
  );
};

export default NoActivationAlert;
