import { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";

import { useAssistantContext } from "@/contexts/AssistantContext";

import axios from "@/lib/axios";

const useSetEditAssistant = () => {
  const { assistant, setAssistant } = useAssistantContext();
  let { assistantId } = useParams();

  const isMissingAsssistantEdit = assistantId && !assistant;
  const [isLoadingAssistant, setIsLoadingAssistant] = useState(isMissingAsssistantEdit);

  const history = useHistory();

  const findAssistantEdit = async () => {
    try {
      const response = await axios.get(`/ai/assistants/${assistantId}`);
      setAssistant(response.data.assistant);
    } catch (error) {
      toastr.error(I18n.t("request_feedback_alert.error.message"))
      history.push("/ai/leadster");
    }

    setIsLoadingAssistant(false);
  };

  useEffect(() => {
    if (isMissingAsssistantEdit) {
      findAssistantEdit();
    }
  }, []);

  return {
    isLoadingAssistant,
  };
};

export default useSetEditAssistant;
