import React from "react";

import ScriptCardBlock from "../ScriptCardBlock";
import ScriptCardFooter from "../ScriptCardFooter";
import PrivacyPolicyInput from "../../PrivacyPolicyInput";

import parse from 'html-react-parser';

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';

const PrivacyPolicy = () => {
  const scriptId = useContextSelector(ScriptContext, ({ state }) => state.id);
  const privacyPolicyUrl = useContextSelector(ScriptContext, ({ state }) => state.privacyPolicyUrl);

  const cardBody = {
    title: `${I18n.t("views.manage_flows.privacy_policy.subtitle")}`,
    icon: `ti ti-clipboard-check`,
    description: `${I18n.t("views.manage_flows.privacy_policy.privacy_policy_description")}`,
  };

  const cardFooter = {
    title: `${I18n.t("views.manage_flows.privacy_policy.security_in_the_generation_of_your_leads")}`,
    description: `${I18n.t("views.manage_flows.privacy_policy.security_in_the_generation_of_your_leads_description")}`
  };

  return (
    <>
      <ScriptCardBlock
        title={cardBody.title}
        icon={cardBody.icon}
        description={cardBody.description}
      >
        <PrivacyPolicyInput
          privacyPolicyUrl={privacyPolicyUrl}
          scriptId={scriptId}
        />
      </ScriptCardBlock>
      <ScriptCardFooter title={cardFooter.title}>
        <p className="mb-0 tracking-wide">{parse(cardFooter.description)}</p>
      </ScriptCardFooter>
    </>
  );
};

export default PrivacyPolicy;
