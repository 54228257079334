import styled from "styled-components";

export const GoogleIconContainer = styled.div`
  width: 33px;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  white-space: nowrap;
`;
