import { useState, useEffect } from "react";

import Confetti from "react-confetti";
import axios from "axios";
import parse from "html-react-parser";

import useCurrentUserStore from "@/store/currentUserStore";

import * as Styled from "./styles";

const LeadsCelebrationModal = ({ leads, commission }) => {
  const { isNational } = useCurrentUserStore((state) => state.currentUser);

  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    axios.get("/leads_celebration_modal_opening");
    MixpanelService.track("leads_celebration_modal_opening", { leads });
  }, []);

  const getLeadsImage = () => {
    switch (leads) {
      case 50:
        return "50leads.png";
      case 100:
        return "100leads.png";
      default:
        return "200leads.png";
    }
  };

  const getBodyMensage = () => {
    switch (leads) {
      case 50:
        return parse(I18n.t("customer_success.leads_celebration.you_conquered_your_first_html"))
      case 100:
        return parse(I18n.t("customer_success.leads_celebration.you_just_reached_html"))
      default:
        return parse(I18n.t("customer_success.leads_celebration.you_just_hit_that_amazing_mark_html", { leads }))
    }
  };

  const handleClickReferral = () => {
    MixpanelService.track("leads_celebration_modal_click", { leads }, true);
    location.href = "/referral";
  };

  return (
    <Styled.Modal className="modal modal-blur fade show" show={showModal}>
      <Confetti />

      <div className="modal-dialog modal-dialog-centered">
        <Styled.ModalContent className="modal-content">
          <Styled.ModalHeader className="modal-header">
            <Styled.BtnClose type="button" onClick={closeModal} className="btn-close" />
          </Styled.ModalHeader>

          <Styled.ModalBody className="modal-body">
            <Styled.ModalBodyText>
              <div className="text-primary fs-2 fw-bold mb-2">
                {I18n.t("customer_success.leads_celebration.congratulations")}
              </div>

              <p className="lead mb-0">
                {getBodyMensage()}
              </p>
            </Styled.ModalBodyText>

            <Styled.ModalBodyImage src={`/images/celebration/${getLeadsImage()}`} />
          </Styled.ModalBody>

          {isNational ? (
            <Styled.ModalFooter className="modal-footer">
              <Styled.ModalFooterTitle>
                <div className="text-white fs-3 fw-medium">
                  {I18n.t("customer_success.leads_celebration.earn_money_by_referring_leadster", { money: commission })}
                </div>
              </Styled.ModalFooterTitle>

              <button
                type="button"
                onClick={handleClickReferral}
                className="btn btn-primary"
              >
                {I18n.t("customer_success.leads_celebration.discover_the_program")}
              </button>
            </Styled.ModalFooter>
          ) : null}
        </Styled.ModalContent>
      </div>
    </Styled.Modal>
  );
};

export default LeadsCelebrationModal;
