import { useState } from "react";

import Modal from "react-bootstrap/Modal";

import ReactPortal from "@/components/ReactPortal";

const ConfirmExclusionButton = ({ countSelectedItems, deleteSelection }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleConfirmExclusion = async () => {
    setIsLoading(true);

    try {
      await deleteSelection();
      handleCloseModal();
    } catch (error) {}

    setIsLoading(false);
  };

  return (
    <>
      <button type="button" className="btn btn-outline-danger ms-2" onClick={handleShowModal}>
        <i className="ti ti-trash icon" />
        {I18n.t("views.leadster_ai.page.add_content.content_list.multi_selection.exclude", {
          count: countSelectedItems,
        })}
      </button>

      <ReactPortal>
        <Modal
          show={showModal}
          className="modal-blur"
          backdrop="static"
          size="sm"
          centered
          onHide={handleCloseModal}
        >
          <div className="modal-status bg-danger" />

          <Modal.Body className="text-center py-4">
            <div className="mb-2">
              <i className="ti ti-alert-triangle icon text-danger icon-lg" />
            </div>

            {isLoading ? (
              <>
                <div className="mb-3 fw-medium">
                  {I18n.t("views.leadster_ai.page.add_content.content_list.multi_selection.excluding")}
                </div>
                <div className="progress progress-sm">
                  <div className="progress-bar progress-bar-indeterminate" />
                </div>
              </>
            ) : (
              <>
                <div className="h3">
                  {I18n.t("views.leadster_ai.page.add_content.content_list.multi_selection.confirm_title", {
                    count: countSelectedItems
                  })}
                </div>
                <div className="text-secondary">
                  {I18n.t("views.leadster_ai.page.add_content.content_list.multi_selection.confirm_description")}
                </div>
              </>
            )}
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-between">
            <button type="button" className="btn" onClick={handleCloseModal} disabled={isLoading}>
              {I18n.t("general.actions.cancel")}
            </button>

            <button
              type="button"
              className="btn btn-danger"
              onClick={handleConfirmExclusion}
              disabled={isLoading}
            >
              {I18n.t("general.actions.exclude")}
            </button>
          </Modal.Footer>
        </Modal>
      </ReactPortal>
    </>
  );
};

export default ConfirmExclusionButton;
