import { useState } from "react";

import GenerateLinkModal from "@/components/invite/GenerateLinkModal";

const GenerateLinkBtn = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);

  return(
    <>
      <button type="button" className="btn btn-primary" onClick={handleOpenModal}>
        Gerar link e cupom de desconto
      </button>

      <GenerateLinkModal
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};


export default GenerateLinkBtn;
