import { useState, useCallback } from "react";
import PropTypes from "prop-types";

import ModalCreditCard from "@/components/billing/Modals/ModalCreditCard";

const ButtonUpdateCc = ({ title }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return(
    <>
      <button className="btn btn-light" onClick={handleOpenModal}>
        <i className="ti ti-credit-card"/> &nbsp; {title}
      </button>

      <ModalCreditCard
        title={I18n.t("billing.modal.update_your_credit_card_details.title")}
        show={showModal}
        onClose={handleCloseModal}
      />
    </>
  );
};

ButtonUpdateCc.propTypes = {
  title: PropTypes.string.isRequired
}

export default ButtonUpdateCc;
