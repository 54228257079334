import React from "react";

import * as S from "./styles";

const InfoCard = ({ children, ...props }) => {
  return <S.Container {...props}>{children}</S.Container>;
}

InfoCard.Icon = function InfoCardMessage({ children, ...props }) {
  return <S.Icon {...props}>{children} </S.Icon>;
};

InfoCard.Message = function InfoCardMessage({ children, ...props }) {
  return <S.Message {...props}>{children} </S.Message>;
};

export default InfoCard;
