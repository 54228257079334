export default [
  {
    title: I18n.t("guide_modal.leadster_ai.onboard.title_01"),
    content: I18n.t("guide_modal.leadster_ai.onboard.subtitle_01_html"),
    media: {
      type: I18n.t("guide_modal.leadster_ai.onboard.type_01"),
      url: I18n.t("guide_modal.leadster_ai.onboard.media_url_01"),
    },
  },
  {
    title: I18n.t("guide_modal.leadster_ai.onboard.title_02"),
    content: I18n.t("guide_modal.leadster_ai.onboard.subtitle_02_html"),
    media: {
      type: I18n.t("guide_modal.leadster_ai.onboard.type_02"),
      url: I18n.t("guide_modal.leadster_ai.onboard.media_url_02"),
    },
  },
  {
    title: I18n.t("guide_modal.leadster_ai.onboard.title_03"),
    content: I18n.t("guide_modal.leadster_ai.onboard.subtitle_03_html"),
    media: {
      type: I18n.t("guide_modal.leadster_ai.onboard.type_03"),
      url: I18n.t("guide_modal.leadster_ai.onboard.media_url_03"),
    },
  },
  {
    title: I18n.t("guide_modal.leadster_ai.onboard.title_04"),
    content: I18n.t("guide_modal.leadster_ai.onboard.subtitle_04_html"),
    media: {
      type: I18n.t("guide_modal.leadster_ai.onboard.type_04"),
      url: I18n.t("guide_modal.leadster_ai.onboard.media_url_04"),
    },
  },
];
