import React, { useState } from 'react';

import { Icon, Item } from './styles';

const CheckedItem = ({ item }) => {
  return (
    <Item>
      <Icon className="fe fe-check-circle" />
      {item.label}
    </Item>
  );
};

export default CheckedItem;
