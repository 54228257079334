const LineChartOptions = {
  chart: {
    toolbar: { show: false },
    sparkline: {
      enabled: true
    }
  },
  grid: {
    show: false,
    padding: {
      top: 10,
      bottom: -3,
      left: -1,
      right: -1
    }
  },
  markers: {
    size: 3,
    strokeWidth: 0,
    hover: {
      size: 4,
    }
  },
  stroke: {
    width: 2.5,
    curve: 'straight'
  },
  legend: {
    show: false
  },
  xaxis: {
    labels: {
      show: false
    }
  },
  yaxis: {
    labels: {
      show: false,
      formatter: function (val) {
        return parseInt(val);
      },
    }
  },
  colors: ['#1976a2', '#ff7b04', '#00a532', '#975fbc', '#e00122'],
  fill: {
    colors: ['#1976a2', '#ff7b04', '#00a532', '#975fbc', '#e00122'],
  },
};

export default LineChartOptions;
