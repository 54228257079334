import React, { useState, useRef } from "react";

import axios from "@/lib/axios";
import swal from "sweetalert";

const EmailSender = () => {
  const [email, setEmail] = useState("");
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const mailInput = useRef();

  const checkIsValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSendEmail = (e) => {
    if (!checkIsValidEmail(email)) {
      mailInput.current.focus();
      setIsInvalidEmail(true);
      return;
    }

    axios
      .post("/implementation/send_activation_email", { email })
      .then((response) => swal("", response.data.message, "success"))
      .catch(() => swal("", I18n.t("views.onboard.hello.notification.error_while_sending_the_email"), "error"))
      .finally(() => {
        setIsInvalidEmail(false);
        setEmail("");
    });
  };

  return (
    <div className='mb-3'>
      <div className="row g-2 mb-2">
        <div className="input-icon col">
          <span className="input-icon-addon">
            <i className="ti ti-mail"></i>
          </span>

          <input
            type="email"
            ref={mailInput}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            className={`form-control form-control-lg ${
              isInvalidEmail ? "is-invalid" : ""
            }`}
            placeholder={I18n.t("views.onboard.partials.inform_your_email")}
          />
        </div>

        <button
          type="button"
          className="btn btn-primary btn-lg col-auto"
          onClick={handleSendEmail}
          disabled={!email.length}
        >
          <i className="ti ti-send me-2" />
          {I18n.t("general.actions.send")}
        </button>
      </div>

      {isInvalidEmail && (
        <div className="text-danger fs-4">
          {I18n.t("views.onboard.hello.notification.please_provide_a_valid_email")}
        </div>
      )}
    </div>
  );
};

export default EmailSender;
