export default {
  conversion: {
    icon: "chart-dots-2",
    label: I18n.t("views.analytics.conversion_analysis"),
  },
  question_qualification: {
    icon: "chart-pie",
    label: I18n.t("views.analytics.option_list_fields"),
  },
  whatsapp: {
    icon: "brand-whatsapp",
    label:  I18n.t("views.analytics.whatsapp_redirection"),
  },
};
