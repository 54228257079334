import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { captureException } from "@sentry/react";
import axios from "@/lib/axios";

import { buildWhatsAppLink } from "@/utils/whatsapp_helpers";
import { useUpsellContext } from "../../context";
import useAlertDefault from "@/components/shared/AlertDefault/hooks/useAlertDefault";
import useCurrentUserStore from "@/store/currentUserStore";

const useLogic = () => {
  const { firstName } = useCurrentUserStore((state) => state.currentUser);
  const history = useHistory();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { progressData, saveProgressData, resetPaymentProgressData } = useUpsellContext();
  const { showAlert } = useAlertDefault((state) => state);

  const getDatas = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.post("/upsell/summary-upsell-calculation",
        { plan_id: progressData.idPlan }
      );
      setData(data);
    } catch (err) {
      captureException(err);

      showAlert({
        icon: "ERROR",
        title: I18n.t("views.upsell.stepper.step_two.validates.alert_3.title"),
        description: I18n.t("views.upsell.stepper.step_two.validates.alert_3.description_html"),
        buttons: [
          {
            text: I18n.t("views.upsell.stepper.step_two.validates.alert_3.btn_whats"),
            action: () => {
              window.open(buildWhatsAppLink({
                number: '41991074307',
                message: I18n.t("views.upsell.stepper.step_two.validates.alert_3.message", {
                  user_name: firstName
                }),
                mobileDevice: navigator.userAgentData !== undefined && navigator.userAgentData.mobile
              }))
            }
          }
        ],
        closeButton: {
          text: I18n.t("shared.actions.cancel"),
          action: () => {
            history.push("/upsell/")
            resetPaymentProgressData();
          }
        }
      });
    };

    setIsLoading(false);
  }

  useEffect(() => {
    getDatas();
  }, []);

  useEffect(() => {
    saveProgressData(prev => ({
      ...prev,
      isPeriodPlanChanged: data?.current_plan.period !== data?.new_plan.period,
      currentPlan: data?.current_plan,
      selectedPlan: data?.new_plan,
    }));
  }, [data]);

  return {
    data: {
      data,
      selectedPlan: data?.new_plan,
      currentPlan: data?.current_plan,
      installments: data?.installments,
      daysUntilEndOfTheCurrentPlan: data?.remaining_days,
      isLoading,
    },
  };
};

export default useLogic;
