import React from "react";

import { ImageStep, CardContainer } from "./styles";

const Third = () => {
  return (
    <div
      className="card card-sm shadow-sm col"
      data-bs-toggle="popover"
      data-bs-placement="bottom"
      data-bs-html="true"
      data-bs-trigger="hover"
      data-bs-content="<div class='fw-medium'>
        Ao ativar o código de instalação, o seu botão estará habilitado para funcionar
        de modo correto em seu site, <strong>atraindo e gerando muitos leads para o seu negócio.</strong>
      </div>"
    >
      <CardContainer className="d-flex h-100">
        <div className="col-7 col-lg-6 p-3 pe-1 h-100">
          <span className="bg-dark text-white avatar avatar-rounded shadow mb-3">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-tabler icon-tabler-lock" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.75" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <rect x="5" y="11" width="14" height="10" rx="2"></rect>
              <circle cx="12" cy="16" r="1"></circle>
              <path d="M8 11v-4a4 4 0 0 1 8 0v4"></path>
            </svg>
          </span>

          <div className="fs-3 fw-bolder text-nowrap">
            Conquiste mais leads
          </div>
        </div>

        <ImageStep className="col-5 col-lg-6" />
      </CardContainer>
    </div>
  );
};

export default Third;
