import React from "react";

import useCurrentUserStore from "@/store/currentUserStore";

import { ImageStep, CardContainer } from "./styles";

const First = () => {
  const { applicationName } = useCurrentUserStore((state) => state.currentUser);
  return (
    <div
      className="card card-sm shadow-sm col"
      data-bs-toggle="popover"
      data-bs-placement="bottom"
      data-bs-html="true"
      data-bs-trigger="hover"
      data-bs-content={`<div class="fw-medium">
        ${I18n.t("views.onboard.partials.steps.first.you_created_your_account", {application_name: applicationName})}
      </div>`}
    >
      <CardContainer className="d-flex h-100">
        <div className="col-6 p-3">
          <span className="bg-success text-white avatar avatar-rounded shadow shadow mb-3">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M5 12l5 5l10 -10"></path>
            </svg>
          </span>

          <div className="fs-3 fw-bold">
            {I18n.t("views.onboard.partials.steps.first.account_created")}
          </div>
        </div>

        <ImageStep className="col-5 col-lg-6" />
      </CardContainer>
    </div>
  );
};

export default First;
