import React from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";

const axisSharedOptions = {
  labels: {
    show: false,
  }
}

const fontStyleSharedOptions = {
  style: {
    fontFamily: '"DM Sans", sans- serif'
  }
}

const LinearChart = ({ title, description, data, color, height }) => {
  const { categories, dataSet } = data;

  const lineChartOptions = {
    options: {
      title: {
        text: title,
        style: {
          ...fontStyleSharedOptions.style,
          fontSize: "24px",
        }
      },
      subtitle: {
        text: description,
        style: {
          ...fontStyleSharedOptions.style,
          fontSize: "16px",
        }
      },
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      colors: color ? [color] : undefined,
      fill: {
        width: "1",
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        ...axisSharedOptions,
      },
      yaxis: {
        ...axisSharedOptions,
      },
      markers: {
        size: 6,
        strokeWidth: 0,
      }
    },
    series: [
      {
        name: description,
        data: dataSet,
      },
    ]
  };

  return (
    <Card >
      <Chart
        className="mt-2 me-2"
        options={lineChartOptions.options}
        series={lineChartOptions.series}
        type="line"
        width="100%"
        height={height}
      />
    </Card>
  );
}

export default LinearChart;
