import { components } from "react-select";

const useCustomComponents = (values, allowMultiValues) => {
  const MultiValueContainer = ({ ...props }) => {
    const isConnectorVisible = values.length > 1 && values.at(0) !== props.data.value;

    return (
      <div className="d-flex mt-1">
        {isConnectorVisible && (
          <span className="badge bg-gray text-lowercase px-1 mx-1">
            {I18n.t("views.manage_flows.conditional_block.operators.or")}
          </span>
        )}

        <components.MultiValueContainer {...props}>
          {props.children}
        </components.MultiValueContainer>
      </div>
    );
  };

  const Option = (props) => {
    const { data, children } = props;
    const isNewOption = data["__isNew__"];
    const isOptionDisabled = allowMultiValues ? false : !!values.length

    return (
      <components.Option {...props}>
        {isNewOption ? children : (
          <div className="d-flex justify-content-between">
            <div className="fw-bold">
              <span className="me-2">+</span>
              {children}
            </div>

            <div className="text-muted fw-medium small">
              {isOptionDisabled
                ? I18n.t("views.manage_flows.conditional_block.creatable_select.one_option_allowed")
                : I18n.t("views.manage_flows.conditional_block.creatable_select.click_to_add")
              }
            </div>
          </div>
        )}
      </components.Option>
    );
  };

  return { MultiValueContainer, Option };
}

export default useCustomComponents;
