import React from "react";

import FreeTrialingAlert from "./Alerts/FreeTrialingAlert";
import StartTrialAlert from './Alerts/StartTrialAlert';
import PaidTrialingAlert from "./Alerts/PaidTrialingAlert";
import TrialEndedAlert from "./Alerts/TrialEndedAlert";
import NotAllowedAlert from "./Alerts/NotAllowedAlert";

import useCurrentUserStore from "@/store/currentUserStore";

const AlertHandler = ({ hasPermission, onDelete }) => {
  const currentUser = useCurrentUserStore((state) => state.currentUser);
  const { isPurchased, freeTrialStarted, freeTrialEnded } = currentUser.plugins.calendarSchedule;

  if (hasPermission) {
    if (isPurchased) {
      return <></>;
    }

    if (currentUser.isTrialing) {
      return <FreeTrialingAlert />;
    }

    if (freeTrialStarted) {
      return <PaidTrialingAlert />;
    }

    return <></>;
  }

  if (currentUser.isPaid) {
    if (freeTrialStarted && freeTrialEnded) {
      return <TrialEndedAlert onDelete={onDelete} />;
    }

    if (!freeTrialStarted) {
      return <StartTrialAlert onDelete={onDelete} />;
    }
  }

  return <NotAllowedAlert onDelete={onDelete} />;
};

export default AlertHandler;
