import React from 'react';
import Chart from "react-apexcharts";
import LineChartOptions from './../../dashboard/LineChartCard/LineChartOptions';

const CompanyLineChartCard = ({
  value,
  label,
  data = [],
  options,
  percentage,
  info_icon = '',
  link = ''
}) => {
  return (
    <div className="card">
      <div className="card-body">
        {(!link && !info_icon) && <div className='h4'>{label}</div>}
        <div>
          <div className="h1 d-inline fw-medium">{value}</div>

          {( percentage) && (
            <div className={`mx-2 h4 d-inline fw-medium text-${(percentage >= 0) ? 'success' : 'danger'}`}>
              <i className={`me-1 ti ti-trending-${(percentage >= 0) ? 'up' : 'down'}`}></i>
              {percentage}%
            </div>
          )}
        </div>
      </div>
      <div className="card-chart-bg">
        <div style={{ height: '100%', maxHeight: '64px', position: 'relative' }}>
        <Chart
          options={options || LineChartOptions}
          series={data}
          type="area"
          height="64"
        />
        </div>
      </div>
    </div>
  );
};

export default CompanyLineChartCard;
