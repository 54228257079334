import React from 'react';

import SmallBlock from './SmallBlock';
import MediumBlock from './MediumBlock';
import BigBlock from './BigBlock';

const AnalyticSkeletonScreen = () => {
  return (
    <>
      <div className="row">
        {[1, 2].map((val) => {
          return (
            <div className="col-6" key={val}>
              <MediumBlock />
            </div>
          );
        })}
      </div>

      <div className="row">
        {[1, 2, 3, 4].map((val) => {
          return (
            <div className="col-sm-6 col-lg-3" key={val}>
              <SmallBlock />
            </div>
          )
        })}
      </div>

      <div className="row">
        <div className="col-12">
          <BigBlock />
        </div>
      </div>
    </>
  );
};

export default AnalyticSkeletonScreen;
