import React from "react";

const SkeletonLoader = () => {
  return (
    <div className="row w-100">
      <div className="card placeholder-wave mb-3">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-auto">
              <div className="avatar avatar-rounded placeholder"></div>
            </div>
            <div className="col-6">
              <div className="placeholder col-12"></div>
            </div>
            <div className="col text-end">
              <div className="placeholder placeholder-lg col-2 me-2"></div>
              <div className="placeholder placeholder-lg col-2 me-2"></div>
              <div className="placeholder placeholder-lg col-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
