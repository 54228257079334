import React from "react";

import DesktopAndMobileTabs from "./DesktopAndMobileTabs";
import TutorialButton from "@/components/guided_tutorial/TutorialButton";

const ScriptCardBlockDesktopAndMobile = ({
  title,
  description,
  icon,
  desktopAndMobileData = {
    sameTabLabel: `${I18n.t("views.manage_flows.script_editor.same_flow")}`,
    diffTabLabel: `${I18n.t("views.manage_flows.script_editor.custom_flow")}`,
    label: `${I18n.t("views.manage_flows.script_editor.flow")}`,
  },
  customMobileStateKey,
  desktopComponent,
  mobileComponent,
  cardBodyClass = 'card-body',
  tutorialUrl
}) => {
  return (
    <div className="card">
      <div className="card-header d-flex flex-column align-items-start py-3">
        <div className='h2 inline-block'>
          <i className={`${icon} text-primary me-2`} />
          {title}
          {tutorialUrl && (<TutorialButton tutorialUrl={tutorialUrl} />)}
        </div>

        <p className='mb-0'>{description}</p>
      </div>

      <DesktopAndMobileTabs
        sameLabel={`${desktopAndMobileData.sameTabLabel} ${I18n.t("views.manage_flows.script_editor.for_mobile_and_desktop")}`}
        diffLabel={`${desktopAndMobileData.diffTabLabel} ${I18n.t("views.manage_flows.script_editor.for_mobile_and_desktop")}`}
        label={desktopAndMobileData.label}
        cardBodyClass={cardBodyClass}
        customMobileStateKey={customMobileStateKey}
        desktopComponent={desktopComponent}
        mobileComponent={mobileComponent}
      />
    </div>
  );
};

export default ScriptCardBlockDesktopAndMobile;
