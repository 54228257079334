export default {
  "software": {
    "title": "Software",
    "calls": [
      {
        "avg_conversion": "4,12%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.software.call_1_html")
      },
      {
        "avg_conversion": "3,90%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call":  I18n.t("views.call.software.call_2_html")
      },
      {
        "avg_conversion": "3,90%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call":  I18n.t("views.call.software.call_3_html")
      },
      {
        "avg_conversion": "3,60%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call":  I18n.t("views.call.software.call_4_html")
      },
      {
        "avg_conversion": "4,20%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call":  I18n.t("views.call.software.call_5_html")
      },
      {
        "avg_conversion": "4,30%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call":  I18n.t("views.call.software.call_6_html")
      },
      {
        "avg_conversion": "4,00%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call":  I18n.t("views.call.software.call_7_html")
      },
      {
        "avg_conversion": "3,70%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call":  I18n.t("views.call.software.call_8_html")
      },
      {
        "avg_conversion": "4,12%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call":  I18n.t("views.call.software.call_9_html")
      },
      {
        "avg_conversion": "3,70%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call":  I18n.t("views.call.software.call_10_html")
      },
      {
        "avg_conversion": "4,30%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call":  I18n.t("views.call.software.call_11_html")
      },
      {
        "avg_conversion": "4,00%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.software.call_12_html")
      },
      {
        "avg_conversion": "4,20%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.software.call_13_html")
      }
    ]
  },
  "educacional": {
    "title": "Educacional",
    "calls": [
      {
        "avg_conversion": "5,70%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.educational.call_1_html")
      },
      {
        "avg_conversion": "5,40%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.educational.call_2_html")
      },
      {
        "avg_conversion": "5,00%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.educational.call_3_html")
      },
      {
        "avg_conversion": "5,60%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.educational.call_4_html")
      },
      {
        "avg_conversion": "5,70%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.educational.call_5_html")
      },
      {
        "avg_conversion": "5,50%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.educational.call_6_html")
      },
      {
        "avg_conversion": "5,00%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.educational.call_7_html")
      },
      {
        "avg_conversion": "5,20%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.educational.call_8_html")
      },
      {
        "avg_conversion": "5,00%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.educational.call_9_html")
      },
      {
        "avg_conversion": "5,70%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.educational.call_10_html")
      },
      {
        "avg_conversion": "5,60%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.educational.call_11_html")
      },
      {
        "avg_conversion": "5,50%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.educational.call_12_html")
      },
      {
        "avg_conversion": "4,50%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.educational.call_13_html")
      },
      {
        "avg_conversion": "5,00%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.educational.call_14_html")
      },
      {
        "avg_conversion": "5,20%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.educational.call_15_html")
      },
      {
        "avg_conversion": "5,00%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.educational.call_16_html")
      },
      {
        "avg_conversion": "5,40%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.educational.call_17_html")
      },
      {
        "avg_conversion": "5,30%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.educational.call_18_html")
      },
      {
        "avg_conversion": "5,10%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.educational.call_19_html")
      }
    ]
  },
  "imoveis": {
    "title": "Imóveis",
    "calls": [
      {
        "avg_conversion": "2,00%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.properties.call_1_html")
      },
      {
        "avg_conversion": "2,30%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.properties.call_2_html")
      },
      {
        "avg_conversion": "2,40%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.properties.call_3_html")
      },
      {
        "avg_conversion": "2,60%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.properties.call_4_html")
      },
      {
        "avg_conversion": "2,20%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.properties.call_5_html")
      },
      {
        "avg_conversion": "2,30%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.properties.call_6_html")
      },
      {
        "avg_conversion": "2,00%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.properties.call_7_html")
      },
      {
        "avg_conversion": "2,20%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.properties.call_8_html")
      },
      {
        "avg_conversion": "2,30%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.properties.call_9_html")
      },
      {
        "avg_conversion": "2,40%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.properties.call_10_html")
      },
      {
        "avg_conversion": "2,40%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.properties.call_11_html")
      },
      {
        "avg_conversion": "2,60%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.properties.call_12_html")
      },
      {
        "avg_conversion": "2,60%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.properties.call_13_html")
      },
      {
        "avg_conversion": "2,60%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.properties.call_14_html")
      },
      {
        "avg_conversion": "2,60%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.properties.call_15_html")
      },
      {
        "avg_conversion": "2,40%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.properties.call_16_html")
      },
      {
        "avg_conversion": "2,20%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.properties.call_17_html")
      },
      {
        "avg_conversion": "2,20%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.properties.call_18_html")
      }
    ]
  },
  "juridico": {
    "title": "Jurídico",
    "calls": [
      {
        "avg_conversion": "2,00%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.legal.call_1_html")
      },
      {
        "avg_conversion": "2,11%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.legal.call_2_html")
      },
      {
        "avg_conversion": "2,14%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.legal.call_3_html")
      },
      {
        "avg_conversion": "2,10%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.legal.call_4_html")
      },
      {
        "avg_conversion": "2,20%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.legal.call_5_html")
      },
      {
        "avg_conversion": "2,20%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.legal.call_6_html")
      }
    ]
  },
  "venda_produtos": {
    "title": "Venda de Produtos",
    "calls": [
      {
        "avg_conversion": "4,00%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.product_sales.call_1_html")
      },
      {
        "avg_conversion": "4,20%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.product_sales.call_2_html")
      },
      {
        "avg_conversion": "3,80%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.product_sales.call_3_html")
      },
      {
        "avg_conversion": "3,50%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.product_sales.call_4_html")
      },
      {
        "avg_conversion": "4,00%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.product_sales.call_5_html")
      },
      {
        "avg_conversion": "3,70%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.product_sales.call_6_html")
      },
      {
        "avg_conversion": "4,10%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.product_sales.call_7_html")
      },
      {
        "avg_conversion": "4,20%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.product_sales.call_8_html")
      }
    ]
  },
  "financeiro": {
    "title": "Financeiro",
    "calls": [
      {
        "avg_conversion": "6,60%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.financial.call_1_html")
      },
      {
        "avg_conversion": "6,50%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.financial.call_2_html")
      },
      {
        "avg_conversion": "6,50%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.financial.call_3_html")
      },
      {
        "avg_conversion": "6,10%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.financial.call_4_html")
      },
      {
        "avg_conversion": "6,60%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.financial.call_5_html")
      },
      {
        "avg_conversion": "6,60%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.financial.call_6_html")
      },
      {
        "avg_conversion": "6,30%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.financial.call_7_html")
      },
      {
        "avg_conversion": "6,30%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.financial.call_8_html")
      },
      {
        "avg_conversion": "6,60%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.financial.call_9_html")
      },
      {
        "avg_conversion": "6,50%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.financial.call_10_html")
      },
      {
        "avg_conversion": "6,50%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.financial.call_11_html")
      },
      {
        "avg_conversion": "6,30%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.financial.call_12_html")
      },
      {
        "avg_conversion": "6,40%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.financial.call_13_html")
      },
      {
        "avg_conversion": "6,10%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.financial.call_14_html")
      }
    ]
  },
  "seguros": {
    "title": "Seguros",
    "calls": [
      {
        "avg_conversion": "6,60%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.insurance.call_1_html")
      },
      {
        "avg_conversion": "6,00%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.insurance.call_2_html")
      },
      {
        "avg_conversion": "6,20%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.insurance.call_3_html")
      },
      {
        "avg_conversion": "6,40%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.insurance.call_4_html")
      },
      {
        "avg_conversion": "6,30%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.insurance.call_5_html")
      },
      {
        "avg_conversion": "6,30%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.insurance.call_6_html")
      },
      {
        "avg_conversion": "6,40%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.insurance.call_7_html")
      }
    ]
  },
  "telefonia_internet": {
    "title": "Telefonia e Internet",
    "calls": [
      {
        "avg_conversion": "4,20%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.telephony_and_internet.call_1_html")
      },
      {
        "avg_conversion": "4,10%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.telephony_and_internet.call_2_html")
      },
      {
        "avg_conversion": "3,00%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.telephony_and_internet.call_3_html")
      },
      {
        "avg_conversion": "3,90%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.telephony_and_internet.call_4_html")
      },
      {
        "avg_conversion": "4,00%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.telephony_and_internet.call_5_html")
      },
      {
        "avg_conversion": "4,20%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.telephony_and_internet.call_6_html")
      },
      {
        "avg_conversion": "4,40%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.telephony_and_internet.call_7_html")
      },
      {
        "avg_conversion": "4,40%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.telephony_and_internet.call_8_html")
      },
      {
        "avg_conversion": "4,50%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.telephony_and_internet.call_9_html")
      },
      {
        "avg_conversion": "4,50%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.telephony_and_internet.call_10_html")
      },
      {
        "avg_conversion": "4,00%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.telephony_and_internet.call_11_html")
      }
    ]
  },
  "outros": {
    "title": "Outros",
    "calls": [
      {
        "avg_conversion": "3,60%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.others.call_1_html")
      },
      {
        "avg_conversion": "3,60%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.others.call_2_html")
      },
      {
        "avg_conversion": "3,00%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.others.call_3_html")
      },
      {
        "avg_conversion": "3,20%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.others.call_4_html")
      },
      {
        "avg_conversion": "3,00%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.others.call_5_html")
      },
      {
        "avg_conversion": "3,00%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.others.call_6_html")
      },
      {
        "avg_conversion": "3,20%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.others.call_7_html")
      },
      {
        "avg_conversion": "3,40%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.others.call_8_html")
      },
      {
        "avg_conversion": "3,20%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.others.call_9_html")
      },
      {
        "avg_conversion": "3,60%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.others.call_10_html")
      },
      {
        "avg_conversion": "3,20%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.others.call_11_html")
      },
      {
        "avg_conversion": "3,40%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.others.call_12_html")
      },
      {
        "avg_conversion": "3,20%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.others.call_13_html")
      },
      {
        "avg_conversion": "3,00%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.others.call_14_html")
      },
      {
        "avg_conversion": "3,20%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.others.call_15_html")
      },
      {
        "avg_conversion": "3,40%",
        "type": I18n.t("views.call.type.discovery"),
        "tooltip": "discovery",
        "call": I18n.t("views.call.others.call_16_html")
      },
      {
        "avg_conversion": "3,00%",
        "type":I18n.t("views.call.type.consideration"),
        "tooltip": "consideration",
        "call": I18n.t("views.call.others.call_17_html")
      },
      {
        "avg_conversion": "3,40%",
        "type": I18n.t("views.call.type.interest"),
        "tooltip": "interest",
        "call": I18n.t("views.call.others.call_18_html")
      }
    ]
  }
}
