import React from "react";

import parse from "html-react-parser";

const FreeTrialingAlert = () => {
  return (
    <div className="alert alert-primary mb-3">
      <div className="d-flex align-items-center">
        <div>
          <i className="ti ti-info-circle icon alert-icon fs-1" />
        </div>

        <div>
          <div className="alert-title mb-2">
            {I18n.t("views.manage_flows.scheduler_block.meeting_scheduling")}
            <span className="badge bg-success-lt ms-2">
             {I18n.t("views.manage_flows.scheduler_block.free_trial_ongoing")}
            </span>
          </div>

          <p className="mb-1 text-muted">
            {parse(I18n.t("views.manage_flows.scheduler_block.trialing_feature_information_html"))}
          </p>

          <a
            className='d-block'
            href={I18n.t("shared.helpscout.google_calendar_scheduling")}
            target="_blank"
          >
            {I18n.t("views.manage_flows.scheduler_block.learn_more")} {" "}
            <i className="ti ti-external-link"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default FreeTrialingAlert;
