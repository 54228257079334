import React from 'react';
import Chart from "react-apexcharts";
import LineChartOptions from './LineChartOptions';
import Modal from "./Modal";

const LineChartCard = ({
  value,
  label,
  data = [],
  options,
  percentage
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="h1 fw-medium">{value}</div>

        {(!percentage) && <div className="text-muted">{label}</div>}

        {(!!percentage) && (
          <div>
            <span className="text-muted">
              {label} | {percentage}
            </span>
            <Modal/>
          </div>
        )}
      </div>
      <div className="card-chart-bg">
        <div style={{ height: '100%', maxHeight: '64px', position: 'relative' }}>
        <Chart
          options={options || LineChartOptions}
          series={data}
          type="area"
          height="64"
        />
        </div>
      </div>
    </div>
  );
};

export default LineChartCard;
