import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

import axios from "@/lib/axios";

import swal from 'sweetalert';

const I18N_SCOPE = "views.dashboard.integrate_reportei";

const IntegrateReporteiButton = ({ integrateReporteiPath }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleIntegrate = async () => {
    setIsLoading(true);

    try {
      const response = await axios.post(integrateReporteiPath);
      const data = response.data;

      if (data.session_link) {
        const popupWidth = 1200;
        const popupHeight = 800;

        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth
          ? window.innerWidth
          : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : screen.width;
        const height = window.innerHeight
          ? window.innerHeight
          : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screen.height;

        const left = width / 2 - popupWidth / 2 + dualScreenLeft;
        const top = height / 2 - popupHeight / 2 + dualScreenTop;

        const popup = window.open(
          data.session_link,
          "ReporteiIntegration",
          `scrollbars=yes, width=${popupWidth}, height=${popupHeight}, top=${top}, left=${left}`
        );

        if (!popup) {
          swal("", I18n.t(`${I18N_SCOPE}.modal.error_messages.error_popup_blocked`), "error");
          setIsLoading(false);
          return;
        }

        if (window.focus) {
          popup.focus();
        }

        const timer = setInterval(() => {
          if (popup.closed) {
            clearInterval(timer);
            window.location.reload();
          }
        }, 1000);
      } else {
        swal("", I18n.t(`${I18N_SCOPE}.modal.error_messages.invalid_session_link`), "error");
        setIsLoading(false);
      }
    } catch (error) {
      const errorMsg =
        error.response && error.response.data && error.response.data.error
          ? `${I18n.t(`${I18N_SCOPE}.modal.error_messages.error_integrating`)}: ${error.response.data.error}`
          : I18n.t(`${I18N_SCOPE}.modal.error_messages.unexpected_error`);

      swal("", errorMsg, "error");
      setIsLoading(false);
    }
  };

  return (
    <Button
      variant="primary"
      onClick={handleIntegrate}
      disabled={isLoading}
    >
      {isLoading ? <Spinner animation="border" variant="light" /> : I18n.t(`${I18N_SCOPE}.button.integrate`)}
    </Button>
  );
};

IntegrateReporteiButton.propTypes = {
  integrateReporteiPath: PropTypes.string.isRequired,
};

export default IntegrateReporteiButton;
