import React, { useState, useEffect } from "react";
import { MemoryRouter as Router, Route, withRouter } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { withHistoryDirection } from "./HistoryDirection";
import { GlobalContext, AppState, useAppState } from "./AppState";

// Root component
import OnboardScreen from "./OnboardScreen";

// Critical path components container
import ContentContainer from "./ContentContainer";

// Constant components
import Sidebar from "./Sidebar";

// Spinner HOC
import { withSpinner } from "./Spinner";

// Screens
import ChooseObjective from "./ChooseObjective";
import ChooseBusinessModel from "./ChooseBusinessModel";
import ChooseSegment from "./ChooseSegment";
import ChooseAvatar from "./ChooseAvatar";
import ChooseTooltip from "./ChooseTooltip";

import ObjectiveSidebar from "./sidebar/ObjectiveSidebar";
import BusinessModelSidebar from "./sidebar/BusinessModelSidebar";
import SegmentSidebar from "./sidebar/SegmentSidebar";
import AvatarSidebar from "./sidebar/AvatarSidebar";
import TooltipSidebar from "./sidebar/TooltipSidebar";

// Add spinner to the screens
// We don't use a separate spinner screen so the spinner is only displayed in
// the first appearance of each component.
// Same reason those are in the global scope.
const ChooseObjectiveWithSpinner = withSpinner(
  ChooseObjective, "", I18n.t("views.onboard.create_the_wizard_for_your_website")
);

const ChooseBusinessModelWithSpinner = withSpinner(
  ChooseBusinessModel, "", "", (appState) => {
    const transitionPhrasesByObjective = {
      increase_lead_generation: I18n.t("views.onboard.we_increase_your_lead_generation_by_talking"),
      qualify_leads: I18n.t("views.onboard.we_automatically_qualify_your_leads"),
      distribute_and_direct_leads: I18n.t("views.onboard.we_qualify_your_leads_to_understand_the_need"),
      send_leads_data_to_whatsapp: I18n.t("views.onboard.qualification_your_lead_views_the_whatsApp_button"),
      automate_service: I18n.t("views.onboard.today_we_do_not_focus_on_automation"),
      other: I18n.t("views.onboard.other_platform_goals"),
    };

    return transitionPhrasesByObjective[appState.objective];
  }
);

const ChooseSegmentWithSpinner = withSpinner(
  ChooseSegment, "", "", (appState) => {
    return appState.businessModel == "B2C"
      ? I18n.t("views.onboard.business_model_step.transition_b2c")
      : I18n.t("views.onboard.business_model_step.transition_b2b")
  },
);

const ChooseAvatarWithSpinner = withSpinner(
  ChooseAvatar, "", "", (appState) => {
    let segmentTranslation = I18n.t(`shared.segment.${appState.segment}`)
    return appState.segment == "Outros"
      ? I18n.t("views.onboard.increase_the_conversion_of_hits_into_leads")
      : I18n.t("views.onboard.increase_conversion_average", {segment: segmentTranslation});
  },
);

const ChooseTooltipWithSpinner = withSpinner(
  ChooseTooltip, "", I18n.t("views.onboard.lets_choose_your_call")
);

const routesOrder = {};
const routes = [
  { path: "/objective", Component: ChooseObjectiveWithSpinner },
  { path: "/business-model", Component: ChooseBusinessModelWithSpinner },
  { path: "/segment", Component: ChooseSegmentWithSpinner },
  { path: "/avatar", Component: ChooseAvatarWithSpinner },
  { path: "/tooltip", Component: ChooseTooltipWithSpinner },
];

routes.forEach((route, i) => (routesOrder[route.path] = i));

const directionalRoutes = routes.map((route) => ({
  path: route.path,
  Component: withHistoryDirection(route.Component, routesOrder, route.path),
}));

const sidebarRoutes = [
  { path: "/objective", Component: ObjectiveSidebar },
  { path: "/business-model", Component: BusinessModelSidebar },
  { path: "/segment", Component: SegmentSidebar },
  { path: "/avatar", Component: AvatarSidebar },
  { path: "/tooltip", Component: TooltipSidebar },
];

// Probes changes to the router location and saves to our store
const LocationProbe = withRouter((props) => {
  const state = useAppState();

  useEffect(() => {
    if (window.gtag) {
      gtag("event", "play", { event_category: "Onboard", event_action: `step-onboard-start` });
    }
  }, []);

  useEffect(() => props.history.push(state.path), []);
  useEffect(() =>
    props.history.listen((location) => {
      state.setPath(location.pathname);
      state.setSpinning(true);

      // Send to gtag
      if (window.gtag) {
        gtag("event", "play", { event_category: "Onboard", event_action: `step-${location.pathname}` });
      }
    })
  );

  return null;
});

export default function Onboard(props) {
  const updatedAppState = { ...props.app_state, path: "/objective" };
  const [appState, setAppState] = useState(new AppState(updatedAppState));

  return (
    <GlobalContext.Provider value={[appState, setAppState]}>
      <Router>
        <LocationProbe />

        <OnboardScreen>
          <ContentContainer>
            <TransitionGroup>
              {directionalRoutes.map(({ path, Component }) => {
                return (
                  <Route path={path} key={path}>
                    {({ match }) => <Component match={match} />}
                  </Route>
                );
              })}
            </TransitionGroup>
          </ContentContainer>

          <Sidebar routesOrder={routesOrder} sidebarRoutes={sidebarRoutes} />
        </OnboardScreen>
      </Router>
    </GlobalContext.Provider>
  );
}
