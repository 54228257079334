import styled from "styled-components";

export const NewAssistent = styled.div`
  color: #007DFF;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 15px 0px;
  cursor: pointer;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Subtitle = styled.div`
  font-weight: 700;
`;

export const Scroll = styled.div`
  height: 16rem;
`;
