import React, { useEffect, useState, useCallback } from "react";

import { useAppState } from "../AppState";

import RichTextEditor from '@/components/shared/RichTextEditor';

import Carousel from '@brainhubeu/react-carousel';

import * as Styled from "./style";

const defaultTooltips = [
  { segment: "Tecnologia e TI", initialMessage: I18n.t("views.onboard.segment_items.b2b.price_for_your_quote_html"), conversion: 0.052 },
  { segment: "Tecnologia e TI", initialMessage: I18n.t("views.onboard.segment_items.b2b.best_solution_for_your_need_html"), conversion: 0.051 },
  { segment: "Tecnologia e TI", initialMessage: I18n.t("views.onboard.segment_items.b2b.custom_quote_html"), conversion: 0.051 },
  { segment: "Indústria e Fabricação", initialMessage: I18n.t("views.onboard.segment_items.b2b.price_for_your_quote_html"), conversion: 0.052 },
  { segment: "Indústria e Fabricação", initialMessage: I18n.t("views.onboard.segment_items.b2b.best_solution_for_your_need_html"), conversion: 0.051 },
  { segment: "Indústria e Fabricação", initialMessage: I18n.t("views.onboard.segment_items.b2b.custom_quote_html"), conversion: 0.051 },
  { segment: "Marketing e Publicidade", initialMessage: I18n.t("views.onboard.segment_items.b2b.price_for_your_quote_html"), conversion: 0.052 },
  { segment: "Marketing e Publicidade", initialMessage: I18n.t("views.onboard.segment_items.b2b.best_solution_for_your_need_html"), conversion: 0.051 },
  { segment: "Marketing e Publicidade", initialMessage: I18n.t("views.onboard.segment_items.b2b.custom_quote_html"), conversion: 0.051 },
  { segment: "Serviços Médicos e Saúde", initialMessage: I18n.t("views.onboard.segment_items.b2b.price_for_your_quote_html"), conversion: 0.052 },
  { segment: "Serviços Médicos e Saúde", initialMessage: I18n.t("views.onboard.segment_items.b2b.best_solution_for_your_need_html"), conversion: 0.051 },
  { segment: "Serviços Médicos e Saúde", initialMessage: I18n.t("views.onboard.segment_items.b2b.custom_quote_html"), conversion: 0.051 },
  { segment: "Logística e Transporte", initialMessage: I18n.t("views.onboard.segment_items.b2b.price_for_your_quote_html"), conversion: 0.052 },
  { segment: "Logística e Transporte", initialMessage: I18n.t("views.onboard.segment_items.b2b.best_solution_for_your_need_html"), conversion: 0.051 },
  { segment: "Logística e Transporte", initialMessage: I18n.t("views.onboard.segment_items.b2b.custom_quote_html"), conversion: 0.051 },
  { segment: "Energia", initialMessage: I18n.t("views.onboard.segment_items.b2b.price_for_your_quote_html"), conversion: 0.052 },
  { segment: "Energia", initialMessage: I18n.t("views.onboard.segment_items.b2b.best_solution_for_your_need_html"), conversion: 0.051 },
  { segment: "Energia", initialMessage: I18n.t("views.onboard.segment_items.b2b.custom_quote_html"), conversion: 0.051 },
  { segment: "Consultoria", initialMessage: I18n.t("views.onboard.segment_items.b2b.price_for_your_quote_html"), conversion: 0.052 },
  { segment: "Consultoria", initialMessage: I18n.t("views.onboard.segment_items.b2b.best_solution_for_your_need_html"), conversion: 0.051 },
  { segment: "Consultoria", initialMessage: I18n.t("views.onboard.segment_items.b2b.custom_quote_html"), conversion: 0.051 },
  { segment: "Imóveis", initialMessage: I18n.t("views.onboard.segment_items.properties.ideal_property_html"), conversion: 0.031 },
  { segment: "Imóveis", initialMessage: I18n.t("views.onboard.segment_items.properties.best_proposal_html"), conversion: 0.028 },
  { segment: "Imóveis", initialMessage: I18n.t("views.onboard.segment_items.properties.exclusive_properties_html"), conversion: 0.028 },
  { segment: "Venda de Serviços", initialMessage: I18n.t("views.onboard.segment_items.sale_of_services.ideal_service_html"), conversion: 0.042 },
  { segment: "Venda de Serviços", initialMessage: I18n.t("views.onboard.segment_items.sale_of_services.best_price_html"), conversion: 0.035 },
  { segment: "Venda de Produtos", initialMessage: I18n.t("views.onboard.segment_items.sale_of_products.indicated_solutions_html"), conversion: 0.041 },
  { segment: "Venda de Produtos", initialMessage: I18n.t("views.onboard.segment_items.sale_of_products.indicated_product_html"), conversion: 0.033 },
  { segment: "Agência", initialMessage: I18n.t("views.onboard.segment_items.agency.increase_your_sales_html"), conversion: 0.038 },
  { segment: "Agência", initialMessage: I18n.t("views.onboard.segment_items.agency.budget_html"), conversion: 0.031 },
  { segment: "Concessionária", initialMessage: I18n.t("views.onboard.segment_items.concessionaire.approved_financing_html"), conversion: 0.043 },
  { segment: "Concessionária", initialMessage: I18n.t("views.onboard.segment_items.concessionaire.best_proposal_html"), conversion: 0.041 },
  { segment: "Concessionária", initialMessage: I18n.t("views.onboard.segment_items.concessionaire.best_choice_html"), conversion: 0.037 },
  { segment: "Software", initialMessage: I18n.t("views.onboard.segment_items.software.free_demo_html"), conversion: 0.036 },
  { segment: "Software", initialMessage: I18n.t("views.onboard.segment_items.software.best_solution_html"), conversion: 0.035 },
  { segment: "Software", initialMessage: I18n.t("views.onboard.segment_items.software.customized_budget_html"), conversion: 0.031 },
  { segment: "Educacional", initialMessage: I18n.t("views.onboard.segment_items.educational.ideal_course_html"), conversion: 0.027 },
  { segment: "Educacional", initialMessage: I18n.t("views.onboard.segment_items.educational.vacancy_in_five_minutes_html"), conversion: 0.025 },
  { segment: "Educacional", initialMessage: I18n.t("views.onboard.segment_items.educational.our_course_html"), conversion: 0.023 },
  { segment: "Financeiro", initialMessage: I18n.t("views.onboard.segment_items.financial.invest_better_html"), conversion: 0.024 },
  { segment: "Financeiro", initialMessage: I18n.t("views.onboard.segment_items.financial.earn_more_money_html"), conversion: 0.022 },
  { segment: "Financeiro", initialMessage: I18n.t("views.onboard.segment_items.financial.personalized_investment_plan_html"), conversion: 0.022 },
  { segment: "Jurídico", initialMessage: I18n.t("views.onboard.segment_items.legal.online_lawyer_html"), conversion: 0.025 },
  { segment: "Jurídico", initialMessage: I18n.t("views.onboard.segment_items.legal.need_a_lawyer_html"), conversion: 0.0214 },
  { segment: "Jurídico", initialMessage: I18n.t("views.onboard.segment_items.legal.we_can_help_in_your_case_html"), conversion: 0.0211 },
  { segment: "Seguros", initialMessage: I18n.t("views.onboard.segment_items.insurance.simulate_your_insurance_html"), conversion: 0.0580 },
  { segment: "Seguros", initialMessage: I18n.t("views.onboard.segment_items.insurance.budget_in_five_minutes_html"), conversion: 0.0515 },
  { segment: "Seguros", initialMessage: I18n.t("views.onboard.segment_items.insurance.the_best_health_plan_for_you_to_save_html"), conversion: 0.0511 },
  { segment: "Telefonia e Internet", initialMessage: I18n.t("views.onboard.segment_items.telephony_and_internet.check_the_value_for_your_internet_html"), conversion: 0.087 },
  { segment: "Telefonia e Internet", initialMessage: I18n.t("views.onboard.segment_items.telephony_and_internet.find_the_best_internet_plan_html"), conversion: 0.082 },
  { segment: "Telefonia e Internet", initialMessage: I18n.t("views.onboard.segment_items.telephony_and_internet.coverage_in_your_zip_code_html"), conversion: 0.081 },
  { segment: "E-commerce", initialMessage: I18n.t("views.onboard.segment_items.b2b.price_for_your_quote_html"), conversion: 0.052 },
  { segment: "E-commerce", initialMessage: I18n.t("views.onboard.segment_items.b2b.best_solution_for_your_need_html"), conversion: 0.051 },
  { segment: "E-commerce", initialMessage: I18n.t("views.onboard.segment_items.b2b.custom_quote_html"), conversion: 0.051 },
  { segment: "Infoproduto", initialMessage: I18n.t("views.onboard.segment_items.infoproduto.quick_and_tangible_html"), conversion: 0.052 },
  { segment: "Infoproduto", initialMessage: I18n.t("views.onboard.segment_items.infoproduto.company_sales_increase_message_html"), conversion: 0.051 },
  { segment: "Infoproduto", initialMessage: I18n.t("views.onboard.segment_items.infoproduto.content_perfect_for_you_message_html"), conversion: 0.051 },
];

function renderPercent(p) {
  return (Math.floor(p * 10000) / 100).toString() + '%';
}

const TooltipSelector = ({ segment, setTooltip }) => {
  const appState = useAppState();

  const filteredTooltip = useCallback(() => {
    return defaultTooltips.filter((tooltip) => (segment === 'Outros' || tooltip.segment === segment));
  });

  const [tooltips, setTooltips] = useState(filteredTooltip);
  const [selectedTooltip, setSelectedTooltip] = useState(0);

  const maxLengthTooltip = 105;

  setTooltip(tooltips[selectedTooltip].initialMessage);
  useEffect(() => feather.replace());

  useEffect(() => {
    appState.setTooltipMessage(tooltips[selectedTooltip].initialMessage);
  }, [tooltips, selectedTooltip, segment]);

  return (
    <div>
      <Carousel
        arrows
        draggable={false}
        onChange={(value) => setSelectedTooltip(value)}
      >
        {tooltips.map((tooltip, tooltipIdx) => (
          <div key={`${tooltip.segment}-${tooltipIdx}`} className="tooltip-selector">
            <div className="tooltip">
              <div className="tooltip-top-container">
                <div className="tooltip-badge">
                  {I18n.t("views.onboard.segment")} <span>{I18n.t(`shared.segment.${tooltip.segment}`)}</span>
                </div>
                <div className="tooltip-badge">
                  {I18n.t("views.onboard.conversion")} <span>{renderPercent(tooltip.conversion)}</span>
                </div>
              </div>

              <div className="tooltip-message">
                <div className="tooltip-avatar" style={{ backgroundImage: `url(${appState.avatar})` }} />

                <Styled.EditorContainer>
                  <RichTextEditor
                    value={tooltip.initialMessage}
                    inline={true}
                    init={{
                      menubar: false,
                      plugins: "emoticons",
                      toolbar: "bold italic underline emoticons",
                      smart_paste: false,
                    }}
                    onEditorChange={(value, editor) => {
                      const currentTextValue = editor.getContent({ format: "text" });

                      if (currentTextValue.length <= maxLengthTooltip) {
                        const updatedTooltips = _.cloneDeep(tooltips);
                        _.set(updatedTooltips, `${tooltipIdx}.initialMessage`, value);
                        _.set(updatedTooltips, `${tooltipIdx}.characterCounter`, currentTextValue.length);
                        setTooltips(updatedTooltips);
                      }
                    }}
                  />
                </Styled.EditorContainer>
              </div>

              {_.get(tooltip, 'characterCounter', 0) >= maxLengthTooltip &&
                <div className="form-text" style={{ textAlign: 'center', marginBottom: 10, justifyContent: 'center' }}>
                  <i data-feather="alert-triangle" style={{ marginRight: 5 }}></i>
                  {I18n.t("views.onboard.reached_the_character_limit", { max_length: maxLengthTooltip })}
                </div>
              }
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default TooltipSelector;
