import styled from "styled-components";

export const MainImage = styled.img`
  width: "100%";
  max-height: 370px;
  object-fit: "contain";
`;

export const FinishButton = styled.button.attrs({
  className: "btn btn-primary",
  type: "button",
})`
  ${(props) => props.position === "center" && `
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  `}

  ${(props) => props.position === "right" && `
    margin-left: auto;
  `}
`;
