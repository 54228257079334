import crypto from "crypto";

export default class Encryptor {
  static algorithm = "aes-256-cbc";
  static secretKey = Buffer.from(process.env.ENCRYPTOR_SECRET_KEY, "base64");

  static encrypt(data) {
    const iv = crypto.randomBytes(16);
    const cipher = crypto.createCipheriv(this.algorithm, this.secretKey, iv);

    let encryptedData = cipher.update(JSON.stringify(data), "utf8", "base64");
    encryptedData += cipher.final("base64");

    return `${iv.toString("base64")}--${encryptedData}`;
  }

  static decrypt(encryptedData) {
    const [ivBase64, encryptedBase64] = encryptedData
      .split("--")
      .map((part) => Buffer.from(part, "base64"));

    const decipher = crypto.createDecipheriv(this.algorithm, this.secretKey, ivBase64);

    let decryptedData = decipher.update(encryptedBase64, null, "utf8");
    decryptedData += decipher.final("utf8");

    return JSON.parse(decryptedData);
  }
}
