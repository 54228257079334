import parse from "html-react-parser";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ScriptItem = ({ script }) => {
  return (
    <>
      <div className="card flow-item mb-3">
        <div className="card-body row align-items-center row-cards">

          <div className="col-lg-auto d-flex align-items-center">
            <div className="custom-switch custom-control-inline align-middle me-3">
              <label className="form-check form-check-single form-switch ps-0">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  disabled={true}
                  checked={script.enabled}
                />
              </label>
            </div>

            <div className="fs-3 d-inline-block">
              <OverlayTrigger overlay={<Tooltip>{script.name}</Tooltip>}>
                <span className="badge bg-muted-lt text-body fw-medium p-2">
                  {_.truncate(script.name, { length: 30 })}
                </span>
              </OverlayTrigger>
            </div>
          </div>

          <div className="col-lg-5 d-flex align-items-center">
            <div
              className="avatar avatar-sm avatar-rounded me-2"
              style={{ backgroundImage: `url(${script.avatar})`}}
            >
              <span className="badge bg-success" />
            </div>

            <div className="d-flex align-items-center text-truncate text-muted">
              <i className="ti ti-devices me-1" />
              {parse(script.conversion_call)}
            </div>
          </div>

          <div className="col-lg-auto ms-auto">
            <a
              href={`/scripts/${script.id}/edit?referrer_action=link-leadster-ai`}
              className="me-0 me-sm-1 btn-primary btn"
              target="_blank"
            >
              <i className="ti ti-edit icon me-1" />
              {I18n.t("views.leadster_ai.page.home.add_assistant")}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScriptItem;
