import { useState } from "react";

import Card from "react-credit-cards-2";

import swal from "sweetalert";

import "react-credit-cards-2/dist/lib/styles-compiled";

import {
  formatCreditCardNumber,
  formatName,
  formatCVC,
  formatExpirationDate,
  formatTaxVat,
} from "./FormatUtils";

import {
  validateCreditCard,
  validateCardHolderName,
  validateExpirationDate,
  validateCvc,
  validateTaxVat,
} from "./ValidateUtils";

import {
  gatewayConfigData,
  saveCreditCardAndRedirect,
  loadStoreData,
} from "../PaymentUtils";

import {
  profileTokenVindi,
} from "./VindiUtils";

const CreditCardForm = () => {
  const [state, setState] = useState({
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    focus: "",
    tax_vat: "",
    showLoader: false,
  });

  const handleInputFocus = (event) => {
    setState((prev) => ({ ...prev, focus: event.target.name }));
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const formatters = {
      number: formatCreditCardNumber,
      name: formatName,
      expiry: formatExpirationDate,
      cvc: (value) => { return formatCVC(value, state.number) },
      tax_vat: formatTaxVat,
    }

    const formatter = formatters[name];

    let formatVal = "";

    if (formatter) formatVal = formatter(value);

    setState((prev) => ({ ...prev, [name]: formatVal }));
  }

  const handleInputBlur = (event) => {
    const { name, value } = event.target;

    const formatters = {
      number: validateCreditCard,
      name: validateCardHolderName,
      expiry: validateExpirationDate,
      cvc: (value) => { return validateCvc(value, state.number) },
      tax_vat: validateTaxVat,
    }

    const formatter = formatters[name];

    if (formatter) {
      try {
        formatter(value);
      } catch (error) {
        swal("", error.message, "error").then(_r => "");
      }
    }
  }

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      setState((prev) => ({...prev, showLoader: true}));

      const formData = new FormData(event.target);
      const storeData = loadStoreData();
      const configData = await gatewayConfigData(storeData);

      const data = {
        form: formData,
        config: configData,
        profile_token: await profileTokenVindi(formData, configData),
        store_data: storeData,
      }

      await saveCreditCardAndRedirect(data);
    } catch (exception) {
      setState((prev) => ({...prev, showLoader: false}));

      swal("", exception.message, "error").then(_r => "");
    }
  }

  return (
    <div className="container credit-card-form">
      <Card
        number={state.number}
        name={state.name}
        expiry={state.expiry}
        cvc={state.cvc}
        focused={state.focus}
        acceptedCards={["visa", "mastercard", "elo", "american-express", "hipercard", "diners-club", "jcb"]}
        placeholders={{name: I18n.t("billing.modal.payment.credit_card.name_print")}}
        locale={{valid: I18n.t("billing.modal.payment.credit_card.shelf_life")}}
      />

      <form id="payment-form" onSubmit={event => handleSubmit(event)}>
        <div className="row mt-2">
          <div className="col-12">
            <label className="form-label">{I18n.t("billing.modal.payment.credit_card.card_number")}</label>
            <input
              autoComplete="off"
              type="tel"
              name="number"
              value={state.number}
              className="form-control"
              placeholder={I18n.t("billing.modal.payment.credit_card.card_number")}
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12">
            <label className="form-label">{I18n.t("billing.modal.payment.credit_card.name")}</label>
            <input
              autoComplete="off"
              type="text"
              name="name"
              value={state.name}
              className="form-control"
              maxLength="40"
              placeholder={I18n.t("billing.modal.payment.credit_card.name")}
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-7">
            <label className="form-label">{I18n.t("billing.modal.payment.credit_card.shelf_life")}</label>
            <input
              autoComplete="off"
              type="tel"
              name="expiry"
              value={state.expiry}
              className="form-control"
              placeholder={I18n.t("billing.modal.payment.credit_card.shelf_life")}
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
          </div>

          <div className="col-5">
            <label className="form-label">{I18n.t("billing.modal.payment.credit_card.cvv")}</label>
            <input
              autoComplete="off"
              type="tel"
              name="cvc"
              value={state.cvc}
              className="form-control"
              placeholder={I18n.t("billing.modal.payment.credit_card.cvv")}
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
          </div>

        </div>

        <div className="row mt-2">
          <div className="col-xs-12 col-sm-12">
            <label className="form-label">{I18n.t("billing.modal.payment.credit_card.tax_vat")}</label>
            <input
              autoComplete="off"
              type="tel"
              name="tax_vat"
              value={state.tax_vat}
              maxLength="18"
              className="form-control"
              placeholder={I18n.t("billing.modal.payment.credit_card.tax_vat")}
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
          </div>
        </div>

        {!state.showLoader &&
          <button className="billing-btn" type="submit">
            <span>
              {I18n.t("billing.modal.payment.credit_card.button_confirm")}
              <i className="fe fe-arrow-right"/>
            </span>
          </button>
        }
        {state.showLoader &&
          <div className="loader"/>
        }
      </form>
    </div>
  );
};

export default CreditCardForm;
