import React from "react";

import { useAssistantContext } from "@/contexts/AssistantContext";

const Header = () => {
  const { settings } = useAssistantContext();

  return (
    <div>
      <h1 className="page-title">{settings.title}</h1>
      <div className="page-subtitle lead">
        {settings.description ?? <p>{settings.description}</p>}
      </div>
    </div>
  );
};

export default Header;
