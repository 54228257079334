import React from "react";

import { Divisor } from "./styles";

const Separator = ({ primary = false }) => {
  return (
    <div className="d-flex align-items-center">
      <Divisor className={`m-0 bg-${primary ? "info" : "muted"} opacity-100`} />
    </div>
  );
};

export default Separator;
