import { useEffect } from "react";

import NavBar from "@/components/leadster_ia/NavBar/NavBar";
import Layout from "@/components/leadster_ia/cards/theme/Layout";
import ResponseLimit from '@/components/leadster_ia/subpages/CreateYourAiAssistant/ResponseLimit';

import { Stack } from "react-bootstrap";

import { ROUTES, getRoutesWithoutErrorPage } from "./routes";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { useAssistantContext } from "@/contexts/AssistantContext";

const ANALYTICS_ROUTES = ROUTES.analytics;

const analyticsNavbarIcons = [
  <i className="ti ti-report-analytics me-2 text-primary" />,
  <i className="ti ti-clock-hour-9 me-2 text-primary" />
];

const Analytics = () => {
  let { path } = useRouteMatch();
  const { settings, updateSettings } = useAssistantContext();

  const subpagesSettings = settings.pages.assistant.subpages;
  const routesWithoutErrorPage = getRoutesWithoutErrorPage(ANALYTICS_ROUTES);

  useEffect(() => {
    updateSettings({ title: I18n.t("views.leadster_ai.page.analytics.title"), description: "" });
  }, []);

  return (
    <div className="w-100 pt-2">
      <Stack className="w-100 align-items-start" direction="horizontal" gap={3}>
        <Stack gap={3} style={{ maxWidth: '342px' }}>
          <NavBar routes={routesWithoutErrorPage} icons={analyticsNavbarIcons} />
          <ResponseLimit />
        </Stack>

        <div className="d-flex align-items-center justify-content-center w-100">
          <Layout title={subpagesSettings.title} description={subpagesSettings.description}>
            <Switch>
              {ANALYTICS_ROUTES.map((route, index) => (
                <Route
                  key={index}
                  exact={index === 0 && route.path === ""}
                  path={`${path}${route.path}`}
                  component={route.component}
                />
              ))}
            </Switch>
          </Layout>
        </div>
      </Stack>
    </div>
  );
}

export default Analytics;
