import styled from "styled-components";

// TODO: Remove this file after 11/13/2024
export const AlertContainer = styled.div.attrs({
  className: "alert alert-important bg-white-lt alert-dismissible mb-3 py-2 shadow-lg",
})`
  .btn-close {
    padding: 0.75rem;
  }
  .font-l {
    font-size: 1.5rem;
    margin-bottom: 2px;
  }
  .font-sm {
    font-size: 0.8rem;
  }
  .gif {
    max-height: 250px;
    min-height: 250px;
  }

  min-height: 250px;
`;
