import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import DefaultFlowCard from "./DefaultFlowCard";
import DuplicateFlowCard from "./DuplicateFlowCard";
import ReactPortal from "@/components/ReactPortal";

import parse from "html-react-parser";
import { classBinding } from "@/utils/helpers";

import * as Styled from "./style";

const CreateFlowButton = ({
  pageRank,
  newConversionCall,
  suggestedConversionCall,
  disabled = false,
}) => {
  const [showHasScriptAlert, setShowHasScriptAlert] = useState(pageRank.already_has_script);
  const modalRef = useRef();

  const handleClickCreateScript = () => {
    if (disabled) return;

    showModal();

    MixpanelService.track('page_rank_click_create_new_script');
  }

  const showModal = () => $(modalRef.current).modal("show");
  const closeModal = () => $(modalRef.current).modal("hide");

  const popoverButtonProps = disabled ? {
    "data-bs-toggle": "popover",
    "data-bs-placement": "top",
    "data-bs-trigger": "hover",
    "data-bs-content": I18n.t("views.page_rank.rank_table.data.btn_create_error_popover"),
  } : {};

  return (
    <>
      <button
        type="button"
        className={classBinding({
          "disabled": disabled,
          "btn-outline-warning": pageRank.already_has_script,
          "btn-outline-primary": !pageRank.already_has_script,
        },"btn")}
        {...popoverButtonProps}
        onClick={handleClickCreateScript}
      >
        <i className="ti ti-plus icon" />
        {I18n.t("views.page_rank.rank_table.data.btn_create")}
      </button>

      {pageRank.already_has_script && (
        <Styled.AlreadyHasScriptAlert>
          {I18n.t("views.page_rank.rank_table.data.already_has_script")}
        </Styled.AlreadyHasScriptAlert>
      )}

      <ReactPortal>
        <div ref={modalRef} className="modal modal-blur fade">
          <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header py-3">
                <div>
                  <div className="modal-title mb-1">
                    {I18n.t("views.page_rank.rank_table.data.modal_create.title")}
                  </div>

                  <div className="text-muted">
                    {I18n.t("views.page_rank.rank_table.data.modal_create.subtitle")}{" "}
                    <Styled.PageLink href={pageRank.url} target="_blank">
                      {pageRank.url}
                    </Styled.PageLink>
                  </div>
                </div>

                <button type="button" className="btn-close" data-bs-dismiss="modal" />
              </div>

              {showHasScriptAlert ? (
                <>
                  <div className="modal-body">
                    <p className="lead">
                      {parse(I18n.t("views.page_rank.rank_table.data.modal_create.has_script_alert.first_paragraph_html"))}
                    </p>

                    <p className="lead mb-0">
                      {parse(I18n.t("views.page_rank.rank_table.data.modal_create.has_script_alert.second_paragraph_html"))}
                    </p>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn px-5 btn-primary"
                      onClick={() => setShowHasScriptAlert(false)}
                    >
                      {I18n.t("general.actions.continue")}
                    </button>
                  </div>
                </>
              ) : (
                <div className="modal-body px-4 py-3">
                  <div className="row row-deck g-2">
                    <div className="col-md-6 mb-3 mb-md-0">
                      <DefaultFlowCard
                        pageUrl={pageRank.url}
                        callId={pageRank.call_id}
                        newConversionCall={newConversionCall}
                        suggestedConversionCall={suggestedConversionCall}
                      />
                    </div>

                    <div className="col-md-6 mb-3 mb-md-0">
                      <DuplicateFlowCard
                        pageUrl={pageRank.url}
                        callId={pageRank.call_id}
                        newConversionCall={newConversionCall}
                        suggestedConversionCall={suggestedConversionCall}
                        closeParentModal={closeModal}
                      />
                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </ReactPortal>
    </>
  );
};

CreateFlowButton.propTypes = {
  pageRank: PropTypes.object.isRequired,
  newConversionCall: PropTypes.string.isRequired,
  suggestedConversionCall: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CreateFlowButton;
