import { useEffect } from "react";

import moment from 'moment';

const useDatePickRange = (element, startDate, endDate, onChange) => {
  useEffect(() => {
    initDateRangePicker();
  }, [element]);

  const initDateRangePicker = () => {
    if (!element) return;

    $(element).daterangepicker({
      opens: "left",
      startDate: moment(startDate),
      endDate: moment(endDate),
      ranges: {
        [I18n.t("date.date_range.today")]: [moment(), moment()],
        [I18n.t("date.date_range.yesterday")]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        [I18n.t("date.date_range.last_7_days")]: [moment().subtract(6, 'days'), moment()],
        [I18n.t("date.date_range.last_30_days")]: [moment().subtract(29, 'days'), moment()],
        [I18n.t("date.date_range.this_month")]: [moment().startOf('month'), moment().endOf('month')],
        [I18n.t("date.date_range.last_month")]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      locale: {
        "format": I18n.t("js.formats.default.date"),
        "applyLabel": I18n.t("shared.actions.apply"),
        "cancelLabel": I18n.t("shared.actions.cancel"),
        "fromLabel": I18n.t("shared.actions.in"),
        "toLabel": I18n.t("shared.actions.until"),
        "customRangeLabel": I18n.t("shared.actions.custom"),
        "weekLabel": "S",
        "daysOfWeek": I18n.t("date.abbr_day_names"),
      },
    },
      function (startDate, endDate) {
        onChange(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      }
    );
  }
};

export default useDatePickRange;
