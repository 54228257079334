import styled from "styled-components";

export const chatbotContainer = styled.div.attrs(({ hideButtonClose, hideBrand }) => ({
  id: "nld-custom-container",
  hideButtonClose: hideButtonClose,
  hideBrand: hideBrand,
}))`
    display: block;
    width: 50%;
    margin: 0 auto;
    flex: 1;
    position: relative;

    .nld-close {
        display: ${({ hideButtonClose }) => (hideButtonClose ? "none" : "block")};
    }
    .nld-brand-footer {
        display: ${({ hideBrand }) => (hideBrand ? "none" : "block")};
    }
`;
