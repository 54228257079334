import React, { useEffect, useRef } from "react";

import { useAppState } from "./AppState";

const requestAnimationFrame = requestAnimationFrame || (fn => setTimeout(fn, 1/30));
const cancelAnimationFrame = cancelAnimationFrame || clearTimeout;

const vertices = [
  0.063442, -0.133228, 0.426653,
  0.977009, -0.133228, 0.533454,
  1.532128, 0.421890, 0.357985,
  1.906437, 0.688348, 0.047178,
  1.906437, 0.282318, 0.169598,
  1.570193, -0.053926, 0.050322,
  1.614603, 0.986189, 0.132667,
  0.796199, 0.237908, 1.300901,
  -0.180810, 0.237908, 1.410532,
  -0.561463, -0.142744, 0.412815,
  -0.561463, -0.364792, 0.132667,
  -0.840609, -0.527204, 0.280297,
  -0.339415, 0.612217, 1.646155,
  0.282318, 0.612217, 1.565695,
  0.615389, 0.945288, 1.348660,
  0.615390, 1.745578, 0.132667,
  1.218090, 1.471789, 0.132667,
  0.983354, 1.306909, 0.505209,
  0.983354, 0.913567, 1.129856,
  0.643939, 0.574152, 1.119094,
  0.301351, 2.009670, 0.132667,
  0.034894, 1.798585, 0.660404,
  -0.218874, 1.995884, 0.132667,
  -0.551946, 1.724569, 0.905945,
  -0.247952, 1.420576, 1.353961,
  0.169709, 1.420576, 0.978321,
  0.069259, 0.997628, 1.303234,
  -0.443565, 0.997628, 1.533128,
  -0.972250, 1.526312, 1.223962,
  -0.972250, 1.766262, 0.132667,
  -0.882374, 1.029349, 1.132848,
  -0.747559, 0.894534, 1.381997,
  -0.747559, 0.688347, 1.381997,
  -0.879730, 0.556176, 1.110656,
  -1.366120, 1.533278, 0.132667,
  -1.366120, 1.095434, 0.759052,
  -1.152003, 0.881317, 1.088431,
  -1.152003, -0.128470, 0.632778,
  -1.167864, -0.551418, 0.132666,
  -1.493005, -0.226277, 0.575321,
  -1.493005, 0.429292, 0.576977,
  -1.902735, 0.774179, 0.132667,
  -1.781137, 1.227617, 0.132667,
  -1.781137, 1.066357, 0.347320,
  -1.556447, 0.841666, 0.603271,
  -1.905379, 0.264260, 0.132667,
  -1.770564, 0.172880, 0.299743,
  -1.770564, -0.133757, 0.132667,
  0.063442, -0.133228, -0.426653,
  0.977009, -0.133228, -0.533454,
  1.532128, 0.421890, -0.357985,
  1.906437, 0.688348, -0.047178,
  1.906437, 0.282318, -0.169598,
  1.570193, -0.053926, -0.050323,
  1.614603, 0.986189, -0.132667,
  0.796199, 0.237908, -1.300901,
  -0.180810, 0.237908, -1.410532,
  -0.561463, -0.142744, -0.412815,
  -0.561463, -0.364792, -0.132667,
  -0.840609, -0.527204, -0.280297,
  -0.339415, 0.612217, -1.646155,
  0.282318, 0.612217, -1.565695,
  0.615389, 0.945288, -1.348660,
  0.615390, 1.745578, -0.132667,
  1.218090, 1.471789, -0.132667,
  0.983354, 1.306909, -0.505209,
  0.983354, 0.913567, -1.129856,
  0.643939, 0.574152, -1.119094,
  0.301351, 2.009670, -0.132667,
  0.034894, 1.798585, -0.660404,
  -0.218874, 1.995884, -0.132667,
  -0.551946, 1.724569, -0.905945,
  -0.247952, 1.420576, -1.353961,
  0.169709, 1.420576, -0.978321,
  0.069259, 0.997628, -1.303234,
  -0.443565, 0.997628, -1.533128,
  -0.972250, 1.526312, -1.223962,
  -0.972250, 1.766262, -0.132667,
  -0.882374, 1.029349, -1.132848,
  -0.747559, 0.894534, -1.381997,
  -0.747559, 0.688347, -1.381997,
  -0.879730, 0.556176, -1.110656,
  -1.366120, 1.533278, -0.132667,
  -1.366120, 1.095434, -0.759052,
  -1.152003, 0.881317, -1.088431,
  -1.152003, -0.128470, -0.632778,
  -1.167864, -0.551418, -0.132666,
  -1.493005, -0.226277, -0.575321,
  -1.493005, 0.429292, -0.576977,
  -1.902735, 0.774179, -0.132667,
  -1.781137, 1.227617, -0.132667,
  -1.781137, 1.066357, -0.347320,
  -1.556447, 0.841666, -0.603271,
  -1.905379, 0.264260, -0.132667,
  -1.770564, 0.172880, -0.299743,
  -1.770564, -0.133757, -0.132667,
];

const lines = [
  2, 1,
  3, 2,
  4, 5,
  5, 6,
  8, 7,
  8, 9,
  10, 9,
  11, 10,
  13, 14,
  15, 14,
  16, 15,
  13, 12,
  18, 17,
  19, 18,
  19, 20,
  22, 21,
  24, 23,
  25, 24,
  25, 26,
  28, 27,
  28, 29,
  29, 30,
  31, 32,
  32, 33,
  34, 33,
  36, 35,
  37, 36,
  37, 38,
  40, 39,
  40, 41,
  41, 42,
  43, 44,
  44, 45,
  46, 47,
  47, 48,
  50, 49,
  51, 50,
  52, 53,
  53, 54,
  56, 55,
  56, 57,
  58, 57,
  59, 58,
  61, 62,
  63, 62,
  64, 63,
  61, 60,
  66, 65,
  67, 66,
  67, 68,
  70, 69,
  72, 71,
  73, 72,
  73, 74,
  76, 75,
  76, 77,
  77, 78,
  79, 80,
  80, 81,
  82, 81,
  84, 83,
  85, 84,
  85, 86,
  88, 87,
  88, 89,
  89, 90,
  91, 92,
  92, 93,
  94, 95,
  95, 96
];

const paint = (frameRequestRef, canvas, ctx, props) => {
  // Responsive canvas
  const [w, h] = [canvas.clientWidth, canvas.clientHeight];
  canvas.width = w; canvas.height = h;

  // 3d rotation
  const dot = (a, b) => a[0]*b[0]+a[1]*b[1]+a[2]*b[2];
  const mul = (v, m) => [ dot(m.slice(0, 3), v), dot(m.slice(3, 6), v), dot(m.slice(6, 9), v) ];
  const rotY = a => [
    Math.cos(a), 0, -Math.sin(a),
    0, 1, 0,
    Math.sin(a), 0, Math.cos(a)
  ];

  // Paint

  ctx.clearRect(0, 0, w, h);

  const rot = rotY(-new Date()/250);

  const drawVertice = i => {
    const vertice = mul(vertices.slice(i, i+3), rot);
    // Ortho

    const [ x, y, z ] = vertice;
    const zoom = Math.sqrt(w*w+h*h)/2*.35;
    const [ sx, sy ] = [ x*zoom + w/2, -y*zoom + h/5*4 ];

    // ctx.strokeStyle = `rgba(0, 169, 255, ${opacity})`;
    // const opacity = Math.min(1, Math.max(0, z));
    // ctx.lineWidth = 1;

    // ctx.beginPath();
    // ctx.arc(sx, sy, 2, 0, 2 * Math.PI);
    // ctx.stroke();

    return [ sx, sy, z ];
  }

  for (let i=0;i<lines.length;i+=2) {
    const [ v1, v2 ] = lines.slice(i, i+2);

    const [ sx, sy, sz ] = drawVertice((v2-1)*3);
    const [ ex, ey, ez ] = drawVertice((v1-1)*3);

    const opacity = Math.min(1, Math.max(0, Math.min(sz, ez) + 1));

    ctx.strokeStyle = `rgba(0, 169, 255, ${opacity})`;
    ctx.lineWidth = 1;

    ctx.beginPath();
    ctx.moveTo(sx, sy);
    ctx.lineTo(ex, ey);
    ctx.stroke();
  }

  // Request the next animation frame
  frameRequestRef.current = requestAnimationFrame(() => {
    paint(frameRequestRef, canvas, ctx, props);
  });
};

function Spinner(props) {
  const canvasRef = useRef(null);
  const frameRequestRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    frameRequestRef.current = requestAnimationFrame(() => {
      paint(frameRequestRef, canvas, ctx, props);
    });

    return () => {
      cancelAnimationFrame(frameRequestRef.current);
    };
  });

  return <canvas className="spinner" ref={canvasRef} />;
}

// Always show the spinner before showing `Component`
// TODO: receive the sidebar message(s)
function withSpinner(Component, title = "", description = "", functionalDescription) {
  const windowWidth = window.innerWidth;

  const desktopTransitionTime = 5000;
  const mobileTransitionTime = 750;

  const transitionTime = windowWidth > 800 ? desktopTransitionTime : mobileTransitionTime;

  let spinning = true;

  return (props) => {
    const appState = useAppState();

    useEffect(() => {
      if (spinning) {
        if (typeof functionalDescription !== "undefined") {
          appState.showSidebarMessage(
            "",
            functionalDescription(appState)
          );
        } else {
          appState.showSidebarMessage(
            title,
            description
          );
        }

        const timeout = setTimeout(() => {
          appState.hideSidebarMessages();
          spinning = false;
          appState.setSpinning(false);
        }, transitionTime);

        return () => clearTimeout(timeout);
      }
    }, []);

    return <>
      {spinning? (<Spinner />) : (<div className="fade-in"><Component {...props} /></div>)}
    </>;
  };
}

export { withSpinner };
export default Spinner;
