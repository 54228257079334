const ColSkeleton = ({ itensNum }) => {
  return Array.from(Array(itensNum).keys()).map((_, key) => (
    <div className="col col-4" key={key}>
      <div className="card placeholder-wave">
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-8 placeholder placeholder-lg" />
          </div>
          <div className="row">
            <div className="col-10 placeholder" />
          </div>
        </div>
      </div>
    </div>
  ));
};

export default ColSkeleton;
