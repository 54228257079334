import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import HelpPopover from "@/components/shared/HelpPopover";

import fetchEvaluationConversations from "./services/fetchEvaluationConversations";

import * as S from "./styles";

const EvaluationConversation = ({ assistantId, scriptId, leadId, isLike, onClick }) => {
  const [like, setLike] = useState(isLike);

  const handleLike = () => {
    fetchEvaluationConversations({ scriptId, leadId, assistantId, isLike: true, onFinalize: onClick })
    setLike(true);
  };

  const handleDislike = () => {
    fetchEvaluationConversations({ scriptId, leadId, assistantId, isLike: false, onFinalize: onClick })
    setLike(false);
  }

  useEffect(() => {
    setLike(isLike);
  }, [isLike]);

  return (
    <Card.Footer className="bg-white d-flex justify-content-between">
      <div className="d-flex justify-content-start">
        <HelpPopover
          className="m-2"
          content={I18n.t("views.leadster_ai.page.analytics.history.help_popover")}
          placement="top"
        />
        <S.Title className="my-2">{I18n.t("views.leadster_ai.page.analytics.history.evaluate_responses")}</S.Title>
      </div>
      <div className="d-flex justify-content-end">
        <S.IconUp like={like} onClick={handleLike} />
        <S.IconDown like={like} onClick={handleDislike} />
      </div>
    </Card.Footer>
  );
};

export default EvaluationConversation;
