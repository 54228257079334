import React from 'react';

import AdsAnalytics from '../AdsAnalytics';

const AdsAnalyticsBlock = ({
  title = '',
  show,
  ads = 'others',
  image_path = '',
  image_width = '150'
}) => {
  return show && (
    <div>
      <div className="d-flex justify-content-start">
        {image_path && (
          <div className="mt-3 mb-5 d-block">
            <img src={image_path} width={image_width} />
          </div>
        )}
        {!!title && (
          <div className="mt-3 mb-2 d-block">
            <h3 className="text-primary h1">
              {title}
            </h3>
          </div>
        )}
        <div className="ps-3 flex-grow-1">
          <hr style={{ width: '100%' }}/>
        </div>
      </div>

      <AdsAnalytics ads={ads} />
    </div>
  );
};

export default AdsAnalyticsBlock;
