import { useState, useEffect } from "react";

import axios from "@/lib/axios";
import swal from 'sweetalert';
import useAnalyticsStore from '@/components/ScriptAnalytics/store';

const useWhatsAppBlocksFetcher = () => {
  const { scriptId } = useAnalyticsStore((state) => state);

  const [whatsAppBlocksAnalytics, setWhatsAppBlocksAnalytics] = useState([]);
  const [period, setPeriod] = useState("current");
  const [channel, setChannel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const endpoint = `/script/${scriptId}/analytics/whatsapp-blocks-distribution`;
    const params = {
      "filter[period]": period,
      "filter[channel]": channel
    };

    setIsLoading(true);

    axios.get(endpoint, { params })
      .then(({ data }) => setWhatsAppBlocksAnalytics(data.blocks))
      .catch(() => {
        setWhatsAppBlocksAnalytics([]);
        swal("", I18n.t("views.analytics.error.info_retrieval"), "error")
      })
      .then(() => setIsLoading(false));
  }, [period, channel]);

  return {
    whatsAppBlocksAnalytics,
    period,
    setPeriod,
    channel,
    setChannel,
    isLoading
  };
};

export default useWhatsAppBlocksFetcher;
