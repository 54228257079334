import styled from "styled-components";

export const Card = styled.div`
  overflow: hidden;
  transition: 400ms;

  &:hover{
    border: 1px solid #007DFF;

    .card-header{
      border-bottom: 1px solid var(--tblr-primary);
    }

    .card-header, .card-body{
      background-color: var(--tblr-primary-fg);
    }
  }

  .card-header{
    min-height: 56px;
    width: 100%;
    margin: 0;
    background: linear-gradient(45deg, rgba(var(--tblr-primary-rgb), 0.2) 0%, rgba(var(--tblr-info-rgb), 0.4) 100%);
  }

  .card-header, .card-body{
    transition: 400ms;
  }
`;
