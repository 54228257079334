import React, { useCallback, useState } from "react";

import { Form } from "react-bootstrap";

import { useAssistantContext } from "@/contexts/AssistantContext";
import { useDropzone } from "react-dropzone";

import axios from "@/lib/axios";

import { classBinding } from "@/utils/helpers";

import * as S from "./style";

const DocumentUpload = () => {
  const { addPendingBase, assistant, updateAssistant } = useAssistantContext();

  const [errors, setErrors] = useState({});
  const [, updateState] = useState();

  const forceUpdate = useCallback(() => updateState({}), []);

  const hasErrors = !!Object.keys(errors).length;
  const isUploadDisabled = !!assistant.pending_knowledge_base;

  const handleUpload = (file) => {
    if (isUploadDisabled) return;

    MixpanelService.track("leadster_ai_add_file");
    setErrors({});

    const formData = new FormData();
    formData.append("data_source", file);
    formData.append("base_type", "file");
    formData.append("ai_assistant_id", assistant.id);

    updateAssistant({
      status_alert: "uploading_file",
      path: file.path,
      has_multiple_url_process_data: false,
    });

    axios
      .post("/ai/knowledge_bases", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => addPendingBase(data.knowledge_base))
      .catch(({ response }) => setErrors(response?.data || null));
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length <= 0) return;

    handleUpload(acceptedFiles[0]);
  }, []);

  const onDropRejected = useCallback((fileRejections) => {
    const parsedErrorMessages = {
      "file-too-large": "max_size_file",
    };

    fileRejections.map(({ errors: fileErrors }) => {
      fileErrors.map((error) => {
        const errorCode = _.get(parsedErrorMessages, error.code, "invalid_file");
        const errorMessage = I18n.t(`views.leadster_ai.page.add_content.add_file.${errorCode}`);

        setErrors(_.set(errors, "data_source", errorMessage));
      });
    });

    forceUpdate();
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    multiple: false,
    noClick: isUploadDisabled,
    noDrag: isUploadDisabled,
    disabled: isUploadDisabled,
    accept: {
      "application/vnd.oasis.opendocument.text": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
      "application/pdf": [],
      "text/plain": [],
    },
    maxSize: 10485760,
    onDrop,
    onDropRejected,
  });

  return (
    <>
      <S.Dropzone
        className={classBinding(
          {
            "drag-active": isDragActive,
            "is-invalid": hasErrors || isDragReject,
            disabled: isUploadDisabled,
          },
          "form-control"
        )}
        {...getRootProps({
          onClick: () => MixpanelService.track("leadster_ai_select_file"),
        })}
      >
        <input {...getInputProps()} />

        <div className="d-flex flex-column align-items-center justify-content-center">
          <i className="ti ti-file-upload me-2 fs-1 text-primary mb-3" />

          <p className="text-center fw-medium mb-2">
            {I18n.t("views.leadster_ai.page.add_content.add_file.drag_and_drop_file")}
          </p>

          <p className="fw-bold text-center">
            {I18n.t("views.leadster_ai.page.add_content.add_file.supported_file")}
            <br />
            {I18n.t("views.leadster_ai.page.add_content.add_file.max_size_file")}
          </p>
        </div>
      </S.Dropzone>

      <Form.Control.Feedback type="invalid">
        {isDragReject
          ? I18n.t("views.leadster_ai.page.add_content.add_file.invalid_file")
          : _.get(errors, "data_source")}
      </Form.Control.Feedback>
    </>
  );
};

export default DocumentUpload;
