import React from "react";
import NotificationsListEditor from "./NotificationsListEditor";
import NotificationEditor from "./NotificationEditor";

export default class NotificationsEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: "",
    };

    this.listEditor = React.createRef();
  }

  getNotifications() {
    return this.listEditor.current.getNotifications();
  }

  render() {
    const { avatar, notifications, onChange, standalone } = this.props;
    const { preview } = this.state;

    if (standalone) {
      window.neuroleadNotificationsEditor = this;
    }

    return <div>
        <NotificationsListEditor ref={this.listEditor}
                                 list={notifications}
                                 component={NotificationEditor}
                                 defaultElementData={{template: ""}}
                                 callback={message => this.setState({ preview: message })}
                                 onChange={() => onChange? onChange(this.getNotifications()) : null} />

        <div className="form-group mt-3">
            <label className="form-label">{I18n.t("views.manage_flows.script_editor.preview_of_your_call")}</label>

            <div style={{ position: "relative", width: "100%", height: "32px", marginTop: "48px", marginLeft: "-20px" }}>
                <div className="nld-chatbot nld-left" style={{ zIndex: 1 }}>
                    <div className="nld-avatar nld-shown-slide" style={{position: "relative", backgroundImage: `url(${avatar})`, bottom: "32px"}}>
                      {preview && preview !== 'undefined' &&
                        <div className="nld-chatbot-invite-container">
                          <div className="nld-chatbot-invite-message">
                            <span className="nld-invite-message-text" dangerouslySetInnerHTML={{ __html: preview }}></span>
                            <div className="nld-clear"></div>
                          </div>
                        </div>
                      }
                    </div>
                </div>
            </div>
        </div>
    </div>
  }
}
