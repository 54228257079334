const buildPayloadBySerializer = (scriptEditorSerializer) => {
  const scriptVerison = scriptEditorSerializer.script_version;
  const scriptRule = scriptEditorSerializer.script_rule;
  const additionalData = scriptEditorSerializer.additional_data;

  const payloadState = {
    id: scriptEditorSerializer.id,
    companyId: scriptEditorSerializer.company_id,
    uid: scriptEditorSerializer.uid,
    name: scriptEditorSerializer.name,
    enabled: scriptEditorSerializer.enabled,
    default: scriptEditorSerializer.default,
    privacyPolicyUrl: scriptEditorSerializer.privacy_policy_url,
    ast: scriptRule.ast,
    withIntegrations: scriptEditorSerializer.with_integrations,
    scriptVersion: {
      previewContent: JSON.parse(scriptVerison.content),
      desktopContent: JSON.parse(scriptVerison.content),
      mobileContent: JSON.parse(scriptVerison.mobile_content),
      hasCustomDesignMobile: scriptVerison.has_custom_design_mobile,
      hasCustomNotificationsMobile: scriptVerison.has_custom_notifications_mobile,
      hasCustomScriptMobile: scriptVerison.has_custom_script_mobile,
    },
    additionalData: {
      website: additionalData.website,
      companyIntegrations: additionalData.companyIntegrations,
    },
  };

  return payloadState;
}


export { buildPayloadBySerializer };
