import React, { useState, useCallback } from 'react';
import { Item, Unchecked, Link, HiddenLink } from './styles';

const UncheckedItem = ({ item }) => {
  const hasHash = item.link[0] === '#'
  const [isModal] = useState(hasHash);

  const sendGtagEvent = useCallback((action) => {
    const location = window.location.href;

    if (!!(window.gtag && action)) {
      gtag('event', 'play', {
        'event_category': 'Onboard',
        'event_action': action,
        'event_label': location
      });
    }
  }, []);

  const handleModalClick = useCallback((e, action) => {
    e.preventDefault();
    sendGtagEvent(action);
  }, []);

  const handleClick = useCallback((e, action) => {
    e.preventDefault();
    sendGtagEvent(action);
    $('#script-page-link')[0].click();
  }, []);

  return (
    <Item>
      {item.link && (
        <>
          <Unchecked active={item.active} />

          {isModal ? (
            <Link
              href="#"
              onClick={(e) => handleModalClick(e, item.event_action)}
              active={item.active}
              data-bs-toggle="modal"
              data-bs-target={item.link}
              target="_blank">
              {item.label}
            </Link>
          ) : (
            <>
              <Link href="#" active={item.active} onClick={(e) => handleClick(e, item.event_action)}>
                {item.label}
              </Link>

              <HiddenLink href={item.link} id="script-page-link" />
            </>
          )}
        </>
      )}

      {!item.link && (
        <>
          <Unchecked active={item.active} /> {item.label}
        </>
      )}
    </Item>
  );
};

export default UncheckedItem;
