import React from "react";

import { Editor } from "@tinymce/tinymce-react";

import useCurrentUserStore from "@/store/currentUserStore";

const RichTextEditor = (props) => {
  const { locale: userLocale } = useCurrentUserStore((state) => state.currentUser);

  const parsedLocales = {
    "pt-BR": "pt_BR",
    "pt": "pt_BR",
    "es": "en",
    "en": "en",
  };

  return (
    <Editor
      tinymceScriptSrc={"https://cdn.leadster.com.br/leadster/plugins/tinymce/tinymce.min.js"}
      init={{
        language: _.get(parsedLocales, userLocale, "pt_br"),
        ...(props.init ? props.init : {}),
      }}
      {...props}
    />
  );
};

export default RichTextEditor;
