import { useState } from "react";

import ScriptActivationModal from "@/components/leadster_ia/shared/ScriptActivationModal";

import * as S from "./styles";

const AddToFlowCard = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);

  return (
    <div className="card card-sm">
      <S.HeaderImage />

      <div className="card-body">
        <div className="fs-3 fw-medium d-flex align-items-center mb-2">
          <i className="ti ti-edit icon text-primary me-2" />
          {I18n.t("views.leadster_ai.page.activate_assistant.to_existing_flow.title")}
        </div>

        <p className="small">
          {I18n.t("views.leadster_ai.page.activate_assistant.to_existing_flow.description")}
        </p>

        <button
          type="button"
          className="btn btn-outline-primary w-100"
          onClick={handleOpenModal}
        >
          {I18n.t("views.leadster_ai.page.activate_assistant.to_existing_flow.add_button")}
        </button>
      </div>

      <ScriptActivationModal
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default AddToFlowCard;
