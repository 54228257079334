import React from "react";

import TreeEditor from './TreeEditor'

import { ScriptContextProvider, ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from "use-context-selector";
import useNeuroleadClientUpdater from "../../hooks/useNeuroleadClientUpdater";

const Editor = () => {
  const desktopContent = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.desktopContent);
  useNeuroleadClientUpdater(desktopContent);

  return (
    <>
      <TreeEditor name="ConversationFlow" />

      <input value={JSON.stringify(desktopContent.script)} type="hidden" name="script" />
      <input value={JSON.stringify(desktopContent.requiredFields || [])} type="hidden" name="requiredFields" />
    </>
  );
};

const AbScriptEditor = ({ convoScript, additionalData }) => {
  const payloadState = {
    scriptVersion: {
      desktopContent: convoScript,
      mobileContent: convoScript,
    },
    additionalData: {
      website: additionalData.website,
      userEmail: additionalData.userEmail,
      userName: additionalData.userName,
    },
  };

  return (
    <ScriptContextProvider payloadState={payloadState}>
      <Editor />
    </ScriptContextProvider>
  );
};

export default AbScriptEditor;
