import styled from "styled-components";

export const Container = styled.div`
  overflow: hidden;

  .rec-dot {
    background-color: var(--tblr-gray-300);
    box-shadow: none;
  }

  .rec-dot_active {
    background-color: var(--tblr-info);
    box-shadow: none;
  }
`;
