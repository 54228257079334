import { toParams, toQuery } from "./utils";

class PopupWindow {
  constructor(id, url, isCrossOrigin = true) {
    this.id = id;
    this.url = url;
    this.response = null;
    this.isCrossOrigin = isCrossOrigin;

    this.handlePostMessage = this.handlePostMessage.bind(this);
  }

  handlePostMessage(event) {
    if (event.data.message === "deliverResult") {
      this.response = event.data.result;
    }
  }

  open() {
    const width = 680;
    const height = 768;
    const left = window.screenX + ((window.outerWidth - width) / 2);
    const top = window.screenY + ((window.outerHeight - height) / 2.5);
    const poupOptions = { height, width, top, left };

    if (this.isCrossOrigin) {
      window.addEventListener('message', this.handlePostMessage);
    }

    this.window = window.open(this.url, this.id, toQuery(poupOptions, ","));
  }

  close() {
    this.cancel();
    this.window.close();
    window.removeEventListener("message", this.handlePostMessage);
  }

  poll() {
    this.promise = new Promise((resolve, reject) => {
      this.iid = window.setInterval(() => {
        try {
          const popup = this.window;

          if (!popup || popup.closed !== false) {
            this.close();
            return reject({ type: 'error', code: 'popup_closed' });
          }

          if (this.isCrossOrigin) {
            if (this.response) {
              if (this.response.status === 'success') {
                this.close();
                return resolve(this.response);
              }

              if (this.response.status === 'error') {
                return reject(this.response);
              }
            } else {
              popup.postMessage({ message: 'requestResult' }, '*');
              return;
            }
          } else {
            // location unchanged, still polling
            if (popup.location.href === this.url ||popup.location.pathname === "blank") {
              return;
            }

            const locationValue = popup.location["search"];
            const params = toParams(locationValue);

            resolve(params);
            this.close();
          }
        } catch (error) {
          if (error.name === "SecurityError" && error.message.includes("Blocked a frame with origin")) {
            console.warn("Encountered a cross-origin error, is your authorization URL on a different server?");
          } else {
            console.error(error);
          }
        }
      }, 500);
    });
  }

  cancel() {
    if (this.iid) {
      window.clearInterval(this.iid);
      this.iid = null;
    }
  }

  then(...args) {
    return this.promise.then(...args);
  }

  catch(...args) {
    return this.promise.catch(...args);
  }

  static open(...args) {
    const popup = new this(...args);

    popup.open();
    popup.poll();

    return popup;
  }
}

export default PopupWindow;
