export const nameToAttributes = (name) => {
  const attributes = {};

  for (let key of name.split("::").slice(0, -1)) {
    attributes[key] = true;
  }

  return attributes;
};

export const attributesToPrefix = (attributes) => {
  const prefix = Object.keys(attributes)
    .filter((k) => attributes[k])
    .sort((a, b) => (!isNaN(a) && isNaN(b) ? 1 : -1))
    .join("::");

  return prefix ? prefix + "::" : "";
};

export const removeItemFromArray = (arr, value) => {
  const index = arr.indexOf(value);

  if (index > -1) {
    arr.splice(index, 1);
  }

  return arr;
};

export const nameToSuffix = (name) => {
  return name.split("::").slice(-1).join("");
};
