import { useEffect } from "react";

import { useAssistantContext } from "@/contexts/AssistantContext";

const useScriptFlow = () => {
  const { assistant } = useAssistantContext();

  const startScript = () => {
    const toChatLangConfig = {
      "pt-BR": "pt-BR",
      "en": "en-US",
      "es": "es-ES",
      "nl": "nl-NL",
      "de": "de-DE",
      "it": "it-IT",
      "ar": "ar-SA",
      "hi": "hi-IN",
      "ru": "ru-RU",
      "fr": "fr-FR",
    };

    window.neuroleadDefaultScript = {
      ...window.neuroleadDefaultScript,
      lang: _.get(toChatLangConfig, I18n.currentLocale(), "pt-BR"),
      title: "Leadster AI",
      avatar: "https://cdn.leadster.com.br/uploads/ccc4f0079d6d5402e77cf1a3d7f78741.png",
      script: [
        {
          say: I18n.t( "views.leadster_ai.page.test_assistant.assistant.initial_message"),
        },
        {
          uid: "8qw764423",
          iaInteraction: true,
          say: I18n.t("views.leadster_ai.page.test_assistant.assistant.secondary_message"),
          label: "iaInteraction",
          botId: assistant.id,
          endConversationLabel: I18n.t(
            "views.manage_flows.script_editor.ia_interaction.settings.default_end_conversation_label"
          ),
          defaultMessage: assistant.default_message,
        },
      ],
      version: 2,
      waitTime: 7000,
      inputColor: "#ecae26",
      notifications: [],
      userMainColor: "#2196f3",
      robotMainColor: "#2196f3",
      userLightColor: "#eee",
      robotLightColor: "#ecae26",
      userAccentColor: "#fff",
      robotAccentColor: "#000",
      companyId: -1,
      scriptId: -1,
      notificationsEnabled: false,
      alwaysAutoOpen: true,
      standalone: false,
    };

    (function (a, b, c, d) {
      try {
        var e = b.head || b.getElementsByTagName("head")[0];
        var f = b.createElement("script");
        f.setAttribute("src", c);
        f.setAttribute("charset", "UTF-8");
        f.defer = true;
        a.neuroleadId = d;
        e.appendChild(f);
      } catch (g) {}
    })(window, document, window.script_url, "-1");
  };

  const endScript = () => {
    window.neurolead = null;
  };

  useEffect(() => {
    startScript();

    return () => {
      endScript();
    };
  }, []);
};

export default useScriptFlow;
