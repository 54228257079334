import React from "react";

const PREFIX_CDN = "https://cdn.leadster.com.br/leadster/onboarding"

export default function OptionButton({ selected, value, name, imageFile, onSelect }) {
  return (
    <div
      className={`option-button ${selected ? "selected" : ""}`}
      onClick={() => onSelect(value)}
    >
      {imageFile && <img alt={name} src={`${PREFIX_CDN}/${imageFile}`} width={50} />}
      <span>{name}</span>
    </div>
  );
}
