import React from "react";
import PropTypes from "prop-types";

import GoogleLogin from "../GoogleLogin";
import GoogleLogout from "../GoogleLogout";

import { can } from "@/lib/user-permission"

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'calendar_schedule_plugin'
});

const MyAccountConnection = ({ representative }) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const refreshPage = () => {
    setTimeout(() => {
      const [, tabReference] = document.URL.split("#");

      if (tabReference === 'tab-google') {
        window.location.reload(false)
        return;
      }

      history.pushState('', '', document.URL + '#tab-google');
      window.location.reload(false)
    }, 850);
  }

  const onSuccessCallback = () => {
    swal("", I18n.t("views.users.edit.google_calendar.enabled.account.connected"), "success");
    refreshPage();
  }

  return (
    !representative ? (
      <GoogleLogin
        className={"btn-primary"}
        onSuccessCallback={onSuccessCallback}
        enabled={hasPermission}
      />
    ) : (
      <div>
        <div className="row g-3 mb-3">
          <div className="col-md-6">
            <label className="form-label">{I18n.t("views.users.edit.google_calendar.enabled.account.name")}</label>
            <input type="text" className="form-control" value={representative.name} disabled />
          </div>

          <div className="col-md-6">
            <label className="form-label">{I18n.t("views.users.edit.google_calendar.enabled.account.email")}</label>
            <input type="text" className="form-control" value={representative.email} disabled />
          </div>
        </div>

        <div className="text-end">
          <GoogleLogout
            className={"btn-outline-danger fs-3"}
            onSuccessCallback={refreshPage}
            enabled={hasPermission}
          />
        </div>
      </div>
    )
  );
};

MyAccountConnection.propTypes = {
  representative: PropTypes.object,
};

export default MyAccountConnection;
