import React from "react";

import ScriptCardBlockDesktopAndMobile from "../../ScriptCardBlockDesktopAndMobile";
import ScriptCardFooter from "../../ScriptCardFooter";
import DesignContent from "./DesignContent";

import parse from "html-react-parser";

const ChatbotDesign = () => {
  const cardBody = {
    title: I18n.t("views.manage_flows.script_editor.chat_design"),
    icon: `ti ti-brush`,
    description: I18n.t("views.manage_flows.script_editor.chat_design_description"),
    desktopAndMobileData: {
      sameTabLabel: I18n.t("views.manage_flows.script_editor.same_design"),
      diffTabLabel: I18n.t("views.manage_flows.script_editor.custom_design"),
      label: I18n.t("views.manage_flows.script_editor.design"),
    },
  };

  const cardFooter = {
    title: I18n.t("views.manage_flows.script_editor.conversion_best_practices"),
    firstDescription: I18n.t("views.manage_flows.script_editor.first_best_practices_conversion_description"),
    secondDescription: I18n.t("views.manage_flows.script_editor.second_best_practices_conversion_description"),
  };

  return (
    <>
      <ScriptCardBlockDesktopAndMobile
        title={cardBody.title}
        icon={cardBody.icon}
        description={cardBody.description}
        customMobileStateKey="hasCustomDesignMobile"
        desktopAndMobileData={cardBody.desktopAndMobileData}
        desktopComponent={<DesignContent isMobile={false} />}
        mobileComponent={<DesignContent isMobile={true} />}
        tutorialCode={null}
      />
      <ScriptCardFooter title={cardFooter.title}>
        <p className="mb-2 tracking-wide">
          {parse(cardFooter.firstDescription)}
        </p>
        <p className="mb-0 tracking-wide">
          {cardFooter.secondDescription}
        </p>
      </ScriptCardFooter>
    </>
  );
};

export default ChatbotDesign;
