import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
  padding-top: 30px;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 3px dashed #CCC;
  }
`;

export const Header = styled.div`
  padding: 35px 0;
`;
