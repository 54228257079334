import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function NavButtons({ prev, next }) {
  useEffect(() => feather.replace());

  const justifyContent = !!prev ? "space-between" : "flex-end";

  return (
    <div id="nav-button" style={{ display: "flex", justifyContent: justifyContent }}>
      {prev && (
        <Link to={prev} className="btn-previous" id="previous-button">
          {I18n.t("general.actions.return")}
        </Link>
      )}

      {next && (
        <Link to={next} className="btn-next" id="next-button">
          {I18n.t("general.actions.next")} <i data-feather="arrow-right"></i>
        </Link>
      )}
    </div>
  );
}
