const useConcatListUniqueById = (listA, listB) => {
  let idsSet = new Set(listA.map(item => item.id));
  listB.forEach(item => {
    if (!idsSet.has(item.id)) {
      listA.push(item);
      idsSet.add(item.id);
    }
  });
  return listA;
};

export default useConcatListUniqueById;

