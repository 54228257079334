import styled from "styled-components";

export const FlexBlock = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const InvisibleBlock = styled.div`
  width: 13%;
`;

export const VerticalLine = styled.div`
  width: ${(props) => props.active ? '1px' : '0'};
  height: 100px;
  background: ${(props) => props.active ? 'var(--tblr-primary)' : 'var(--tblr-gray-300)'};
  margin: 0 auto;
`;
