export const toParams = (query) => {
  const q = query.replace(/^(\?|#)/, "");

  return q.split("&").reduce((values, param) => {
    const [key, value] = param.split("=");

    return { ...values, [key]: decodeURIComponent(value) };
  }, {});
};

export const toQuery = (params, delimiter = "&") => {
  const keys = Object.keys(params);

  return keys.reduce((str, key, index) => {
    let query = `${str}${key}=${params[key]}`;

    if (index < keys.length - 1) {
      query += delimiter;
    }

    return query;
  }, "");
};

export const toOauthPathRoute = (queryParams) => {
  let oauthRoute = new URL('https://app.leadster.com.br/auth/google_oauth2');

  Object.entries(queryParams).forEach(([key, value]) => {
    oauthRoute.searchParams.append(key, value);
  })

  return oauthRoute.pathname + oauthRoute.search
}
