import parse from "html-react-parser";

const EmptyAlert = () => {
  return (
    <>
      <div className="avatar avatar-rounded mb-2 bg-primary">😴</div>
      <div className="text-muted fw-normal mb-3">
        {parse(I18n.t("views.leadster_ai.page.analytics.dashboard.graph.no_data_html"))}
      </div>
    </>
  );
};

export default EmptyAlert;
