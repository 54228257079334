import { useCallback, useState } from "react";
import axios from "@/lib/axios";
import swal from 'sweetalert';
import useCookie from "@/hooks/useCookie";

const UserDeleteAccount = ({ user_id }) => {
  const { value: cookieValue, setValue: setCookieValue } = useCookie("leadsterDeletionTimestamp", { expires: 2 });
  
  const [isButtonDisabled, setIsButtonDisabled] = useState(!!cookieValue);

  const deleteAccount = useCallback(async (e) => {
    const sweetEl = (message, icon) => {
      return swal(message, { icon: icon, closeOnClickOutside: false, closeOnEsc: false });
    };

    const willDelete = await swal({
      title: I18n.t("views.users.unsubscribe.are_you_sure"),
      text: I18n.t("views.users.unsubscribe.alert_message"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (willDelete) {
      try {
        await axios.get(`/users/${user_id}/deletion-request`).then(() => {
          setCookieValue(true);
          setIsButtonDisabled(true);
        });

        sweetEl(I18n.t("services.user_deletion_request.messages.success"), "success").then(() => {
          window.location.reload();
        });
      } catch (err) {
        const { response } = err;
        const { data } = response;

        sweetEl(data.message, "error").then(() => {});
      }
    }
  }, []);

  return (
    <button
      type='button'
      className="btn btn-link text-muted"
      onClick={deleteAccount}
      disabled={isButtonDisabled}
    >
      {isButtonDisabled
        ? I18n.t("views.users.unsubscribe.awaiting_deletion")
        : I18n.t("views.users.unsubscribe.want_to_delete_your_account")}
    </button>
  );
}

export default UserDeleteAccount;
