import { Modal, Image } from "react-bootstrap";
import PropTypes from "prop-types";

import ReactPortal from "@/components/ReactPortal";

import CreditCard from "../Payment/CreditCard/CreditCardForm";

import acceptedCcImg from "@/../assets/images/billings/accepted-credit-cards.png";

const ModalCreditCard = ({ title = "", show = false, onClose }) => {
  const handleClose = () => {
    onClose();
  }

  return (
    <ReactPortal>
      <Modal show={show} className="modal-blur" backdrop="static" centered onHide={handleClose}>
        <Modal.Header closeButton>
          <div className="modal-status bg-success"/>
          <Modal.Title className="fw-bold">{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="secure-box">
            <i className="ti ti-lock fa-2x"/>
            {I18n.t("billing.modal.update_your_credit_card_details.you_are_in_a_secure_environment")}
          </div>

          <div className="mb-2">
            <Image src={acceptedCcImg} fluid />
          </div>

          <CreditCard/>

        </Modal.Body>
      </Modal>
    </ReactPortal>
  );
};

ModalCreditCard.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ModalCreditCard;
