import React, { useState, useEffect } from "react";

import { useAppState } from "@/components/onboard/AppState";

import TopLogo from "@/components/onboard/TopLogo";
import Selector from "@/components/onboard/Selector";
import OptionButton from "@/components/onboard/OptionButton";
import NavButtons from "@/components/onboard/NavButtons";

import { marketSegments } from "./segments";

const SEGMENTS_LIMIT = 11;

const ChooseSegment = () => {
  const appState = useAppState();

  const [isFirstPage, setIsFirstPage] = useState(true);

  const getFirstPageData = () => marketSegments.slice(0, SEGMENTS_LIMIT);
  const getLastPageData = () => marketSegments.slice(SEGMENTS_LIMIT);
  const currentPageData = isFirstPage ? getFirstPageData() : getLastPageData();

  useEffect(() => feather.replace(), []);

  return (
    <div id="choose-segment">
      <TopLogo />

      <h1>{I18n.t("views.onboard.segment_step.title")}</h1>
      <h2>{I18n.t("views.onboard.segment_step.subtitle")}</h2>

      <Selector
        onSelect={(option) => appState.setSegment(option)}
        checkIfSelected={(option) => option === appState.segment}
      >
        {[
          ...currentPageData.map(({ key, value, name, imageFile }) => (
            <OptionButton key={key} value={value} name={name} imageFile={imageFile} />
          )),
          <OptionButton
            key={999}
            name={I18n.t(`views.onboard.segment_step.${isFirstPage ? "show_more_btn" : "show_previous_btn"}`)}
            onSelect={() => setIsFirstPage(!isFirstPage)}
          />,
        ]}
      </Selector>

      <NavButtons prev="/business-model" next="/avatar" />
    </div>
  );
};

export default ChooseSegment;
