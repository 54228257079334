import { useState, useCallback, useEffect } from "react";

import useCurrentUserStore from "@/store/currentUserStore";

import useCookie from "@/hooks/useCookie";
import { useLocalStorage } from "@/hooks/useStorage";

import { captureException } from "@sentry/react";

const useShowGuide = (storageKey, expireInDays) => {
  if (!storageKey) {
    return { isGuideAllowed: true, markAsViewed: () => {} };
  }

  const { companyId } = useCurrentUserStore((state) => state.currentUser);
  const [isGuideAllowed, setIsGuideAllowed ] = useState(false);

  const { value: localStorageValue, setValue: setLocalStorageValue } = useLocalStorage(storageKey);
  const { value: cookieValue, setValue: setCookieValue } = useCookie(storageKey, { expires: expireInDays });

  let storageValue = Number.isInteger(expireInDays) ? cookieValue : localStorageValue;
  let setStorageValue = Number.isInteger(expireInDays) ? setCookieValue : setLocalStorageValue;

  useEffect(() => {
    setIsGuideAllowed(canShowAlert());
  }, []);

  const canShowAlert = useCallback(() => {
    if (!storageValue) {
      return true;
    }

    try {
      return !storageValue.includes(companyId);
    } catch (error) {
      captureException(error);
      return false;
    }
  }, []);

  const markAsViewed = () => {
    const newStorageValue = storageValue || [];
    newStorageValue.push(companyId);

    setStorageValue(newStorageValue);
  };

  return { isGuideAllowed, markAsViewed };
};

export default useShowGuide;
