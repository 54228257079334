export const buildWhatsAppLink = ({
  number,
  message = null,
  type = 'web',
  mobileDevice = navigator.userAgentData !== undefined && navigator.userAgentData.mobile,
  ddi = 55
}) => {
  const whatsAppUrl = (type, mobileDevice) => {
    if (mobileDevice === true) { type = 'mobile'; }

    if (type === 'api') {
      return 'https://api.whatsapp.com/send?phone=<:number>&text=<:message>';
    } else if (type === 'mobile') {
      return 'https://wa.me/<:number>?text=<:message>';
    } else {
      return 'https://web.whatsapp.com/send?phone=<:number>&text=<:message>';
    }
  };

  const whatsAppNumber = (number, ddi) => {
    const isInternational = number.startsWith('+');
    return isInternational ? whatsAppInternationalFormat(number) : whatsAppNationalFormat(number, ddi);
  };

  const whatsAppMessage = (message) => {
    return message ? encodeURIComponent(message) : '';
  };

  const whatsAppInternationalFormat = (number) => {
    return number.replace(/\D/g, '');
  };

  const whatsAppNationalFormat = (number, ddi) => {
    return `${ddi}${number.replace(/\D/g, '')}`;
  };

  let url = whatsAppUrl(type, mobileDevice);
  url = url.replace('<:number>', whatsAppNumber(number, ddi));
  url = url.replace('<:message>', whatsAppMessage(message));

  return url;
};
