import useCurrentUserStore from "@/store/currentUserStore";

import { buildWhatsAppLink } from "@/utils/whatsapp_helpers";
import { getCustomVariable } from "@/lib/custom-variables";

const ButtonAction = ({ link, candidateUpsell }) => {
  const { isNational } = useCurrentUserStore((state) => state.currentUser);

  if (isNational) {
    return candidateUpsell ? (
      <a href="/upsell" className="btn btn-success text-uppercase">
        {I18n.t("views.shared.alerts.see_recommended_plans")}
      </a>
    ) : (
      <a href={link} className="btn btn-success text-uppercase">
        {I18n.t("views.shared.alerts.talk_to_team")}
      </a>
    );
  }

  return candidateUpsell ? (
    <a href="/upsell" className="btn btn-success text-uppercase">
      {I18n.t("views.shared.alerts.see_recommended_plans")}
    </a>
  ) : (
    <div className="d-flex">
      <button
        type="button"
        className="btn btn-primary contact-tidio"
        data-tidio-message={I18n.t("shared.tidio.subscription_request_message")}
      >
        <i className="ti ti-messages icon" />
        {I18n.t("shared.actions.contact_chat")}
      </button>

      <a
        className="btn btn-success ms-2"
        href={buildWhatsAppLink({
          number: getCustomVariable("contact_whatsapp_international"),
          message: I18n.t("shared.tidio.subscription_request_message"),
        })}
        target="_blank"
        rel="noopener"
      >
        <i className="ti ti-brand-whatsapp icon" />
        {I18n.t("shared.actions.contact_whatsapp")}
      </a>
    </div>
  );
};

export default ButtonAction;
