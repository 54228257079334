import React, { useEffect } from "react";
import useNeuroleadClientUpdater from "../hooks/useNeuroleadClientUpdater";

import PagesPersonalization from "../ConversationalPersonalization/PagesPersonalization";
import ChatbotDesign from "../ConversationalPersonalization/ChatbotDesign";
import SmartCalls from "../QuestionsAndAnswers/SmartCalls";
import ConversationFlow from "../QuestionsAndAnswers/ConversationFlow";
import MarketingEvents from "../ScriptEvents/MarketingEvents";
import MarketingEmails from "../ScriptEvents/MarketingEmails";
import ScriptIntegrations from "../ScriptEvents/ScriptIntegrations";
import PrivacyPolicy from "../ScriptEvents/PrivacyPolicy";

import ScriptBlockStep from "./ScriptBlockStep";
import VerticalLineDiv from "./VerticalLineDiv";
import HorizontalLineDiv from "./HorizontalLineDiv";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';

import { MainBlock, FlexBlockCenter } from "./styles";

const Editor = () => {
  const activeComponentIndex = useContextSelector(ScriptContext, ({ state }) => state.activeComponentIndex);
  const previewConvoScript = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.previewContent);
  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);

  useNeuroleadClientUpdater(previewConvoScript);

  useEffect(() => {
    if (window.pageYOffset < 300) return;

    setTimeout(
      () => window.scroll({ top: 0, left: 0, behavior: "smooth" }),
      300
    );
  }, [activeComponentIndex]);

  const updateBlockActive = (newStepIndex) => {
    if (newStepIndex === activeComponentIndex) return;

    dispatch({ type: "SET_ALL_ACTIVE_COMPONENT_INDEX", payload: newStepIndex });
  };

  const storageLink = "https://storage.googleapis.com/neuro-cdn/new-editor";

  const blocksData = [
    {
      step: 1,
      image: `${storageLink}/leadster.png`,
      label: I18n.t("views.manage_flows.script_editor.customize_for_your_audience"),
    },
    {
      step: 2,
      image: `${storageLink}/send_message.png`,
      label: I18n.t("views.manage_flows.script_editor.qualify_your_leads"),
    },
    {
      step: 3,
      image: `${storageLink}/events.png`,
      label: I18n.t("views.manage_flows.script_editor.integrate_with_your_systems"),
    },
  ];

  return (
    <MainBlock>
      <FlexBlockCenter>
        <ScriptBlockStep
          onClick={() => updateBlockActive(0)}
          key={0}
          step={blocksData[0].step}
          active={activeComponentIndex === 0}
          image={blocksData[0].image}
          label={blocksData[0].label}
        />

        <HorizontalLineDiv active={[1, 2].includes(activeComponentIndex)} />

        <ScriptBlockStep
          onClick={() => updateBlockActive(1)}
          key={1}
          step={blocksData[1].step}
          active={activeComponentIndex === 1}
          image={blocksData[1].image}
          label={blocksData[1].label}
        />

        <HorizontalLineDiv active={activeComponentIndex === 2} />

        <ScriptBlockStep
          onClick={() => updateBlockActive(2)}
          key={2}
          step={blocksData[2].step}
          active={activeComponentIndex === 2}
          image={blocksData[2].image}
          label={blocksData[2].label}
        />
      </FlexBlockCenter>

      <VerticalLineDiv />

      {activeComponentIndex === 0 && (
        <>
          <PagesPersonalization />
          <VerticalLineDiv blocksData={blocksData} />
          <ChatbotDesign />
        </>
      )}

      {activeComponentIndex === 1 && (
        <>
          <ConversationFlow />
          <VerticalLineDiv blocksData={blocksData} />
          <SmartCalls />
        </>
      )}

      {activeComponentIndex === 2 && (
        <>
          <MarketingEvents />
          <VerticalLineDiv blocksData={blocksData} />

          <MarketingEmails />
          <VerticalLineDiv blocksData={blocksData} />

          <ScriptIntegrations />
          <VerticalLineDiv blocksData={blocksData} />

          <PrivacyPolicy />
        </>
      )}

      <VerticalLineDiv blocksData={blocksData} />

      <FlexBlockCenter>
        <ScriptBlockStep
          onClick={() => updateBlockActive(0)}
          key={0}
          step={blocksData[0].step}
          active={activeComponentIndex === 0}
          image={blocksData[0].image}
          label={blocksData[0].label}
        />

        <HorizontalLineDiv active={[1, 2].includes(activeComponentIndex)} />

        <ScriptBlockStep
          onClick={() => updateBlockActive(1)}
          key={1}
          step={blocksData[1].step}
          active={activeComponentIndex === 1}
          image={blocksData[1].image}
          label={blocksData[1].label}
        />

        <HorizontalLineDiv active={activeComponentIndex === 2} />

        <ScriptBlockStep
          onClick={() => updateBlockActive(2)}
          key={2}
          step={blocksData[2].step}
          active={activeComponentIndex === 2}
          image={blocksData[2].image}
          label={blocksData[2].label}
        />
      </FlexBlockCenter>
    </MainBlock>
  );
};

export default Editor;
