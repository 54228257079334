import styled from "styled-components";

import ReactBootstrapModal from "react-bootstrap/Modal";

export const Modal = styled(ReactBootstrapModal)`
  .modal-content {
    border-radius: 15px;
    max-height: 600px;

    .card {
      &.avaliable-flow {
        cursor: pointer;
        max-height: 210px;

        &:hover {
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15%),
            0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        }
      }

      .card-title {
        font-size: 0.8rem;
        line-height: 1.3;
        font-weight: 600;
        margin-bottom: 5px;
      }
    }

    .side-bar {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      background-color: #f1f1f1;

      .divisor {
        background-color: #e5e5e5;
        padding: 3px 0 3px 23px;
        font-size: 13px;
        color: #787e88;
        font-weight: bold;
        margin: 0 -8px 10px -32px;
        text-transform: uppercase;
      }

      ul {
        list-style: none;
        padding: 0;
        display: inline-block;

        li {
          color: #5c616a;
          font-weight: 500;
          font-size: 14px;
          line-height: 30px;
          cursor: pointer;

          &.active {
            color: #0392ff;
            text-decoration: underline;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .container-shadow {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(#f5f7fb 33%, rgba(245, 247, 251, 0)),
        linear-gradient(rgba(245, 247, 251, 0), #f5f7fb 66%) 0 100%,
        radial-gradient(
          farthest-side at 50% 0,
          rgba(87, 87, 87, 0.5),
          rgba(0, 0, 0, 0)
        ),
        radial-gradient(
            farthest-side at 50% 100%,
            rgba(87, 87, 87, 0.5),
            rgba(0, 0, 0, 0)
          )
          0 100%;
      background-repeat: no-repeat;
      background-attachment: local, local, scroll, scroll;
      background-size: 100% 1px, 100% 5px, 100% 7px, 100% 5px;
      pointer-events: none;
      border-radius: 2px;
    }
  }

  .flow-container {
    height: 345px;
    overflow: auto;

    @media (max-width: 705px) {
      height: 305px;
    }

    @media (max-width: 510px) {
      height: 245px;
    }
  }
`;
