import React from 'react';
import {
  Component,
  Avatar,
  Header,
  Body,
  Title,
  Decoration,
  Date,
} from './styles'

import {notificationClick} from '../../notification_events';

const Item = ({notification}) => {
  return (
    <Component onClick={() => notificationClick(notification, 'list') } >
      <div style={{ opacity: (notification.clicked ? 0.5 : 1) }}>
      <Decoration
        style={{ backgroundColor: (notification.clicked ? "white" : "var(--tblr-primary)")}}
      ></Decoration>
      <Header className='row'>
        <Avatar
          className="rounded-circle"
          src={notification.icon}
        ></Avatar>
        <Title>{ notification.title }</Title>
        <Date>{ notification.createdAt }</Date>
      </Header>
      <Body>
        { notification.text }
        <a href={notification.link}>{ notification.callToAction }</a>
      </Body>
      </div>
    </Component>
  );
}

export default Item;
