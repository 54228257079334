import { useState } from "react";
import useUpdateEffect from "@/hooks/useUpdateEffect";

import DeleteButton from "../ScriptEditorParts/DeleteButton";
import AddButton from "./AddButton";
import ConditionItem from "./ConditionItem";
import ConfigurationButton from "./ConfigurationButton";

import swal from "sweetalert";

const ScriptBlockCondition = (props) => {
  const [mainOperator, setMainOperator] = useState(props.definition.if.mainOperator);
  const [conditions, setConditions] = useState(props.definition.if.conditions);
  const [rejectLead, setRejectLead] = useState(props.definition?.reject_lead || false);

  useUpdateEffect(() => {
    props.setDefinition({ ...props.definition, if: { ...props.definition.if, mainOperator }});
  }, [mainOperator]);

  useUpdateEffect(() => {
    props.setDefinition({ ...props.definition, if: { ...props.definition.if, conditions }});
  }, [conditions]);

  useUpdateEffect(() => {
    props.setDefinition({ ...props.definition, reject_lead: rejectLead });
  }, [rejectLead]);

  const addNewCondition = () => {
    setConditions([ ...conditions, { field: "", operator: "=", values: [] } ]);
  };

  const updateCondition = (updatedCondition, index) => {
    const newConditions = [...conditions];
    newConditions[index] = updatedCondition;
    setConditions(newConditions);
  };

  const removeCondition = (index) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);

    setConditions(newConditions);
  };

  const removeBlock = () => {
    swal({
      title: I18n.t("views.manage_flows.conditional_block.remove_block_alert.title"),
      text: I18n.t("views.manage_flows.conditional_block.remove_block_alert.description"),
      icon: "warning",
      buttons: [
        I18n.t("general.actions.cancel"),
        I18n.t("views.manage_flows.conditional_block.remove_block_alert.confirm_button")
      ],
    }).then(willDelete => {
      if (willDelete) {
        props.onDelete();
      }
    })
  };

  const renderConfigurationButton = () => {
    return <ConfigurationButton
      rejectLead={rejectLead}
      onSaveSettings={(settings) => setRejectLead(settings["rejectLead"])}
    />
  }

  const scriptFields = [];
  props.mapAttributesName(props.getFullTree(), scriptFields);

  return (
    <div className="mb-2">
      <div className="row g-2">
        <div className="col-md">
          <ConditionItem
            condition={conditions[0]}
            mainOperator={mainOperator}
            scriptFields={scriptFields}
            getSelectorOptions={props.getSelectorOptions}
            onChangeCondition={(updatedCondition) => updateCondition(updatedCondition, 0)}
            renderConfigurationButton={renderConfigurationButton}
          />
        </div>

        <div className="col-md-auto text-end">
          <DeleteButton onDelete={removeBlock} />
        </div>
      </div>

      {conditions.slice(1).map((condition, index) => (
        <div className="row g-2" key={index}>
          <div className="col-md">
            <ConditionItem
              condition={condition}
              mainOperator={mainOperator}
              scriptFields={scriptFields}
              enableUpdateMainOperator={index == 0}
              getSelectorOptions={props.getSelectorOptions}
              onChangeCondition={(updatedCondition) => updateCondition(updatedCondition, index + 1)}
              onChangeMainOperator={setMainOperator}
            />
          </div>

          <div className="col-auto">
            <DeleteButton onDelete={() => removeCondition(index + 1)} />
          </div>
        </div>
      ))}

      <div className="text-center">
        <AddButton onClick={addNewCondition} />
      </div>
    </div>
  );
};

export default ScriptBlockCondition;
