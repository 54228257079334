import styled from "styled-components";


export const Button = styled.div.attrs((props) => ({
  className: `btn ${props.disabled ? 'btn-secondary' : 'btn-primary'} ${props.size ? `btn-${props.size}` : 'btn-sm'}`,
}))`
  color: white;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  i {
    display: ${ props => props.icon ? 'inline' : 'none'};
  }
`;
