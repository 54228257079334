import React from "react";
import useCurrentUserStore from "@/store/currentUserStore";

export default function SegmentSidebar() {
  const { applicationName, isResellerOwner } = useCurrentUserStore((state) => state.currentUser);
  return (
    <>
      <h1>
        {I18n.t("views.onboard.increase_result.text01", { application_name: applicationName })}
        <br />
        {I18n.t("views.onboard.increase_result.text02")}
      </h1>

      {!isResellerOwner && (
        <img
          style={{ marginTop: 12, maxWidth: 385 }}
          src={I18n.t("views.onboard.conversion_statistics_image")}
          alt={I18n.t("views.onboard.leadster_reviews_description")}
        />
      )}
    </>
  );
}
