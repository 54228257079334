import React, { useRef, useEffect } from "react";

import { Container } from "./styles.js";
import parse from "html-react-parser";

const InfoModal = () => {
  const modal = useRef()
  const openModal = () => $(modal.current).modal('show');

  useEffect(() => {
    window.initJqueryTooltip();
  }, [])

  return (
    <Container>
      <span onClick={openModal}
        className="form-help bg-primary-lt text-white d-none d-md-inline-block ms-1"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={I18n.t("shared.actions.click_to_learn_more")}
      >
        <i className='fa fa-info'></i>
      </span>

      <div ref={modal} className="modal modal-blur fade" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

            <div className="modal-body">
              <h2 className="text-center mb-3">
                {I18n.t("views.dashboard.modal.info_modal.title")}
              </h2>

              <p>{I18n.t("views.dashboard.modal.info_modal.text_1")}</p>

              <div className='h4'>{I18n.t("views.dashboard.modal.info_modal.text_2")}</div>
              <p>
                {parse(I18n.t("views.dashboard.modal.info_modal.text_3_html"))}
              </p>
              <p>{I18n.t("views.dashboard.modal.info_modal.text_4")}</p>

              <div className="list text-center d-flex justify-content-center p-0 fw-bold">
                <div className="item p-3 me-3 small">
                  <img className="mb-1" src="https://cdn.leadster.com.br/leadster/dashboard/ico/icon-site.png" />
                  <div className="mb-1">{I18n.t("views.dashboard.modal.info_modal.items.sites_analyzed.value")}</div>
                  <div>{I18n.t("views.dashboard.modal.info_modal.items.sites_analyzed.text")}</div>
                </div>

                <div className="item p-3 me-3 small">
                  <img className="mb-1" src="https://cdn.leadster.com.br/leadster/dashboard/ico/icon-acesso.png" />
                  <div className="mb-1">{I18n.t("views.dashboard.modal.info_modal.items.unique_hits.value")}</div>
                  <div>{I18n.t("views.dashboard.modal.info_modal.items.unique_hits.text")}</div>
                </div>

                <div className="item p-3 small">
                  <img className="mb-1" src="https://cdn.leadster.com.br/leadster/dashboard/ico/icon-leads.png" />
                  <div className="mb-1">{I18n.t("views.dashboard.modal.info_modal.items.leads_generated.value")}</div>
                  <div>{I18n.t("views.dashboard.modal.info_modal.items.leads_generated.text")}</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default InfoModal;
