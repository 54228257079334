import { Link } from "react-router-dom";

import useLogic from "./useLogic";
import { useUpsellContext } from "../../context";

import CardBase from "../CardBase";
import Skeleton from "./Skeleton";
import CardSummary from "./CardSummary";
import CardPaymentInfo from "./CardPaymentInfo";
import HelpPopover from "@/components/shared/HelpPopover";

import CurrencyFormatter from "@/utils/formatter-currency";
import CalculatorUtils from "../../utils/calculators";
import FormatterUtils from "../../utils/formatter";
import dayjs from "@/lib/dayjs";

import * as S from "./styles";

const StepTwo = () => {
  const { progressData, resetPaymentProgress } = useUpsellContext();
  const { data } = useLogic();

  if (data.isLoading || !data.data) return <Skeleton />;

  const totalDaysSelectedPlan = CalculatorUtils.calcDaysByPeriod(data.selectedPlan.period);
  const currentDate = FormatterUtils.formatDate();
  const endCurrentPlanDate = FormatterUtils.formatDate(data.currentPlan.expires);
  const endCurrentPlanDate_NextDay = CalculatorUtils.calcAdittionalDays(data.currentPlan.expires, + 1);
  const endSelectedPlanDate = CalculatorUtils.calcAdittionalDays(new Date(), totalDaysSelectedPlan);
  const endSelectPlanDateAfterFinalPlanCurrentDate = CalculatorUtils.calcAdittionalDays(
    dayjs(data.currentPlan.expires).add(1, "day").format("YYYY-MM-DD"),
    totalDaysSelectedPlan
  );
  const selectedPlanPriceByPeriod = CalculatorUtils.calcPricebyPeriod(data.selectedPlan.price - data.data.discount_amount, data.selectedPlan.period);
  const currentPlanAccessFormatted = FormatterUtils.formatAccess(data.currentPlan.access_limit);
  const selectedPlanAccessFormatted = FormatterUtils.formatAccess(data.selectedPlan.access_limit);
  const isPeriodPlanChanged = data.currentPlan.period !== data.selectedPlan.period;
  const totalRemainingPayable = data.selectedPlan.price - data.data.amount_to_pay - data.data.discount_amount;
  const accessDifference = FormatterUtils.formatAccess(data.selectedPlan.access_limit - data.currentPlan.access_limit);
  const newInvoiceDueDate = CalculatorUtils.calcAdittionalDays(new Date(), 3);

  return (
    <>
      {(progressData.overdueInvoice && !isPeriodPlanChanged) && (
        <div class="alert alert-warning mt-4" role="alert">
          <div class="text-secondary">
            {I18n.t("views.upsell.stepper.step_two.overdue_alert")}
          </div>
        </div>
      )}

      <S.Wrapper className="mt-4 gap-3" >
        <div className="w-100 d-flex flex-column gap-3">
          <CardBase
            summaryNumber={1}
            title={I18n.t("views.upsell.stepper.step_two.section_1.title")}
          >
            <div className="row fs-3">
              <div className="col-3">{I18n.t("views.upsell.stepper.step_two.section_1.new_plan")}</div>
              <div className="col-9 fw-bold">
                {data.selectedPlan.plan}
              </div>
            </div>
          </CardBase>
          <CardBase
            summaryNumber={2}
            title={I18n.t("views.upsell.stepper.step_two.section_2.title")}
          >
            {!isPeriodPlanChanged && (
              <div className="d-flex flex-column gap-3 overflow-hidden">
                {/* Current invoice */}
                <div className="w-fit card" style={{ background: `rgba(var(--tblr-primary-rgb), 0.08)` }}>
                  <div className="card-header row fw-bold text-primary row">
                    <div className="d-flex align-items-center gap-2">
                      {I18n.t("views.upsell.stepper.step_two.section_2.period_not_changed.title")}
                      <HelpPopover
                        placement="top"
                        content={I18n.t("views.upsell.stepper.step_two.section_2.period_not_changed.popover_1")}
                      />
                    </div>
                  </div>
                  <div className="card-body space-y-3">
                    <div className="row">
                      <span className="col-3">{I18n.t("views.upsell.current_limit")}</span>
                      <span className="col-9 fw-bold">
                        {currentPlanAccessFormatted} {I18n.t("views.upsell.access")}
                      </span>
                    </div>

                    <div className="row">
                      <span className="col-3">{I18n.t("views.upsell.new_limit")}</span>
                      <span className="col-9 fw-bold">
                        {selectedPlanAccessFormatted} {I18n.t("views.upsell.access")}
                      </span>
                    </div>

                    <div className="row">
                      <span className="col-3">{I18n.t("views.upsell.difference")}</span>
                      <span className="col-9 fw-bold">
                        {accessDifference} {I18n.t("views.upsell.access")}
                      </span>
                    </div>

                    <div className="row">
                      <span className="col-3">{I18n.t("views.upsell.usage_periods")}</span>
                      <span className="col-9 fw-bold space-x-2 align-items-center">
                        {I18n.t("views.upsell.usage_periods_days", {
                          current_date: currentDate,
                          end_date: endCurrentPlanDate,
                          days: data.daysUntilEndOfTheCurrentPlan
                        })}
                      </span>
                    </div>

                    <div className="row">
                      <span className="col-3">{I18n.t("views.upsell.amount_to_pay")}</span>
                      <span className="col-9 fw-bold text-primary">
                        {CurrencyFormatter.formatter(data.data.amount_to_pay)}
                      </span>
                    </div>
                  </div>
                  <div className="card-body row fw-bold">
                    <span className="col-3">{I18n.t("views.upsell.due_date")}</span>
                    <span className="col-9 fw-bold">
                      {newInvoiceDueDate}
                    </span>
                  </div>
                </div>

                {/* Next invoice */}
                <div className="w-fit card">
                  <div className="card-header row fw-bold row">
                    <div className="d-flex align-items-center gap-2">
                      {I18n.t("views.upsell.next_invoice")}
                      <HelpPopover
                        placement="top"
                        content={I18n.t("views.upsell.stepper.step_two.section_2.period_not_changed.popover_2")}
                      />
                    </div>
                  </div>
                  <div className="card-body space-y-3">
                    <div className="row">
                      <span className="col-3">{I18n.t("views.upsell.new_limit")}</span>
                      <span className="col-9 fw-bold">
                        {selectedPlanAccessFormatted} {I18n.t("views.upsell.access")}
                      </span>
                    </div>

                    <div className="row">
                      <span className="col-3">{I18n.t("views.upsell.usage_periods")}</span>
                      <span className="col-9 fw-bold space-x-2 align-items-center">
                        {I18n.t("views.upsell.usage_periods_days", {
                          current_date: endCurrentPlanDate_NextDay,
                          end_date: endSelectPlanDateAfterFinalPlanCurrentDate,
                          days: totalDaysSelectedPlan
                        })}
                      </span>
                    </div>

                    {!!Number(data.data.discount_amount) && (
                      <div className="row">
                        <span className="col-3">
                          {I18n.t("views.upsell.discount_1")}
                        </span>
                        <span className="col-9 fw-bold d-flex align-items-center gap-2">
                          {I18n.t("views.upsell.lifetime", {
                            value: CurrencyFormatter.formatter(data.data.discount_amount)
                          })}
                          <HelpPopover
                            placement="top"
                            content={I18n.t("views.upsell.stepper.step_two.section_2.period_not_changed.popover_3")}
                          />
                        </span>
                      </div>
                    )}

                    <div className="row">
                      <span className="col-3">{I18n.t("views.upsell.amount_to_pay")}</span>
                      <span className="col-9 fw-bold text-primary">
                        {I18n.t("views.upsell.month_price", {
                          value: CurrencyFormatter.formatter(selectedPlanPriceByPeriod)
                        })}
                      </span>
                    </div>
                  </div>
                  <div className="card-body row fw-bold">
                    <span className="col-3">{I18n.t("views.upsell.due_date")}</span>
                    <span className="col-9 fw-bold">
                      {I18n.t("views.upsell.stepper.step_two.section_2.period_not_changed.charged_in", {
                        date: endCurrentPlanDate_NextDay
                      })}
                    </span>
                  </div>
                </div>

                <div className="col-12 fw-bold">
                  {I18n.t("views.upsell.stepper.step_two.section_2.period_not_changed.discount_current_plan")}
                </div>
              </div>
            )}

            {isPeriodPlanChanged && (
              <div className="card w-fit">
                <div className="card-header row fw-bold">
                  <span className="col-12">{data.selectedPlan?.plan}</span>
                </div>
                <div className="card-body space-y-3">
                  <div className="row">
                    <span className="col-3">{I18n.t("views.upsell.periodicity")}</span>
                    <span className="col-9 fw-bold">{data.selectedPlan.period}</span>
                  </div>

                  <div className="row">
                    <span className="col-3">{I18n.t("views.upsell.access_limit")}</span>
                    <span className="col-9 fw-bold">
                      {selectedPlanAccessFormatted} {I18n.t("views.upsell.access")}
                    </span>
                  </div>

                  <div className="row">
                    <span className="col-3">{I18n.t("views.upsell.total_period")}</span>
                    <span className="col-9 fw-bold">
                      {currentDate}
                      {" "}-{" "}
                      {endSelectedPlanDate}
                    </span>
                  </div>

                  {data.daysUntilEndOfTheCurrentPlan > 1 && (
                    <div className="row">
                      <span className="col-3">{I18n.t("views.upsell.discount_1")}</span>
                      <span className="col-9 fw-bold d-flex align-items-center gap-2">
                        -
                        {CurrencyFormatter.formatter(totalRemainingPayable)}

                        <HelpPopover
                          placement="top"
                          content={I18n.t("views.upsell.stepper.step_two.section_2.period_changed.popover_1")}
                        />
                      </span>
                    </div>
                  )}

                  {!!Number(data.data.discount_amount) && (
                    <div className="row">
                      <span className="col-3">
                        {I18n.t("views.upsell.discount_2")}
                      </span>
                      <span className="col-9 fw-bold d-flex align-items-center gap-2">
                        {I18n.t("views.upsell.lifetime", {
                          value: CurrencyFormatter.formatter(data.data.discount_amount)
                        })}
                        <HelpPopover
                          placement="top"
                          content={I18n.t("views.upsell.stepper.step_two.section_2.period_changed.popover_2")}
                        />
                      </span>
                    </div>
                  )}



                  <div className="row">
                    <span className="col-3">{I18n.t("views.upsell.amount_to_pay")}</span>
                    <span className="col-9 fw-bold text-primary">
                      {CurrencyFormatter.formatter(data.data.amount_to_pay)}
                    </span>
                  </div>
                </div>
              </div>


            )}
          </CardBase>

          <CardPaymentInfo
            price={data.data.amount_to_pay}
            installments={data.data.installments}
            financialEmail={data.data.financial_email}
            paymentMethods={data.data.payment_method}
          />
        </div>

        <CardSummary
          planName={data.selectedPlan.plan}
          planPrice={data.data.amount_to_pay}
          planPeriod={data.selectedPlan.period}
          remainingDays={data.daysUntilEndOfTheCurrentPlan}
        />
      </S.Wrapper>

      <Link
        to={"/upsell"}
        className="btn mt-3"
        onClick={resetPaymentProgress}
      >
        {I18n.t("views.upsell.back_to_plans")}
      </Link>
    </>
  );
};

export default StepTwo;
