import React from "react";

import { NewStyle } from "@/components/scripts/Shared/styles";

export const New = () => {

  return(
    <NewStyle>
      <div>
        <i className="ti ti-sparkles icon me-2"></i>
          {I18n.t("views.manage_flows.script_editor.news")}
      </div>
    </NewStyle>
  )
}
