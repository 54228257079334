import React, { useRef } from "react";

import RulesEditor from "./RulesEditor";
import ScriptCardBlock from "../ScriptCardBlock";
import ScriptCardFooter from "../ScriptCardFooter";

import parse from "html-react-parser";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';

const PagesPersonalization = () => {
  const isDefault = useContextSelector(ScriptContext, ({ state }) => state.default);
  const rulesEditorRef = useRef();

  const addButtonsList = [
    {
      label: I18n.t("views.manage_flows.script_editor.meta_ads_access"),
      ruleType: "v3_facebook_ads_match",
      ruleValue: "",
    },
    {
      label: I18n.t("views.manage_flows.script_editor.linkedin_ads_access"),
      ruleType: "v3_linkedin_ads_match",
      ruleValue: "",
    },
    {
      label: I18n.t("views.manage_flows.script_editor.google_ads_access"),
      ruleType: "v3_google_ads_match",
      ruleValue: "",
    },
    {
      label: I18n.t("views.manage_flows.script_editor.main_page"),
      ruleType: "v2_home",
      ruleValue: "",
    },
    {
      label: I18n.t("views.manage_flows.script_editor.price_page"),
      ruleType: "v1_match",
      ruleValue: "/preco",
    },
  ];

  const cardBody = {
    title: I18n.t("views.manage_flows.script_editor.page_and_campaign_customization"),
    icon: `ti ti-copy`,
    description: I18n.t("views.manage_flows.script_editor.create_rules_to_determine_which_audience"),
  };

  const cardFooterAllPages = {
    title: I18n.t("views.manage_flows.script_editor.pages_where_our_code_is_installed"),
    description: I18n.t("views.manage_flows.script_editor.we_recommend_that_you_customize_the_approach"),
  };

  const cardFooterSpecificPages = {
    title: I18n.t("views.manage_flows.script_editor.we_have_customization_rules_ready_for_you_to_use"),
    description: I18n.t("views.manage_flows.script_editor.structure_of_your_site_and_campaigns_html"),
  };

  const cardFooter = {
    title: isDefault
      ? cardFooterAllPages.title
      : cardFooterSpecificPages.title,

    description: isDefault
      ? cardFooterAllPages.description
      : cardFooterSpecificPages.description,
  };

  return (
    <>
      <ScriptCardBlock
        title={cardBody.title}
        icon={cardBody.icon}
        description={cardBody.description}
        cardBody={false}
        tutorialUrl={I18n.t('shared.helpscout.rules_page')}
      >
        <RulesEditor ref={rulesEditorRef} />
      </ScriptCardBlock>

      <ScriptCardFooter title={cardFooter.title}>
        <p className='mb-0 tracking-wide'>{parse(cardFooter.description)}</p>
        <div className={`mt-3 btn-list ${isDefault ? "d-none" : ""}`}>
          {addButtonsList.map((button) => {
            return <button
              key={button.label}
              type="button"
              className={`btn btn-outline-primary btn-sm rounded`}
              style={{ fontSize: ".83rem" }}
              onClick={() => {
                rulesEditorRef.current.addCustomListData(button.ruleType, button.ruleValue);
                MixpanelService.track('script_used_ready_made_personalization', {
                  "Personalização": button.label
                 });
              }}
            >
              <i className="ti ti-plus me-1"></i>
              {button.label}
            </button>;
          })}
        </div>
      </ScriptCardFooter>
    </>
  );
};

export default PagesPersonalization;
