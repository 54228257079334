import React from "react";
import { Card } from "react-bootstrap";

const IdleMessage = ({ title, description, icon }) => {
  return (
    <Card>
      <Card.Body
        className="w-100 h-100 p-4 d-flex flex-column align-items-center justify-content-center"
        style={{ minHeight: "625px" }}
      >
        <img
          className="rounded-circle mb-3"
          src={icon.imgUrl}
          style={{ width: "70px", height: "70px", padding: "20px", backgroundColor: icon.color }}
        />

        <div className="h3 text-center mb-2">{title}</div>

        <p className="text-center">{description}</p>
      </Card.Body>
    </Card>
  );
};

export default IdleMessage;
