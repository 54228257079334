import React from "react";

const WhatsAppButton = ({ children }) => {
  const whatsappMessage = encodeURIComponent(
    I18n.t("views.leadster_ai.page.trial_alerts.contact_button.chat_default_message")
  );

  return (
    <a
      href={`https://web.whatsapp.com/send?phone=5541991625792&text=${whatsappMessage}`}
      className="btn btn-success"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default WhatsAppButton;
