import React from 'react';

const OptimizationImage = ({ img, width = 100 }) => {
  const baseUrl = 'https://storage.googleapis.com/neuro-cdn/leadster/optimization';
  const speedometerUrl = `${baseUrl}/${img}.png`;

  return (
    <img
      src={speedometerUrl}
      alt="Otimização"
      className="optimization-image img-fluid"
      width={width}
    />
  );
};

export default OptimizationImage;
