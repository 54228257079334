import React from "react";

const WhatsAppButton = ({ children }) => {
  const whatsappMessage = encodeURIComponent(I18n.t("views.manage_flows.scheduler_block.hire_feature_contact_message"));

  return (
    <a
      href={`https://web.whatsapp.com/send?phone=5541991625792&text=${whatsappMessage}`}
      className="btn btn-success"
      target="_blank"
    >
      {children}
    </a>
  );
};

export default WhatsAppButton;
