import styled from "styled-components";

export const Dropzone = styled.div`
  border: var(--tblr-border-width) dashed var(--tblr-border-color);
  color: var(--tblr-secondary);
  padding: 2rem;
  cursor: pointer;

  &.drag-active {
    border: var(--tblr-border-width) dashed var(--tblr-primary);
    background: rgba(var(--tblr-primary-rgb), 0.01);
    color: var(--tblr-primary);
  }

  &.is-invalid {
    border: var(--tblr-border-width) dashed var(--tblr-danger);
    background: rgba(var(--tblr-danger-rgb), 0.01);
    color: var(--tblr-danger);

    i {
      color: var(--tblr-danger) !important;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
