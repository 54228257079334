import React from "react";
import PropTypes from "prop-types";

import useChartConfig from "./hooks/useChartConfig";

import InsufficientDataAlert from "../../Alerts/InsufficientDataAlert";

import ReactApexChart from "react-apexcharts";

import { ChartContainer } from "./styles";

const BarChart = ({ data }) => {
  const [chartConfig, hasInsufficientData] = useChartConfig(data);

  if (hasInsufficientData) {
    return <InsufficientDataAlert />;
  }

  return (
    <ChartContainer>
      <ReactApexChart
        options={chartConfig.options}
        series={chartConfig.series}
        type="bar"
        height={350}
      />
    </ChartContainer>
  );
};

BarChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default BarChart;
