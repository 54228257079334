import { useRef, useEffect, useState, forwardRef, useImperativeHandle, useCallback } from "react";
import PropTypes from "prop-types";

import useUpdateEffect from "@/hooks/useUpdateEffect";
import useCurrentUserStore from "@/store/currentUserStore";

import intlTelInput from "intl-tel-input";

import { Container } from "./styles";

const MAX_PHONE_LENGTH = 30;

const IntlPhoneInput = forwardRef(({ initialValue = "", onUpdatePhoneNumber = () => {}, ...props }, ref) => {
  const { isNational } = useCurrentUserStore((state) => state.currentUser);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [dialCode, setDialCode] = useState("");

  const inputRef = useRef();
  const intTelInstanceRef = useRef();

  useEffect(() => {
    initializeIntlInput();

    inputRef.current.addEventListener("countrychange", handleDialCodeChange);

    return () => {
      inputRef.current?.removeEventListener("countrychange", handleDialCodeChange);
    };
  }, []);

  useUpdateEffect(() => {
    if (!phoneNumber.length) {
      onUpdatePhoneNumber("");
    } else {
      const newPhoneNumber = `+${dialCode}${phoneNumber}`;
      onUpdatePhoneNumber(newPhoneNumber);
    }
  }, [phoneNumber, dialCode]);

  useImperativeHandle(ref, () => ({
    cleanNumber() {
      setPhoneNumber("");
    }
  }));

  const initializeIntlInput = useCallback(() => {
    const parsePhoneNumber = (phoneNumber) => {
      const defaultDialCode = isNational ? 55 : 1;
      let parsedPhoneNumber = phoneNumber;

      if (!parsedPhoneNumber.startsWith("+")) {
        parsedPhoneNumber = `+${defaultDialCode}${parsedPhoneNumber}`;
      }

      return parsedPhoneNumber.replace(/[^\d.+]/g, "");
    };

    intTelInstanceRef.current = intlTelInput(inputRef.current, { separateDialCode: true, countrySearch: true });
    intTelInstanceRef.current.setNumber(parsePhoneNumber(initialValue));

    setDialCode(intTelInstanceRef.current.getSelectedCountryData().dialCode);
    setPhoneNumber(inputRef.current.value);
  }, []);

  const handleDialCodeChange = useCallback(() => {
    setDialCode(intTelInstanceRef.current.getSelectedCountryData().dialCode);
  }, []);

  const handleInputChange = (e) => {
    let newPhoneNumber = e.target.value.replace(/\D/g, "");

    if (newPhoneNumber.length > MAX_PHONE_LENGTH) {
      newPhoneNumber = newPhoneNumber.slice(0, MAX_PHONE_LENGTH);
    }

    setPhoneNumber(newPhoneNumber);
  };

  return (
    <Container>
      <input
        ref={inputRef}
        value={phoneNumber}
        type="text"
        className="form-control"
        onChange={handleInputChange}
        maxLength={MAX_PHONE_LENGTH}
        placeholder="Ex: 4299998888 / 08001011123 / 3023843808"
        {...props}
      />
    </Container>
  );
});

IntlPhoneInput.propTypes = {
  initialValue: PropTypes.string,
  onUpdatePhoneNumber: PropTypes.func
};

export default IntlPhoneInput;
