import React from "react";
import PropTypes from "prop-types";

import { classBinding } from "@/utils/helpers";
import avaliableTabs from "./avaliable-tabs";

const NavigationTabs = ({ activeTab = "conversion", setActiveTab }) => {
  return (
    <>
      <ul className="nav nav-pills">
        {Object.entries(avaliableTabs).map(([tab, data]) => (
          <li className="nav-item" key={tab} onClick={() => setActiveTab(tab)}>
            <button
              type="button"
              className={classBinding({ active: activeTab == tab }, "nav-link")}
            >
              <i className={`ti ti-${data.icon} icon me-2`} />
              {data.label}
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

NavigationTabs.propTypes = {
  activeTab: PropTypes.oneOf(Object.keys(avaliableTabs)),
  setActiveTab: PropTypes.func.isRequired,
};

export default NavigationTabs;
