import "./styles.scss";
import React from "react";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';
import { can } from "@/lib/user-permission";

import parse from "html-react-parser";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const Box = ({ isMobile, identifier, calls, avatar }) => {
  const hasPermission = can(PERMISSION_SCOPE);
  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);

  let dispatchActionType = "SET_DESKTOP_CONVOSCRIPT_VALUE";

  if (isMobile) {
    dispatchActionType = "SET_MOBILE_CONVOSCRIPT_VALUE";
  }

  const setCallInEditor = (e, value) => {
    e.preventDefault();
    if (!hasPermission) return;

    const key = 'tooltipMessage';
    dispatch({ type: dispatchActionType, payload: { key, value } });
    dispatch({ type: "SET_PREVIEW_CONVOSCRIPT_VALUE", payload: { key, value } });

    MixpanelService.track('script_selected_conversion_call');
    $("#conversion-call-options").modal('hide');
  }

  return(
    <>
      <div className="boxes" id={identifier}>
        { Object.keys(calls).map((item, index) =>
          <div className="box mb-2" key={"box_" + index}>
            <div className="row">
              <div className="label col-12 col-md-auto">
                {I18n.t("views.call.smart_calls.average_conversion")} <span>{calls[item]['avg_conversion']}</span>
              </div>

              <span
                className="label col-12 col-md-auto cursor-help"
                data-bs-toggle="popover"
                data-bs-trigger="hover"
                data-bs-placement="right"
                data-bs-html="true"
                data-bs-content={`
                  <div class="text-primary fw-bold mb-2">
                    ${I18n.t("views.call.smart_calls.call_of")} ${calls[item]['type']}
                  </div>
                  ${I18n.t(`views.call.tooltip.${calls[item]['tooltip']}`)}
                `}
              >
                {I18n.t("views.call.type.title")} <span>{calls[item]['type']}</span>
              </span>
            </div>

            <div className="row">
              <div className="col-8">
                <div className="d-flex">
                  <img className="avatar rounded-circle me-3" src={avatar} alt="Avatar" />
                  <div className='position-relative'>
                    <div className="arrow-text"/>
                    <div className="text-example">
                      {parse(calls[item]['call'])}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-4 d-flex justify-content-center align-items-center">
                <button
                  type="button"
                  className="btn btn-primary btn-rounded fw-bold w-100"
                  onClick={(e) => setCallInEditor(e, calls[item]['call'])}
                >
                  <i className="ti ti-plus icon"></i>
                  {I18n.t("views.call.smart_calls.use_call")}
                </button>
              </div>
            </div>

          </div>
        )}
      </div>
    </>
  )
}

export default Box;
