import React from "react";
import PropTypes from "prop-types";

import { classBinding } from "@/utils/helpers";

const ListGroupAlert = ({
  title,
  subtitle = null,
  status = "secondary",
  strongAlert = false,
  preventBorder = false,
}) => {
  return (
    <div className={classBinding({ "border-0": preventBorder }, "list-group-item")}>
      <div className={`alert alert-${status} mb-0`}>
        <h4 className="alert-title mb-2 text-body">{title}</h4>
        {!!subtitle && (
          <div className={strongAlert ? `text-${status} fw-medium` : "text-muted"}>
            {subtitle}
          </div>
        )}
      </div>
    </div>
  );
};

ListGroupAlert.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  status: PropTypes.string,
};

export default ListGroupAlert;
