import styled from "styled-components";

export const Container = styled.div.attrs((props) => ({
  className: "rounded d-flex align-items-center p-3",
  side: props.side || "left",
}))`
  background: linear-gradient(
    90deg,
    ${(props) => (props.side === "left" ? "#C1E0FE" : "#CBEEFB")},
    ${(props) => (props.side === "right" ? "#D5FBF9" : "#E5F0FD")}
  );
`;

export const Icon = styled.span.attrs(() => ({
  className: "d-flex align-items-center justify-content-center text-primary me-1 fs-2",
}))``;

export const Message = styled.p.attrs(() => ({
  className: "d-flex align-items-center fw-medium m-0 p-0",
}))`
  gap: 4px;
`;
