import React, { cloneElement } from "react";

export default function Selector(props) {
  if (!props.children) return null;

  const children = props.children.map((c) =>
    cloneElement(c, {
      onSelect: c.props.onSelect ? c.props.onSelect : props.onSelect,
      selected: props.checkIfSelected(c.props.value),
    })
  );

  return (
    <div className="option-button-group">
      {children}
    </div>
  );
}
