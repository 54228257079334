import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 33%;

  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
  }
`;

export const Icon = styled.i`
    font-size: 60px;
    color: var(--tblr-success);
`;
