import PropTypes from "prop-types";

import ListGroupAlert from './ListGroupAlert';
import GoogleLogin from '@/components/representatives/GoogleLogin';
import TutorialButton from "@/components/guided_tutorial/TutorialButton";
import HelpPopover from "@/components/shared/HelpPopover";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from "use-context-selector";
import { can } from "@/lib/user-permission"

import useCurrentUserStore from "@/store/currentUserStore";

import * as S from "./styles";

const PERMISSION_SCOPE = Object.freeze({
  action: "manage",
  subject: "calendar_schedule_plugin",
});

const SellersSelector = ({
  selectedRepresentativesIds,
  updateDefinition,
  findRepresentatives,
  setSelectedRepresentativesIds,
}) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const allowConnectionToGoogle = useContextSelector(ScriptContext, ({ state }) => state.allowConnectionToGoogle);
  const representatives = useContextSelector(ScriptContext, ({ state }) => state.representatives);
  const { isPurchased, maxRepresentativesAllowed } = useCurrentUserStore((state) => state.currentUser.plugins.calendarSchedule);

  const allowedRepresentatives = representatives.filter((representative) => {
    return !selectedRepresentativesIds.includes(representative.id);
  });

  const selectedRepresentatives = representatives.filter((representative) => {
    return selectedRepresentativesIds.includes(representative.id)
  });

  const handleAddRepresentative = (representativeId) => {
    if (!hasPermission) return;
    if (selectedRepresentativesIds.indexOf(representativeId) !== -1) return;

    const updatedRepresentatives = [representativeId, ...selectedRepresentativesIds];
    setSelectedRepresentativesIds(updatedRepresentatives);
    updateDefinition({ representatives: updatedRepresentatives });
  }

  const handleRemoveRepresentative = (representativeId) => {
    if (!hasPermission) return;
    if (selectedRepresentativesIds.indexOf(representativeId) === -1) return;

    const updatedRepresentatives = selectedRepresentativesIds.filter((id) => id !== representativeId);
    setSelectedRepresentativesIds(updatedRepresentatives);
    updateDefinition({ representatives: updatedRepresentatives });
  }

  return (
    <div className='form-group'>
      <div className="form-label">
        {I18n.t("manage_flow.scheduler_block.calendar_accounts_label")}
        <TutorialButton tutorialUrl={I18n.t('shared.helpscout.google_calendar_function')} />
      </div>

      <div className="row g-3 mb-3">
        <div className="col-lg-6">
          <div className="list-group list-group-flush border rounded">
            <div className="list-group-header px-2">
              <div className="row">
                <div className='col'>
                  {I18n.t("views.manage_flows.scheduler_block.synced_accounts_available")}
                  <HelpPopover
                    className="ms-2"
                    content={I18n.t("views.manage_flows.scheduler_block.team_linked_accounts_message")}
                    placement="top"
                  />
                </div>

                {isPurchased && (
                  <div className='col-auto text-end'>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{I18n.t("views.manage_flows.scheduler_block.synced_accounts_limit_message")}</Tooltip>}
                    >
                      <a href="/users/edit#tab-google" target="_blank" className="badge fw-bold bg-primary-lt">
                        {representatives.length} / {maxRepresentativesAllowed}{" "}
                        {I18n.t("views.manage_flows.scheduler_block.linked_accounts")}
                      </a>
                    </OverlayTrigger>
                  </div>
                )}
              </div>
            </div>

            <S.ListGroupContainer>
              {allowedRepresentatives.map((representative) => {
                return (
                  <div className="list-group-item py-2" key={representative.id}>
                    <div className="d-flex align-items-center">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{I18n.t("general.actions.add")}</Tooltip>}
                      >
                        <label className="form-switch col-auto">
                          <input
                            className="form-check-input cursor-pointer"
                            type="checkbox"
                            onClick={() => handleAddRepresentative(representative.id)}
                          />
                        </label>
                      </OverlayTrigger>

                      <div className="ms-2 text-truncate">
                        <div className="text-body fw-medium">
                          {representative.name}
                        </div>
                        <div className="d-block text-muted text-truncate">
                          {representative.email}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

              {!representatives.length ? (
                <ListGroupAlert
                  title={I18n.t("views.manage_flows.scheduler_block.no_synced_accounts_message")}
                  subtitle={I18n.t("views.manage_flows.scheduler_block.register_google_accounts_message")}
                  status='danger'
                />
              ) : (!allowedRepresentatives.length ? (
                <ListGroupAlert
                  title={I18n.t("views.manage_flows.scheduler_block.all_selected_message")}
                  subtitle={I18n.t("views.manage_flows.scheduler_block.register_new_google_accounts_message")}
                  status='primary'
                />
              ) : null)}
            </S.ListGroupContainer>

            <div className="p-2 d-flex flex-column h-100 align-items-center justify-content-center py-2">
              {allowConnectionToGoogle && (
                <>
                  <GoogleLogin
                    onSuccessCallback={() => findRepresentatives()}
                    enabled={hasPermission}
                    className="btn-primary w-100"
                  />

                  <div className="hr-text my-3">{I18n.t("views.manage_flows.script_editor.or")}</div>
                </>
              )}

              <a href="/users/edit#tab-google" target='_blank'>
                <i className="ti ti-users me-2"></i>
                { representatives.length ?
                  I18n.t("views.manage_flows.scheduler_block.manage_google_calendar_accounts") :
                  I18n.t("views.manage_flows.scheduler_block.register_your_team")
                }
              </a>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="list-group list-group-flush border rounded h-100">
            <div className="list-group-header px-2">
              {I18n.t("views.manage_flows.scheduler_block.active_accounts")}
              <HelpPopover
                className="ms-2"
                content={I18n.t("views.manage_flows.scheduler_block.active_team_accounts_message")}
                placement="top"
              />
            </div>

            <S.ListGroupContainer>
              {selectedRepresentatives.map((representative) => {
                return  (
                  <div className={`list-group-item py-2 ${selectedRepresentatives.length === 1 ? 'border-0' : ''}`} key={representative.id}>
                    <div className="d-flex align-items-center">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{I18n.t("general.actions.remove")}</Tooltip>}
                      >
                        <label className="form-switch col-auto">
                          <input
                            className="form-check-input form-check-green cursor-pointer"
                            type="checkbox"
                            checked
                            onClick={() => handleRemoveRepresentative(representative.id)}
                          />
                        </label>
                      </OverlayTrigger>

                      <div className="ms-2 text-truncate">
                        <div className="text-body fw-medium">
                          {representative.name}
                        </div>
                        <div className="text-muted text-truncate">
                          {representative.email}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

              {!selectedRepresentatives.length && (
                <ListGroupAlert
                  title={I18n.t("views.manage_flows.scheduler_block.no_active_accounts_message")}
                  subtitle={I18n.t("views.manage_flows.scheduler_block.activate_account_instructions")}
                  status='danger'
                  strongAlert
                  preventBorder
                />
              )}
            </S.ListGroupContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

SellersSelector.propTypes = {
  selectedRepresentativesIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  updateDefinition: PropTypes.func.isRequired,
  findRepresentatives: PropTypes.func.isRequired,
  setSelectedRepresentativesIds: PropTypes.func.isRequired,
};

export default SellersSelector;
