import React from "react";

import swal from "sweetalert";

const MAX_RULE_LENGTH = 256;

export default class RuleEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paramKey: "",
      paramValue: "",
    }
  }

  componentDidUpdate() {
    window.initJqueryTooltip();
  }

  updateParamsData(name, value) {
    this.setState({ [name]: value }, () => {
      this.props.update({
        ...this.props.data,
        rule: this.state.paramKey + "=" + this.state.paramValue
      });
    })
  }

  updateParamsKey(el) {
    this.updateParamsData('paramKey', $(el).val())
  }

  updateParamsValue(el) {
    this.updateParamsData('paramValue', $(el).val())
  }

  updateRule(el) {
    const rule = $(el).val();

    this.props.update({
      ...this.props.data,
      rule: rule
    });
  }

  updateKind(kind) {
    this.props.update({
      ...this.props.data,
      kind: kind
    });

    const rule = this.props.data.rule;

    if (kind == "v3_param_match") {
      if (rule.split("=").length > 1) {
        this.updateParamsData('paramKey', rule.split("=")[0]);
        this.updateParamsData('paramValue', rule.split("=")[1]);
      }
    }
  }

  removeRuleById(id) {
    if (this.props.rulesCount <= 1) {
      return swal("", I18n.t("manage_flow.script_editor.rules_must_contain_at_least_one_item"), "warning");
    }

    this.props.updateRulesCount(-1);
    this.props.remove(id);
  }

  render() {
    const defaultKind = "v1_match";

    const { id, kind, rule } = this.props.data;
    const { dynamicData, index } = this.props;

    const typeToOperator = {
      "all": I18n.t("views.manage_flows.script_editor.and"),
      "any": I18n.t("views.manage_flows.script_editor.or"),
    };

    const placeholders = {
      "v1_match": I18n.t("views.manage_flows.script_editor.paste_your_url_here_or_type_a_word"),
      "v1_not_match": I18n.t("views.manage_flows.script_editor.paste_your_url_here_or_type_a_word"),
      "v1_domain_and_path_match": I18n.t("views.manage_flows.script_editor.paste_or_type_your_url"),
      "v1_domain_match": I18n.t("views.manage_flows.script_editor.paste_your_domain_or_part_of_it_here"),
      "v2_not_domain_match": I18n.t("views.manage_flows.script_editor.paste_your_domain_or_part_of_it_here"),
    };

    const hasParameter = {
      "v1_match": true,
      "v1_not_match": true,
      "v1_domain_and_path_match": true,
      "v1_domain_match": true,
      "v2_not_domain_match": true,
      "v2_home": false,
      "v3_google_ads_match": false,
      "v3_facebook_ads_match": false,
      "v3_linkedin_ads_match": false,
      "v3_param_match": false,
    }

    return <div className="row g-2 mb-md-3">
      <div className="col-12 col-md-auto d-md-flex justify-content-md-center align-items-md-center">
        <div className={`hr-text my-3 d-md-none ${!!Math.min(1, index) ? 'd-flex' : 'd-none'}`}>
          <span>{typeToOperator[dynamicData]}</span>
        </div>

        <div className="d-none d-md-block" style={{ opacity: Math.min(1, index) }}>
          {typeToOperator[dynamicData]}
        </div>
      </div>

      <div className="col-12 col-sm-12 col-md mt-0 mt-md-2">
        <select
          className="form-select"
          defaultValue={kind || defaultKind}
          onChange={(e) => this.updateKind(e.target.value)}
        >
          <option value="v1_match">{I18n.t("views.manage_flows.script_editor.contains_the_url_or_word")}</option>
          <option value="v1_not_match">{I18n.t("views.manage_flows.script_editor.does_not_contain_the_url_or_word")}</option>
          <option value="v1_domain_match">{I18n.t("views.manage_flows.script_editor.the_domain_contains")}</option>
          <option value="v2_not_domain_match">{I18n.t("views.manage_flows.script_editor.the_domain_does_not_contain")}</option>
          <option value="v1_domain_and_path_match">{I18n.t("views.manage_flows.script_editor.url_exactly_same")}</option>
          <option value="v2_home">{I18n.t("views.manage_flows.script_editor.its_home_page")}</option>
          <option value="v3_google_ads_match">{I18n.t("views.manage_flows.script_editor.origin_is_google_ads")}</option>
          <option value="v3_facebook_ads_match">{I18n.t("views.manage_flows.script_editor.origin_is_meta_ads")}</option>
          <option value="v3_linkedin_ads_match">{I18n.t("views.manage_flows.script_editor.origin_is_linkedin_ads")}</option>
          <option value="v3_param_match">{I18n.t("views.manage_flows.script_editor.url_parameter")}</option>
        </select>
      </div>

      { hasParameter[kind] && (
        <>
          <div className="col-auto d-none d-md-flex justify-content-center align-items-center">
            <i className="ti ti-arrow-right" />
          </div>

          <div className="col-12 col-md form-group">
            <div className="input-group">
              <input
                onChange={e => this.updateRule(e.target)}
                type="text"
                maxLength={MAX_RULE_LENGTH}
                className={'form-control input-lg required-rule' + (rule.length >= MAX_RULE_LENGTH ? ' input-error' : '')}
                placeholder={placeholders[kind || defaultKind]}
                value={rule}
              />

              <span className="input-group-text">
                {rule.length}/{MAX_RULE_LENGTH}
              </span>
            </div>
          </div>
        </>
      )}

      {kind === 'v3_param_match' && (
        <>
          <div className="col-auto d-none d-md-flex justify-content-center align-items-center">
            <a
              href={I18n.t("shared.helpscout.url_param_page_personalization")}
              className="form-help bg-primary-lt text-white"
              target="_blank"
              title={I18n.t("manage_flow.script_editor.see_how_to_use_url_parameters")}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
            >
              <i className="fa fa-info" />
            </a>
          </div>

          <div className="col-4 col-md-3 form-group">
            <input
              type="text"
              onChange={e => this.updateParamsKey(e.target)}
              maxLength={MAX_RULE_LENGTH}
              className={'form-control input-lg required-rule' + (rule.length >= MAX_RULE_LENGTH ? ' input-error' : '')}
              placeholder={I18n.t("manage_flow.script_editor.example_utm_source")}
              value={rule.split("=")[0]}
            />
          </div>

          <div className="col-1 col-md-auto  d-flex justify-content-center align-items-center">
            <i className="ti ti-equal" />
          </div>

          <div className="col-7 col-md-3 form-group">
            <div className="input-group">
              <input
                type="text"
                onChange={e => this.updateParamsValue(e.target)}
                maxLength={MAX_RULE_LENGTH}
                className={'form-control input-lg required-rule' + (rule.length >= MAX_RULE_LENGTH ? ' input-error' : '')}
                placeholder={I18n.t("manage_flow.script_editor.example_facebook")}
                value={rule.split("=")[1]}
              />

              <span className="input-group-text">
                {rule.length}/{MAX_RULE_LENGTH}
              </span>
            </div>
          </div>
        </>
      )}

      <div className="col col-md-auto d-grid">
        <button
          type="button"
          className="btn btn-danger btn-icon"
          onClick={() => this.removeRuleById(id)}
        >
          <i className="ti ti-trash-x icon" />
        </button>
      </div>
    </div>;
  }
}
