import React from "react";

const NoAnalyticsAvaliableAlert = () => {
  return (
    <div className="empty mt-0">
      <div className="empty-header">{I18n.t("views.analytics.error.ops")}</div>
      <p className="empty-title">
        {I18n.t("views.analytics.not_found_section")}
      </p>
    </div>
  );
};

export default NoAnalyticsAvaliableAlert;
