import styled from 'styled-components';

export const Container = styled.div`
  justify-content: center;
  align-items: center;
`;

export const CodeBlock = styled.div`
  margin: 0;
  width: 100%;
`;

export const Button = styled.button`
  font-size: .875rem;
  border-radius: 20px;
  margin-top: 20px;
`;
