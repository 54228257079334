import styled from "styled-components";

export const DropdownButton = styled.button.attrs({
  type: "button",
  className: "btn dropdown-toggle border-start-0",
})`
  &:hover,
  &:active,
  &.show {
    color: var(--tblr-btn-color) !important;
    text-decoration: none !important;
    background-color: var(--tblr-btn-bg) !important;
    border-color: var(--tblr-btn-border-color) !important;
  }
`;
