import styled from "styled-components";

export const MessageCard = styled.div.attrs((props) => ({
    className: `${props.isAnswer ? 'mb-4' : 'mb-0'} p-3`,
    isAnswer: props.isAnswer,
}))`
  max-width: 332px;
  background-color: ${props => props.isAnswer ? '#CCE2FB' : '#F5F8FA'};
  padding: 10px;
  border-radius: 15px;
  color: initial;
  align-self: ${props => props.isAnswer ? 'end' : 'start'};
  border-bottom-right-radius: ${props => props.isAnswer ? '5px' : '15px'};
  border-bottom-left-radius: ${props => props.isAnswer ? '15px' : '5px'};
  time {
    font-size: 9px;
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
  }
`;

export const ListGroupItem = styled.div.attrs((props) => ({
  className: "list-group-item",
  Active: props.active,
}))`
  background-color: ${props => props.active ? '#CCE2FB' : 'white'};
  &:hover {
    background-color: #CCE2FB;
  }
`;

export const Answer = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width:100%;
`;

export const Message = styled.p`
  overflow: hidden;
  width:100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const Avatar = styled.img.attrs((props) => ({
  className: "rounded-circle text-center me-2 d-flex align-items-center justify-content-center bg-secondary text-white",
}))`
  width: 32px;
  height: 32px;
`;
