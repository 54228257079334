import { useEffect, useState, useRef, memo } from "react";

import useDinamycFields from "@/components/scripts/hooks/useDinamycFields";

import CloneBlockButton from "@/components/scripts/ScriptEditorParts/CloneBlockButton";
import AvailabilitySchedule from "../AvailabilitySchedule";
import MultiplePhones from "./MultiplePhones";
import DeleteButton from '../ScriptEditorParts/DeleteButton';
import PermissionsGate from '@/components/permissions/PermissionsGate';
import IntlPhoneInput from "@/components/shared/IntlPhoneInput";
import TutorialButton from "@/components/guided_tutorial/TutorialButton";
import RichTextEditor from "@/components/shared/RichTextEditor";
import HelpPopover from "@/components/shared/HelpPopover";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { can } from "@/lib/user-permission";
import { selectAllLabels } from "@/components/scripts/ScriptEditor/scriptFieldLabels";
import { nameToSuffix, removeItemFromArray } from "../ScriptEditorParts/script-utils";
import setupEditor from "../ScriptEditorParts/setup-editor";

import imgWhatsAppLogo from '../../../../assets/images/wpp_logo2.png'

import { Container } from "./styles";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const ScriptBlockWhatsapp = (props) => {
  const hasPermission = can(PERMISSION_SCOPE);
  const dynamicFields = useDinamycFields();

  const [wppMessage, setWppMessage] = useState(props.definition.wppMessage || "");
  const [botMessage, setBotMessage] = useState(props.definition.botMessage || "");
  const [useMultiplePhones, setUseMultiplePhones] = useState(!!props.definition.useMultiplePhones);
  const [useSchedule, setUseSchedule] = useState(!!props.definition.useSchedule);
  const [useAutoRedirect, setUseAutoRedirect] = useState(!!props.definition.useAutoRedirect);
  const [useCancelAutomaticRedirectTime, setUseCancelAutomaticRedirectTime] = useState(!!props.definition.useCancelAutomaticRedirectTime);

  const [label, setLabel] = useState(props.definition.label || "");
  const [tmpLabel, setTmpLabel] = useState(props.definition.label || "");
  const [labelAlreadyExist, setLabelAlreadyExist] = useState(false);

  const modalRef = useRef();

  const phoneNumbers = props.definition.phoneNumbers || [];
  const schedules = props.definition.schedules || [];

  const saveSettings = () => {
    if (!hasPermission) return;
    if (isInvalidLabel()) return;

    const newLabel = tmpLabel.trim();

    setTmpLabel(newLabel);
    setLabel(newLabel);
    definition({ label: newLabel });

    $(modalRef.current).modal("hide");
  };

  useEffect(() => {
    window.initInputMasks();
  });

  const isEmptyLabel = () => {
    return !tmpLabel.trim();
  };

  const isInvalidLabel = () => {
    return isEmptyLabel() || labelAlreadyExist;
  };

  const handleWppMessageChange = (msg, editor) => {
    if (!hasPermission) return;

    setWppMessage(msg);
    definition({ wppMessage: editor.getContent({ format: 'text' }) });
  }

  const handleEnableSchedule = (isEnabled) => {
    if (!hasPermission) return;

    setUseSchedule(isEnabled);
    definition({ useSchedule: isEnabled });
  }

  const handleEnableMultiplePhones = (isEnabled) => {
    if (!hasPermission) return;

    setUseMultiplePhones(isEnabled);
    definition({ useMultiplePhones: isEnabled });
  }

  const handleBotMessageChange = (msg, editor) => {
    setBotMessage(msg);
    definition({ botMessage: editor.getContent({ format: 'text' }) });
  }

  const updatePhoneNumbers = (newPhoneNumber) => {
    const newDefinition = { ...props.definition, wppPhone: newPhoneNumber };
    props.setDefinition(newDefinition);
  };

  const definition = (def) => {
    const mergedDef = Object.assign(props.definition, def);
    if (def.wppPhone !== undefined) {
      updatePhoneNumbers(def.wppPhone);
    }
    props.setDefinition(mergedDef);
  }

  const handleUpdateLabel = (newLabel) => {
    if (!hasPermission) return;

    const replacedValue = newLabel.replace(/[^:\+\w\s]/gi, "");
    const labels = selectAllLabels();
    const previewLabels = removeItemFromArray(labels, label);

    setTmpLabel(replacedValue);
    setLabelAlreadyExist(previewLabels.includes(replacedValue.trim()));
  };

  const inputClassName = () => {
    const isInvalid = isInvalidLabel() ? "is-invalid" : "";
    return `form-control ${isInvalid}`;
  };

  return (
    <Container>
      <div className="row gx-2 mb-3">
        <div className="col">
          <div className="input-group">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{I18n.t("views.manage_flows.script_editor.click_here_to_edit_settings")}</Tooltip>}
            >
              <button
                type="button"
                className="btn btn-success pe-1"
                onClick={() => $(modalRef.current).modal("show")}
              >
                <img src={imgWhatsAppLogo} width={16} height={16} className="me-2" />
                {nameToSuffix(label)}
                <i className="ti ti-dots-vertical ms-2" />
              </button>
            </OverlayTrigger>

            <div className="form-control p-0 border-0">
              <RichTextEditor
                value={botMessage}
                inline={true}
                init={{
                  menubar: false,
                  placeholder: I18n.t("views.manage_flows.script_editor.type_a_message_here"),
                  plugins: [],
                  toolbar: "autocompleteButton",
                  setup: (editor) => setupEditor(editor, dynamicFields, props.id),
                }}
                onEditorChange={handleBotMessageChange}
              />
            </div>
          </div>
        </div>

        <div className="col-auto">
          <CloneBlockButton onDuplicate={props.onDuplicate} enabled={hasPermission} />
        </div>

        <div className="col-auto">
          <DeleteButton onDelete={props.onDelete} />
        </div>
      </div>

      <div>
        <div className="mb-3">
          <label className="form-check form-switch d-flex">
            <input
              className="form-check-input me-2"
              type="checkbox"
              defaultChecked={useSchedule}
              disabled={!hasPermission}
              onChange={(e) => handleEnableSchedule(e.target.checked)}
            />

            <PermissionsGate scope={PERMISSION_SCOPE} usePopover>
              <span className="form-check-label fw-medium">
                {I18n.t("views.manage_flows.whatsapp_block.opening_hours")}
                <TutorialButton tutorialUrl={I18n.t('shared.helpscout.whatsapp_redirect')} />
              </span>
            </PermissionsGate>
          </label>

          <div className="form-text text-body">
            {I18n.t("views.manage_flows.whatsapp_block.when_the_whatsapp_button_will_be_enabled")}
          </div>

          {useSchedule && (
            <AvailabilitySchedule
              initialSchedules={schedules}
              setDefinition={(schedules) => definition({ schedules })}
            />
          )}
        </div>

        <div className="mb-3">
          <div>
            <label className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked={useAutoRedirect}
                onChange={(e) => {
                  setUseAutoRedirect(e.target.checked);
                  definition({ useAutoRedirect: e.target.checked })
                }}
              />
              <span className="form-check-label fw-medium">
                {I18n.t("views.manage_flows.whatsapp_block.open_whatsapp_automatically")}
              </span>
            </label>

            <div className="form-text text-body">
              {I18n.t("views.manage_flows.whatsapp_block.send_the_lead_to_the_conversation_on_whatsapp")}
            </div>
          </div>
        </div>

        <div className="mb-3 ms-4 border rounded p-3">
          <div>
            <label className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked={useCancelAutomaticRedirectTime}
                disabled={!useAutoRedirect}
                onChange={(e) => {
                  setUseCancelAutomaticRedirectTime(e.target.checked);
                  definition({ useCancelAutomaticRedirectTime: e.target.checked })
                }}
              />
              <span className="form-check-label fw-medium">
                {I18n.t("views.manage_flows.whatsapp_block.redirection_cancellation_time")}
              </span>
            </label>

            <div className="form-text text-body">
              {I18n.t("views.manage_flows.whatsapp_block.redirection_cancellation_time_description")}
            </div>
          </div>
        </div>

        <div className="mb-3">
          <div>
            <label className="form-check form-switch d-flex">
              <input
                className="form-check-input me-2"
                type="checkbox"
                defaultChecked={useMultiplePhones}
                disabled={!hasPermission}
                onChange={(e) => handleEnableMultiplePhones(e.target.checked)}
              />

              <PermissionsGate scope={PERMISSION_SCOPE} usePopover>
                <span className="form-check-label fw-medium">
                  {I18n.t("views.manage_flows.whatsapp_block.whatsapp_roulette")}
                </span>
              </PermissionsGate>
            </label>

            <div className="form-text text-body">
              {I18n.t("views.manage_flows.whatsapp_block.split_your_leads_among_your_team")}
            </div>
          </div>
        </div>

        <div className="mb-3">
          {useMultiplePhones ? (
            <MultiplePhones
              initialPhoneNumbers={phoneNumbers}
              updateDefinition={(phoneNumbers) => definition({ phoneNumbers })}
            />
          ) : (
            <div className="row">
              <div className="col-12 col-md-5">
                <label className="form-label">
                  {I18n.t("views.manage_flows.whatsapp_block.your_whatsapp_number")}
                </label>

                <IntlPhoneInput
                  initialValue={props.definition.wppPhone || ""}
                  onUpdatePhoneNumber={(newPhoneNumber) => {
                    definition({ wppPhone: newPhoneNumber });
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="form-label">
            {I18n.t("views.manage_flows.whatsapp_block.message_sent_on_whatsapp")}
          </div>

          <PermissionsGate scope={PERMISSION_SCOPE}>
            <div>
              <RichTextEditor
                value={wppMessage}
                inline={true}
                disabled={!hasPermission}
                init={{
                  menubar: false,
                  placeholder: I18n.t("views.manage_flows.script_editor.type_a_message_here"),
                  plugins: [],
                  toolbar: "autocompleteButton",
                  setup: (editor) => setupEditor(editor, dynamicFields, props.id),
                }}
                onEditorChange={handleWppMessageChange}
              />
            </div>
          </PermissionsGate>
        </div>
      </div>

      <div ref={modalRef} className="modal modal-blur fade" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
              <h5 className="modal-title">
              {I18n.t("views.manage_flows.script_editor.user_response_settings")}
              </h5>
            </div>

            <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon>
              <div className="modal-body py-3">
                <div>
                  <label className="form-label fw-medium">
                    {I18n.t("views.manage_flows.script_editor.field_name")}
                    <HelpPopover
                      className="ms-2"
                      content={I18n.t("views.manage_flows.script_editor.this_field_does_not_accept_accents")}
                    />
                  </label>

                  <input
                    type="text"
                    className={inputClassName()}
                    onChange={(e) => handleUpdateLabel(e.target.value)}
                    value={tmpLabel}
                    maxLength="255"
                    placeholder={I18n.t("views.manage_flows.script_editor.name_of_this_field")}
                  />

                  <div className="invalid-feedback mt-2">
                    {isEmptyLabel() && (<div>{I18n.t("views.manage_flows.script_editor.field_name_cannot_be_blank")}</div>)}
                    {labelAlreadyExist && (<div>{
                      I18n.t("views.manage_flows.script_editor.another_field_with_this_name_already_exists")
                    }</div>)}
                  </div>
                </div>
              </div>
            </PermissionsGate>

            <div className="modal-footer">
              <button
                type="button"
                className="btn me-auto"
                onClick={() => $(modalRef.current).modal("hide")}
              >
                {I18n.t("shared.actions.cancel")}
              </button>

              <PermissionsGate scope={PERMISSION_SCOPE}>
                <button
                  type="button"
                  className="btn btn-primary btn-rounded"
                  disabled={isInvalidLabel()}
                  onClick={saveSettings}
                >
                  {I18n.t("shared.actions.save")}
                </button>
              </PermissionsGate>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default memo(ScriptBlockWhatsapp, () => true)
