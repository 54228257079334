import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Form, Col, Row, ListGroup, Card } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import {fetchLinkedScripts, fetchMessage, fetchMessageHistory} from "../services/fetchServices";
import useCreateYourAiAssistant from "@/hooks/useCreateYourAiAssistant";

import IdleMessage from "./components/IdleMessage";
import EvaluationConversation from "./components/EvaluationConversation";
import ModalLeadView from "@/components/lead/ModalLeadView";
import HelpPopover from "@/components/shared/HelpPopover";

import HourGlassIcon from "@/../assets/images/ai_assistant/hourglass-done.png";
import SleepingFaceIcon from "@/../assets/images/ai_assistant/sleeping-face.png";

import * as S from "./styles";

const MessageHistory = () => {
  useCreateYourAiAssistant("/history", "analytics");

  const { assistantId } = useParams();

  const [showMessages, setShowMessages] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [messageList, setMessageList] = useState([]);
  const [messageHistoryList, setMessageHistoryList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [scriptFilter, setScriptFilter] = useState([]);
  const [scriptSelectFilter, setScriptSelectFilter] = useState([]);
  const [conversationFilter, setConversationFilter] = useState(null);
  const [evaluationFilter, setEvaluationFilter] = useState(null);

  const handleScriptFilterChange = (event) => {
    setScriptSelectFilter(event.target.value === "all" ? [null] : event.target.value);
  }

  const handleConversationFilterChange = (event) => {
    setConversationFilter(event.target.value === "all" ? [null] : event.target.value);
  }

  const handleEvaluationFilterChange = (event) => {
    setEvaluationFilter(event.target.value === "all" ? [null] : event.target.value);
  }

  const loadScriptFilter = async () => {
    const { linkedScripts } = await fetchLinkedScripts({ assistantId })

    setScriptFilter(linkedScripts);
    setScriptSelectFilter(linkedScripts?.map(({ id }) => id));
  }

  const loadMessages = async () => {
    const { messages } = await fetchMessage({
      page: 1,
      scripts: scriptSelectFilter,
      conversation: conversationFilter,
      evaluationConversation: evaluationFilter,
      assistantId
    });

    setMessageList(messages);
    setShowMessages(true);
  }

  const loadMessagesHistory = async (leadId) => {
    const { messagesHistory } = await fetchMessageHistory({
      selectedMessage: leadId,
      page: 1,
      scripts: scriptSelectFilter,
      conversation: conversationFilter,
      evaluationConversation: evaluationFilter,
      assistantId
    });

    setMessageHistoryList(messagesHistory);
  }

  useEffect(() => {
    loadScriptFilter();
  }, []);

  useEffect(() => {
    loadMessages();
    setMessageHistoryList([]);
    setSelectedRecord({});
  }, [scriptSelectFilter, conversationFilter, evaluationFilter]);

  return (
    <div>
      <Row className="mb-3">
        <Col>
          <Form.Select onChange={handleScriptFilterChange} defaultValue={"all"}>
            <option value="all">{I18n.t("views.leadster_ai.page.analytics.history.all_flows")}</option>
            {scriptFilter?.map(({ id, name }) => (
              <option key={id} value={id}>{name}</option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <Form.Select onChange={handleConversationFilterChange} defaultValue={"all"}>
            <option value="all">{I18n.t("views.leadster_ai.page.analytics.history.all_answers")}</option>
            <option value="done">{I18n.t("views.leadster_ai.page.analytics.history.answered")}</option>
            <option value="not_found">{I18n.t("views.leadster_ai.page.analytics.history.unanswered")}</option>
          </Form.Select>
        </Col>
        <Col>
          <Form.Select onChange={handleEvaluationFilterChange} defaultValue={"all"}>
            <option value="all">{I18n.t("views.leadster_ai.page.analytics.history.all_reviews")}</option>
            <option value="true">{I18n.t("views.leadster_ai.page.analytics.history.positive_reviews")}</option>
            <option value="false">{I18n.t("views.leadster_ai.page.analytics.history.negative_reviews")}</option>
          </Form.Select>
        </Col>
      </Row>

      <Row>
        <Col style={{ maxWidth: '469px' }}>
          {showMessages && messageList.length != 0 ? (
            <InfiniteScroll
              dataLength={messageList.length}
              hasMore={hasMore}
              inverse={false}
              height={625}
            >
              <ListGroup>
                {messageList.map((record) => (
                  <S.ListGroupItem
                    key={record.lead_id}
                    active={record.lead_id === selectedRecord?.lead_id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedRecord(record);
                      loadMessagesHistory(record.lead_id);
                    }}
                  >
                    <div className="mb-2 text-secondary">
                      <time>{dayjs(record.created_at).format(I18n.t("js.formats.default.date_time"))}</time>
                    </div>
                    <S.Answer className="mb-2 fw-bolder">Visitante: {record.question}</S.Answer>
                    <S.Message className="fw-light" >{record.assistant_name}: {record.answer}</S.Message>
                  </S.ListGroupItem>
                ))}
              </ListGroup>
            </InfiniteScroll>
          ) : (
            <IdleMessage
              title={I18n.t("views.leadster_ai.page.analytics.history.idle_messages.title")}
              description={I18n.t("views.leadster_ai.page.analytics.history.idle_messages.description")}
              icon={{ imgUrl: HourGlassIcon, color: '#00CFFF' }}
            />
          )}
        </Col>

        <Col style={{ maxWidth: '469px' }}>
          {showMessages && messageHistoryList.length != 0 ? (
            <Card>
              <Card.Header className="bg-white d-flex justify-content-between">
                {selectedRecord.lead_deleted_at ? (
                  <div className="d-flex align-items-center fw-bold">
                    <i className="ti ti-user-x me-2 fs-2/3/4/5" />
                    <span>{I18n.t("views.leadster_ai.page.analytics.history.no_data")}</span>
                    <HelpPopover
                      className="ms-2"
                      content={I18n.t("views.leadster_ai.page.analytics.history.no_data_popover")}
                      placement="top"
                    />
                  </div>
                ) : (
                  <>
                    <div className="fw-bold">{I18n.t("views.leadster_ai.page.analytics.history.see_data")}</div>
                    <ModalLeadView id={selectedRecord.lead_id} size="xl" icon="user" />
                  </>
                )}
              </Card.Header>
              <Card.Body className='d-flex flex-column justify-content-end'>
                <InfiniteScroll
                  dataLength={messageHistoryList.length}
                  next={() => { }}
                  hasMore={hasMore}
                  inverse={true}
                  height={458}
                >
                  {messageHistoryList.map(({ created_at, question, answer, avatar, assistant_name }, key) => (
                    <div key={key}>
                      <S.MessageCard isAnswer>
                        <p className='m-0'>
                          {question}
                        </p>
                        <time>{dayjs(created_at).format(I18n.t("js.formats.default.date_time"))}</time>
                      </S.MessageCard>
                      <div className='d-flex align-items-end mb-4'>
                        <S.Avatar alt={assistant_name[0]} src={avatar} />
                        <S.MessageCard>
                          <p className='m-0'>
                            {answer}
                          </p>
                          <time>{dayjs(created_at).format(I18n.t("js.formats.default.date_time"))}</time>
                        </S.MessageCard>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              </Card.Body>
              <EvaluationConversation
                leadId={selectedRecord.lead_id}
                scriptId={selectedRecord.script_id}
                assistantId={selectedRecord.ai_assistant_id}
                isLike={selectedRecord.like}
                onClick={() => { loadMessages(); }}
              />
            </Card>
          ) : (
            <IdleMessage
              title={I18n.t("views.leadster_ai.page.analytics.history.idle_message_details.title")}
              description={I18n.t("views.leadster_ai.page.analytics.history.idle_message_details.description")}
              icon={{ imgUrl: SleepingFaceIcon, color: '#007DFF' }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default MessageHistory;
