import create from 'zustand';

const useAlertDefault = create((set) => ({
	alert: {
		open: false,
		icon: '',
		image: '',
		title: '',
		description: '',
		buttons: [{
			text: '',
			action: null
		}],
		closeButton: {
			text: 'Fechar',
			action: null,
			closeAfter: true
		}
	},
	showAlert: (alertData) =>
		set((state) => ({
			alert: {
				...state.alert,
				...alertData,
				open: true,
			},
		})),
	hideAlert: () =>
		set((state) => ({
			alert: {
				...state.alert,
				open: false,
				title: '',
				image: '',
				icon: '',
				description: '',
				buttons: [],
				closeButton: {
					text: '',
					action: null,
					closeAfter: true
				}
			},
		})),
}));

export default useAlertDefault;
