import React, { useCallback } from "react";
import useWindowSize from "../../../hooks/useWindowSize";

import swal from "sweetalert";

import { Container, CodeBlock, Button } from "./styles";

const CopyScriptCode = ({ code, trackingCode, trackingPlatform }) => {
  const [width, height] = useWindowSize();

  const copyToClipboard = useCallback((e) => {
    navigator.clipboard.writeText($("#neuroleadTag").text());

    if (trackingCode) {
      MixpanelService.track(String(trackingCode), { platform: trackingPlatform });
    }

    swal("", I18n.t("views.onboard.hello.notification.code_copied_successfully"), "success");
  }, []);

  const buttonLabel = useCallback(() => {
    return width > 606 ? I18n.t("views.onboard.copy_code") : I18n.t("shared.actions.copy");
  }, [width]);

  return (
    <Container>
      <CodeBlock className="card">
        <code className="prettyprint language-js" id="neuroleadTag">
          {code}
        </code>
      </CodeBlock>

      <Button
        type="button"
        className="btn btn-primary"
        onClick={copyToClipboard}
      >
        <i className="ti ti-copy icon" />
        {buttonLabel()}
      </Button>
    </Container>
  );
};

export default CopyScriptCode;
