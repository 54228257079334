import React from "react";

import { Card } from "react-bootstrap";

const TemplateInfo = () => {
  return (
    <Card className="bg-primary-lt text-center">
      <Card.Body>
        <p className="fw-medium">{I18n.t("views.leadster_ai.page.template.see_how")}</p>

        <a
          href={I18n.t("views.leadster_ai.page.template.link_tutorial")}
          className="btn btn-outline-primary text-decoration-none w-100"
          target="_blank"
        >
          {I18n.t("views.leadster_ai.page.template.button")}
        </a>
      </Card.Body>
    </Card>
  );
};

export default TemplateInfo;
