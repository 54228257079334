import React from "react";

import PaymentBankSlip from "./PaymentBankSlip";
import CurrencyFormatter from "react-currency-format";
import ButtonCreateCc from "@/components/billing/Buttons/ButtonCreateCc";
import useStep2Store from "@/components/billing/store/useStep2Store";

export default class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
    this.discount = React.createRef();
    this.state = {
      PaymentForm: '',
      coupon: ''
    };

    this.saveStep2Data();
  }

  openModal(payment_type) {
    this.setState({ PaymentForm: payment_type }, function(){
      $(this.modal.current).modal('show');
    });
  }

  saveStep2Data(){
    useStep2Store.setState({
      plan_id: this.props.plan.id,
      event_type: "create"
    });
  }

  render () {
    return (
      <div className="step2">
        <div className="price text-blue">
          <span className="price_complement block">
            { this.props.plan['period'] === 'monthly' ? "" : I18n.t("billing.plans.annual_price_discount") }
          </span>
          <CurrencyFormatter value={this.props.plan.period === 'monthly' ? this.props.plan.price : (this.props.plan.price/12)}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            fixedDecimalScale={true}
                            prefix={I18n.t("number.currency.format.unit")}
                            isNumericString={true}
                            decimalSeparator={','}
                            decimalScale={2}/>
          &nbsp;
          <span className="price_complement">/{I18n.t("billing.plans.month")}</span>
        </div>
        <div className="billing_period" onClick={ event => this.props.changeBillingPeriod() }>
          <span className={`month ${this.props.billingPeriod === 'month' ? 'active' : ''}`}>{I18n.t("billing.plans.monthly")}</span>
          <span className={`year ${this.props.billingPeriod === 'year' ? 'active' : ''}`}>{I18n.t("billing.plans.yearly")}</span>
          <span className={`selected ${this.props.billingPeriod}_active`}/>
        </div>

        <h3>{I18n.t("billing.plans.select_payment_method")}</h3>
        <div className="plan-info">
          {I18n.t("billing.plans.payment_options")}
        </div>

        <ButtonCreateCc/>

        <div className='separator'>
          <span className="text">{I18n.t("billing.plans.where")}</span>
          <hr/>
        </div>

        <button className="billing-btn" onClick={ event => this.openModal('bank_slip') }>
          {I18n.t("billing.plans.bank_slip")} <i className="fe fe-arrow-right"/>
        </button>

        <div className="coupon-box d-none">
          <input type="text" ref={this.discount} className='form-control' placeholder={I18n.t("billing.plans.discount_coupon.placeholder")}/>
          <button onClick={e => this.props.applyCoupon($(this.discount.current))}>{I18n.t("billing.plans.discount_coupon.button_to_apply")}</button>
        </div>
        { (this.props.plan['period'] === 'yearly' || this.props.coupons.length > 0) &&
          <div className="total-box">
            { this.props.coupons.length > 0 &&
              <div className='coupon-info-box'>
                <h5>{I18n.t("billing.plans.coupons_applied")}</h5>
                <table>
                  <tbody>
                    {this.props.coupons.map(coupon =>(
                      <tr key={coupon.code}>
                        <td><strong>{coupon.code}</strong></td>
                        <td>{coupon.rule}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            }
            { this.props.plan['period'] === 'yearly' &&
            <div className='price-box'>
              <hr/>

              <span className="price_complement block">
                {I18n.t("billing.plans.total_to_be_debited")} &nbsp;
                <CurrencyFormatter value={this.props.updated_price !== null ? this.props.updated_price : this.props.plan.price}
                                  displayType={'text'}
                                  thousandSeparator={'.'}
                                  fixedDecimalScale={true}
                                  prefix={I18n.t("number.currency.format.unit")}
                                  isNumericString={true}
                                  decimalSeparator={','}
                                  decimalScale={2}/>
                </span>
            </div>
            }
            { (this.props.plan['period'] === 'monthly' && this.props.coupons.length > 0) &&
              <div className='price-box'>
                <hr/>
                <h5>{I18n.t("general.actions.total")}</h5>
                <span className="price_complement block">
                  {I18n.t("billing.plans.first_installment")} &nbsp;
                  <CurrencyFormatter value={this.props.updated_price}
                                    displayType={'text'}
                                    thousandSeparator={'.'}
                                    fixedDecimalScale={true}
                                    prefix={I18n.t("number.currency.format.unit")}
                                    isNumericString={true}
                                    decimalSeparator={','}
                                    decimalScale={2}/>
                    </span>
              </div>
            }
          </div>
        }
        <div>
          <div ref={this.modal} className={`modal modal-blur fade ${this.state.PaymentForm}`} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {I18n.t("billing.modal.payment.title")}
                  </h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                  <div className="modal-status bg-success"/>
                </div>

                <div className="modal-body motivos">
                  <div style={{minHeight: '30em'}}>
                    { this.state.PaymentForm === 'bank_slip' &&
                    <PaymentBankSlip
                      companyId={this.props.companyId}
                      plan={this.props.plan}
                      magic_key={this.props.magic_key}
                      chatUrl='./bank_slip_chat'
                    />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
