import dayjs from "@/lib/dayjs";

class FormatterUtils {
  static formatAccess = (number) => {
    const locale = 'pt-BR';
    return number.toLocaleString(locale)
  }

  static formatAbbreviatedAccess(number) {
    if (number >= 1000 && number < 100000) {
      return `${(number / 1000).toFixed(0)} mil`;
    }
    return number.toString();
  }

  static formatDate(
    date,
    formater= I18n.t("js.formats.default.date")
  ) {
    const result = dayjs(date);
    return result.format(formater);
  }

  static formatDateToDefaultFormatDate(date, currentDateFormat) {
    const delimiter = currentDateFormat.includes('/') ? '/' : '-';
    const parts = date.split(delimiter);

    let day, month, year;
    switch (currentDateFormat) {
      case 'dd/mm/yyyy':
      case 'dd-mm-yyyy':
        [day, month, year] = parts;
        break;
      case 'mm/dd/yyyy':
      case 'mm-dd-yyyy':
        [month, day, year] = parts;
        break;
      default:
        throw new Error("Formato de data não suportado");
    }

    return [year, month.padStart(2, '0'), day.padStart(2, '0')];
  }

  static formatFinancialEmail(email) {
    const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const formatPositions = (positions) => {
      if (positions.length === 0) return '';
      if (positions.length === 1) return positions[0];
      const lastPosition = positions.pop();
      return `${positions.join(', ')} e ${lastPosition}`;
    };

    const validateEmails = (emails) => {
      const invalidPositions = [];
      emails.forEach((email, index) => {
        if (!isValidEmail(email) && email) invalidPositions.push(index + 1);
      });
      return formatPositions(invalidPositions);
    };

    const hasDuplicateEmails = (emails) => new Set(emails).size !== emails.length;

    const sanitizeEmailInput = (input) => {
      const regexPatterns = {
        endsWithLetterWithSpace: /[a-zA-Z] $/,
        containsTwoSpaces: / {2,}/,
        consecutiveCommas: /,{2,}/g,
        letterCommaLetter: /([a-zA-Z]),([a-zA-Z])/g
      };
      let value = input;
      if (regexPatterns.endsWithLetterWithSpace.test(value)) {
        value = value.replace(/ (?!.* )/, ', ');
      }
      return value
        .replace(regexPatterns.containsTwoSpaces, ' ')
        .replace(regexPatterns.consecutiveCommas, ',')
        .replace(regexPatterns.letterCommaLetter, '$1, $2');
    };

    const emails = email.split(',').map(email => email.trim());
    const sanitizedInput = sanitizeEmailInput(email);
    const invalidPositions = validateEmails(emails);
    const duplicateEmails = hasDuplicateEmails(emails);

    let error = '';
    if (invalidPositions) {
      error = `Os e-mails nas posições: ${invalidPositions} são inválidos.`;
    } else if (!sanitizedInput) {
      error = 'Por favor insira um email válido.';
    } else if (duplicateEmails) {
      error = 'Os emails não podem ser iguais.';
    }

    return { error, value: sanitizedInput };
  }
}

export default FormatterUtils;
