export default [
  { value: "=", label: "=", allowMultiValues: true },
  { value: "!=", label: "≠", allowMultiValues: false },
  { value: "<=", label: "&le;", allowMultiValues: false },
  { value: ">=", label: "&ge;", allowMultiValues: false },
  { value: ">", label: "&gt;", allowMultiValues: false },
  { value: "<", label: "&lt;", allowMultiValues: false },
  { value: "matches", label: I18n.t("views.manage_flows.script_editor.contains"), allowMultiValues: true },
  { value: "doesn't match", label: I18n.t("views.manage_flows.script_editor.does_not_contain"), allowMultiValues: false },
];
