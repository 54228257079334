import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ErrorPage from "./ErrorPage";
import AiAssistant from "./AiAssistant";

const Home = () => {
  return (
    <Router>
      <Switch>
        <Route path="/ai/leadster" component={AiAssistant} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    </Router>
  );
};

export default Home;
