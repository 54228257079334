import styled, { keyframes } from 'styled-components';

export const Card = styled.div`
  background: white;
  border-radius: .25rem;
  padding-bottom: 50px;
`;

const loading = keyframes`
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.8;
  }
`;

export const Percentage = styled.div`
  background-color: rgba(0, 0, 0, .5);
  width: 10px;
  height: 4px;
  margin: 10px 0 20px 0;

  animation: ${loading} 1.5s infinite linear;
`;

export const Title = styled.div`
  background-color: #E2E2E2;
  max-width: 45%;
  height: 15px;

  animation: ${loading} 1.5s infinite linear;
`;
