import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { classBinding } from "@/utils/helpers";

const CloneBlockButton = ({ onDuplicate, enabled = true }) => {
  const handleClickDuplicate = () => {
    if (!enabled) return;

    onDuplicate();
  };

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{I18n.t("views.manage_flows.script_editor.clone_block")}</Tooltip>}
    >
      <button
        type="button"
        className={classBinding({ "disabled": !enabled }, "btn btn-icon")}
        onClick={handleClickDuplicate}
      >
        <i className="ti ti-copy icon"></i>
      </button>
    </OverlayTrigger>
  );
};

export default CloneBlockButton;
