import { useState } from "react";

import ScriptActivationModal from "@/components/leadster_ia/shared/ScriptActivationModal";
import NotAllowedOverlay from "../NotAllowedOverlay";

import { can } from "@/lib/user-permission";

const AddToFlowButton = ({ assistant }) => {
  const hasPermission = can({ action: "manage", subject: "leadster_ai_plugin" });

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);

  if (assistant.is_full_configured || !assistant.already_populated_bases) {
    return <></>;
  }

  return (
    <>
      <NotAllowedOverlay enabled={!hasPermission}>
        <button
          type="button"
          className="btn btn-primary"
          disabled={!hasPermission}
          onClick={handleOpenModal}
        >
          <i className="ti ti-message-2-plus icon" />
          {I18n.t("views.leadster_ai.page.home.add_to_flow")}
        </button>
      </NotAllowedOverlay>

      <ScriptActivationModal
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default AddToFlowButton;
