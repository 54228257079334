import React, { useState, useEffect } from "react";
import IntData from 'intl-tel-input/build/js/data';
import { Select } from "./style";

const SelectPhoneDialCode = ({ initialValue, onSelectDialCode }) => {
  const [selected, setSelected] = useState("");
  const [listCountries, setListCountries] = useState([]);
  const [listPhoneDialCodes, setListPhoneDialCodes] = useState({});

  const onSelect = (country) => {
    setSelected(country);
    onSelectDialCode(listPhoneDialCodes[country].secondary);
  }

  useEffect(() => {
    loadPhoneDialCodes();
  }, []);

  const preSelectDialCode = (listDialCodeCountryObject) => {
    if (initialValue){
      setSelected(listDialCodeCountryObject[initialValue]);
    }
  }

  const loadPhoneDialCodes = () => {
    const listPhoneObject = {};
    const listDialCodeCountryObject = {};
    const listCountries = [];

    IntData.forEach((countryDialCode) => {
      const countryIso2 = countryDialCode.iso2.toUpperCase();
      const dialCode = countryDialCode.dialCode;

      listPhoneObject[countryIso2] = {
        primary: countryDialCode.name,
        secondary: dialCode
      };

      listDialCodeCountryObject[dialCode] = countryIso2;

      listCountries.push(countryIso2);
    })

    setListPhoneDialCodes(listPhoneObject);
    setListCountries(listCountries);
    preSelectDialCode(listDialCodeCountryObject);
  };

  return (
    <Select
        selected={selected}
        onSelect={onSelect}
        showSelectedLabel={true}
        showSecondarySelectedLabel={true}
        showOptionLabel={true}
        showSecondaryOptionLabel={true}
        customLabels={listPhoneDialCodes}
        countries={listCountries}
        searchable={true}
        placeholder={I18n.t("views.manage_flows.script_editor.phone_international.select_dial_code")}
      />
  );
};

export default SelectPhoneDialCode;
