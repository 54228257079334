import styled from 'styled-components';

const blue = '#23aef7';

export const Item = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const Unchecked = styled.div`
  cursor: no-drop;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: ${props =>
    (props.active ? `1px solid ${blue}` : '1px solid #000')};
  margin-right: 10px;

  &:hover {
    border: ${props =>
      (props.active ? `1px solid ${blue}` : '1px solid #000')};
  }
`;

export const Link = styled.a`
  text-decoration: ${props => (props.active ? 'underline' : 'none')};
  color: ${props => (props.active ? blue : '#000')};

  &:hover {
    color: ${props => (props.active ? blue : '#000')};
  }
`;

export const HiddenLink = styled.a`
  display: none;
`;
