import styled from "styled-components";

export const Container = styled.div`
    border-radius: 5px;
    border: 1px solid #CCE2FB;
    background: #F2F8FE;
    padding: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    .form-check {
      margin-bottom: 0;
    }
    .form-check-label {
      margin-bottom: 0.5rem;
    }
  `;
