import styled from "styled-components";

export const Card = styled.div`
  height: fit-content;

  > .card-header{
    width: 100%;
    background: linear-gradient(45deg, rgba(var(--tblr-primary-rgb), 0.2) 0%, rgba(var(--tblr-info-rgb), 0.4) 100%);
  }
`;
