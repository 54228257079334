import styled from "styled-components";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .group-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
  }
`;
