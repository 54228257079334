import styled from "styled-components";

export const Block = styled.div`
  display: flex;
  width: 14%;
  height: 0;
  padding-bottom: 12%;
  text-align: center;
  ${(props) => props.active && `border: none;`}
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border-bottom: 4px solid ${(props) => (props.active ? "var(--tblr-primary)" : "none")};
  border-radius: 3px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  &:hover {
    ${(props) => !props.active && `
      border-bottom: 3px solid var(--tblr-primary);
    `}
  }
`;

export const TriangleTopLeft = styled.div`
  width: 0;
  height: 0;
  border-top: 50px solid ${(props) => (props.active ? "var(--tblr-primary)" : "var(--tblr-gray-400)")};
  border-right: 50px solid transparent;

  @media (max-width: 991px) {
    border-top: 40px solid ${(props) => (props.active ? "var(--tblr-primary)" : "var(--tblr-gray-400)")};
    border-right: 40px solid transparent;
  }

  @media (max-width: 576px) {
    border-top: 30px solid ${(props) => (props.active ? "var(--tblr-primary)" : "var(--tblr-gray-400)")};
    border-right: 30px solid transparent;
  }

  @media (max-width: 540px) {
    border-top: 20px solid ${(props) => (props.active ? "var(--tblr-primary)" : "var(--tblr-gray-400)")};
    border-right: 20px solid transparent;
  }
`;

export const FlexBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: -30px;

  @media (max-width: 991px) {
    margin-top: -40px;
  }

  @media (max-width: 778px) {
    margin-top: -20px;
  }

  @media (max-width: 767px) {
    margin-top: -28px;
  }

  @media (max-width: 576px) {
    margin-top: -18px;
  }
`;

export const Label = styled.h2`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.active ? 'var(--tblr-primary)' : 'var(--tblr-dark)'};
  margin-top: 12px;
  line-height: 24px;

  @media (max-width: 1280px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 991px) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 991px) {
    font-size: 12px;
  }

  @media (max-width: 778px) {
    display: none;
  }
`;

export const Step = styled.span`
  position: absolute;
  top: 5px;
  left: 10px;
  color: #fff;
  font-weight: 600;

  @media (max-width: 991px) {
    left: 5px;
  }

  @media (max-width: 960px) {
    font-size: 13px;
  }

  @media (max-width: 576px) {
    top: 1px;
    left: 2px;
    font-size: 10px;
  }
`;

export const Image = styled.img`
  @media (max-width: 991px) {
    width: 20px;
  }
`;
