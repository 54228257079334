export const marketSegments = [
  { key: 1, value: "Software", name: I18n.t("shared.segment.Software"), imageFile: "software.png" },
  { key: 2, value: "Educacional", name: I18n.t("shared.segment.Educacional"), imageFile: "educational.png" },
  { key: 3, value: "Imóveis", name: I18n.t("shared.segment.Imóveis"), imageFile: "buildings_and_properties.png" },
  { key: 4, value: "Agência", name: I18n.t("shared.segment.Agência"), imageFile: "agency.png" },
  { key: 5, value: "E-commerce", name: I18n.t("shared.segment.E-commerce"), imageFile: "e-commerce.png" },
  { key: 6, value: "Infoproduto", name: I18n.t("shared.segment.Infoproduto"), imageFile: "infoproduct.png" },
  { key: 7, value: "Venda de Produtos", name: I18n.t("shared.segment.Venda de Produtos"), imageFile: "products.png" },
  { key: 8, value: "Venda de Serviços", name: I18n.t("shared.segment.Venda de Serviços"), imageFile: "service.png" },
  { key: 9, value: "Jurídico", name: I18n.t("shared.segment.Jurídico"), imageFile: "legal.png" },
  { key: 10, value: "Tecnologia e TI", name: I18n.t("shared.segment.Tecnologia e TI"), imageFile: "tech.png" },
  { key: 11, value: "Indústria e Fabricação", name: I18n.t("shared.segment.Indústria e Fabricação"), imageFile: "industry.png" },
  { key: 12, value: "Financeiro", name: I18n.t("shared.segment.Financeiro"), imageFile: "finance.png" },
  { key: 13, value: "Seguros", name: I18n.t("shared.segment.Seguros"), imageFile: "secure.png" },
  { key: 14, value: "Telefonia e Internet", name: I18n.t("shared.segment.Telefonia e Internet"), imageFile: "telephony-and-internet.png" },
  { key: 15, value: "Consultoria", name: I18n.t("shared.segment.Consultoria"), imageFile: "consultancy.png" },
  { key: 16, value: "Marketing e Publicidade", name: I18n.t("shared.segment.Marketing e Publicidade"), imageFile: "marketing.png" },
  { key: 17, value: "Serviços Médicos e Saúde", name: I18n.t("shared.segment.Serviços Médicos e Saúde"), imageFile: "health.png" },
  { key: 18, value: "Logística e Transporte", name: I18n.t("shared.segment.Logística e Transporte"), imageFile: "logistic.png" },
  { key: 19, value: "Energia", name: I18n.t("shared.segment.Energia"), imageFile: "energy.png" },
  { key: 20, value: "Outros", name: I18n.t("shared.segment.Outros"), imageFile: "other.png" },
];
