import AssistantItem from "@/components/leadster_ia/cards/AssistantList/AssistantItem";

import useAssistantExclusionModal from "../AssistantList/hooks/useAssistantExclusionModal";
import { useAssistantContext } from "@/contexts/AssistantContext";

const AssistantList = () => {
  const { assistants } = useAssistantContext();
  const { modal: removeAssistantModal, onShow } = useAssistantExclusionModal();

  const handleRemoveAssistant = (assistant) => {
    onShow(assistant)
  }

  return (
    <div className="position-relative w-100 d-flex flex-column gap-3">
      <span className="position-absolute fw-bolder fs-4" style={{ left: 0, top: "-36px" }}>
        {I18n.t("views.leadster_ai.page.home.assistent_created", { length: assistants.length })}
      </span>

      {assistants.map((assistant) => {
        return <AssistantItem
          key={assistant.id}
          assistant={assistant}
          onClickRemoveAssistant={handleRemoveAssistant}
        />;
      })}

      {removeAssistantModal}
    </div>
  );
};

export default AssistantList;
