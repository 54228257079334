import TidioButton from "./TidioButton";
import WhatsAppButton from "./WhatsAppButton";

import useCurrentUserStore from "@/store/currentUserStore";

const HireFeatureButton = () => {
  const { isNational } = useCurrentUserStore((state) => state.currentUser);

  if (isNational) {
    return (
      <WhatsAppButton>
        <i className="ti ti-shopping-cart icon" />
        {I18n.t("views.manage_flows.scheduler_block.hire_feature")}
      </WhatsAppButton>
    );
  }

  return (
    <>
      <TidioButton>
        <i className="ti ti-message icon" />
        {I18n.t("shared.actions.contact_chat")}
      </TidioButton>

      <WhatsAppButton>
        <i className="ti ti-brand-whatsapp icon" />
        {I18n.t("shared.actions.contact_whatsapp")}
      </WhatsAppButton>
    </>
  );
};

export default HireFeatureButton;
