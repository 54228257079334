import styled from "styled-components";

export const ContainerStep4 = styled.div`
  .control-label {
    display: block;
    font-weight: 500;
    margin-bottom: 0.5rem;
    font-size: 0.9375rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .error-detail {
    margin-bottom: 0;
  }

  span.required {
    margin-left: 5px;
    color: var(--tblr-danger)
  }

  .array-item-add {
    display: flex;
    justify-content: center;
  }

  fieldset.field-array {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0px 10px;
  }

  legend {
    float: none;
    width: inherit;
    padding: inherit;
    font-size: 1.5rem;
    line-height: inherit;
  }
`;
