import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import { ModalOverlay, ModalWrapper } from "./style";

const Modal = ({ children, onClose}) => {

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return ReactDOM.createPortal(
    <ModalOverlay onClick={onClose}>
      <ModalWrapper onClick={(event) => event.stopPropagation()}>
        {children}
      </ModalWrapper>
    </ModalOverlay>,
    document.body
  )
}

export default Modal
