import React, { useState, useEffect } from "react";

import HelpPopover from "@/components/shared/HelpPopover";

import PageData from "./PageData";
import RowError from "./RowError"
import RowEmpty from "./RowEmpty"

import { classBinding } from '@/utils/helpers';
import axios from "@/lib/axios";

import swal from "sweetalert";
import * as Styled from "./style";

const RankTable = () => {
  const [pageRanks, setPageRanks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmptyResult, setIsEmptyResult] = useState(false);
  const [generalError, setGeneralError] = useState({message_html: "", button_label: "", help_url: "#"});

  useEffect(() => {
    setIsLoading(true);

    axios.get("/page-ranks/list-urls")
      .then(({ data }) => {
        setPageRanks(data.rank_urls);
        setIsEmptyResult(data.general_error != null);
        setGeneralError(data.general_error)
      })
      .catch(() => {
        setPageRanks([]);

        swal(I18n.t("request_feedback_alert.error.title"), I18n.t("request_feedback_alert.error.message"), "error");
      }).then(() => setIsLoading(false))
  }, []);

  return (
    <div className={classBinding({ "content-loading": isLoading }, "table-responsive")}>
      <Styled.MainTable isEmptyResult={isEmptyResult}>
        <thead>
          <tr>
            {!isEmptyResult && <th></th>}
            <th>
              {I18n.t("views.page_rank.rank_table.headers.page.value")}
              <HelpPopover
                className="ms-2"
                content={I18n.t("views.page_rank.rank_table.headers.page.popover_html")}
              />
            </th>
            <th className="text-center">
              {I18n.t("views.page_rank.rank_table.headers.unique_access.value")}
              <HelpPopover
                className="ms-2"
                content={I18n.t("views.page_rank.rank_table.headers.unique_access.popover_html")}
              />
            </th>
            <th className="text-center">
              {I18n.t("views.page_rank.rank_table.headers.conversion_rate.value")}
              <HelpPopover
                className="ms-2"
                content={I18n.t("views.page_rank.rank_table.headers.conversion_rate.popover_html")}
              />
            </th>
            <th>
              {I18n.t("views.page_rank.rank_table.headers.generated_ia_calls.value")}
              <HelpPopover
                className="ms-2"
                content={I18n.t("views.page_rank.rank_table.headers.generated_ia_calls.popover_html")}
              />
            </th>
            <th className="text-center">
              {I18n.t("views.page_rank.rank_table.headers.create_flow.value")}
              <HelpPopover
                className="ms-2"
                content={I18n.t("views.page_rank.rank_table.headers.create_flow.popover_html")}
              />
            </th>
          </tr>
        </thead>

        <tbody>
          { isEmptyResult ? (
            <tr className="empty-table-row">
              <td className="text-center lead" colSpan={5}>
                {isLoading ? (
                  I18n.t("views.page_rank.rank_table.data.loading_data")
                ) : (
                  <RowError
                    error={generalError}
                  />
                )}
              </td>
            </tr>
          ) : (
            pageRanks.map((pageRank, i) => (
              <PageData
                key={i}
                rowIndex={i + 1}
                pageRank={pageRank}
              />
            ))
          )}

          { isEmptyResult && <RowEmpty/> }
        </tbody>
      </Styled.MainTable>
    </div>
  );
};

export default RankTable;
