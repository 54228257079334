import CardValidators from "card-validator";

import { cnpj, cpf } from "cpf-cnpj-validator";

import {onlyNumber} from "@/utils/helpers";

export function formatCreditCardNumber(cardNumber) {
  cardNumber = onlyNumber(cardNumber);

  if (cardNumber.length < 5) return cardNumber;

  let validator = CardValidators.number(cardNumber);

  if (validator.card === null) return cardNumber;

  let card = validator.card;
  let gaps = card.gaps;

  cardNumber = cardNumber.slice(0, card.lengths[0]);

  return gaps.reduce((formattedStr, gap, index) => {
    const insertionIndex = gap + index;

    return formattedStr.slice(0, insertionIndex) + " " + formattedStr.slice(insertionIndex);
  }, cardNumber);
}

export function formatName(name) {
  return name.toUpperCase();
}

export function formatExpirationDate(value) {
  const number = onlyNumber(value);

  if (number.length >= 3) {
    return `${number.slice(0, 2)}/${number.slice(2, 4)}`;
  }

  return number;
}

export function formatCVC(value, cardNumber) {
  value = onlyNumber(value);
  cardNumber = onlyNumber(cardNumber);

  let maxLen = 5;
  let validator = CardValidators.number(cardNumber);

  if (validator.card !== null) maxLen = validator.card.code.size;

  return value.slice(0, maxLen);
}

export function formatTaxVat(value) {
  const number = onlyNumber(value);

  if (number.length === 11) {
    return cpf.format(number);
  }

  return cnpj.format(number);
}
