import { useEffect } from 'react';

const useChatInteractionTracker = (element) => {
  const attachEventListenerKeyDown = () => {
    const inputNld = element.current.querySelector(".input-custom-nld input");

    if (!inputNld) return;

    const listenerCallback = (e) => {
      if (e.keyCode === 13) {
        chatInteractionTracker();
      }
    };

    inputNld.removeEventListener("keydown", listenerCallback);
    inputNld.addEventListener("keydown", listenerCallback);
  };

  const attachEventListenerClick = () => {
    const divNldSubmit = element.current.querySelector(".nld-submit");

    if (!divNldSubmit) return;

    const listenerCallback = () => {
      chatInteractionTracker();
    };

    divNldSubmit.removeEventListener("click", listenerCallback);
    divNldSubmit.addEventListener("click", listenerCallback);
  };

  const chatInteractionTracker = () => {
    MixpanelService.track("leadster_ai_test_assistant_asked");
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      attachEventListenerKeyDown();
      attachEventListenerClick();
    });

    observer.observe(element.current, { subtree: true, childList: true });

    return () => {
      observer.disconnect();
    };
  }, [element])
};

export default useChatInteractionTracker;
