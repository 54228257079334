import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const fadeInDown = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 40px, 0)
  }
}`;

const fadeInUp =  keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}`;

export const Container = styled.div`
  position: fixed;
  width: 230px;
  border-radius: 20px;
  font-family: "Inter", sans-serif;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.15);
  padding: 20px;
  left: 10px;
  text-align: center;
  z-index: 1000;
  bottom: 1px;

  visibility: ${props => props.out ? 'hidden' : 'visible'};
  animation: ${props => props.out ? fadeInDown : fadeInUp} 0.3s linear;
  transition: visibility 0.2s linear;
`;

export const Image = styled.img`
  width: 80px;
  display: block;
  margin: 0 auto;
`;

export const Title = styled.h5`
  line-height: 1.3;
  font-weight: 600;
  margin: 10px 0 15px 0;
  font-size: 1.1rem;
`;

export const Minimize = styled.div`
  width: 25px;
  height: 5px;
  background: rgba(0, 0, 0, .1);
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, .3);
  }
`;

export const MinimizeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MinimizedContainer = styled.div`
  visibility: ${props => props.out ? 'hidden' : 'visible'};
  animation: ${props => props.out ? fadeOut : fadeIn} 0.4s linear;
  transition: visibility 0.4s linear;

  width: 60px;
  height: 60px;
  border-radius: 50%;

  position: fixed;
  border: 1px solid rgba(0, 0, 0, .2);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.15);
  z-index: 1000;
  bottom: 10px;
  left: 20px;

  background-color: #fff;
  cursor: pointer;
  padding-right: 2px;
  transition: border 200ms linear 0s, transform 200ms linear 0s;

  &:hover {
    border: 1px solid rgba(0, 0, 0, .3);
  }
`
