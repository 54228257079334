import React, { useRef } from "react";

function currentTime() {
  return +new Date();
}

function Linear(x) {
  return Math.max(0, Math.min(1, x));
}

function CubicOut(x) {
  return Math.max(0, Math.min(1, x*x*x));
}

function CubicIn(x) {
  x = 1-Math.max(0, Math.min(1, x));

  return 1-x*x*x;
}

function useInterpolatedRef(initialValue, fn=CubicIn) {
  const ref = useRef({
    start: { t: 0, value: initialValue },
    end: { t: 0, value: initialValue }
  });

  function currentValue() {
    const interp = ref.current;
    const t = currentTime();

    if (interp.end.t > interp.start.t) {
      const x = (t-interp.start.t)/(interp.end.t-interp.start.t);
      return fn(x)*(interp.end.value-interp.start.value)+interp.start.value;
    } else {
      return interp.end.value;
    }
  }

  return {
    get: () => currentValue(),
    set: (v, dt=1) => {
      const t = currentTime();

      if (ref.current.end.value !== v) {
        ref.current = {
          start: { t: t, value: currentValue() },
          end: { t: t+dt, value: v }
        };
      }
    },
    done: () => ref.current.end.t <= currentTime(),
  };
}

export { useInterpolatedRef, Linear, CubicIn, CubicOut };
