import { Button, OverlayTrigger, Popover } from "react-bootstrap";

import parse from "html-react-parser";
import { useHistory } from "react-router-dom";

const CountQuestions = ({ assistant }) => {
  if (!assistant.is_full_configured) return null;

  const history = useHistory();

  const {
    count_done: countDone,
    count_not_found: countNotFound,
    count_all: countTotal,
  } = assistant.messages_status;

  return (
    <OverlayTrigger
      overlay={
        <Popover>
          <Popover.Body>
            <div className="mb-1">
              {parse(I18n.t("views.leadster_ai.page.home.questions_count.done_html", { count: countDone }))}
            </div>
            <div className="mb-1">
              {parse(I18n.t("views.leadster_ai.page.home.questions_count.not_found_html", { count: countNotFound }))}
            </div>
            <div className="fw-bold">
              {I18n.t("views.leadster_ai.page.home.questions_count.history")}
            </div>
          </Popover.Body>
        </Popover>
      }
    >
      <Button
        variant="outline-primary"
        onClick={() => history.push(`leadster/analytics/${assistant.id}/history`)}
      >
        {I18n.t("views.leadster_ai.page.home.questions_count.total", { count: countTotal })}
      </Button>
    </OverlayTrigger>
  );
}

export default CountQuestions;
