import { useEffect } from "react";

import usePrevious from "@/hooks/usePrevious";

const useNeuroleadClientUpdater = (convoScript) => {
  const previousConvoScript = usePrevious(convoScript);

  useEffect(() => {
    if (!window.neurolead) return;
    if (!previousConvoScript) return;

    if (previousConvoScript.userMainColor !== convoScript.userMainColor) {
      window.neurolead.update("color", convoScript.userMainColor);
      return;
    }

    if (previousConvoScript.title !== convoScript.title) {
      window.neurolead.update("title", convoScript.title);
      return;
    }

    if (previousConvoScript.tooltipMessage !== convoScript.tooltipMessage) {
      window.neurolead.update("tooltipMessage", convoScript.tooltipMessage);
      return;
    }

    const overwriteProperties = {
      side: "right",
      standalone: false,
      modalMode: false,
      alwaysAutoOpen: false,
      disable1stPartyBranding: false,
    };

    const newScript = Object.assign(
      {},
      window.neurolead.convoScript,
      convoScript,
      overwriteProperties
    );

    window.neurolead.reload(newScript);
  }, [convoScript]);
};

export default useNeuroleadClientUpdater;
