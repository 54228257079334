import React from "react";

import { Card } from "react-bootstrap";

const Layout = ({ title, description, footer, contentClassName, children }) => {
  return (
    <Card className="w-100">
      <div className="card-header flex-column align-items-start">
        <div className="card-title mb-1">
          {title}
        </div>

        <div className="card-subtitle">
          {description}
        </div>
      </div>

      <Card.Body className={contentClassName}>
        {children}
      </Card.Body>

      {footer && (
        <Card.Footer className="d-flex justify-content-end bg-white">
          {footer}
        </Card.Footer>
      )}
    </Card>
  );
}

export default Layout;
