const CardInformation = ({ title, description }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="h2 mb-2"> {title}</div>
        <div className="text-secondary fs-4">{description}</div>
      </div>
    </div>
  );
};

export default CardInformation;
