import useCurrentUserStore from "@/store/currentUserStore";
import * as S from "./styles";

const Characters = ({ value, alreadyInUse, createdAt }) => {
  const { locale: userLocale } = useCurrentUserStore((state) => state.currentUser);

  const characters = value.toLocaleString(userLocale, { minimumFractionDigits: 0 });
  const i18nCharacters = I18n.t("views.leadster_ai.page.add_content.add_links.characters", { value: characters });
  const formtCreatedAt = createdAt ? dayjs(createdAt).format(I18n.t("js.formats.default.date_time")) : "";
  const i18nCreatedAt = createdAt
    ? I18n.t("views.leadster_ai.page.add_content.add_links.created_at", { date: formtCreatedAt })
    : "";

  return (
    <>
      <S.FormLabelBadge
        className="mt-2 fw-bold"
        data-toggle="tooltip"
        data-placement="top"
        title={i18nCreatedAt}
        disabled={alreadyInUse}
      >
        <i className="icon ti ti-check me-2 fs-2" /> {i18nCharacters}
      </S.FormLabelBadge>

      <S.FormLabelSecundary className="mt-2" disabled={alreadyInUse}>
        {i18nCharacters}
      </S.FormLabelSecundary>
    </>
  );
};

export default Characters;
