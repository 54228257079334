import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const NotAllowedOverlay = ({ children, enabled }) => {
  return (
    <OverlayTrigger
      trigger={enabled ? ["hover", "focus"] : false}
      overlay={
        <Popover>
          <Popover.Body>
            {I18n.t("views.leadster_ai.page.home.not_allowed_edit")}
          </Popover.Body>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default NotAllowedOverlay;
