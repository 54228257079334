import styled from "styled-components";

export const NavWrapper = styled.nav`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const NavGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: 0.8;
  pointer-events: none;

  *{ transition: 0.4s; }

  &.active{
    opacity: 1;
    pointer-events: all;

    .text{
      font-weight: 600;
    }
  }

  /* Intern Items */
  .btn{
    width: 25px;
    aspect-ratio: 1;
    padding: 0px;
    border-radius: 50%;
  }
  .line-dots{
    width: 100px;
    height: 0;
    border-bottom: 2px dotted #475569;
    opacity: 0.5;
  }

  @media (max-width: 1080px) {
    display: none;
    width: 100%;

    &.active{
      display: grid;
      grid-template-columns: auto auto 1fr;
    }

    .line-dots{
      min-width: 100%;
    }
  }
`;
