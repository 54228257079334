import { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";

import SkeletonModalLoader from "./components/SkeletonModalLoader";
import LinkedScriptList from "./components/LinkedScriptList";

import axios from "@/lib/axios";
import swal from "sweetalert";

import parse from "html-react-parser";
import { useAssistantContext } from "@/contexts/AssistantContext";

const useAssistantExclusionModal = () => {
  const { removeAssistant } = useAssistantContext();

  const [show, setShow] = useState(false);
  const [assistant, setAssistant] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [scripts, setScripts] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    loadScripts();
  }, [assistant, show]);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (assistant) => {
    setShow(true);
    setAssistant(assistant)
  };

  const handleConfirm = () => {
    setIsLoading(true);
    destroyAssistant();
  };

  const destroyAssistant = () => {
    axios
      .delete(`/ai/assistants/${assistant.id}`)
      .then(() => {
        removeAssistant(assistant.id);
        toastr.success(I18n.t("views.leadster_ai.page.home.notification_delete"))
      })
      .catch(() => swal(I18n.t("shared.messages.request_feedback_alert.error.title"), I18n.t("shared.messages.request_feedback_alert.error.message"), "error"))
      .then(() => {
        setIsLoading(false)
        handleClose()
      });
  };

  const loadScripts = () => {
    if (!show) return;

    setIsLoading(true);

    axios
      .get(`/ai/scripts/assistant-scripts/${assistant.id}`)
      .then(({ data }) => setScripts(data.scripts))
      .catch(() => {
        swal(
          I18n.t("shared.messages.request_feedback_alert.error.title"),
          I18n.t("shared.messages.request_feedback_alert.error.message"),
          "error"
        );
      })
      .then(() => setIsLoading(false));
  };

  const renderModal = (
    <Modal className="modal-blur" show={show} onHide={handleClose} size="xl" centered>
      <ModalHeader closeButton>
        <ModalTitle className="fw-bold">
          {I18n.t("views.leadster_ai.page.home.notification_question_delete.title")}
        </ModalTitle>
      </ModalHeader>

      <ModalBody>
        {isLoading && <SkeletonModalLoader />}

        {!isLoading && scripts.length ? (
          <>
            <div className="alert alert-warning">
              <h4 className="alert-title">
                {I18n.t("views.leadster_ai.page.home.assistant_linked_script.title", { assistant_name: assistant.name})}
              </h4>

              <div className="text-secondary">
                {I18n.t("views.leadster_ai.page.home.assistant_linked_script.description")}
              </div>
            </div>

            {scripts.map((script) => (
              <LinkedScriptList key={script.id} script={script} />
            ))}
          </>
        ) : (
          parse(I18n.t("views.leadster_ai.page.home.notification_question_delete.body", {
            assistant: assistant.name,
          }))
        )}
      </ModalBody>

      <ModalFooter>
        <Button variant="outline-secondary" onClick={handleClose}>
          {I18n.t("shared.actions.cancel")}
        </Button>

        <Button type="submit" variant="danger" onClick={handleConfirm} disabled={isLoading || !!scripts.length >0}>
          {I18n.t("shared.actions.exclude")}
        </Button>
      </ModalFooter>
    </Modal>
  );

  return {
    show,
    onShow: handleShow,
    modal: renderModal,
  }
}

export default useAssistantExclusionModal;
