import styled from 'styled-components';

export const Tab = styled.div`
  cursor: pointer;
  border-bottom: 2px solid ${props => props.active ? 'rgb(5, 146, 255)' : 'transparent'};
  border-left: ${(props) => {
    (props.borderLeft && props.active)
      ? '1px solid rgb(204, 204, 204)'
      : 'none'}};
  border-right: ${props => props.borderLeft ? 'none' : '1px solid rgb(204, 204, 204)'};
  background: ${props => props.active ? 'transparent' : 'rgb(240, 239, 239)'};
  color: ${props => props.active ? '#007dff' : 'gray'};
  padding: 0;
  margin: 0;
`;
