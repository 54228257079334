import { Button, Card, OverlayTrigger, Popover } from "react-bootstrap";

import StartNewTrialButton from "@/components/leadster_ia/shared/StartNewTrialButton";

import useResponseTracker from "./hooks/useResponseTracker";

import useCurrentUserStore from "@/store/currentUserStore";
import { buildWhatsAppLink } from "@/utils/whatsapp_helpers";
import { getCustomVariable } from "@/lib/custom-variables";

const ResponseLimit = () => {
  const {
    isNational,
    locale: userLocale,
    plugins: { leadsterAi: { allowedNewTrial } },
  } = useCurrentUserStore((state) => state.currentUser);

  const { limitResponses, consumedResponses, reachedLimit } = useResponseTracker();

  return (
    <Card>
      <Card.Body>
        <div className="fs-3 fw-medium mb-2">
          {I18n.t("views.leadster_ai.page.response_limit.title")}

          <OverlayTrigger
            overlay={
              <Popover className="bg-white p-2">
                {I18n.t("views.leadster_ai.page.response_limit.tooltip")}
              </Popover>
            }
          >
            <span className="form-help d-none d-sm-inline px-1 ms-2">?</span>
          </OverlayTrigger>
        </div>

        <div className="mb-2 small">
          {I18n.t("views.leadster_ai.page.response_limit.subtitle")}
        </div>

        <div className="h3 fw-medium">
          {I18n.t("views.leadster_ai.page.response_limit.limit", {
            limit: limitResponses.toLocaleString(userLocale, { minimumFractionDigits: 0 }),
            consumed: consumedResponses.toLocaleString(userLocale, { minimumFractionDigits: 0 }),
          })}
        </div>

        {reachedLimit && !allowedNewTrial ? (
          <>
            <hr className="my-3" />

            <div className="fw-medium p-2 bg-danger-lt small rounded mb-2">
              {I18n.t("views.leadster_ai.page.response_limit.alert_exceded")}
            </div>

            <div className="d-grid gap-2">
              <div className="fw-medium small text-center">
                {I18n.t("views.leadster_ai.page.response_limit.alert_buy")}
              </div>

              {isNational ? (
                <a
                  href={buildWhatsAppLink({
                    number: "41991625792",
                    message: I18n.t("shared.tidio.subscription_request_message"),
                  })}
                  className="btn btn-success w-100"
                  target="_blank"
                  rel="noopener"
                >
                  <i className="ti ti-brand-whatsapp icon" />
                  {I18n.t("views.leadster_ai.page.response_limit.contact_us")}
                </a>
              ) : (
                <>
                  <Button
                    variant="primary"
                    className="w-100 contact-tidio"
                    data-tidio-message={I18n.t("shared.tidio.subscription_request_message")}
                  >
                    <i className="ti ti-messages icon" />
                    {I18n.t("shared.actions.contact_chat")}
                  </Button>

                  <a
                    className="btn btn-success w-100"
                    href={buildWhatsAppLink({
                      number: getCustomVariable("contact_whatsapp_international"),
                      message: I18n.t("shared.tidio.subscription_request_message"),
                    })}
                    target="_blank"
                    rel="noopener"
                  >
                    <i className="ti ti-brand-whatsapp icon" />
                    {I18n.t("shared.actions.contact_whatsapp")}
                  </a>
                </>
              )}
            </div>
          </>
        ) : null}

        {allowedNewTrial && (
          <>
            <hr className="my-3" />

            <div className="rounded-1 bg-primary-lt p-2">
              <p className="text-body fw-medium small mb-0">
                {I18n.t("views.leadster_ai.page.response_limit.allowed_trial.text_1")}{" "}
                {I18n.t("views.leadster_ai.page.response_limit.allowed_trial.text_2")}
              </p>
            </div>

            <div className="border border-primary shadow text-center rounded-1 mt-3 p-3">
              <p className="small fw-bold">
                {I18n.t("views.leadster_ai.page.response_limit.allowed_trial.text_3")}
              </p>

              <StartNewTrialButton variant="outline-primary" />
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ResponseLimit;
