import React from 'react';

import {
  Card,
  Title,
  Content,
  Description
} from './styles';

const BigBlock = () => {
  return (
    <Card className="card">
      <div className="card-body">
        <Title />

        <Content />

        <Description />
      </div>
    </Card>
  );
};

export default BigBlock;
