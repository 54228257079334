import styled from "styled-components";

export const Button =  styled.button`
  --tblr-btn-color: #007DFF;
  --tblr-btn-bg: #E0F2FF;

    &:hover {
      color: #007DFF;
      background-color: #f1f8fd;
  }
`;

export const Icon = styled.i`
  transform: rotate(90deg);
  font-size: 1rem;
`;
