import React from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { can } from "@/lib/user-permission";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const DeleteButton = ({ onDelete, permissionScope = PERMISSION_SCOPE }) => {
  const hasPermission = can(permissionScope);

  const handleDelete = () => {
    if (!hasPermission) return;
    onDelete();
  };

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{I18n.t("general.actions.remove")}</Tooltip>}
    >
      <button
        type="button"
        className="btn btn-icon"
        disabled={!hasPermission}
        onClick={handleDelete}
      >
        <i className="ti ti-trash-x icon"></i>
      </button>
    </OverlayTrigger>
  );
};

export default DeleteButton;
