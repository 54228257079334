import React, { useEffect, useState } from "react";

import PermissionsGate from '@/components/permissions/PermissionsGate';
import { TimePicker } from '../Shared/TimePicker';

import { can } from "@/lib/user-permission";

const MAX_SCHEDULES = 8;
const AVALIABLE_DAYS = [1, 2, 3, 4, 5, 6, 0];

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const AvailabilitySchedule = ({ initialSchedules, setDefinition }) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const [startAt, setStartAt] = useState("00:00");
  const [endAt, setEndAt] = useState("00:00");
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [schedules, setSchedules] = useState(initialSchedules);
  const [errors, setErrors] = useState({});

  const translatedDays = {
    0: I18n.t("general.daysOfWeek.sun"),
    1: I18n.t("general.daysOfWeek.mon"),
    2: I18n.t("general.daysOfWeek.tue"),
    3: I18n.t("general.daysOfWeek.wed"),
    4: I18n.t("general.daysOfWeek.thu"),
    5: I18n.t("general.daysOfWeek.fri"),
    6: I18n.t("general.daysOfWeek.sat")
  };

  useEffect(() => {
    window.initJqueryTooltip();
  })

  const resetAllState = () => {
    setErrors({});
    setStartAt("00:00");
    setEndAt("00:00");
    setDaysOfWeek([]);
  };

  const hasError = (field) => !!errors[field];
  const getError = (field) => errors[field] || "";

  const getNewScheduleErrors = () => {
    const newScheduleErrors = {};

    const alreadyExists = !!schedules.find(schedule => {
      const now = new Date();

      let startAtHours, startAtMinutes, endAtHours, endAtMinutes;
      [startAtHours, startAtMinutes] = startAt.split(":");
      [endAtHours, endAtMinutes] = endAt.split(":");

      let scheduleStartAtHours, scheduleStartAtMinutes, scheduleEndAtHours, scheduleEndAtMinutes;
      [scheduleStartAtHours, scheduleStartAtMinutes] = schedule.startAt.split(":");
      [scheduleEndAtHours, scheduleEndAtMinutes] = schedule.endAt.split(":");

      const newStartDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startAtHours, startAtMinutes, 0);
      const newEndDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), endAtHours, endAtMinutes, 0);

      const scheduleStartDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), scheduleStartAtHours, scheduleStartAtMinutes, 0);
      const scheduleEndDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), scheduleEndAtHours, scheduleEndAtMinutes, 0);

      return (newStartDate >= scheduleStartDate && newEndDate <= scheduleEndDate)
        && daysOfWeek.every(day => schedule.daysOfWeek.includes(day));
    })

    if (alreadyExists) {
      newScheduleErrors['schedule_hours'] = I18n.t("views.manage_flows.whatsapp_block.there_is_already_a_schedule");
    }

    if (!startAt || !endAt) {
      newScheduleErrors['schedule_hours'] = I18n.t("views.manage_flows.whatsapp_block.inform_the_fields_correctly");
    }

    if (new Date(`1/1/2000 ${startAt}:01`) > new Date(`1/1/2000 ${endAt}:00`)) {
      newScheduleErrors['schedule_hours'] = I18n.t("views.manage_flows.whatsapp_block.start_time_must_be_less_than_end_time");
    }

    if (schedules.length >= MAX_SCHEDULES) {
      newScheduleErrors["schedule_hours"] = I18n.t("views.manage_flows.whatsapp_block.maximum_of_schedules",
      { maxSchedules: MAX_SCHEDULES });
    }

    if (!daysOfWeek.length) {
      newScheduleErrors['schedule_days'] = I18n.t("views.manage_flows.whatsapp_block.select_the_days_of_the_week");
    }

    return newScheduleErrors;
  }

  const handleAddButton = () => {
    if (!hasPermission) return;

    const newScheduleErrors = getNewScheduleErrors();

    if (!_.isEmpty(newScheduleErrors)) {
      return setErrors(newScheduleErrors);
    }

    const newSchedule = {startAt, endAt, daysOfWeek};
    const updatedSchedules = [...schedules, newSchedule];

    setDefinition(updatedSchedules);
    setSchedules(updatedSchedules);
    resetAllState();
  };

  const handleRemoveButton = (scheduleToDelete) => {
    if (!hasPermission) return;

    const updatedSchedules = schedules.filter(
      (schedule) => schedule !== scheduleToDelete
    );

    setSchedules(updatedSchedules);
    setDefinition(updatedSchedules);
  }

  const onChangeDayCheckbox = (e) => {
    if (!hasPermission) return;

    const updatedDaysOfWeek = [...daysOfWeek];

    if (e.target.checked) {
      updatedDaysOfWeek.push(+e.target.value);
    } else {
      const index = updatedDaysOfWeek.indexOf(+e.target.value);
      updatedDaysOfWeek.splice(index, 1);
    }

    setDaysOfWeek(updatedDaysOfWeek);
  }

  const ErrorInput = ({ fieldName }) => {
    if (hasError(fieldName)) {
      return (
        <div className="text-danger text-sm mt-2">
          {getError(fieldName)}
        </div>
      )
    }

    return <></>;
  }

  return (
    <fieldset className="form-fieldset mt-2">
      <div className="row g-3">
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">
              <i className="ti ti-clock me-1"></i>
              {I18n.t("views.manage_flows.whatsapp_block.time")}
            </label>

            <div className="input-group">
              <TimePicker
                value={startAt}
                className={`form-control ${hasError("schedule_hours") && "is-invalid"}`}
                placeholder={I18n.t("views.manage_flows.whatsapp_block.schedule_mask")}
                onSelectTime={(selectedTime) => setStartAt(selectedTime)}
                disabled={!hasPermission}
              />

              <span className="input-group-text">
                <i className="ti ti-arrow-right icon"></i>
              </span>

              <TimePicker
                value={endAt}
                className={`form-control ${hasError("schedule_hours") && "is-invalid"}`}
                placeholder={I18n.t("views.manage_flows.whatsapp_block.schedule_mask")}
                onSelectTime={(selectedTime) => setEndAt(selectedTime)}
                disabled={!hasPermission}
              />
            </div>

            <ErrorInput fieldName="schedule_hours" />
          </div>

          <div className="mb-2">
            <label className="form-label">
              <i className="ti ti-calendar-plus me-1"></i>
              {I18n.t("views.manage_flows.whatsapp_block.days_of_the_week")}
            </label>

            <div className="d-flex align-items-center justify-content-lg-between flex-wrap">
              {AVALIABLE_DAYS.map((numericWeekDay) => (
                <label className="form-check form-check-inline" key={numericWeekDay}>
                  <input
                    className={`form-check-input ${hasError("schedule_days") && "is-invalid"}`}
                    value={numericWeekDay}
                    type="checkbox"
                    checked={daysOfWeek.includes(numericWeekDay)}
                    disabled={!hasPermission}
                    onChange={onChangeDayCheckbox}
                  />
                  <span className="form-check-label">
                    {translatedDays[numericWeekDay]}
                  </span>
                </label>
              ))}
            </div>

            <ErrorInput fieldName="schedule_days" />
          </div>

          <PermissionsGate scope={PERMISSION_SCOPE}>
            <button
              type="button"
              className="btn btn-success float-end"
              onClick={() => handleAddButton()}
            >
              {I18n.t("general.actions.add")}
            </button>
          </PermissionsGate>
        </div>

        <div className="col-md">
          <div className="h4 mb-2">
            <i className="ti ti-calendar-stats me-1"></i>
            {I18n.t("views.manage_flows.whatsapp_block.schedules")} {schedules.length}/{MAX_SCHEDULES}
          </div>

          {!schedules.length && (
            <div className="alert alert-warning">
              <h4 className="alert-title text-body">
                {I18n.t("views.manage_flows.whatsapp_block.no_programming_registered")}
              </h4>

              <div className="text-muted">
                {I18n.t("views.manage_flows.whatsapp_block.include_new_schedules")}
              </div>
            </div>
          )}

          <ul className="list-unstyled">
            {schedules.map((schedule) => (
              <li
                className="mb-2"
                key={`${schedule.startAt}-${schedule.endAt}-${schedule.daysOfWeek.join(", ")}`}
              >
                <PermissionsGate scope={PERMISSION_SCOPE}>
                  <button
                    className="btn btn-icon me-2"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={I18n.t("general.actions.remove")}
                    onClick={() => handleRemoveButton(schedule)}
                  >
                    <i className="ti ti-trash-x icon"></i>
                  </button>
                </PermissionsGate>

                <div className="d-inline-block">
                  <strong>{`${schedule.startAt} ~ ${schedule.endAt}`}</strong>
                  <span className="ms-2">
                    ({schedule.daysOfWeek.sort().map((day) => translatedDays[day]).join(", ")})
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </fieldset>
  );
};

export default AvailabilitySchedule;
