import React from "react";

import { Alert, Button, Card } from "react-bootstrap";

import HelpPopover from "@/components/shared/HelpPopover";

import useContents from "./hooks/useContents";
import useCurrentUserStore from "@/store/currentUserStore";

const ContentLimit = () => {
  const { locale: userLocale } = useCurrentUserStore((state) => state.currentUser);
  const { consumedCharacters, limitCharacters, isCloseToLimit, isReachingLimit } = useContents();

  return (
    <Card>
      <Card.Body>
        <div className="fs-3 fw-medium mb-2">
          {I18n.t("views.leadster_ai.page.home.content_limit.title")}

          <HelpPopover
            className="ms-1"
            content={I18n.t("views.leadster_ai.page.home.content_limit.tooltip_html")}
          />
        </div>

        <p className="mb-2">
          {I18n.t("views.leadster_ai.page.home.content_limit.subtitle")}
        </p>

        <div className="d-flex align-items-center gap-1 mb-3">
          {(isReachingLimit) && <i className="ti ti-alert-triangle text-danger icon" />}

          <div className="fw-bold">
            {I18n.t("views.leadster_ai.page.home.content_limit.limit", {
              consumed: consumedCharacters.toLocaleString(userLocale, { minimumFractionDigits: 0 }),
              limit: limitCharacters.toLocaleString(userLocale, { minimumFractionDigits: 0 }),
            })}
          </div>
        </div>

        {isReachingLimit && (
          <Alert variant="danger" className="mb-2">
            {I18n.t("views.leadster_ai.page.home.content_limit.alert_exceded")}
          </Alert>
        )}

        {isCloseToLimit && (
          <Card
            body
            border={isReachingLimit ? "outline-primary" : "white"}
            style={{ backgroundColor: isReachingLimit ? "white" : "#CCE2FB" }}
          >
            <div className="mb-3">
              {isReachingLimit
                ? I18n.t("views.leadster_ai.page.home.content_limit.alert_buy")
                : I18n.t("views.leadster_ai.page.home.content_limit.alert_winning")}
            </div>

            <div className="d-grid gap-2">
              <Button variant="outline-primary" className="d-flex align-items-center justify-content-center">
                <i className="ti ti-brand-whatsapp me-1" />
                {I18n.t("views.leadster_ai.page.home.content_limit.contact_us")}
              </Button>
            </div>
          </Card>
        )}
      </Card.Body>
    </Card>
  );
};

export default ContentLimit;
