import { useState } from "react";

import ScriptBySegmentModal from "@/components/scripts/ScriptBySegmentModal";

import * as S from "./styles";

const NewFlowCard = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="card card-sm">
      <S.HeaderImage />

      <div className="card-body">
        <div className="fs-3 fw-medium d-flex align-items-center mb-2">
          <i className="ti ti-message-2-plus icon text-info me-2" />
          {I18n.t("views.leadster_ai.page.activate_assistant.to_new_flow.title")}
        </div>

        <p className="small">
          {I18n.t("views.leadster_ai.page.activate_assistant.to_new_flow.description")}
        </p>

        <button
          type="button"
          className="btn btn-outline-primary w-100"
          onClick={() => setShowModal(true)}
        >
          {I18n.t("views.leadster_ai.page.activate_assistant.to_new_flow.create_button")}
        </button>
      </div>

      <ScriptBySegmentModal
        show={showModal}
        onClose={() => setShowModal(false)}
        referrerAction="link-leadster-ai"
      />
    </div>
  );
};

export default NewFlowCard;
