import React from "react";

import parse from "html-react-parser";

import * as S from "./style";

const ErrorPage = () => {
  return (
    <S.Container>
      {parse(I18n.t("views.leadster_ai.page.error_page.body_html"))}
    </S.Container>
  );
};

export default ErrorPage;
