import styled, { css } from 'styled-components';

export const AvatarSpan = styled.span`
  ${(props) =>
    !!props.url && css`
      border: ${props.selected ? '2px solid #467fcf' : 'none' };
      background-image: url(${props.url});

      ${!props.disabled && css`
        &:hover {
          border: 2px solid #467fcf;
        }
      `}
    `
  };
`;
