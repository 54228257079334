export default {
  control: (baseStyles) => ({
    ...baseStyles,
    border: "1px solid var(--tblr-border-color)",
    boxShadow: "none",
    borderRadius: "var(--tblr-border-radius)",
    "&:hover": {
      borderColor: "var(--tblr-border-color)",
    },
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    maxHeight: "56px",
    overflowY: "auto",
  }),
  multiValue: (baseStyles) => ({
    ...baseStyles,
    margin: "0",
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    zIndex: 10,
  }),
};
