import React, { useState, useEffect } from "react";

import { useAppState } from "./AppState";

import TopLogo from "@/components/onboard/TopLogo"
import Selector from "./Selector";
import NavButtons from "./NavButtons";
import OptionAvatar from "./OptionAvatar";
import OptionColor from "./OptionColor";

import { SketchPicker } from "react-color";

const styles = {
  title: {
    margin: '20px 6px 2px 0',
    display: 'flex',
    alignItems: 'center'
  },
  colorSelector: {
    display: "flex",
    flexDirection: "row",
    marginBottom: '20px',
  },
  alertMaxLength: {
    marginLeft: 15,
    marginTop: 5
  },
};

export default function ChooseAvatar() {
  const appState = useAppState();

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [usedCustomColor, setUsedCustomColor] = useState(false);
  const [exceededMaxLengthAvatar, setExceededMaxLengthAvatar] = useState(false);

  const maxLengthAvatar = 40;

  useEffect(() => feather.replace());

  const avatars = [
    { name: "Ana", value: "https://cdn.leadster.com.br/neurolead/img/avatar/3.png" },
    { name: "Beatriz", value: "https://cdn.leadster.com.br/neurolead/img/avatar/12.png" },
    { name: "Rafael", value: "https://cdn.leadster.com.br/neurolead/img/avatar/2.png" },
    { name: "Carolina", value: "https://cdn.leadster.com.br/neurolead/img/avatar/5.png" }
  ];

  const popover = {
    position: "absolute",
    zIndex: "2",
    bottom: "30px"
  };

  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  return (
    <div id="choose-avatar">
      <TopLogo />
      <div>
        <h1>{I18n.t("views.onboard.choose_avatar_for_your_assistant")}</h1>

        {appState.segment == 'Outros' ? (
          <h2>{I18n.t("views.onboard.engagement_in_our_assistants")}</h2>
        ) : (
          <h2>{I18n.t("views.onboard.engagement_within_the_segment", { segment: I18n.t(`shared.segment.${appState.segment}`) })}</h2>
        )}
      </div>

      <h3 style={styles.title}>
        <i data-feather="instagram"></i>
        {I18n.t("views.onboard.select_a_photo")}
      </h3>

      <div className="flexonnext">
        <Selector
          onSelect={(avatar) => {
            const avatarName = `${avatar.name} - ${appState.companyName}`
            appState.setAvatar(avatar.value, avatarName)
          }}
          checkIfSelected={(option) => option === appState.avatar}
        >
          {avatars.map((avatar, index) => {
            return <OptionAvatar key={index} value={avatar.value} avatar={avatar} />;
          })}
        </Selector>
      </div>

      <h3 style={styles.title}>
        <i data-feather="user"></i>
        {I18n.t("views.onboard.avatar_name")}
      </h3>

      <div style={{ marginTop: '5px' }}>
        <input
          type="text"
          className="text-input"
          placeholder={`Ana - Empresa`}
          value={appState.name.substr(0, maxLengthAvatar)}
          maxLength={maxLengthAvatar}
          onChange={(event) => {
            const inputValue = event.target.value;
            setExceededMaxLengthAvatar(inputValue.length == maxLengthAvatar);
            appState.setName(inputValue);
          }}
        />

        {exceededMaxLengthAvatar &&
          <div className="form-text" style={styles.alertMaxLength}>
            <i data-feather="alert-triangle" style={{ marginRight: 5 }}></i>
            {I18n.t("views.onboard.reached_the_character_limit", { max_lenght: maxLengthAvatar })}
          </div>
        }
      </div>

      <h3 style={styles.title}>
        <i data-feather="feather"></i>
        {I18n.t("views.onboard.select_chat_color")}
      </h3>

      <div style={styles.colorSelector}>
        <Selector
          onSelect={(option) => appState.setColor(option)}
          checkIfSelected={(option) => option === appState.color}
        >
          <OptionColor key={1} value="#2196F3" />
          <OptionColor key={2} value="#4CAF50" />
          <OptionColor key={3} value="#FBC02D" />
          <OptionColor key={4} value="#E91E63" />
          <OptionColor key={5} value="#9E9E9E" />
        </Selector>
        <div>
          <div
            className="other-color-selector"
            onClick={() => setDisplayColorPicker(!displayColorPicker)}
            style={{
              borderColor: usedCustomColor === true ? appState.color : "",
            }}
          >
            <p>{I18n.t("views.onboard.other")}</p>
          </div>
          {displayColorPicker ? (
            <div style={popover}>
              <div style={cover} onClick={() => setDisplayColorPicker(false)} />
              <SketchPicker
                color={appState.color}
                onChange={(color, event) => {
                  appState.setColor(color.hex);
                  setUsedCustomColor(true);
                }}
              />
            </div>
          ) : null}
        </div>
      </div>

      <NavButtons next="/tooltip" prev="/segment" />
    </div>
  );
}
