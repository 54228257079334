import * as S from "./styles";

const Skeleton = () => {
	return (
		<S.Wrapper className="mt-4">
			{Array(3).fill().map((_, index) => (
				<div className="card shadow-sm placeholder-glow" key={index} style={{ minWidth: "235px" }}>
					<div className="card-header">
						<h3 className="card-title fw-bold placeholder col-4" />
					</div>
					<div className="card-body d-flex flex-column">
						<span className="placeholder col-3" />
						<p className="h3 placeholder mt-1 col-5" />
						<span className="placeholder col-6" />
						<br />
						<span style={{ height: "30px" }} className="placeholder col-12" />
					</div>
				</div>
			))}
		</S.Wrapper>
	);
};

export default Skeleton;
