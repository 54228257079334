import styled from "styled-components";

export const FormLabelSecundary = styled.div.attrs({
  className: "mt-2 fw-bold fs-5",
})`
  color: #7e8896;
  display: ${(props) => (props.disabled ? "none" : "block ruby")};
`;

export const FormLabelBadge = styled.div.attrs((props) => ({
  className: `badge bg-success fs-6 py-1 ${props.disabled ? "d-flex" : "d-none"}`,
}))`
  height: fit-content;
`;
