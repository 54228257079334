import React, { useState } from "react";

import PermissionsGate from "@/components/permissions/PermissionsGate";

import { can } from "@/lib/user-permission";

import axios from "@/lib/axios";
import swal from "sweetalert";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'dashboard_config'
});

const SwitchAvailability = ({ enabled, url, description, param_name }) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const [checked, setChecked] = useState(enabled);

  const handleAvailability = (e) => {
    if (!hasPermission) return;

    const checkedValue = e.target.checked;
    setChecked(checkedValue);

    const action = checkedValue ? "enable" : "disable";
    const label = checkedValue ? I18n.t("manage_flow.script_editor.enabled") : I18n.t("manage_flow.script_editor.disable");

    axios
      .post(url, { [param_name]: action })
      .then(() => {
        swal({
          text: `${label} ${I18n.t("manage_flow.script_editor.successfully")}`,
          icon: 'success'
        });
      })
      .catch(() => {
        swal({
          text: "Você não possuí as permissões necessárias para realizar esta ação!",
          icon: 'warning'
        });
      });
  };

  return (
    <PermissionsGate scope={PERMISSION_SCOPE} usePopover>
      <label className="form-check form-switch d-inline-block">
        <input
          className="form-check-input me-2"
          type="checkbox"
          checked={!!checked}
          onChange={handleAvailability}
        />

        <span className="form-check-label">{description}</span>
      </label>
    </PermissionsGate>
  );
};

export default SwitchAvailability;
