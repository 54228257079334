import React from "react";

import { captureException } from "@sentry/react";

const TidioButton = ({ children }) => {
  const handleSendTidioMessage = () => {
    if (!window.tidioChatApi) {
      toastr.error(I18n.t("shared.tidio.not_available"));
      return captureException(new Error("TidioChat is not configured"));
    }

    window.tidioChatApi.messageFromVisitor(
      I18n.t("views.leadster_ai.page.trial_alerts.contact_button.chat_default_message")
    );
  };

  return (
    <button
      type="button"
      className="btn btn-primary"
      onClick={handleSendTidioMessage}
    >
      {children}
    </button>
  );
};

export default TidioButton;
