import HireFeatureButton from "./HireFeatureButton";

import useCurrentUserStore from "@/store/currentUserStore";

import dayjs from "@/lib/dayjs";

import parse from "html-react-parser";

const TrialingAlert = () => {
  const { trialStartedAt, trialEndAt, freeQuestions } = useCurrentUserStore(
    (state) => state.currentUser.plugins.leadsterAi
  );

  return (
    <div className="alert alert-primary mb-3">
      <div className="d-flex align-items-center">
        <div>
          <i className="ti ti-info-circle icon alert-icon fs-1" />
        </div>

        <div>
          <div className="alert-title mb-3">
            {I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.title")}
            <span className="badge bg-success-lt ms-2">
              {I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.trialing.status")}
            </span>
          </div>

          <p className="mb-1 text-muted">
            {parse(I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.trialing.description_html", {
              started_at: dayjs(trialStartedAt).format(I18n.t("js.formats.default.date")),
              end_at: dayjs(trialEndAt).format(I18n.t("js.formats.default.date")),
              free_questions: freeQuestions,
            }))}
          </p>

          <a
            href={I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.link_know_more")}
            className="mb-3 d-block"
            target="_blank"
            rel="noopener noreferrer"
          >
            {I18n.t("shared.actions.click_to_learn_more")}
            <i className="ti ti-external-link ms-1" />
          </a>

          <div className="btn-list">
            <HireFeatureButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrialingAlert;
