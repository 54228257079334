import ScriptItem from "./ScriptItem";

const ScriptsList = ({ scripts }) => {
  if (!scripts.length) {
    return null;
  }

  return (
    <div className="overflow-auto row mb-3" style={{ maxHeight: 215 }}>
      {scripts.map((script) => (
        <ScriptItem script={script} key={script.id} />
      ))}
    </div>
  );
};

export default ScriptsList;
