import { useRef } from "react";
import useUpdateEffect from '@/hooks/useUpdateEffect';

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from "use-context-selector";

const useDinamycFields = () => {
  const dynamicFields = useContextSelector(ScriptContext, ({ state }) => state.dynamicFields)
  const updatedDynamicFieldsRef = useRef(dynamicFields);

  useUpdateEffect(() => {
    for (const prop of Object.getOwnPropertyNames(updatedDynamicFieldsRef.current)) {
      delete updatedDynamicFieldsRef.current[prop];
    }

    _.assign(updatedDynamicFieldsRef.current, dynamicFields);
  }, [dynamicFields]);

  return updatedDynamicFieldsRef.current;
};

export default useDinamycFields;
