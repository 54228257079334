import create from "zustand";

const useScriptAnalyticsStore = create((set) => ({
  scriptId: null,
  currentScriptVersionHash: "",

  setData: (data) => set(data),
}));

export default useScriptAnalyticsStore;
