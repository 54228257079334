import React from "react";

import Flatpickr from "react-flatpickr";

export const TimePicker = ({ value, className, onSelectTime, ...props }) => {
  const handleOnChangeTime = (date, selectedTime) => {
    onSelectTime(selectedTime);
  };

  return (
    <Flatpickr
      value={value}
      className={className}
      onChange={handleOnChangeTime}
      options={{
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      }}
      {...props}
    />
  );
};
