import { useState, useEffect, useRef } from "react";

import CardInformation from "./components/CardInformation";
import SumarizationInfo from "./components/SumarizationInfo";
import ColSkeleton from "./components/ColSkeleton/ColSkeleton";
import DonutChart from "./components/DonutChart";

import { Row, Col, Form } from "react-bootstrap";

import { fetchLinkedScripts, fetchAssistantAnalitcs, fetchSummaryQuestions } from "../services/fetchServices";
import useDatePickRange from "./hooks/useDatePickRange";
import useCreateYourAiAssistant from "@/hooks/useCreateYourAiAssistant";

import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import dayjs from "@/lib/dayjs";

const ServiceAnalytics = () => {
  useCreateYourAiAssistant("/dashboard", "analytics");

  const { assistantId } = useParams();

  const [isLoading, setLoading] = useState(true);
  const [isLoadingDonutCharts, setIsLoadingDonutCharts] = useState(true);

  const [scriptFilter, setScriptFilter] = useState([]);
  const [scriptSelectFilter, setScriptSelectFilter] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [currentWithConext, setCurrentWithConext] = useState({});
  const [currentWithoutConext, setCurrentWithoutConext] = useState({});
  const [lastUpdated, setLastUpdated] = useState();
  const [startDate, setStartDate] = useState(dayjs().subtract(6, 'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));

  const dataRange = useRef();

  useDatePickRange(dataRange.current, startDate, endDate, (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  });

  const handleScriptFilterChange = (event) => {
    setScriptSelectFilter(event.target.value === "all" ? [null] : event.target.value);
  }

  const loadScriptFilter = async () => {
    const { linkedScripts } = await fetchLinkedScripts({ assistantId });

    setScriptFilter(linkedScripts);
    setScriptSelectFilter(linkedScripts?.map(({ id }) => id));
  }

  const loadAssistantAnalytics = async () => {
    setLoading(true);

    const { analytics } = await fetchAssistantAnalitcs({
      scriptIds: scriptSelectFilter,
      assistantId,
      startDate,
      endDate,
    });

    setCurrentData(analytics);
    setLoading(false);
  }

  const loadSummaryQuestions = async () => {
    setIsLoadingDonutCharts(true);

    const { sumaryQuestions } = await fetchSummaryQuestions({ assistantId });
    const template = { startAt: null, endAt: null, series: [], options: { labels: [] } }

    setCurrentWithConext({ ...template, ...(sumaryQuestions?.with_context || {}) });
    setCurrentWithoutConext({ ...template, ...(sumaryQuestions?.without_context || {}) });
    setLastUpdated(sumaryQuestions?.last_updated);
    setIsLoadingDonutCharts(false);
  }

  useEffect(() => {
    loadScriptFilter();
    loadSummaryQuestions();
  }, []);

  useEffect(() => {
    loadAssistantAnalytics();
  }, [scriptSelectFilter, startDate, endDate]);

  return (
    <>
      <div>
        <Row className="mb-3">
          <Col>
            <Form.Select onChange={handleScriptFilterChange} defaultValue="all">
              <option value="all">
                {I18n.t('views.leadster_ai.page.analytics.dashboard.all_flows')}
              </option>

              {scriptFilter?.map(({ id, name }) => (
                <option key={id} value={id}>{name}</option>
              ))}
            </Form.Select>
          </Col>

          <Col>
            <Form.Control ref={dataRange} readOnly />
          </Col>
        </Row>

        <div className="text-muted small mb-3">
          {parse(I18n.t('views.leadster_ai.page.analytics.dashboard.real_time_updated_html'))}
        </div>

        <Row className="mb-3 g-3 row-deck row-cards">
          {isLoading ? <ColSkeleton itensNum={6} /> : (
            <>
              <Col sm={4}><CardInformation {...currentData?.total_visitors} /></Col>
              <Col sm={4}><CardInformation {...currentData?.average_duration} /></Col>
              <Col sm={4}><CardInformation {...currentData?.average_questions} /></Col>
              <Col sm={4}><CardInformation {...currentData?.total_questions} /></Col>
              <Col sm={4}><CardInformation {...currentData?.answered_questions} /></Col>
              <Col sm={4}><CardInformation {...currentData?.unanswered_questions} /></Col>
            </>
          )}
        </Row>
      </div>

      <hr />

      <div>
        <div className="h3 fw-bold mb-1">
          {I18n.t('views.leadster_ai.page.analytics.dashboard.graph.title')}
        </div>

        <p className="text-secondary">
          {parse(I18n.t('views.leadster_ai.page.analytics.dashboard.graph.subtitle'))}
        </p>

        <SumarizationInfo lastUpdated={lastUpdated} />

        <Row>
          <Col>
            <DonutChart
              title={I18n.t('views.leadster_ai.page.analytics.dashboard.graph.with_context.title')}
              subtitle={I18n.t('views.leadster_ai.page.analytics.dashboard.graph.with_context.subtitle')}
              isLoading={isLoadingDonutCharts}
              data={currentWithConext}
            />
          </Col>

          <Col>
            <DonutChart
              title={I18n.t('views.leadster_ai.page.analytics.dashboard.graph.without_context.title')}
              subtitle={I18n.t('views.leadster_ai.page.analytics.dashboard.graph.without_context.subtitle')}
              isLoading={isLoadingDonutCharts}
              data={currentWithoutConext}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ServiceAnalytics;
