import React, { useState } from "react";
import useDinamycFields from "@/components/scripts/hooks/useDinamycFields";
import RichTextEditor from "@/components/shared/RichTextEditor";
import setupEditor from "../../ScriptEditorParts/setup-editor";

const AssistantInputMessage = ({ onDefinition, hasPermission, conversationId, say }) => {
  const dynamicFields = useDinamycFields();
  const [botMessage, setMessage] = useState(say || I18n.t("views.manage_flows.script_editor.ia_interaction.default_message"));

  const handleMessageChande = (msg, editor) => {
    setMessage(msg);
    onDefinition({ say: editor.getContent({ format: 'text' }) });
  }

  return (
    <>
      <div className="form-control p-0 border-0">
        <RichTextEditor
          value={botMessage}
          inline={true}
          disabled={!hasPermission}
          init={{
            menubar: false,
            plugins: [],
            toolbar: false,
            placeholder: I18n.t("views.manage_flows.script_editor.ia_interaction.placeholder_message"),
            toolbar: "autocompleteButton",
            setup: (editor) => setupEditor(editor, dynamicFields, conversationId),
          }}
          onEditorChange={handleMessageChande}
        />
      </div>
    </>
  );
};

export default AssistantInputMessage;
