import { useState, useCallback, useEffect } from "react";

import useCurrentUserStore from "@/store/currentUserStore";

import { captureException } from "@sentry/react";

const STORAGE_KEY = "leadster_ai_trial_ended";

const useShowAlert = () => {
  const { companyId } = useCurrentUserStore((state) => state.currentUser);

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (canShowAlert()) {
      setShowAlert(true);
      updateStoreValue();
    }
  }, []);

  const canShowAlert = useCallback(() => {
    const storageValue = window.localStorage.getItem(STORAGE_KEY);

    if (!storageValue) {
      return true;
    }

    try {
      return !JSON.parse(storageValue).includes(companyId);
    } catch (error) {
      captureException(error);
      return false;
    }
  }, []);

  const updateStoreValue = () => {
    const storageValue = window.localStorage.getItem(STORAGE_KEY);
    const newStorageValue = !!storageValue ? JSON.parse(storageValue) : [];

    newStorageValue.push(companyId);

    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(newStorageValue));
  };

  return {
    showAlert,
  };
};

export default useShowAlert;
