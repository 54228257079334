import { useState, useEffect, useRef } from "react";

import GuideModal from "@/components/shared/GuideModal";
import useShowGuideStorage from "@/components/shared/GuideModal/hooks/useShowGuideStorage";

import useCreateYourAiAssistant from "@/hooks/useCreateYourAiAssistant";
import { useAssistantContext } from "@/contexts/AssistantContext";
import EventBus from "@/utils/event_bus";

import { useHistory } from "react-router-dom";
import parse from "html-react-parser";
import Select from "react-select";

import guideContents from "./guideContents";
import folderIcon from "@/../assets/images/ai_assistant/file-folder-animated.gif";
import ChatPreview from "@/components/leadster_ia/subpages/CreateYourAiAssistant/ChatPreview";

import * as S from "./styles";

const TestAssistant = () => {
  useCreateYourAiAssistant("/test");

  const history = useHistory();
  const guideStorageKey = "leadster-ai-preview";
  const intervalIdRef = useRef();

  const { assistant } = useAssistantContext();
  const { isGuideAllowed } = useShowGuideStorage(guideStorageKey);

  const [showFinishModal, setShowFinishModal] = useState(false);
  const [retailerItemOptions, setRetailerItemOptions] = useState([]);

  const goToNextStep = () => {
    history.push("./activation");
  };

  const handleClickAddToFlow = () => {
    if (isGuideAllowed) {
      return setShowFinishModal(true);
    }

    goToNextStep();
  };

  const handleSelectChange = (selectedOption) => {
    EventBus.dispatch("updateRetailerItemId", selectedOption?.value);
  };

  const handleLoadOptions = () => {
    assistant?.retailer_item_ids_with_url.map((item) => {
      if (!item[0]) return;
      setRetailerItemOptions((prev) => [...prev, { value: item[0], label: item[1] }]);
    });
  };

  const handleViewedLeadsterAIBlock = () => {
    clearInterval(intervalIdRef.current);

    intervalIdRef.current = setInterval(() => {
      if (document.getElementById("nld-event-bus")) {
        EventBus.on("viewedLeadsterAIBlock", handleLoadOptions);
        clearInterval(intervalIdRef.current);
      }
    }, 1000);
  };

  useEffect(() => {
    handleViewedLeadsterAIBlock();

    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, []);

  return (
    <>
      {assistant.already_populated_bases ? (
        <>
          <GuideModal
            enabled={showFinishModal}
            storageKey={guideStorageKey}
            guideContents={guideContents}
            finishButtonText={I18n.t("general.actions.add")}
            finishCallback={goToNextStep}
          />

          {retailerItemOptions.length > 0 && (
            <div className="card mb-3">
              <div className="card-body">
                <div className="card-title mb-2">
                  {I18n.t("views.leadster_ai.page.test_assistant.select_content.title")}
                </div>
                <div className="card-subtitle mb-3 fs-4">
                  {I18n.t("views.leadster_ai.page.test_assistant.select_content.subtitle")}
                </div>

                <Select
                  options={retailerItemOptions}
                  isMulti={false}
                  isClearable={true}
                  isSearchable={true}
                  onChange={handleSelectChange}
                  placeholder={I18n.t("views.leadster_ai.page.test_assistant.select_content.placeholder")}
                  className="mb-3"
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 2147483647 }),
                  }}
                />
              </div>
            </div>
          )}

          <S.Container>
            <ChatPreview hideButtonClose />
          </S.Container>

          <div className="border-top mt-3 pt-3 text-end">
            <button type="button" className="btn btn-primary" onClick={handleClickAddToFlow}>
              {I18n.t("views.leadster_ai.page.test_assistant.add_to_flow")}
            </button>
          </div>
        </>
      ) : (
        <S.Container>
          <S.Icon src={folderIcon} />

          <div className="fs-3 fw-bold my-3">{I18n.t("views.leadster_ai.page.test_assistant.text_01")}</div>

          <S.Paragraph className="text-center">
            {parse(I18n.t("views.leadster_ai.page.test_assistant.text_02_html"))}
          </S.Paragraph>
        </S.Container>
      )}
    </>
  );
};

export default TestAssistant;
