import styled from "styled-components";
import { Form } from "react-bootstrap";

export const SelectorCheckbox = styled.div`
  transition: all 0.2s ease;

  background-color: ${({ isInvalid }) =>
    isInvalid ? "rgba(var(--tblr-danger-rgb), 0.1)" : "rgba(var(--tblr-primary-rgb), 0.04)"};

  border-color: ${({ isInvalid }) => (isInvalid ? "var(--tblr-danger)" : "var(--tblr-border-color)")};

  &:hover {
    cursor: pointer !important;
    background: ${({ isInvalid }) =>
      isInvalid ? "rgba(var(--tblr-danger-rgb), 0.15)" : "rgba(var(--tblr-primary-rgb), 0.08)"};
  }

  &.selected {
    background-color: ${({ isInvalid }) =>
      isInvalid ? "rgba(var(--tblr-danger-rgb), 0.05)" : "rgba(var(--tblr-primary-rgb), 0.05)"};

    border-color: ${({ isInvalid }) => (isInvalid ? "var(--tblr-danger)" : "var(--tblr-primary)")};
  }
`;

export const CustomFormCheck = styled(Form.Check).attrs({
  inline: true,
  type: "radio",
  className: "d-flex align-items-center flex-row",
})`
  .form-check-input {
    margin-right: 0.5rem;
    flex-shrink: 0;
  }

  .form-check-label {
    display: flex !important;
    align-items: center !important;
  }
`;
