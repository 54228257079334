import React, { useState } from "react";
import PropTypes from "prop-types";

import RichTextEditor from "@/components/shared/RichTextEditor";

import Carousel, { consts } from "react-elastic-carousel";

import { classBinding } from "@/utils/helpers";

import * as Styled from "./style";

const CarouselConversionCalls = ({
  conversionCallsList,
  setNewConversionCall,
  selectedConversionCallIndex,
  setSelectedConversionCallIndex,
}) => {
  const [conversionCalls, setConversionCalls] = useState(conversionCallsList);

  const onChangeCarousel = (editor, carouselIndex) => {
    setSelectedConversionCallIndex(carouselIndex);

    setNewConversionCall(conversionCalls[carouselIndex]);

    MixpanelService.track('page_rank_navigate_gpt_calls', {
      "Página": conversionCalls[carouselIndex]
    });
  };

  const onEditorChange = (value, carouselIndex) => {
    if (carouselIndex !== selectedConversionCallIndex) return;

    let updatedConversionCalls = [...conversionCalls];
    updatedConversionCalls[selectedConversionCallIndex] = value;

    setConversionCalls(updatedConversionCalls);
    setNewConversionCall(value);
  };

  const renderCustomArrow = ({ type, onClick, isEdge }) => {
    const direction = type === consts.PREV ? "left" : "right";

    return (
      <button
        type="button"
        className={classBinding({ "text-muted": isEdge }, "btn-link text-decoration-none")}
        onClick={onClick}
        disabled={isEdge}
      >
        <i className={`ti ti-chevron-${direction}`} />
      </button>
    );
  };

  return (
    <Styled.CarouselContainer>
      <Carousel
        showArrows
        pagination={false}
        enableMouseSwipe={false}
        renderArrow={renderCustomArrow}
        onChange={onChangeCarousel}
      >
        {conversionCalls.map((conversionCall, index) => (
          <div className="d-flex align-items-center p-1" key={index}>
            <Styled.Avatar />

            <Styled.EditorContainer>
              <RichTextEditor
                value={conversionCall}
                onEditorChange={(newValue) => onEditorChange(newValue, index)}
                inline={true}
                init={{
                  height: 120,
                  max_height: 120,
                  menubar: false,
                  plugins: "emoticons",
                  toolbar: "bold italic underline | emoticons",
                  smart_paste: false,
                  convert_urls: false,
                }}
              />

            </Styled.EditorContainer>
          </div>
        ))}
      </Carousel>
    </Styled.CarouselContainer>
  );
};

CarouselConversionCalls.propTypes = {
  conversionCallsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  setNewConversionCall: PropTypes.func.isRequired,
  selectedConversionCallIndex: PropTypes.number.isRequired,
  setSelectedConversionCallIndex: PropTypes.func.isRequired,
};

export default CarouselConversionCalls;
