import _ from 'lodash';

export function classBinding(dynamic, classes = "") {
  return Object.entries(dynamic)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(" ")
    .concat(" ")
    .concat(classes);
};

export function shortUID() {
  return Math.random().toString(36).substring(2, 8);
}

export function cleanObjectEmptyValues(obj) {
  let cleanedObject = _.omitBy(obj, _.isNil);
  cleanedObject = _.omitBy(cleanedObject, (value) => value === "");

  return cleanedObject;
}

export function replaceTag(data, tag, newTag) {
  if (typeof data === 'object') return data

  const openTagRegex = new RegExp(`<${tag}>`, 'g');
  const closeTagRegex = new RegExp(`</${tag}>`, 'g');
  const newOpenTag = `<${newTag}>`;
  const newCloseTag = `</${newTag}>`;

  if (data && data.length > 0){
    return data.replace(openTagRegex, newOpenTag)
      .replace(closeTagRegex, newCloseTag);
  }
}

export function truncateReverse(str, length = 100) {
  const truncatedString = _.truncate(str.split("").reverse().join(""), { length: length });

  return truncatedString.split("").reverse().join("");
}

export function truncate(str, length = 100) {
  return _.truncate(str, {length: length});
}

export function trimHtml(html, options) {

  options = options || {};

  var limit = options.limit || 90,
    preserveTags = (typeof options.preserveTags !== 'undefined') ? options.preserveTags : true,
    wordBreak = (typeof options.wordBreak !== 'undefined') ? options.wordBreak : false,
    suffix = options.suffix || '',
    moreLink = options.moreLink || '';

  var arr = html.replace(/</g, "\n<")
    .replace(/>/g, ">\n")
    .replace(/\n\n/g, "\n")
    .replace(/^\n/g, "")
    .replace(/\n$/g, "")
    .split("\n");

  var sum = 0,
    row, cut, add,
    tagMatch,
    tagName,
    tagStack = [],
    more = false;

  for (var i = 0; i < arr.length; i++) {

    row = arr[i];
    // count multiple spaces as one character
    var rowCut = row.replace(/[ ]+/g, ' ');

    if (!row.length) {
      continue;
    }

    if (row[0] !== "<") {

      if (sum >= limit) {
        row = "";
      } else if ((sum + rowCut.length) >= limit) {

        cut = limit - sum;

        if (row[cut - 1] === ' ') {
          while(cut){
            cut -= 1;
            if(row[cut - 1] !== ' '){
              break;
            }
          }
        } else {

          add = row.substring(cut).split('').indexOf(' ');

          // break on halh of word
          if(!wordBreak) {
            if (add !== -1) {
              cut += add;
            } else {
              cut = row.length;
            }
          }
        }

        row = row.substring(0, cut) + suffix;

        if (moreLink) {
          row += '<a href="' + moreLink + '" style="display:inline">»</a>';
        }

        sum = limit;
        more = true;
      } else {
        sum += rowCut.length;
      }
    } else if (!preserveTags) {
      row = '';
    } else if (sum >= limit) {

      tagMatch = row.match(/[a-zA-Z]+/);
      tagName = tagMatch ? tagMatch[0] : '';

      if (tagName) {
        if (row.substring(0, 2) !== '</') {

          tagStack.push(tagName);
          row = '';
        } else {

          while (tagStack[tagStack.length - 1] !== tagName && tagStack.length) {
            tagStack.pop();
          }

          if (tagStack.length) {
            row = '';
          }

          tagStack.pop();
        }
      } else {
        row = '';
      }
    }

    arr[i] = row;
  }

  return {
    html: arr.join("\n").replace(/\n/g, ""),
    more: more
  };
}

export function onlyNumber(value = "") {
  return value.replace(/\D+/g, "");
}
