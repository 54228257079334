import React, { useState } from "react";

const AdvancedConfigurationToggler = ({ children, isOpened = false }) => {
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(isOpened);

  return (
    <>
      <div className="cursor-pointer form-label" onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}>
        <i className={`ti ti-chevron-${showAdvancedOptions ? 'down' : 'right'} me-2`} />
        {I18n.t("views.manage_flows.script_editor.advanced_settings")}
      </div>

      {showAdvancedOptions && children}
    </>
  );
};

export default AdvancedConfigurationToggler;
