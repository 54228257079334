export const generateSankeyOptions = (orient = "vertical", nodes = [], links = []) => {
  const options = {
    tooltip: {
      trigger: "item",
      triggerOn: "mousemove",
    },
    animation: true,
    series: [
      {
        type: "sankey",
        data: nodes,
        links: links,
        layoutIterations: 32,
        nodeGap: 32,
        top: 0,
        left: 0,
        bottom: 0,
        right: orient == "vertical" ? 0 : 80,
        nodeAlign: "right",
        emphasis: {
          focus: "adjacency",
        },
        orient: orient,
        lineStyle: {
          color: "target",
          curveness: 0.5,
        },
        label: {
          position: orient == "vertical" ? "top" : "right",
          color: "#475569",
          fontFamily: "DM Sans, San Francisco, Segoe UI, Helvetica Neue, sans-serif",
          fontSize: 13,
          fontWeight: 600,
        },
      },
    ],
  };

  return options;
};
