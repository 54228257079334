import { useEffect } from "react";

import { useAssistantContext } from "@/contexts/AssistantContext";

import { getLinkProps } from "@/components/leadster_ia_pages/AiAssistant/subpages/routes";

const useCreateYourAiAssistant = (path = "", routeBase = "createAssistant") => {
  const { updateSettings } = useAssistantContext();
  const linkProps = getLinkProps(path, routeBase);

  useEffect(() => {
    updateSettings({
      pages: {
        assistant: {
          subpages: {
            title: linkProps.title,
            description: linkProps.description,
            createYourAiAssistant: {
              title: linkProps.title,
              description: linkProps.description,
              currentStep: linkProps.path,
            },
          },
        },
      },
    });
  }, []);

  return {
    linkProps,
  };
};

export default useCreateYourAiAssistant;
