import PropTypes from "prop-types";

import useCurrentUserStore from "@/store/currentUserStore";

import Encryptor from "@/lib/encryptor";

const UserProfile = ({ encryptedData }) => {
  const setCurrentUser = useCurrentUserStore((state) => state.setCurrentUser);

  setCurrentUser(Encryptor.decrypt(encryptedData));

  return null;
};

UserProfile.propTypes = {
  encryptedData: PropTypes.string.isRequired,
};

export default UserProfile;
