import React from "react";

import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";

import Select from "react-select";

const Step2 = props => {
  // State Machine
  const { action, state } = useStateMachine(updateAction);

  // Form
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  register("name.allowed_scripts")


  const onSubmit = data => {
    action(data);
    MixpanelService.track('integrations_third_step');
    props.history.push("./step4");
  };

  const integrationBlueprintId = state.data.integration_blueprint_id;
  const isEmailIntegration = integrationBlueprintId == 5;
  const scripts = props.scripts.map(script => ({ value: script.id, label: script.name }))

  const handleOnChange = (newValue, _) => {
    setValue("allowed_scripts", newValue);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row row-cards">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{I18n.t("views.integrations.step02.title")}</h3>
            </div>

            <div className="card-body">
              {isEmailIntegration && (
                <small>* {I18n.t("views.integrations.step02.all_flows")}</small>
              )}

              <Select
                closeMenuOnSelect={false}
                defaultValue={isEmailIntegration ? scripts : state.data.allowed_scripts}
                isMulti
                onChange={handleOnChange}
                options={scripts}
                placeholder={isEmailIntegration ? I18n.t("views.integrations.step02.all_flows") : I18n.t("views.integrations.step02.select_the_flows")}
                isDisabled={isEmailIntegration}
              />

              {errors.allowed_scripts && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {I18n.t("views.integrations.step02.choose_a_stream")}
                </div>
              )}
            </div>

            <div className="card-footer bg-white text-end">
              <div className="d-flex">
                <button
                  className="btn"
                  type="button"
                  onClick={() => props.history.goBack()}
                >
                  {I18n.t("shared.actions.return")}
                </button>
                <button className="btn btn-primary ms-auto" type="submit">
                  {I18n.t("shared.actions.next")}
                  <i className="ti ti-arrow-right ms-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default withRouter(Step2);
