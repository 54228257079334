import "./style.css";
import { useCallback } from "react";

import useDefaultOptionsList from "./hooks/useDefaultOptionsList";

import { isNodeCompatibleWith } from "@/components/scripts/NodeCompat";
import { setBlockUniqueLabel } from "@/components/scripts/ScriptEditor/scriptFieldLabels";

const AddBlockButton = ({ parent, item, alwaysShow }) => {
  const addBlock = useCallback((parent, node, block) => {
    const newBlock = { ...block };

    setBlockUniqueLabel(newBlock);

    if (node) {
      const path = node.path.concat(node.id);
      node.treeAdd(node, node.makeBlock(path, newBlock), "below");
    } else {
      const path = parent.path.concat(parent.id);
      parent.treeAdd(parent, parent.makeBlock(path, newBlock), "inside");
    }
  }, []);

  const handleAddNewBlock = (event, listItem) => {
    event.preventDefault();

    if (!listItem.isAllowed) return;

    addBlock(parent, item, listItem.configuration);
    MixpanelService.track("script_added_block", { Bloco: listItem.text });
  };

  const optionsList = useDefaultOptionsList()
    .map((option) => {
      return {
        ...option,
        itens: option.itens.filter((item) => {
          return item.show && isNodeCompatibleWith(parent, item.action);
        }),
      };
    })
    .filter((option) => !!option.itens.length);

  return (
    <div className={`add-block-button text-center ${!alwaysShow ? 'show-on-hover' : ''}`}>
      <div className="dropdown">
        <button type="button"
          className="btn btn-sm btn-outline-primary py-1"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="ti ti-plus me-1"></i>
          {I18n.t("views.manage_flows.scripts.add_block")}
        </button>

        <div className="dropdown-menu">
          {optionsList.map((option, index) => (
            <div key={index}>
              <h6 className="dropdown-header">{option.grouperText}</h6>

              {option.itens.map((listItem) => (
                <a
                  href="#"
                  key={listItem.action}
                  className={`dropdown-item ${listItem.isAllowed ? '' : 'cursor-not-allowed'}`}
                  onClick={(e) => handleAddNewBlock(e, listItem)}
                >
                  <i className={`dropdown-item-icon me-2 icon ${listItem.icon}`} />
                  {listItem.text}
                  {listItem.isNew && (
                    <div className="badge bg-primary mx-2">
                      <i className="ti ti-sparkles icon"></i> {I18n.t("views.manage_flows.scripts.new")}
                    </div>
                  )}
                </a>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddBlockButton;
