import FunnelChart from "./FunnelChart";
import SankeyChart from "./SankeyChart";
import Filters from "./Filters";

import { classBinding } from "@/utils/helpers";

import useScriptConversionFetcher from "./hooks/useScriptConversionFetcher";

const Conversion = () => {
  const {
    chartData,
    chartType,
    leadsCount,
    scriptVersionHash,
    setScriptVersionHash,
    allowedScriptVersions,
    channel,
    setChannel,
    isLoading,
  } = useScriptConversionFetcher();

  return (
    <>
      <div className={classBinding({ "content-loading": isLoading }, "card mb-3")}>
        <div className="card-body">
          <div className="row align-items-center">
            <div className="h2 mb-3">
              {I18n.t("views.analytics.conversion_analysis")}
            </div>

            <div className="card-subtitle text-muted mb-0">
              {I18n.t("views.analytics.graph_description")}
            </div>
          </div>
        </div>

        <div className="card-body">
          <Filters
            leadsCount={leadsCount}
            allowedScriptVersions={allowedScriptVersions}
            defaultScriptVersionHash={scriptVersionHash}
            onChangeScriptVersionHash={(hash) => setScriptVersionHash(hash)}
            defaultChannel={channel}
            onChangeChannel={(channel) => setChannel(channel)}
          />
        </div>
      </div>

      <div>
        {!isLoading && chartType === "funnel" && (
          <FunnelChart
            labels={_.get(chartData, "labels", [])}
            values={_.get(chartData, "values", [])}
          />
        )}

        {!isLoading && chartType === "sankey" && (
          <SankeyChart
            nodes={_.get(chartData, "nodes", [])}
            links={_.get(chartData, "links", [])}
          />
        )}
      </div>
    </>
  );
};

export default Conversion;
