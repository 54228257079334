import React, { useState } from "react";
import PropTypes from "prop-types";

import CarouselConversionCalls from "./CarouselConversionCalls";
import CreateFlowButton from "./CreateFlowButton";
import ConversionCallAlert from "./ConversionCallAlert";

import {classBinding, truncate} from "@/utils/helpers";

const MAX_URL_LENGTH = 60;
const DEFAULT_CONV_CALL_INDEX = 0;

const PageData = ({ rowIndex, pageRank }) => {
  const [selectedConversionCallIndex, setSelectedConversionCallIndex] = useState(DEFAULT_CONV_CALL_INDEX);
  const [newConversionCall, setNewConversionCall] = useState("");

  const pageUrlTruncateProps = pageRank.url.length < MAX_URL_LENGTH ? {} : {
    className: "cursor-help",
    "data-bs-toggle": "popover",
    "data-bs-placement": "top",
    "data-bs-trigger": "hover",
    "data-bs-content": pageRank.url,
  };

  const callStatusDone = pageRank.call_status === "done";

  return (
    <tr>
      <td className="text-center w-1">{rowIndex}</td>
      <td className="truncate-url">
        <a href={pageRank.url} target={"_blank"} rel={"noopener"}>
          <span {...pageUrlTruncateProps}>
            <i className="fa fa-pencil-square-o"/> {truncate(pageRank.url, MAX_URL_LENGTH)}
          </span>
        </a>
      </td>

      <td className="text-center w-1">
        <span className="fw-medium">{pageRank.access}</span>
      </td>

      <td className="text-center w-1">
        <span
          className={classBinding(
            {
              "text-success": pageRank.conversion_rate >= 5,
              "text-primary": pageRank.conversion_rate >= 3 && pageRank.conversion_rate < 5,
              "text-warning": pageRank.conversion_rate > 1 && pageRank.conversion_rate < 3,
              "text-danger": pageRank.conversion_rate <= 1,
            }, "fw-bolder")}>
            {pageRank.conversion_rate}%
          </span>

      </td>

      <td className="text-center p-1" style={{ minWidth: 440, width: 440 }}>
        {callStatusDone ? (
          <CarouselConversionCalls
            conversionCallsList={pageRank.call_messages}
            setNewConversionCall={setNewConversionCall}
            selectedConversionCallIndex={selectedConversionCallIndex}
            setSelectedConversionCallIndex={setSelectedConversionCallIndex}
          />
        ) : (
          <ConversionCallAlert
            callStatus={pageRank.call_status}
            errorInline={pageRank.error}
          />
        )}
      </td>

      <td className="text-center w-1">
        <CreateFlowButton
          pageRank={pageRank}
          newConversionCall={newConversionCall}
          suggestedConversionCall={callStatusDone ? pageRank.call_messages[selectedConversionCallIndex] : ""}
          disabled={!callStatusDone}
        />
      </td>
    </tr>
  );
};

PageData.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  pageRank: PropTypes.object.isRequired,
};

export default PageData;
