import { useState, useEffect } from "react";

const useActiveTab = (defaultTab) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [loadedTabContents, setLoadedTabContents] = useState([defaultTab]);

  useEffect(() => {
    setLoadedTabContents([...loadedTabContents, activeTab]);
  }, [activeTab]);

  const tabIsLoaded = (tab) => loadedTabContents.includes(tab);

  return [activeTab, setActiveTab, tabIsLoaded];
};

export default useActiveTab;
