import { useState } from "react";

import InsufficientDataAlert from "../../Alerts/InsufficientDataAlert";

import ReactECharts from "echarts-for-react";

import { generateSankeyOptions } from "./utils";

import parse from "html-react-parser";

const SankeyChart = ({ nodes, links }) => {
  const [orient, setOrient] = useState("horizontal");

  const chartOptions = generateSankeyOptions(orient, nodes, links);

  return (
    <div className="card card-sm overflow-auto">
      <div className="card-header flex-column align-items-start">
        <h3 className="card-title d-flex align-items-center mb-2">
          <i className="ti ti-trending-up text-success icon me-2"></i>
          <span>{I18n.t("views.analytics.conversion_by_question")}</span>
        </h3>

        <div className="text-muted mb-0">
          {parse(I18n.t("views.analytics.count_leads_respond_html"))}
        </div>
      </div>

      <div className="card-body">
        {_.isEmpty(links) ? <InsufficientDataAlert /> : (
          <div>
            <div className="row">
              <div className="fom-group mb-3 col-6 col-md-4">
                <label className="form-label">
                  {I18n.t("views.analytics.orientation_graph")}
                </label>

                <select className="form-select" value={orient} onChange={(e) => setOrient(e.target.value)}>
                  <option value="horizontal">
                    {I18n.t("views.analytics.horizontal")}
                  </option>

                  <option value="vertical">
                    {I18n.t("views.analytics.vertical")}
                  </option>
                </select>
              </div>
            </div>

            <ReactECharts option={chartOptions} style={{ height: 700 }} />
          </div>
        )}
      </div>

      <div className="card-header border-top flex-column align-items-start">
        <h3 className="card-title d-flex align-items-center mb-2">
          <i className="ti ti-trending-down text-danger icon me-2"></i>
          <span>{I18n.t("views.analytics.loss_by_question")}</span>
        </h3>

        <div className="text-muted mb-0">
          {parse(I18n.t("views.analytics.count_leads_dropout_html"))}
        </div>
      </div>
    </div>
  );
};

export default SankeyChart;
