import styled from "styled-components";

import logoLeadsterBgImg from "./images/logo_bg.svg";

export const MainTitle = styled.div`
  background-image: url(${logoLeadsterBgImg});
  background-repeat: no-repeat;
  background-size: contain;

  font-size: 3rem;
  line-height: 1.3;

  @media(max-width: 1366px) {
    font-size: 2.6rem;
  }

  @media(max-width: 1024px) {
    font-size: 2.4rem;
  }

  p {
    margin: 32px 0 32px 0;
  }
`;

export const IframeDemo = styled.iframe`
  border-left: 45px solid #060606;
  height: 685px;
  overflow: hidden !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100px;
  background-image: url('/images/loader.gif');

  @media(max-width: 1366px) {
    height: 585px;
  }
`;

export const ButtonGroup = styled.div.attrs({
  className: "row"
})`
  margin-bottom: 16px;
`

export const Button = styled.button.attrs({
  className: "btn btn-lg text-center px-xl-5 col-md-12 col-lg-10"
})`
  i {
    margin-right: 8px;
  }
`

export const Icon = styled.i.attrs({
  className: "ti ti-info-circle col-md-12 col-lg-2"
})`
  width: 47px;
  height: 47.6px;
  padding: 4px 8px;
  font-size: 1.6rem;
  display: flex;
  align-items: center;


  &:hover {
    color: #0D6EFD;
  }
`
