import styled from "styled-components";

export const NotImplementedAlert = styled.div.attrs({
  className: "rounded bg-primary-lt p-3 mb-3 lead",
})`
  color: var(--tblr-body) !important;
`;

export const PageLink = styled.a.attrs({
  className: "fw-medium",
})`
  max-width: calc(var(--tblr-modal-width) - 70px);
  overflow-wrap: break-word;
  font-size: 0.9rem;
  display: inline-block;
`;

export const AlreadyHasScriptAlert = styled.span`
  font-size: 0.7rem;
  font-weight: 500;
  white-space: pre;
  margin-top: 6px;
  display: inline-block;
`;
