import "./styles.scss";

import React from "react";

import parse from "html-react-parser";

import { can } from "@/lib/user-permission";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const Box = ({ isMobile, calls, updatePreviewSmartCall }) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);
  const avatar = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.previewContent.avatar);

  let notifications = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.desktopContent.notifications);
  let dispatchActionType = "SET_DESKTOP_CONVOSCRIPT_VALUE";

  if (isMobile) {
    dispatchActionType = "SET_MOBILE_CONVOSCRIPT_VALUE";
    notifications = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.mobileContent.notifications);
  }

  const handleAddSmartCall = (smartCall) => {
    if (!hasPermission) return;

    const lastestConversationCall = _.maxBy(notifications, notification => notification.id);
    const newId = _.get(lastestConversationCall, 'id', _.random(10, 50)) + 1;
    const newSmartCall = { id: newId, template: smartCall };

    setScriptValue("notifications", [ ...notifications, newSmartCall ]);
    updatePreviewSmartCall(smartCall);

    MixpanelService.track('script_selected_smart_call');
    $("#smart-call-options").modal('hide');
  };

  const setScriptValue = (key, value) => {
    dispatch({ type: dispatchActionType, payload: { key, value } });
    dispatch({ type: "SET_PREVIEW_CONVOSCRIPT_VALUE", payload: { key, value }});
  };

  return (
    <div className="boxes">
      {Object.keys(calls).map((item, index) => {
        return (
          <div className="box mb-2" key={"smart_box_" + index}>
            <div className="row">
              <div className="label col-12 col-md-auto">
                {I18n.t("views.call.smart_calls.average_conversion")} <span>{calls[item]["avg_conversion"]}</span>
              </div>

              <span
                className="label col-12 col-md-auto cursor-help"
                data-bs-toggle="popover"
                data-bs-trigger="hover"
                data-bs-placement="right"
                data-bs-html="true"
                data-bs-content={`
                  <div class="text-primary fw-bold mb-2">
                    ${I18n.t("views.call.smart_calls.call_of")} ${calls[item]['type']}
                  </div>
                ${I18n.t(`views.call.tooltip.${calls[item]['tooltip']}`)}
              `}
              >
                {I18n.t("views.call.type.title")} <span>{calls[item]['type']}</span>
              </span>
            </div>

            <div className="row">
              <div className="col-8">
                <div className="d-flex">
                  <img
                    className="avatar rounded-circle me-3"
                    src={avatar}
                    alt="Avatar"
                  />

                  <div className="position-relative">
                    <div className="arrow-text" />
                    <div className="text-example">
                      {parse(calls[item]["call"])}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-4 d-flex justify-content-center align-items-center">
                <button className="btn btn-primary fw-bold btn-rounded w-100" onClick={() => handleAddSmartCall(calls[item]["call"])}>
                  <i className="ti ti-plus icon"></i>
                  {I18n.t("views.call.smart_calls.use_call")}
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(Box);
