import React, { useState } from "react";

const Filters = ({ defaultPeriod, onChangePeriod, defaultChannel, onChangeChannel }) => {
  const [period, setPeriod] = useState(defaultPeriod);
  const [channel, setChannel] = useState(defaultChannel);

  const handleChangePeriod = (period) => {
    setPeriod(period);
    onChangePeriod(period);
  };

  const handleChangeChannel = (channel) => {
    setChannel(channel);
    onChangeChannel(channel);
  };

  return (
    <div className="row">
      <div className="col-lg mb-3">
        <label className="form-label">{I18n.t("views.analytics.option_list_analysis.sample_date")}</label>

        <div className="form-selectgroup form-selectgroup-boxes d-flex">
          <label className="form-selectgroup-item flex-fill">
            <input
              type="radio"
              value="current"
              className="form-selectgroup-input"
              checked={period === "current"}
              onChange={() => handleChangePeriod("current")}
            />

            <div className="form-selectgroup-label d-flex align-items-center py-2 px-3">
              <div className="me-2">
                <span className="form-selectgroup-check"></span>
              </div>

              <div className="fw-medium">{I18n.t("views.analytics.option_list_analysis.last_change")}</div>
            </div>
          </label>

          <label className="form-selectgroup-item flex-fill">
            <input
              type="radio"
              value="all_time"
              className="form-selectgroup-input"
              checked={period === "all_time"}
              onChange={() => handleChangePeriod("all_time")}
            />

            <div className="form-selectgroup-label d-flex align-items-center py-2 px-3">
              <div className="me-2">
                <span className="form-selectgroup-check"></span>
              </div>

              <div className="fw-medium">{I18n.t("views.analytics.option_list_analysis.field_creation_date")}</div>
            </div>
          </label>
        </div>
      </div>

      <div className="col-lg mb-3">
        <label className="form-label">{I18n.t("views.analytics.source_channel")}</label>

        <select
          className="form-control form-select"
          onChange={(e) => handleChangeChannel(e.target.value || null)}
          value={channel || ""}
        >
          <option value="">{I18n.t("views.analytics.source_options.all_channels")}</option>
          <option value="facebook">{I18n.t("views.analytics.source_options.meta")}</option>
          <option value="google">{I18n.t("views.analytics.source_options.google")}</option>
          <option value="linkedin">{I18n.t("views.analytics.source_options.linkedin")}</option>
          <option value="not_identified">{I18n.t("views.analytics.source_options.not_identified")}</option>
        </select>
      </div>
    </div>
  );
};

export default Filters;
