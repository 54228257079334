import React from "react";

import useCurrentUserStore from "@/store/currentUserStore";

import { ImageStep, ImageResellerStep, CardContainer } from "./styles";

const Third = ({ isCapturePage = false, boostSize = true }) => {
  const { isResellerOwner, applicationName } = useCurrentUserStore((state) => state.currentUser);
  return (
    <div
      className="card bg-info text-white shadow col"
      data-bs-toggle="popover"
      data-bs-placement="bottom"
      data-bs-html="true"
      data-bs-trigger="hover"
      data-bs-content={`<div class="fw-medium">
        ${isCapturePage
          ? I18n.t("views.onboard.partials.steps.third.now_share_the_leadster", { application_name: applicationName })
          : I18n.t("views.onboard.partials.steps.third.now_activate_the_leadster", { application_name: applicationName })
        }
      </div>`}
    >
      <div className="d-flex">
        <CardContainer className="col-7 col-lg-6 p-3 pe-1 h-100" boostSize={boostSize}>
          <span className="bg-dark text-white avatar avatar-md avatar-rounded shadow mb-3">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-tabler icon-tabler-clock" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.75" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <circle cx="12" cy="12" r="9"></circle>
              <polyline points="12 7 12 12 15 15"></polyline>
            </svg>
          </span>

          <div className="fs-3 fw-bolder text-nowrap">
            {isCapturePage ? I18n.t("shared.actions.share") : I18n.t("shared.actions.activate")}
            <br />{I18n.t("views.onboard.partials.steps.third.the_leadster", { application_name: applicationName })}
          </div>
        </CardContainer>

        {isResellerOwner ? <ImageResellerStep className="col-5 col-lg-6" /> : <ImageStep className="col-5 col-lg-6" />}
      </div>
    </div>
  );
};

export default Third;
