const useScroll = (useRef, loading, onCallback) => {
  let scrollHeight, totalHeight;
  scrollHeight = useRef.current.scrollTop;
  totalHeight = useRef.current.scrollHeight - useRef.current.clientHeight;

  const scrollPercentage = (scrollHeight / totalHeight) * 100;

  if (scrollPercentage == 100 && !loading) {
    onCallback();
  }
};

export default useScroll;
