import styled from 'styled-components';

export const Container = styled.div`
  background-color: "#ffffff";
  border-top: ${props => props.borderColor ? `6px solid ${props.borderColor}` : "none"};
`;

export const ActivateButton = styled.button`
  background: #0077b5;
  border: none;
  padding: 1px 5px;
  width: 130px;
  height: 28px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    border: 1px solid rgba(0, 0, 0, .4);
  }

  &:focus {
    outline: none;
  }
`;

export const ActivateText = styled.span`
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
`;

export const NotActivateLink = styled.span`
  color: #0077b5;
  text-decoration: none;
  margin-left: 15px;
  font-weight: bold;

  &:hover {
    color: #0077b5;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const DescriptionText = styled.p`
  color: #7e8896 !important;
`;

