import React, { useRef } from "react";

import ReactPortal from "@/components/ReactPortal";
import ButtonAction from "@/components/alerts/components/ButtonAction";

import parse from "html-react-parser";

const STORAGE_KEY = "candidate_exceed";

const CandidateExceed = ({ link, percentageConsumption, candidateUpsell }) => {
  const modalRef = useRef();

  const canShowAlert = () => {
    return !Boolean(window.sessionStorage.getItem(STORAGE_KEY));
  };

  const onCloseAlertActions = () => {
    window.sessionStorage.setItem(STORAGE_KEY, "true");
  };

  const showModal = () => {
    new bootstrap.Modal(modalRef.current).show();
    onCloseAlertActions();
  };

  if (!canShowAlert()) {
    return <></>;
  }

  return (
    <ReactPortal>
      <div className="modal modal-blur fade" ref={modalRef} aria-modal="true" role="dialog" onLoad={() => showModal()}>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content p-3 bg-white">
            <div className="mb-3">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => onCloseAlertActions()} />
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <h2>{parse(I18n.t("views.shared.alerts.candidate_exceed.title", { consumption: percentageConsumption }))}</h2>

                  <p>{parse(I18n.t("views.shared.alerts.candidate_exceed.description_html"))}</p>

                  <ButtonAction link={link} candidateUpsell={candidateUpsell} />

                </div>

                <div className="col-md-6">
                  <img
                    src="https://cdn.leadster.com.br/images/shared/alerts/candidate-exceeded.png"
                    alt={parse(I18n.t("views.shared.alerts.candidate_exceed.img_title", { consumption: percentageConsumption }))}
                    title={parse(I18n.t("views.shared.alerts.candidate_exceed.img_title", { consumption: percentageConsumption }))}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactPortal>
  );
};

export default CandidateExceed;
