import * as S from "./styles"

const Skeleton = () => {

  const Card = () => (
    <div
      className="card shadow-sm placeholder-glow"
      style={{ height: "fit-content !important" }}
    >
      <div className="card-header">
        <h3 className="card-title fw-bold placeholder col-4" />
      </div>
      <div className="card-body d-flex flex-column gap-1">
        <div className="placeholder col-5" />
        <div className="placeholder col-5" />
        <div className="placeholder col-5" />
      </div>
    </div>
  );

  return (
    <S.Wrapper className="gap-3 mt-3">
      <div className="d-flex flex-column gap-3">
        {Array(3).fill().map((_, index) => <Card key={index} />)}
      </div>
      <Card />
    </S.Wrapper>
  );
};

export default Skeleton;
