import { useState } from "react";

import { Card, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

import useCurrentUserStore from "@/store/currentUserStore";

import axios from "@/lib/axios";
import dayjs from "@/lib/dayjs";
import { truncateReverse } from "@/utils/helpers";

const Content = ({ knowleadgeBase, isChecked, onRemoveBase, onCheck }) => {
  const { locale: userLocale } = useCurrentUserStore((state) => state.currentUser);

  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveKnowledgeBase = () => {
    MixpanelService.track("leadster_ai_remove_content");

    setIsLoading(true);

    axios
      .delete(`/ai/knowledge_bases/${knowleadgeBase.id}`)
      .then(() => {
        toastr.success(I18n.t("shared.messages.request_feedback_alert.success.message"));
        onCheck(knowleadgeBase, false);
        onRemoveBase();
      })
      .catch(() => toastr.error(I18n.t("shared.messages.request_feedback_alert.error.message")))
      .then(() => setIsLoading(false));
  };

  return (
    <Card className="bg-primary-lt card-sm">
      <Card.Body className="py-2">
        <Row className="align-items-center gx-3">
          <Col xs="auto">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              checked={isChecked}
              onChange={(e) => onCheck(knowleadgeBase, e.target.checked)}
            />
          </Col>

          <Col className="fw-bold text-body cursor-default">
            <OverlayTrigger overlay={<Tooltip className="p-2">{knowleadgeBase.base_name}</Tooltip>}>
              {knowleadgeBase.base_type == "url" ? (
                <div className="d-inline-block">
                  <i className="ti icon me-1 ti-world-www" />
                  <a href={knowleadgeBase.base_name} target="_blank" rel="noopener noreferrer">
                    {truncateReverse(knowleadgeBase.base_name, 50)}
                  </a>
                </div>
              ) : (
                <div className="d-inline-block">
                  <i className="ti icon me-1 ti-file-check" />
                  <a href={`/ai/knowledge_bases/${knowleadgeBase.id}/download-file`}>
                    {truncateReverse(knowleadgeBase.base_name, 50)}
                  </a>
                </div>
              )}
            </OverlayTrigger>
          </Col>

          <Col xs="auto" className="ms-auto">
            <span className="me-2 text-body small fw-medium">
              {I18n.t("views.leadster_ai.page.add_content.content_list.added_at", {
                date: dayjs(knowleadgeBase.created_at).format(I18n.t("js.formats.default.date")),
              })}
            </span>

            <span className="me-2 text-muted small fw-medium">
              {I18n.t("views.leadster_ai.page.add_content.content_list.number_characters", {
                characters: knowleadgeBase.character_count.toLocaleString(userLocale, { minimumFractionDigits: 0 }),
              })}
            </span>

            {isLoading ? (
              <div className="spinner-border spinner-border-sm flex-shrink-0" />
            ) : (
              <button
                type="button"
                className="btn btn-ghost-light btn-icon border-0"
                onClick={handleRemoveKnowledgeBase}
              >
                <i className="ti ti-trash text-danger fs-2" />
              </button>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Content;
