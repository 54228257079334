import useCreateYourAiAssistant from "@/hooks/useCreateYourAiAssistant";

import useFetchScripts from "./hooks/useFetchScripts";

import AddToFlowCard from "./AddToFlowCard";
import NewFlowCard from "./NewFlowCard";
import ScriptsList from "./ScriptsList";
import NoActivationAlert from "./NoActivationAlert";

import { classBinding } from "@/utils/helpers";

const ActivateAssistant = () => {
  useCreateYourAiAssistant("/activation");

  const { scripts, isLoading } = useFetchScripts();

  return (
    <div className={classBinding({ "content-loading": isLoading })}>
      <div className="fw-medium mb-3">
        {I18n.t("views.leadster_ai.page.activate_assistant.added_flows", {
          count: scripts.length,
        })}
      </div>

      {!isLoading && <ScriptsList scripts={scripts} />}
      {!isLoading && !scripts.length ? <NoActivationAlert /> : null}

      <div className="row row-deck justify-content-center g-3">
        <div className="col-xl-4 col-lg-5">
          <AddToFlowCard />
        </div>

        <div className="col-xl-4 col-lg-5">
          <NewFlowCard />
        </div>
      </div>
    </div>
  );
};

export default ActivateAssistant;
