import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import useShowGuideStorage from "./hooks/useShowGuideStorage";

import * as S from "./styles";

const GuideModal = ({
  enabled = true,
  autoShow = true,
  isClosable = false,
  storageKey = null,
  guideContents,
  expireInDays,
  previousButtonText = I18n.t("guide_modal.previous_button"),
  finishButtonText = I18n.t("guide_modal.next_button"),
  finishButtonPosition = "right",
  finishCallback = () => {},
}) => {
  const { isGuideAllowed, markAsViewed } = useShowGuideStorage(storageKey, expireInDays);

  const [showModal, setShowModal] = useState(false);
  const [currentSliderPos, setCurrentSliderPos] = useState(1);

  const selectedContent = guideContents[currentSliderPos - 1];
  const contentCount = guideContents.length;
  const isPreviousButtonDisabled = !isClosable && currentSliderPos === 1;
  const isLastStep = currentSliderPos === contentCount;

  useEffect(() => {
    setShowModal(isGuideAllowed && autoShow);
  }, [isGuideAllowed, autoShow]);

  const handleNext = async () => {
    if (currentSliderPos < contentCount) {
      return setCurrentSliderPos(currentSliderPos + 1);
    }

    await new Promise((resolve) => {
      markAsViewed();
      resolve();
    });

    setShowModal(false);
    finishCallback();
  };

  const handlePrevious = () => {
    if (currentSliderPos > 1) {
      return setCurrentSliderPos(currentSliderPos - 1);
    }

    setShowModal(false);
  };

  const onHideModalCallback = () => {
    if (isClosable) {
      setShowModal(false);
    }
  };

  if (!enabled) {
    return <></>;
  }

  return (
    <Modal show={showModal} onHide={onHideModalCallback} centered size="lg">
      {isClosable ? <Modal.Header closeButton={isClosable} /> : null}

      <Modal.Body className="text-center p-2">
        {selectedContent.media.type === "image" ? (
          <S.MainImage src={selectedContent.media.url} />
        ) : (
          <iframe
            width="100%"
            height="370"
            src={selectedContent.media.url}
            title="YouTube video player"
            allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;web-share"
            allowFullScreen
          />
        )}

        <div className="fs-3 fw-bolder my-3 mb-2">
          {selectedContent.title}
        </div>

        <div className="mb-3" dangerouslySetInnerHTML={{ __html: selectedContent.content }} />

        {contentCount > 1 && (
          <div className="fs-3 text-primary fw-medium">
            {I18n.t("guide_modal.slide_number", {
              current: currentSliderPos,
              total: contentCount,
            })}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        {!isPreviousButtonDisabled && (
          <Button variant="light" className="me-auto" onClick={handlePrevious}>
            {currentSliderPos === 0 ? previousButtonText : I18n.t("guide_modal.back_button")}
          </Button>
        )}

        {isLastStep ? (
          <S.FinishButton onClick={handleNext} position={finishButtonPosition}>
            {finishButtonText}
          </S.FinishButton>
        ) : (
          <Button onClick={handleNext}>
            {I18n.t("guide_modal.learn_more")}
            <i className="ti ti-arrow-right icon ms-1 me-0" />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

GuideModal.propTypes = {
  enabled: PropTypes.bool,
  autoShow: PropTypes.bool,
  isClosable: PropTypes.bool,
  storageKey: PropTypes.string,
  guideContents: PropTypes.arrayOf(Object).isRequired,
  expireInDays: PropTypes.number,
  previousButtonText: PropTypes.string,
  finishButtonText: PropTypes.string,
  finishButtonPosition: PropTypes.oneOf(["center", "right"]),
  finishCallback: PropTypes.func,
};

export default GuideModal;
