import React, { useEffect, useState } from "react";
import { Container } from "./styles";

const Assistant = ({
  name,
  nameGroup,
  value,
  defaultChecked,
  disabled,
  hidden,
  loading,
  typeOfAssistant,
  onChange,
}) => {
  const [description, setDescription] = useState("");

  useEffect(() => {
    const scopeI18n = "views.manage_flows.script_editor.ia_interaction.assistant.type_of_assistant";
    setDescription(I18n.t(`${scopeI18n}.${typeOfAssistant}`));
  }, [typeOfAssistant]);

  return (
    <>
      <Container className={hidden ? "d-none" : ""}>
        {!loading &&
          <label className="form-check form-switch">
            <input
              className="form-check-input"
              name={nameGroup}
              type="radio"
              value={value}
              defaultChecked={defaultChecked}
              onChange={onChange}
              disabled={disabled}
              hidden={hidden}
            />
            <span className="form-check-label">{name}</span>
            <span>{description}</span>
          </label>}

        {loading &&
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>}
      </Container>
    </>
  );
};

export default Assistant;
