import { useState } from "react";

import Stack from "react-bootstrap/Stack";

import Content from "./components/Content";
import ConfirmExclusionButton from "./components/ConfirmExclusionButton";
import Pagination from "@/components/shared/Pagination";
import SkeletonLoader from "./components/SkeletonLoader";
import EmptyListAlert from "./components/EmptyListAlert";

import useUpdateEffect from "@/hooks/useUpdateEffect";
import usePagination from "@/hooks/usePagination";
import useListCheckable from "./hooks/useListCheckable";
import { useAssistantContext } from "@/contexts/AssistantContext";

import axios from "@/lib/axios";

import { classBinding, cleanObjectEmptyValues } from "@/utils/helpers";

import * as S from "./styles";

const I18N_SCOPE = "views.leadster_ai.page.add_content.content_list";

const ContentList = () => {
  const { assistant, reloadCharactersUsed } = useAssistantContext();

  const [baseType, setBaseType] = useState("");

  useUpdateEffect(() => {
    resetPaginationAndSelection();
  }, [baseType]);

  const {
    data: knowledgeBases,
    paginationData,
    isLoading,
    setCurrentPage,
    currentPage,
    setPerPage,
    doFetch,
  } = usePagination(`/ai/assistants/${assistant.id}/knowledge-bases`, {
    dataIndex: "ai/knowledge_bases",
    defaultDataValue: [],
    filters: { base_type: baseType },
    dependencies: [baseType],
    onSuccess: () => {
      if (isAllSelected) {
        clearItemSelection();
      }
    },
  });

  const {
    selectedItems,
    isCurrentPageSelected,
    isItemChecked,
    isAllSelected,
    isAnySelected,
    selectAllItems,
    clearItemSelection,
    onChangeItemCheck,
    onChangeCurrentPageCheck,
  } = useListCheckable(knowledgeBases);

  const onRemoveBase = () => {
    reloadCharactersUsed();
    doFetch();
  };

  const resetPaginationAndSelection = () => {
    setCurrentPage(1);
    clearItemSelection();
  }

  const handleDeleteSelection = async () => {
    const payload = {
      ids: selectedItems,
      all_selected: isAllSelected,
      filters: cleanObjectEmptyValues({ base_type: baseType }),
    };

    try {
      await axios.delete(`/ai/assistants/${assistant.id}/knowledge-bases`, { data: payload });

      resetPaginationAndSelection();
      onRemoveBase();

      toastr.success(I18n.t("shared.messages.request_feedback_alert.success.message"));
    } catch (err) {
      toastr.error(I18n.t("shared.messages.request_feedback_alert.error.message"));
    }
  };

  const countSelectedItems = isAllSelected ? paginationData.total_count : selectedItems.length;

  return (
    <div>
      <label className="form-label">
        <i className="ti ti-files icon text-primary me-2" />
        {I18n.t(`${I18N_SCOPE}.title`, { total: paginationData.total_count })}
      </label>

      <div className="row mb-2">
        <div className="col-md d-flex">
          <div className="input-group w-auto">
            <div className="input-group-text border-end-0">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={onChangeCurrentPageCheck}
                checked={isCurrentPageSelected}
              />
            </div>

            <S.DropdownButton data-bs-toggle="dropdown" />

            <div className="dropdown-menu">
              <button className="dropdown-item" onClick={selectAllItems}>
                <i className="ti ti-checklist icon me-2" />
                {I18n.t(`${I18N_SCOPE}.multi_selection.select_all_button`)}
              </button>
            </div>
          </div>

          {isAnySelected && (
            <ConfirmExclusionButton
              countSelectedItems={countSelectedItems}
              deleteSelection={handleDeleteSelection}
            />
          )}
        </div>

        <div className="col-md-auto ms-auto">
          <select
            className="form-control form-select"
            onChange={(e) => setBaseType(e.target.value)}
            value={baseType}
          >
            <option value="">{I18n.t(`${I18N_SCOPE}.base_type_options.empty`)}</option>
            <option value="file">{I18n.t(`${I18N_SCOPE}.base_type_options.file`)}</option>
            <option value="url">{I18n.t(`${I18N_SCOPE}.base_type_options.url`)}</option>
          </select>
        </div>
      </div>

      {isAnySelected && (
        <div className="bg-secondary-lt rounded shadow-sm d-flex justify-content-center fw-medium p-2 mb-2 gap-2">
          <div className="text-body">
            {I18n.t(`${I18N_SCOPE}.multi_selection.${isAllSelected ? "all_selected" : "partial_selected"}`, {
              count: countSelectedItems,
            })}
          </div>

          <div>
            {!isAllSelected && (
              <button type="button" className="btn btn-link fw-medium p-0" onClick={selectAllItems}>
                {I18n.t(`${I18N_SCOPE}.multi_selection.select_all_link`, {
                  count: paginationData.total_count,
                })}
              </button>
            )}

            <span className="mx-2 text-muted">|</span>

            <button
              type="button"
              className="btn btn-link fw-medium p-0"
              onClick={clearItemSelection}
            >
              {I18n.t(`${I18N_SCOPE}.multi_selection.clear_selection`)}
            </button>
          </div>
        </div>
      )}

      <div className={classBinding({ "content-loading": isLoading })}>
        {isLoading && !knowledgeBases.length ? <SkeletonLoader /> : null}
        {!isLoading && !knowledgeBases.length ? <EmptyListAlert /> : null}

        <Stack direction="vertical" gap={2}>
          {knowledgeBases.map((knowleadgeBase) => {
            return (
              <Content
                key={knowleadgeBase.id}
                knowleadgeBase={knowleadgeBase}
                isChecked={isItemChecked(knowleadgeBase)}
                onRemoveBase={() => onRemoveBase(knowleadgeBase)}
                onCheck={onChangeItemCheck}
              />
            );
          })}
        </Stack>

        <Pagination
          className="mb-0"
          isPageRangeEnabled
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          onPerPageChange={setPerPage}
          pageCount={paginationData.total_pages}
          perPage={paginationData.limit_value}
        />
      </div>
    </div>
  );
};

export default ContentList;
