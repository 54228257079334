const nodeCompatMatrix = {
  say: [],
  scheduler: [],
  whatsapp: [],
  jivochat: [],
  save: [],
  selector: [],
  custom: [],
  messages_selector: ["say"],
  if: [ "say", "scheduler", "whatsapp", "jivochat", "save", "selector", "autoRedirect", "iaInteraction" ],
  root: [ "say", "scheduler", "whatsapp", "jivochat", "save", "selector", "if", "custom", "autoRedirect", "iaInteraction" ],
};

export function isNodeCompatibleWith(node, type) {
  if (node.type) {
    return nodeCompatMatrix[node.type].indexOf(type) >= 0;
  } else {
    return nodeCompatMatrix.root.indexOf(type) >= 0;
  }
}
