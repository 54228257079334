import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 8px;
  margin: 30px 0 50px 0;

  &:hover {
    box-shadow:
      0 3px 3px rgb(0 0 0 / 19%),
      0 3px 3px rgb(0 0 0 / 23%);
  }
`;

export const CardBody = styled.div`
  padding: 30px 0 10px 0;
`;

export const Label = styled.h3`
  font-size: 21px;
  cursor: pointer;
`;

export const Video = styled.iframe`
  border-radius: 8px;
`;

export const StepNumber = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 4px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color:white;
  background: var(--tblr-primary);
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%)
`;

export const StepChecked = styled.div`
  width: 35px;
  height: 35px;
  padding: 4px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color:white;
  background: var(--tblr-success);
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%)
`

export const Description = styled.p`
  width: 80%;
  margin: 0 auto;
  padding-bottom: 10px;
`;

export const NotCheckedButton = styled.button`
  border: 0;
  border-radius: 40px;
  font-weight: bold;
  background-color: #F7F7F7;
  width: 80%;
  color: #9CA3AE;
  padding: 15px;
`;

export const CheckedButton = styled.button`
  border: 0;
  border-radius: 40px;
  font-weight: bold;
  background-color: #17C895;
  width:  80%;
  color: white;
  padding: 15px;
`;
