import styled from "styled-components";

export const Container = styled.div`
  .iti {
    width: 100%;

    .iti__search-input {
      height: 30px;
      border: 1px solid var(--tblr-border-color);

      &:focus-visible {
        outline: 0px;
      }
    }
  }
`;

