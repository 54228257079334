import React, { useCallback } from "react";
import swal from "sweetalert";
import { Container, Code, Button } from "./styles";

const CopyMeToClipboard = ({
  value,
  title = `${I18n.t("shared.actions.copied")}!`,
  message = I18n.t("shared.actions.copied_to_clipboard"),
  buttonText = I18n.t("shared.actions.copy_my_link"),
  showContent = true,
  buttonLarge = false,
}) => {
  const successAlert = () => {
    swal(title, message, "success");
  };

  const errorAlert = () => {
    swal(I18n.t("shared.actions.not_copied"), I18n.t("shared.actions.copying_failed"), "error");
  };

  const clipboardAPIEnable = () => {
    return navigator.clipboard && window.isSecureContext;
  };

  const copyWithClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        successAlert();
      })
      .catch(() => {
        errorAlert();
      });
  };

  const copyWithCommand = (text) => {
    try {
      const el = document.createElement("textarea");
      el.value = text;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      successAlert();
    } catch (e) {
      errorAlert();
    }
  };

  const copyClick = useCallback((e) => {
    let text = value;

    if (clipboardAPIEnable()) {
      copyWithClipboard(text);
    } else {
      copyWithCommand(text);
    }
  }, []);

  return (
    <Container>
      {showContent && (
        <Code className="prettyprint text-center me-2">{value}</Code>
      )}

      <Button
        type="button"
        className={`btn btn-primary btn-rounded ${buttonLarge ? "btn-lg" : ""}`}
        onClick={copyClick}
      >
        <i className="ti ti-share me-2" />
        {buttonText}
      </Button>
    </Container>
  );
};

export default CopyMeToClipboard;
