import React from 'react';

import {
  Card,
  Title,
  FirstDescription,
  SecondDescription,
  Percentage
} from './styles';

const MediumBlock = () => {
  return (
    <Card className="card p-3 px-4">
      <Title />

      <Percentage />

      <div className="d-flex">
        <FirstDescription />
        <small className="ms-auto text-muted">-</small>
      </div>

      <div className="d-flex">
        <SecondDescription />
        <small className="ms-auto text-muted">-</small>
      </div>
    </Card>
  )
};

export default MediumBlock;
