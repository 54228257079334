import styled from 'styled-components';

export const Link = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const Header = styled.div`
  cursor: pointer;

  &:hover {
    border: 1px solid #28adfe;
  }
`;

export const MobileContainer = styled.a`
  width: 60px;
  height: 60px;
  border-radius: 50%;

  position: fixed;
  border: 1px solid rgba(0, 0, 0, .2);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.15);
  z-index: 4;
  bottom: 10px;
  left: 20px;

  background-color: #fff;
  cursor: pointer;
  padding-right: 2px;
`
