import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import ScriptToDuplicate from "./ScriptToDuplicate";
import HelpPopover from "@/components/shared/HelpPopover";
import ReactPortal from "@/components/ReactPortal";

import { classBinding } from "@/utils/helpers";
import parse from 'html-react-parser';
import axios from '@/lib/axios'

const DuplicateFlowCard = ({
  pageUrl,
  callId,
  newConversionCall,
  suggestedConversionCall,
  closeParentModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allowedScripts, setAllowedScripts] = useState([]);

  const modalRef = useRef();

  const handleShowModal = () => $(modalRef.current).modal("show");

  const handleLoadScripts = async () => {
    setIsLoading(true);

    closeParentModal();
    handleShowModal();

    axios.get('/page-ranks/list-scripts-to-duplicate')
      .then(({ data }) => setAllowedScripts(data.scripts))
      .catch(() => {
        setAllowedScripts([]);
        swal(I18n.t("request_feedback_alert.error.title"), I18n.t("request_feedback_alert.error.message"), "error");
      })
      .then(() => setIsLoading(false))
  };

  return (
    <>
      <div className="card">
        <div className="card-body text-center position-relative">
          <HelpPopover
            className="position-absolute"
            style={{ right: 20 }}
            content={I18n.t("views.page_rank.rank_table.data.modal_create.card_clone_flow_default.help_popover")}
          />

          <div className="mb-2">
            <i className="ti ti-messages display-5 text-primary" />
          </div>

          <div className="h3 mb-2">
            {I18n.t("views.page_rank.rank_table.data.modal_create.card_clone_flow_default.title")}
          </div>

          <div className="text-muted mb-3">
            {parse(I18n.t("views.page_rank.rank_table.data.modal_create.card_clone_flow_default.description_html"))}
          </div>

          <button
            type="button"
            className="btn btn-outline-primary w-100"
            onClick={handleLoadScripts}
          >
            <i className="ti ti-copy icon"></i>
            {I18n.t("views.page_rank.rank_table.data.modal_create.card_clone_flow_default.btn_action")}
          </button>
        </div>
      </div>

      <ReactPortal>
        <div ref={modalRef} className="modal modal-blur fade">
          <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header py-3">
                <div>
                  <div className="modal-title mb-1">
                    {I18n.t("views.page_rank.rank_table.data.modal_create.card_clone_flow_default.clone_modal.title")}
                  </div>

                  <div className="text-muted">
                    {I18n.t("views.page_rank.rank_table.data.modal_create.card_clone_flow_default.clone_modal.subtitle")}{" "}
                    <span className="text-primary">{pageUrl}</span>
                  </div>
                </div>

                <button type="button" className="btn-close" data-bs-dismiss="modal" />
              </div>

              <div
                className={classBinding({ "content-loading": isLoading }, "modal-body")}
                style={{ minHeight: 250, maxHeight: 455 }}
              >
                {allowedScripts.map((script, i) => (
                  <ScriptToDuplicate
                    key={i}
                    pageUrl={pageUrl}
                    callId={callId}
                    script={script}
                    newConversionCall={newConversionCall}
                    suggestedConversionCall={suggestedConversionCall}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </ReactPortal>
    </>
  );
};

DuplicateFlowCard.propTypes = {
  pageUrl: PropTypes.string.isRequired,
  callId: PropTypes.number.isRequired,
  newConversionCall: PropTypes.string.isRequired,
  suggestedConversionCall: PropTypes.string.isRequired,
  closeParentModal: PropTypes.func.isRequired,
};

export default DuplicateFlowCard;
