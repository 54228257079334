import { Button } from "react-bootstrap";

const AddButton = ({ ...props }) => {
  return (
    <Button variant="outline-primary" size="sm" className="py-1" {...props}>
      <i className="ti ti-plus me-1" />
      {I18n.t("views.manage_flows.conditional_block.new_conditional")}
    </Button>
  );
};

export default AddButton;
