import { useEffect, useState } from "react";

import useCurrentUserStore from "@/store/currentUserStore";
import { useUpsellContext } from "../../../context";
import useLogic from "./useLogic";

import CardBase from "../../CardBase";
import HelpPopover from "@/components/shared/HelpPopover";

import CalculatorUtils from "../../../utils/calculators";
import FormatterUtils from "../../../utils/formatter";
import CurrencyFormatter from "@/utils/formatter-currency";

import * as S from "./styles";

const CardPaymentInfo = ({
  price,
  installments,
  financialEmail,
  paymentMethods,
}) => {
  const { data, methods } = useLogic();
  const { saveProgressData } = useUpsellContext();
  const { email } = useCurrentUserStore((state) => state.currentUser);

  const [formData, setFormData] = useState({
    coupon: '',
    installment: 1,
    financialEmail: {
      value: financialEmail || '',
      error: '',
    }
  });

  const isCreditCardPayment = paymentMethods.payment_method == "credit_card";
  const paymentMethod = paymentMethods.payment_method == "credit_card"
    ? I18n.t("views.upsell.stepper.step_two.card_payment.credit_card", {
      card_number: paymentMethods.payment_display_number
    })
    : I18n.t("views.upsell.stepper.step_two.card_payment.bank_slip");

  const selectedPlanPriceByInstallments = CalculatorUtils.calcPriceByInstallments(price, formData.installment);

  const creditCardFlags = {
    "american_express": "american-express.webp",
    "visa": "visa.webp",
    "mastercard": "mastercard.webp",
    "diners_club": "diners-club.webp",
    "elo": "elo.webp",
    "hipercard": "hipercard.webp",
    "jcb": "jcp.webp",
  }

  const handleChangeCoupon = (e) => {
    const coupon = e.target.value;
    setFormData((prev) => ({
      ...prev,
      coupon,
    }));
  }

  const handleChangeInstallment = (installment) => {
    setFormData((prev) => ({
      ...prev,
      installment,
    }));
  };

  const handleChangeFinancialEmail = (e) => {
    const emails = e.target.value;
    const result = FormatterUtils.formatFinancialEmail(emails);

    setFormData((prev) => ({
      ...prev,
      financialEmail: {
        error: result.error,
        value: result.value,
      },
    }));
  };

  useEffect(() => {
    saveProgressData((prev) => ({
      ...prev,
      payment: {
        ...prev.payment,
        installment: formData.installment,
        method: paymentMethod,
      },
      financialEmail: formData.financialEmail,
    }));
  }, [formData.installment, formData.financialEmail]);

  return (
    <CardBase
      summaryNumber={3}
      title={I18n.t("views.upsell.stepper.step_two.card_payment.title")}
    >
      <S.FormWrapper className="gap-3">
        <div className="w-100">
          <div className="w-100 alert alert-info m-0" role="alert">
            <div className="d-flex align-items-center space-x-3">
              <div className="border px-2 py-1">
                {isCreditCardPayment
                  ? paymentMethods.payment_brand
                    ? <img
                      width={40}
                      height={15}
                      alt="Credit Card Flag"
                      style={{ objectFit: "contain" }}
                      src={`/images/credit-card-flags/${creditCardFlags[paymentMethods.payment_brand]}`}
                    />
                    : <i className="ti ti-credit-card text-primary fs-1"></i>
                  : <i className="ti ti-barcode text-primary fs-1"></i>
                }
              </div>
              <span className="fw-bold text-dark">
                {paymentMethod}
              </span>
            </div>
          </div>
        </div>

        <div className="group-wrapper gap-4">
          {isCreditCardPayment && (
            <div className="space-y-2">
              <span className="fw-bold">{I18n.t("views.upsell.stepper.step_two.card_payment.installments")}</span>
              <div className="dropdown m-0 p-0">
                <p className="btn w-full justify-content-start" data-bs-toggle="dropdown" >
                  {formData.installment}{" "}
                  x{" "}
                  {CurrencyFormatter.formatter(selectedPlanPriceByInstallments)}
                </p>
                <div className="dropdown-menu w-full">
                  {installments.map((installment) => (
                    <button
                      type="button"
                      key={installment}
                      className="dropdown-item"
                      onClick={() => handleChangeInstallment(installment)}
                    >
                      {installment}{" "}
                      x{" "}
                      {CurrencyFormatter.formatter(CalculatorUtils.calcPriceByInstallments(price, installment))}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/*
          <div className="space-y-2">
            <span className="fw-bold">Adicione um cupom</span>
            <form
              className="space-x-2"
              onSubmit={(e) => {
                e.preventDefault();
                methods.validateCoupon(formData.coupon);
              }}
            >
              <div className="w-66">
                <input
                  type="text"
                  className="form-control"
                  name="cupom"
                  placeholder="ASX923D"
                  onChange={handleChangeCoupon}
                />
              </div>
              <button
                type="submit"
                className={`
                  btn btn-primary w-33
                  ${data.isLoadingValidateCode && "btn-loading"}
                `}
              >
                Adicionar
              </button>
            </form>
          </div>
          */}
        </div>

        <div className="group-wrapper gap-4">
          <div className="space-y-2">
            <span className="fw-bold d-flex align-items-center m-0 gap-2">
              {I18n.t("views.upsell.stepper.step_two.card_payment.your_email")}
              <HelpPopover
                placement="top"
                content={I18n.t("views.upsell.stepper.step_two.card_payment.popover_1")}
              />
            </span>
            <input
              readOnly
              type="email"
              value={email}
              className="form-control"
              placeholder="Seu email"
            />
          </div>
          <div className="space-y-2">
            <span className={`
              fw-bold d-flex align-items-center m-0 gap-2
              ${formData.financialEmail.error && "text-danger"}
            `}>
              {I18n.t("views.upsell.stepper.step_two.card_payment.financial_email")}
              <HelpPopover
                placement="top"
                content={I18n.t("views.upsell.stepper.step_two.card_payment.popover_2_html")}
              />
            </span>
            <input
              type="text"
              placeholder="Email"
              name="financial_email"
              value={formData.financialEmail.value}
              onChange={handleChangeFinancialEmail}
              className={`
                form-control
                ${formData.financialEmail.error && "border border-danger"}
              `}
            />
            <p className="text-danger">{formData.financialEmail.error}</p>
          </div>
        </div>
      </S.FormWrapper>
    </CardBase>
  );
};

export default CardPaymentInfo;
