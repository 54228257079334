import { Modal } from 'react-bootstrap';

import useAlertDefault from '@/components/shared/AlertDefault/hooks/useAlertDefault';

import * as S from './styles';

const AlertDefault = () => {
  const { alert, hideAlert } = useAlertDefault();

  const handleAction = (fn) => {
    hideAlert();
    fn();
  };

  const handleRefuse = () => {
    if (alert.closeButton.action) alert.closeButton.action();
    if (!alert.closeButton.action || !alert.closeButton.closeAfter) hideAlert();
  };

  return (
    <Modal show={alert.open} centered size="lg">
      <S.ModalContent>
        <div className='p-2 pb-0 pb-lg-2 d-flex flex-column align-items-center justify-content-center gap-2'>
          {alert.icon === 'ERROR' && <S.ModalIcon $type={'ERROR'} className="ti ti-circle-x pt-3 pt-lg-5" />}
          {alert.icon === 'SUCCESS' && <S.ModalIcon $type={'SUCCESS'} className="ti ti-circle-check pt-3 pt-lg-5" />}
          {alert.icon === 'WARNING' && <S.ModalIcon $type={'WARNING'} className="ti ti-alert-circle pt-3 pt-lg-5" />}
          {alert.image && <img src={alert.image} className='mb-3' />}
          <p className='px-3 px-lg-5 fw-bold h3 text-center' dangerouslySetInnerHTML={{ __html: alert.title }} />
          <p className='px-3 px-lg-5 text-sm text-center' dangerouslySetInnerHTML={{ __html: alert.description }} />
        </div>
        <hr className='hr-text m-0' style={{ width: '100%' }} />
        <div className='d-flex flex-row-reverse flex-wrap align-center justify-content-between w-100 gap-2 px-2 py-3'>
          <div className='d-flex flex-row-reverse flex-wrap align-center gap-2'>
            {alert.buttons.map((btn, index) => (
              <button
                key={index}
                className={`btn ${index === 0 && 'btn-primary'}`}
                onClick={() => handleAction(btn.action)}
              >
                {btn.text}
              </button>
            ))}
          </div>
          <button className="btn" onClick={handleRefuse}>
            {alert.closeButton.text || 'Fechar'}
          </button>
        </div>
      </S.ModalContent>
    </Modal>
  );
}

export default AlertDefault;
