export default {
  id: null,
  uid: null,
  companyId: null,
  name: "",
  enabled: false,
  default: true,
  ast: {},
  rulesList: [],
  privacyPolicyUrl: "",
  disablePreviewChat: false,
  withIntegrations: false,

  scriptVersion: {
    hasCustomDesignMobile: false,
    hasCustomNotificationsMobile: false,
    hasCustomScriptMobile: false,

    previewContent: {},
    desktopContent: {},
    mobileContent: {},
  },

  additionalData: {
    website: null,
    userEmail: null,
    userName: null,
    companyIntegrations: {},
  },

  dynamicFields: {},

  allowConnectionToGoogle: false,
  representatives: [],

  activeComponentIndex: 0,
  errors: {}
};
