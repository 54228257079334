import GuideModal from "@/components/shared/GuideModal";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from "use-context-selector";

import parse from "html-react-parser";

import { linkLeadsterAiGuide } from "./guideContents";

const AlertHandler = () => {
  const withIntegrations = useContextSelector(ScriptContext, ({ state }) => state.withIntegrations);

  const queryParams = new URLSearchParams(window.location.search);
  const referrerAction = queryParams.get("referrer_action");

  return (
    <>
      {referrerAction === "link-leadster-ai" && (
        <GuideModal
          guideContents={linkLeadsterAiGuide}
          finishButtonText={I18n.t("guide_modal.alright_button")}
        />
      )}

      {withIntegrations && referrerAction === "duplicate-script" && (
        <div className="alert alert-important bg-warning-lt alert-warning text-center mb-0 rounded-0 shadow-sm p-3">
          <div className="d-flex justify-content-center align-items-center fw-medium text-black-yellow">
            <i className="ti ti-alert-triangle icon me-2" />
            {parse(I18n.t("controllers.script.alert.add_or_edit_integration.html"))}
          </div>
        </div>
      )}
    </>
  );
};

export default AlertHandler;
