import React from "react";

const InsufficientDataAlert = () => {
  return (
    <div className="empty mt-0">
      <div className="empty-header text-primary mb-2">
        <i className="ti ti-mood-sad display-4" />
      </div>

      <div className="empty-title">{I18n.t("views.analytics.insufficient_data")}</div>

      <p className="empty-subtitle text-muted">
        {I18n.t("views.analytics.no_analysis_data")}
      </p>
    </div>
  );
};

export default InsufficientDataAlert;
