import React, { useState } from "react";

import AvatarImage from "../AvatarImage";

import { can } from "@/lib/user-permission";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const AvatarImages = ({ onChange, selectedAvatar }) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const neurologicImgPath = "https://leadster.com.br/img/avatar";
  const cdnBaseUrl = "https://cdn.leadster.com.br";
  const imagesPath = `${cdnBaseUrl}/neurolead/img`;
  const avatarsPath = `${imagesPath}/avatar`;

  const [avatars] = useState([
    `${imagesPath}/avatar.png`,
    `${avatarsPath}/3.png`,
    `${avatarsPath}/9.png`,
    `${avatarsPath}/12.png`,
    `${neurologicImgPath}/avatar4.png`,
    `${avatarsPath}/2.png`,
  ]);

  const [selected, setSelected] = useState(selectedAvatar);

  const handleClick = (e, avatar) => {
    e.preventDefault();
    if (!hasPermission) return;

    onChange(avatar);
    setSelected(avatar);
  };

  return (
    <>
      {avatars.map((avatar, index) => {
        return (
          <AvatarImage
            key={index}
            url={avatar}
            selected={avatar === selected}
            onClick={(e) => handleClick(e, avatar)}
            disabled={!hasPermission}
          />
        );
      })}
    </>
  );
};

export default AvatarImages;
