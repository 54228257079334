import React from "react";

import InfoCard from "../cards/InfoCard";

const Footer = () => {
  return (
    <div className="d-flex">
      <div style={{ minWidth: 362 }} />

      <InfoCard className="w-100">
        <InfoCard.Icon>
          <i className="ti ti-report-money"/>
        </InfoCard.Icon>

        <InfoCard.Message>
          {I18n.t("views.leadster_ai.page.home.infocard_1.message")}
          <a
            onClick={() => MixpanelService.track("leadster_ai_learn_more")}
            href={I18n.t("shared.helpscout.lead_generation_with_ai_assistant")}
            target="_blank"
          >
            {I18n.t("views.leadster_ai.page.home.infocard_1.link")} <i className="ti ti-external-link"/>
          </a>
        </InfoCard.Message>
      </InfoCard>
    </div>
  );
};

export default Footer;
