import styled from "styled-components";

export const MainBlock = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
`;

export const FlexBlock = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const FlexBlockCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

