import React from "react";

const formartNameField = (name) => {
  if (name.indexOf("::") === -1) {
    return name;
  } else {
    return name.split("::")[1];
  }
};

const leadStatus = (status) => {
  if (status === true) {
    return I18n.t("shared.actions.complete").toUpperCase();
  } else {
    return I18n.t("shared.actions.incomplete").toUpperCase();
  }
};

const leadScore = (number) => {
  try {
    if (number === null) {
      return (
        <a
          href={I18n.t("shared.helpscout.lead_qualification_feature")}
          target="_blank"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
        >
          <img src="/images/lead_score/fire_off_deny.svg" width="17" />
        </a>
      );
    } else if (number == 0) {
      return <img src="/images/lead_score/fire_off.svg" width="17" />;
    } else {
      return new Array(number).fill(
        <img src="/images/lead_score/fire_on.svg" width="17" className="me-1" />
      );
    }
  } catch (error) {
    return "";
  }
};

const Utils = {
  formartNameField,
  leadStatus,
  leadScore,
};

export default Utils;
