import { useEffect, useState } from "react";

import useLogic from "./useLogic";
import { useUpsellContext } from "../../../context";
import useAlertDefault from "@/components/shared/AlertDefault/hooks/useAlertDefault";

import CalculatorUtils from "../../../utils/calculators";
import CurrencyFormatter from "@/utils/formatter-currency";

import CardBase from "../../CardBase";

const CardSummary = ({
  planName,
  planPrice,
  planPeriod,
  remainingDays
}) => {
  const { showAlert } = useAlertDefault((state) => state);
  const { progressData, saveProgressData } = useUpsellContext();
  const { data, methods } = useLogic();

  const [useTermsDatas, setUseTermsDatas] = useState({
    permanentTime: false,
    earlyWarning: false,
    error: false
  });

  const isLongPeriodPlan = planPeriod === "semiannual" || planPeriod === "yearly";
  const selectedPlanTotalPriceLessDiscounts = planPrice - progressData.coupomTotalAmount;
  const selectedPlanPriceByInstallment = CalculatorUtils.calcPriceByInstallments(
    selectedPlanTotalPriceLessDiscounts,
    progressData.payment.installment
  );

  const validateFormDatas = () => {
    if ((!useTermsDatas.earlyWarning || !useTermsDatas.permanentTime) && isLongPeriodPlan) {
      showAlert({
        icon: "ERROR",
        title: I18n.t("views.upsell.stepper.step_two.validates.alert_1.title"),
        description: I18n.t("views.upsell.stepper.step_two.validates.alert_1.description_html"),
        buttons: [],
        closeButton: {
          text: I18n.t("shared.actions.close"),
        }
      });
      setUseTermsDatas(prev => ({
        ...prev,
        error: true
      }));
      return true;
    }

    if (progressData.financialEmail.error) {
      showAlert({
        icon: "ERROR",
        title: I18n.t("views.upsell.stepper.step_two.validates.alert_2.title"),
        description: I18n.t("views.upsell.stepper.step_two.validates.alert_2.description_html", {
          email_error: progressData.financialEmail.error
        }) ,
        buttons: [],
        closeButton: {
          text: I18n.t("shared.actions.close"),
        }
      });
      return true;
    }

    return false;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validateFormDatas();
    if (!error) methods.onSubmit();
  };

  useEffect(() => {
    saveProgressData(prev => ({
      ...prev,
      payment: {
        ...prev.payment,
        amount: selectedPlanTotalPriceLessDiscounts,
        amountByInstallment: selectedPlanPriceByInstallment,
      },
    }));
  }, [selectedPlanTotalPriceLessDiscounts, selectedPlanPriceByInstallment])

  return (
    <CardBase summaryNumber={3} title={I18n.t("views.upsell.stepper.step_two.summary_card.payment_method")}>
      <div className="space-y-2">
        <div className="d-flex align-items-center justify-content-between">
          <span>
            {remainingDays ? I18n.t("views.upsell.stepper.step_two.section_2.period_not_changed.title") : planName}
          </span>
          <b>
            {CurrencyFormatter.formatter(planPrice)}
          </b>
        </div>

        {!!progressData.coupomTotalAmount && (
          <div className="d-flex align-items-center justify-content-between">
            <span>Cupom adicionado</span>
            <b>
              -
              {CurrencyFormatter.formatter(progressData.coupomTotalAmount)}
            </b>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-between py-3 border-top border-bottom">
          <h3 className="fw-bold m-0">{I18n.t("views.upsell.stepper.step_two.summary_card.total")}</h3>
          <div>
            <div className="h1 mb-0">
              <div className="fw-bold text-primary">
                {progressData.payment.installment > 1 &&
                  I18n.t("views.upsell.stepper.step_two.summary_card.installment", {
                    installment: progressData.payment.installment
                  })}
                {CurrencyFormatter.formatter(selectedPlanPriceByInstallment)}
              </div>
            </div>
            <div className="fs-5 text-end">
              {"("}
              {CurrencyFormatter.formatter(selectedPlanTotalPriceLessDiscounts)}
              {")"}
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="w-full d-flex flex-column gap-3 aling-items-start">
          {isLongPeriodPlan && (
            <>
              <div className="d-flex align-items-center flex-row-reverse justify-content-end gap-2">
                <label
                  htmlFor="permanent_time"
                  className={`fs-5 ${(!useTermsDatas.permanentTime && useTermsDatas.error) && "text-danger fw-bold"}`}
                >
                  {I18n.t("views.upsell.stepper.step_two.summary_card.term_1")}
                </label>
                <input
                  type="checkbox"
                  name="check-permanent-time"
                  checked={useTermsDatas.permanentTime}
                  onChange={() => {
                    setUseTermsDatas(prev => ({
                      ...prev,
                      permanentTime: !prev.permanentTime
                    }));
                  }}
                />
              </div>
              <div className="d-flex align-items-center flex-row-reverse justify-content-end gap-2">
                <label
                  htmlFor="early_warning"
                  className={`fs-5 ${(!useTermsDatas.earlyWarning && useTermsDatas.error) && "text-danger fw-bold"}`}
                >
                  {I18n.t("views.upsell.stepper.step_two.summary_card.term_2")}
                  <a href="https://leadster.com.br/termos_de_uso/" target="_blank">
                    {I18n.t("views.upsell.stepper.step_two.summary_card.term_use")}
                  </a>
                </label>
                <input
                  type="checkbox"
                  name="check-early-warning"
                  checked={useTermsDatas.earlyWarning}
                  onChange={() => {
                    setUseTermsDatas(prev => ({
                      ...prev,
                      earlyWarning: !prev.earlyWarning
                    }));
                  }}
                />
              </div>
            </>
          )}

          <button
            type="submit"
            className="btn btn-primary gap-2"
            disabled={data.isLoadingSubmitUpsell}
          >
            {data.isLoadingSubmitUpsell
              ? (
                <div class="spinner-border text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              )
              : (
                <>
                  {I18n.t("views.upsell.stepper.step_two.summary_card.complete_order")}
                  <i className="ti ti-arrow-right"></i>
                </>
              )
            }
          </button>

          <a href={''} className="btn btn-outline-primary">
            {I18n.t("views.upsell.stepper.step_two.summary_card.any_questions")}
          </a>
        </form>
      </div>
    </CardBase>
  );
};

export default CardSummary;
