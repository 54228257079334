import React, { useEffect, useRef } from "react";

import AwsS3 from "@uppy/aws-s3";
import Core from "@uppy/core";
import Dashboard from '@uppy/dashboard';

import PtBrLocale from "@uppy/locales/lib/pt_BR";
import EnUsLocale from "@uppy/locales/lib/en_US";

import AvatarImage from "./AvatarImage";

import useCurrentUserStore from "@/store/currentUserStore";

const ScriptAvatarPicker = ({ onSuccess }) => {
  const { locale: userLocale } = useCurrentUserStore((state) => state.currentUser);

  const uppyRef = useRef();

  const parseLocale = (locale) => {
    if (locale === "pt-BR") return PtBrLocale;

    return EnUsLocale;
  };

  useEffect(() => {
    uppyRef.current = Core({
      locale: parseLocale(userLocale),
      allowMultipleUploads: true,
      autoProceed: true,
      restrictions: {
        allowedFileTypes: ["image/*"],
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
      },
    })
      .use(Dashboard, {
        trigger: ".openAvatarModal",
        target: ".avatar-container",
        showProgressDetails: true,
        note: I18n.t("manage_flow.script_editor.choose_an_avatar_image_for_your_bot"),
        height: 470,
        closeAfterFinish: true,
        proudlyDisplayPoweredByUppy: false,
      })
      .use(AwsS3, {
        companionUrl: window.location.origin
      })
      .on("complete", (result) => {
        if (!result.successful) return;

        const uploadURL = _.get(result, 'successful.0.uploadURL');

        onSuccess(uploadURL);
        uppyRef.current.reset();
      });
  }, []);

  return <AvatarImage className="openAvatarModal cursor-pointer me-2 mb-2" />;
};

export default ScriptAvatarPicker;
