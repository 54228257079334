import styled from "styled-components";

export const CreateButton = styled.button.attrs(({ isActive }) => ({
  title: isActive ? "" : I18n.t("views.manage_flows.button_create"),
  className: `btn btn-primary ${isActive ? "btn-rounded" : "w-4"}`,
  "data-bs-toggle": "tooltip",
  "data-bs-placement": "top",
}))`
  box-shadow: 0 0 0 0 rgba(61, 114, 232, 0.7);
  animation: pulse 1.8s infinite cubic-bezier(0.66, 0, 0, 1);
  font-size: 0.8rem;

  &:hover {
    animation: none;
    box-shadow: none;
  }

  &:disabled {
    animation: initial;
  }

  @keyframes pulse {
    to {
      box-shadow: 0 0 0 15px rgba(61, 114, 232, 0);
    }
  }
`;
