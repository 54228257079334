import React, { useRef } from "react";
import PropTypes from "prop-types";

import PopupWindow from "./PopupWindow";
import ReactPortal from '@/components/ReactPortal';

import { toOauthPathRoute } from './utils';
import { GoogleIconContainer } from './styles';

const GoogleLogin = ({
  enabled = true,
  queryParamsCallbackUrl = {},
  onSuccessCallback = () => {},
  className = "btn-primary w-100",
  text = I18n.t("views.users.edit.google_calendar.enabled.connect_with_google")
}) => {
  const infoModalRef = useRef();
  const googleOauthURL = toOauthPathRoute(queryParamsCallbackUrl);

  const openModal = () => {
    if (!enabled) return;
    $(infoModalRef.current).modal("show");
  };

  const handleConnect = () => {
    if (!enabled) return;
    $(infoModalRef.current).modal("hide");

    PopupWindow.open('google-login', googleOauthURL)
      .then((data) => {
        if (data.status === 'success') {
          return onSuccessCallback();
        }
      });
  };

  return (
    <>
      <div
        className={`btn d-inline-flex justify-content-start py-1 ps-1 cursor-pointer ${className}`}
        onClick={openModal}
      >
        <GoogleIconContainer>
          <img src="/images/calendar_schedule/logo_google.png" alt="Google Logo" />
        </GoogleIconContainer>

        <div className='text-center w-100'>{text}</div>
      </div>

      <ReactPortal>
        <div className="modal modal-blur fade" ref={infoModalRef} aria-modal="true" role="dialog">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{I18n.t("views.users.edit.google_calendar.enabled.login.instructions.title")}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

              <div className="modal-body">
                <div className="text-center mb-3">
                  <img src={I18n.t("views.users.edit.google_calendar.image")} alt="Check Calendar" />
                </div>

                <p className='lead mb-0'>
                  {I18n.t("views.users.edit.google_calendar.enabled.login.instructions.description")}
                </p>
              </div>

              <div className="modal-footer">
                <div
                  className="btn btn-primary d-inline-flex justify-content-start w-100 py-1 ps-1 cursor-pointer"
                  onClick={handleConnect}
                >
                  <GoogleIconContainer>
                    <img src="/images/calendar_schedule/logo_google.png" alt="Google Logo" />
                  </GoogleIconContainer>

                  <div className='text-center w-100'>{text}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactPortal>
    </>
  );
}

GoogleLogin.propTypes = {
  enabled: PropTypes.bool,
  queryParamsCallbackUrl: PropTypes.object,
  onSuccessCallback: PropTypes.func,
  className: PropTypes.string,
  text: PropTypes.string,
};

export default GoogleLogin;
