const SkeletonLoader = () =>
  new Array(3).fill(0).map((_, key) => (
    <div className="card placeholder-wave mb-3" key={key}>
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-7">
            <div className="placeholder placeholder-xs col-9"></div>
            <div className="placeholder placeholder-xs col-7"></div>
          </div>
          <div className="col-2 ms-auto text-end">
            <div className="placeholder placeholder-xs col-8"></div>
            <div className="placeholder placeholder-xs col-10"></div>
          </div>
        </div>
      </div>
    </div>
  ));

export default SkeletonLoader;
