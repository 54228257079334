import React from "react";

import HireFeatureButton from "./HireFeatureButton";

import parse from "html-react-parser";

const NotAllowedAlert = ({ onDelete }) => {
  return (
    <div className="alert alert-danger mb-3">
      <div className="d-flex align-items-center">
        <div>
          <i className="ti ti-info-circle icon alert-icon fs-1" />
        </div>

        <div>
          <div className="alert-title mb-2">
            {I18n.t("views.manage_flows.scheduler_block.meeting_scheduling")}
            <span className="badge bg-danger-lt ms-2">
              {I18n.t("views.manage_flows.scheduler_block.feature_unavailable")}
            </span>
          </div>

          <p className="text-muted mb-2">
            {parse(I18n.t("views.manage_flows.scheduler_block.schedule_meetings_with_team_html"))} {" "}
            <a href={I18n.t("shared.helpscout.google_calendar_scheduling")} target="_blank">
              {I18n.t("views.manage_flows.scheduler_block.learn_more_about_functionality")}{" "}
              <i className="ti ti-external-link"></i>
            </a>
          </p>

          <div className="btn-list">
            <HireFeatureButton>
              <i className="ti ti-shopping-cart icon" />
              {I18n.t("views.manage_flows.scheduler_block.hire_feature")}
            </HireFeatureButton>

            <button type='button' className="btn btn-outline-danger" onClick={onDelete}>
              <i className="ti ti-trash icon"></i>
              {I18n.t("views.manage_flows.scheduler_block.remove_block")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotAllowedAlert;
