import { useState, useCallback } from "react";

import Cookies from "js-cookie";

import useUpdateEffect from "./useUpdateEffect";

export default function useCookie(storageKey, options = {}) {
  const [value, setValue] = useState(() => {
    const cookie = Cookies.get(storageKey);

    if (cookie !== undefined) {
      try {
        return JSON.parse(cookie);
      } catch (error) {
        return null;
      }
    }

    return null;
  });

  useUpdateEffect(() => {
    if (value === undefined) {
      return Cookies.remove(storageKey, options);
    }

    Cookies.set(storageKey, JSON.stringify(value), options);
  }, [storageKey, JSON.stringify(value)]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, []);

  return { value, setValue, remove };
}
