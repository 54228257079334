export default function TooltipSidebar() {
  const isPortugueseLanguage = ["pt-BR", "pt"].includes(I18n.currentLocale());

  return (
    <>
      <h1>{I18n.t("views.onboard.create_a_new_call")}</h1>

      {isPortugueseLanguage ? (
        <div className="embed-container">
          <iframe
            src="https://www.youtube.com/embed/Xfpr9JYX78A"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      ) : (
        <img
          style={{ marginTop: 12, maxWidth: 385 }}
          src={I18n.t("views.onboard.call_image")}
          alt={I18n.t("views.onboard.call_description")}
        />
      )}
    </>
  );
}
