import React from "react";

import SwitchAvailability from "../shared/SwitchAvailability";

const DashboardAdsSwitchAvailability = ({
  companyId,
  tool,
  param_name,
  enabled,
}) => {
  return (
    <SwitchAvailability
      url={`/companies/${companyId}/update_ads`}
      description={I18n.t("views.users.edit.company_form.ads_options.show_ads_message", {ads: tool})}
      enabled={enabled}
      param_name={param_name}
    />
  );
};

export default DashboardAdsSwitchAvailability;
