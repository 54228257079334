import CardValidators from "card-validator";

import { onlyNumber } from "@/utils/helpers";
import { captureException } from "@sentry/react";

import axios from "lib/axios";

export async function profileTokenVindi(formData, configData) {
  return await axios.post(configData.public_profile_url, {
    "card_number": onlyNumber(formData.get("number")),
    "holder_name": formData.get("name"),
    "card_expiration": formData.get("expiry"),
    "card_cvv": onlyNumber(formData.get("cvc")),
    "registry_code": onlyNumber(formData.get("tax_vat")),
    "payment_company_code": getIssuer(formData.get("number")),
    "payment_method_code": "credit_card",
    "allow_as_fallback": true,
  }, {
    headers: {"Authorization": `Basic ${btoa(configData.public_token + ":")}`}
  }).then((response) => {
    return response.data.payment_profile.gateway_token;
  }).catch((error) => {
    captureException(error);
    throw new Error(I18n.t("views.billings.components.cc.validates.profile"));
  });
}

function getIssuer(cardNumber) {
  cardNumber = onlyNumber(cardNumber);

  let validator = CardValidators.number(cardNumber);

  if (validator.card === null) return null;

  return validator.card.type;
}
