import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import TopLogo from "@/components/onboard/TopLogo"
import TooltipSelector from "./TooltipSelector";
import Spinner from "./Spinner";

import axios from "@/lib/axios";
import { useAppState } from "./AppState";

import swal from "sweetalert";

export default function ChooseTooltip(props) {
  const [isClicked, setIsClicked] = useState(false);
  const state = useAppState();

  useEffect(() => feather.replace());

  const handleClickFinish = (event)  => {
    event.preventDefault();
    setIsClicked(true);

    const endpoint = `/companies/${state.companyId}/update_onboarding_state`;
    const payload = state._toStorageFormat();

    axios.put(endpoint, payload)
      .then(() => {
        const search = new URL(window.location.href).search
        window.location = '/hello' + (!!search ? search : '');
      })
      .catch(() => {
        setIsClicked(false);
        swal(I18n.t("request_feedback_alert.error.title"), I18n.t("request_feedback_alert.error.message"), "error");
      });
  }

  return (
    <div id="choose-tooltip">
      <TopLogo />

      <h1>{I18n.t("views.onboard.conversion_call")}</h1>
      <h2 style={{ marginBottom: 0 }}>{I18n.t("views.onboard.tested_calls")}</h2>

      <TooltipSelector segment={state.segment} setTooltip={(value) => state.setTooltip(value)} />

      <div>
        {isClicked ? (
          <div style={{ position: "relative", marginTop: 40, display: "block", height: 72 }}>
            <Spinner />
          </div>
        ) : (
         <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 15px' }}>
           <Link to={'/avatar'} className="btn-previous">
             {I18n.t("shared.actions.return")}
           </Link>

           <a href="#" className="btn-next" disabled={isClicked} onClick={handleClickFinish}>
             {I18n.t("shared.actions.finalize")} <i data-feather="arrow-right"></i>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
