class CurrencyFormatter {
  static formatter(price,
    {
      showCurrencyCode = true,
      currencyCode = I18n.t("number.currency.code"),
      locale = navigator.locale
    } = {}) {
    const options = showCurrencyCode
      ? { style: 'currency', currency: currencyCode }
      : { style: 'decimal' };

    return new Intl.NumberFormat(locale, options).format(price);
  }
}

export default CurrencyFormatter;
