import React from "react";

import useCurrentUserStore from "@/store/currentUserStore";

const TopLogo = () => {
  const { applicationName } = useCurrentUserStore((state) => state.currentUser);

  return (
    <div className="top-logo">
      <img
        className="logo-onboarding"
        src={useCurrentUserStore((state) => state.applicationLogo("https://cdn.leadster.com.br/leadster/onboarding/logo.png"))}
        alt={applicationName}
      />
    </div>
  );
};

export default TopLogo;
