import axios from "@/lib/axios";

class LeadService {
  getLead(id) {
    return axios.get(`/lead_data?lead_id=${id}`);
  }

  setLeadAsClickedViewData(id) {
    return axios.put(`/lead_clicked_update/${id}`);
  }
};

export default new LeadService();
