import React from "react";

export default function ContentContainer(props) {
  return (
    <div className="content-container">
      <div className="content-align-wrapper">
        {props.children}
      </div>
    </div>
  );
}
