import styled from 'styled-components';

export const Component = styled.div`
  display: inline-block;
  border-bottom: 1px solid #eee;
  padding: 15px 9px;
  margin-bottom: 10px;
  width: 100%;
  background-color: white;
  cursor: pointer;
`

export const Avatar = styled.img`
  float: left;
  padding: 0;
  width: 3rem;
  height: 3rem;
  margin-right: 5px;
  border: 1px solid rgba(0,0,0,.1);
`

export const Date = styled.div`
  line-height: 1.5;
  font-size: 11px;
  color: #1A6FE6;
`

export const Header = styled.div`
  position:inline-flex;
  flex-direction: row;
  display: flow-root;
  align-items: center;
`

export const Body = styled.span`
  font-size: 13px;
  line-height: 1.5;
  padding-top: 12px;
  margin-bottom: 0px;
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
`

export const Decoration = styled.div`
  width: 3px;
  height: 155px;
  margin-left: 5px;
  margin-right: 15px;
  float: left;
`
