import React from "react";

import HireFeatureButton from "./HireFeatureButton";

import parse from "html-react-parser";

const TrialEndedAlert = ({ onDelete }) => {
  return (
    <div className="alert alert-danger mb-3">
      <div className="d-flex align-items-center">
        <div>
          <i className="ti ti-info-circle icon alert-icon fs-1" />
        </div>

        <div>
          <div className="alert-title h3 mb-2 text-danger">
            {I18n.t("views.manage_flows.scheduler_block.free_trial_ended")}
          </div>

          <p className="text-muted mb-2">
            {parse(I18n.t("views.manage_flows.scheduler_block.continue_with_feature_html"))}
          </p>

          <div className="btn-list">
            <HireFeatureButton>
              <i className="ti ti-shopping-cart icon" />
              {I18n.t("views.manage_flows.scheduler_block.hire_feature")}
            </HireFeatureButton>

            <button type='button' className="btn btn-outline-danger" onClick={onDelete}>
              <i className="ti ti-trash icon"></i>
              {I18n.t("views.manage_flows.scheduler_block.remove_block")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrialEndedAlert;
