import React from "react";

import AddBlockButton from "./ScriptEditorParts/AddBlockButton";
import Placeholder from "./TreePlaceholder";
import Item from "./Item";

const wrapInPlaceholders = (item, i, children) => [
  { targeted: item.targeted === "above", item: item, insert: "above" },
  item,
  { targeted: item.targeted === "below", item: item, insert: "below" },
];

// Remove { placeholder: true } items IF there are other children
const cleanPlaceholders = (item, i, children) => {
  return children.length > 1 ? !item.placeholder : true;
};

const Tree = (props) => {
  const {
    isMobile, dragging, children, onDragStart, onDragEnd, onDragOver,
    onDrop, treeAdd, treeRemove, treeUpdate, makeBlock,
    getFullTree, globalKeyGet, globalKeySet, additionalData,
  } = props;

  const childrenWithPlaceholders = children
    .filter(cleanPlaceholders)
    .map(wrapInPlaceholders)
    .reduce((acc, v) => acc.concat(v), []);

  return (
    <div className="list">
      {childrenWithPlaceholders.map((child, i) =>
        child.id ? (
          <Item
            isMobile={isMobile}
            dragging={dragging}
            key={child.id}
            id={child.id}
            path={child.path}
            placeholder={child.placeholder}
            data={child.data}
            type={child.type}
            additionalData={additionalData}
            children={child.children}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            onDrop={onDrop}
            treeAdd={treeAdd}
            treeRemove={treeRemove}
            treeUpdate={treeUpdate}
            getFullTree={getFullTree}
            globalKeyGet={globalKeyGet}
            globalKeySet={globalKeySet}
            makeBlock={makeBlock}
            parent={props}
            isLast={i === childrenWithPlaceholders.length - 2}
            isOnly={children.length === 1}
          />
        ) : (
          <Placeholder
            key={-i}
            item={child.item}
            insert={child.insert}
            targeted={child.targeted}
            onDragOver={onDragOver}
            onDrop={onDrop}
            parent={props}
          />
        )
      )}

      {(childrenWithPlaceholders.length === 0 || children.length === 1) && (
        <AddBlockButton parent={props} item={null} alwaysShow={true} />
      )}
    </div>
  );
}

export default Tree;
