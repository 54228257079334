import React from "react";
import { useAppState } from "./AppState";
import AnimatedLoadingLine from "./AnimatedLoadingLine";
import posed, { PoseGroup } from "react-pose";
import { Switch, Route, useLocation } from "react-router-dom";
import neurologicTipImage from "images/dicaneurologic.png";
import parse from 'html-react-parser';
import useCurrentUserStore from "@/store/currentUserStore";

const interpolate = (str, params) => {
  const names = Object.keys(params);
  const vals = Object.values(params);
  return new Function(...names, `return \`${str}\`;`)(...vals);
}

const SidebarContainer = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 0,
    transition: { duration: 400 },
  },
  exit: {
    y: 50,
    opacity: 0,
    delay: 0,
    transition: { duration: 300 },
  },
});

export default function Sidebar(props) {
  const appState = useAppState();
  const pathname = useLocation().pathname;
  let current_path = appState.path
  if(pathname !== '/'){
    current_path = pathname
  }

  return (
    <Switch>
      {appState.sidebarHeading === "" && appState.sidebarContent === "" && (
        <Route path="/color">
          <div className="preview-container">
            <div
              id="website-preview"
              className="row text-right bg-white curved shadow"
              style={{
                backgroundImage: `url(${appState.image_url})`,
              }}
            >
              <div className="browser-frame">
                <div className="browser-frame-button bg-red"></div>
                <div className="browser-frame-button bg-yellow"></div>
                <div className="browser-frame-button bg-green"></div>
              </div>

              <div className="col p-0 m-0" style={{ height: "100%" }}>
                <div
                  id="nld-custom-container"
                  style={{ position: "relative", width: "100%", height: "100%" }}
                >
                  <div style={{
                    position: "absolute",
                    width: 60,
                    height: 60,
                    borderRadius: 30,
                    display: "inline-block",
                    zIndex: 32767,
                    bottom: 24,
                    right: 18,
                    backgroundSize: "cover",
                    boxShadow: "0 0 12px 0 rgba(0, 0, 0, 0.15)",
                    backgroundImage: `url(${appState.avatar})`
                  }}>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Route>
      )}

      <Route>
        <div className="sidebar">
          <div className="sidebar-content">
            <AnimatedLoadingLine
              inside="#fbf9f6"
              line="#ebe9e6"
              highlight="#00a9ff"
              step={props.routesOrder[current_path] + 1}
              steps={Object.keys(props.routesOrder).length}
              amplitude={appState.sidebarAnimated ? 1 : 0}
            />

            <div className="sidebar-top">
              <img
                src={useCurrentUserStore((state) => state.applicationLogo(neurologicTipImage))}
                alt="Dica"
                className="neurologic-tip-image"
              />
            </div>

            <Route
              render={({ location }) => (
                <PoseGroup animateOnMount={true}>
                  {appState.sidebarHeading === "" &&
                    appState.sidebarContent === "" && (
                      <SidebarContainer key={location.key}>
                        <Switch>
                          {props.sidebarRoutes.map(({ path, Component }) => {
                            return (
                              <Route path={path} key={path}>
                                {({ match }) => <Component match={match} />}
                              </Route>
                            );
                          })}
                        </Switch>
                      </SidebarContainer>
                    )}

                  <SidebarContainer key="loading">
                    <h1 className="sidebar-loading">
                      {interpolate(appState.sidebarHeading, {
                        appState: appState,
                      })}
                    </h1>
                    <h2 className="sidebar-loading">
                      {parse(interpolate(appState.sidebarContent, {
                        appState: appState,
                      }))}
                    </h2>
                  </SidebarContainer>
                </PoseGroup>
              )}
            />
          </div>
        </div>
      </Route>
    </Switch>
  );
}
