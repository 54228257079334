import useCurrentUserStore from "@/store/currentUserStore";

export const ROLE = { default: "default", whatsapp: "whatsapp" };

export const isRole = (role) => {
  const currentUserRole = useCurrentUserStore((state) => state.currentUser.role);

  return role === currentUserRole;
};

export const can = ({ action, subject }) => {
  const abilities = useCurrentUserStore((state) => state.currentUser.abilities);

  let isAllowed = _.get(abilities, `${subject}.${action}`, false);

  if (_.get(abilities, `${subject}.manage`, false)) {
    isAllowed = true;
  }

  return isAllowed;
};

export const cannot = (...args) => !can(...args);
