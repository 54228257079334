import { useState, useEffect } from "react";

import axios from "@/lib/axios";
import swal from 'sweetalert';
import useAnalyticsStore from '@/components/ScriptAnalytics/store';

const useSelectorBlocksFetcher = () => {
  const { scriptId } = useAnalyticsStore((state) => state);

  const [selectorBlocksAnalytics, setSelectorBlocksAnalytics] = useState([]);
  const [period, setPeriod] = useState("current");
  const [channel, setChannel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const endpoint = `/script/${scriptId}/analytics/selector-selectors-conversion`;
    const params = {
      "filter[period]": period,
      "filter[channel]": channel
    };

    setIsLoading(true);

    axios.get(endpoint, { params })
      .then(({ data }) => setSelectorBlocksAnalytics(data.blocks))
      .catch(() => {
        setSelectorBlocksAnalytics([]);
        swal("", I18n.t("views.analytics.error.info_retrieval"), "error")
      })
      .then(() => setIsLoading(false));
  }, [period, channel]);

  return {
    selectorBlocksAnalytics,
    period,
    setPeriod,
    channel,
    setChannel,
    isLoading
  };
};

export default useSelectorBlocksFetcher;
