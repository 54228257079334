import React from 'react';
import { buildWhatsAppLink } from "@/utils/whatsapp_helpers";

const Link = ({phone}) => {
  const phoneNumber = phone.replace(/\D/g,'');

  return (
    <>
      { (phoneNumber != "") &&
      <a
        className="mx-2 btn-outline-teal"
        href={buildWhatsAppLink({
          number: phoneNumber,
          type: 'api'
        })}
      >
        <i className="ti ti-brand-whatsapp icon" />
      </a>
      }
    </>
  )
}

export default Link;
