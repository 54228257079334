import styled from "styled-components";

export const Modal = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
`;

export const ModalContent = styled.div`
  box-shadow: 0 0 1em #999;
`;

export const ModalHeader = styled.div`
  min-height: 0;
`;

export const BtnClose = styled.button`
  width: 2rem;
  height: 2rem;
`;

export const ModalBody = styled.div`
  display: flex;
  background-color: var(--tblr-white);

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;
export const ModalBodyImage = styled.img`
  height: 160px;

  @media (max-width: 600px) {
    height: 280px;
  }
`;

export const ModalBodyText = styled.div`
  align-self: center;
  padding-right: 50px;

  @media (max-width: 600px) {
    padding: 20px;
    text-align: center;
  }
`;

export const ModalFooterTitle = styled.div`
  @media (max-width: 600px) {
    font-size: large;
    padding: 0;
    text-align: center;
  }
`;

export const ModalFooter = styled.div`
  background-color: #004774;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 15px 30px;
  color: var(--tblr-white);

  @media (max-width: 600px) {
    padding: 30px 30px;
    flex-direction: column;
  }
`;
