import styled, { css } from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 700px;
  max-height: 95%;
  background-color: white;
  border-radius: 10px;

  @media (max-width: 799px) {
    max-width: 95%;
  }
`;
export const ModalIcon = styled.i`
  ${({ $type }) => css`
    font-size: 60px;
    color: var(${$type === 'ERROR' && '--tblr-danger'});
    color: var(${$type === 'SUCCESS' && '--tblr-success'});
    color: var(${$type === 'WARNING' && '--tblr-warning'});
  `}
`;
