import React from "react";

import useWhatsAppBlocksFetcher from './hooks/useWhatsAppBlocksFetcher';

import ModalListLeads from "./ModalListLeads";
import NoAnalyticsAvaliableAlert from '../Alerts/NoAnalyticsAvaliableAlert';
import Filters from "./Filters";

import { classBinding } from "@/utils/helpers";

import dayjs from "@/lib/dayjs";

import { WhatsAppCard } from "./styles";

const WhatsAppRoulette = () => {
  const {
    whatsAppBlocksAnalytics,
    period,
    setPeriod,
    channel,
    setChannel,
    isLoading,
  } = useWhatsAppBlocksFetcher();

  return (
    <>
      <div className={classBinding({ "card-mode-loading": isLoading }, "card mb-4")}>
        <div className="card-body">
          <div className="row align-items-center">
            <div className="h2 mb-3">{I18n.t("views.analytics.whatsapp_redirection")}</div>

            <div className="card-subtitle text-muted mb-0">
              {I18n.t("views.analytics.whatsapp_redirection_tables")}
            </div>
          </div>
        </div>

        <div className="card-body">
          <Filters
            defaultPeriod={period}
            onChangePeriod={(period) => setPeriod(period)}
            defaultChannel={channel}
            onChangeChannel={(channel) => setChannel(channel)}
          />
        </div>
      </div>

      <div className="row row-deck row-cards">
        {(!isLoading && !whatsAppBlocksAnalytics.length) && (
          <NoAnalyticsAvaliableAlert />
        )}

        {whatsAppBlocksAnalytics.map((block) => (
          <div className="col-lg-6" key={block.uid}>
            <WhatsAppCard className={classBinding({ "card-mode-loading": isLoading }, "card shadow-sm")}>
              <div className="card-header">
                <div className="card-title">
                  <div>
                  {I18n.t("views.analytics.field")}: <span className="text-primary">{block.label}</span>
                  </div>

                  <div>
                    {I18n.t("views.analytics.total_leads")}:{" "}
                    <span className="text-primary">{block.leads_count}</span>
                  </div>
                </div>
              </div>

              {!!block.version_date && (
                <div className="bg-primary-lt fw-medium p-3">
                  <div className="d-flex">
                    <i className="ti ti-bulb icon me-2"></i>

                    <div className='text-body'>
                      {I18n.t("views.analytics.block_version_since")} {" "}
                      <strong>{dayjs(block.version_date).format(I18n.t("js.formats.default.date_time"))}</strong>
                    </div>
                  </div>
                </div>
              )}

              <div className="card-table table-responsive">
                <table className="table table-vcenter card-table table-striped">
                  <thead>
                    <tr>
                      <th>{I18n.t("views.analytics.whatsapp")}</th>
                      <th>{I18n.t("views.analytics.quantity")}</th>
                      <th>{I18n.t("views.analytics.percentage")}</th>
                      <th className="text-center">{I18n.t("views.analytics.actions")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {block.leads_distribuition.map((distribuition) => (
                      <tr key={distribuition.phone}>
                        <td>{distribuition.phone}</td>
                        <td>{distribuition.counter}</td>
                        <td>{distribuition.percentual}</td>
                        <td className="text-center">
                          <ModalListLeads
                            blockData={block}
                            phoneNumber={distribuition.phone}
                            channel={channel}
                            period={period}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </WhatsAppCard>
          </div>
        ))}
      </div>
    </>
  );
};

export default WhatsAppRoulette;
