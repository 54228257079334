import React from "react";

import useCookie from "@/hooks/useCookie";
import * as S from "../style";
import bannerGif from '@/../assets/images/leadster_ai/alerts/banner_leads.gif';

const EXPIRE_IN_DAYS = 7;
const COOKIE_KEY = "banner-leadster-ai-leads";

// TODO: Remove this component after 11/13/2024
const BannerLeads = () => {
  const { value: cookieValue, setValue: setCookieValue } = useCookie(COOKIE_KEY, { expires: EXPIRE_IN_DAYS });

  if (cookieValue) return;

  return (
    <S.AlertContainer>
      <div className="text-black">
        <div className="row">
          <div className="col-6 ps-5 d-flex flex-column justify-content-center">
            <div className="ms-3">
              <p className="font-l">
                <b>Atenda seus lead de</b> <span className="text-primary">forma automática</span>
              </p>
              <p className="mt-3 font-sm">
                Ofereça para seus visitantes <b>um atendimento humanizado, instantâneo e escalável com
                inteligência Atificial.</b> Conheça Assistente Leadster AI
              </p>
              <a className="btn btn-primary mt-2" href="ai/leadster">Teste grátis</a>
            </div>
          </div>
          <div className="col-6">
            <img className="gif" src={bannerGif}/>
          </div>
        </div>
        <a className="btn-close" onClick={() => setCookieValue(true)} />
      </div>
    </S.AlertContainer>
  );â
};

export default BannerLeads;
