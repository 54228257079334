import { useState } from "react";
import PropTypes from "prop-types";

import { Modal, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";

import axios from "@/lib/axios";
import { classBinding } from "@/utils/helpers";

import swal from "sweetalert";

const GenerateLinkModal = ({ onClose, show = false }) => {
  const {
    register,
    setValue,
    handleSubmit,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const formatters = {
      full_name: (value) => { return value.split(" ").map(_.capitalize).join(" ") },
      email: (value) => { return _.toLower(value) },
      whatsapp: (value) => {
        return value.replace(/\D/g, '').slice(0, 11).replace(/^(\d{2})(\d{3})(\d{3})(\d{3})$/, '($1) $2-$3-$4')
      },
    }

    const formatter = formatters[name];

    if (formatter) setValue(name, formatter(value));
  }

  const onSubmit = async (data) => {
    setIsLoading(true);

    await axios.post("/referral/link-generator", {
      full_name: data.full_name,
      email: data.email,
      whatsapp: data.whatsapp,
    })
      .then(() => {
        swal(
          "Tudo certo!",
          "O seu link foi gerado com sucesso!", "success")
          .then(() => {
            window.location.replace("/referral");
        });
      })
      .catch(() => {
        let message = "Falha ao gerar seu link. Verifique os dados e tente novamente. " +
          "Se o problema persistir, contate o suporte."

        swal("", message, "error").then(_r => "");
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <Modal show={show} className="modal-blur" backdrop="static" centered onHide={onClose}>
      <Modal.Header closeButton>
        <div className="modal-status bg-primary"/>
        <Modal.Title className="fw-bold">Vamos gerar o seu link</Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <p>Preencha os campos abaixo com suas informações</p>

          <Form.Group className="mb-3">
            <Form.Label className="required">Nome Completo</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder="Exemplo: Alberto Silva"
              {...register("full_name", {
                required: true,
                minLength: 3,
                onChange: handleInputChange,
              })}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="required">E-mail</Form.Label>
            <Form.Control
              type="email"
              autoComplete="off"
              placeholder="Exemplo: jose@empresa.com.br"
              {...register("email", {
                required: true,
                onChange: handleInputChange,
              })}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="required">WhatsApp</Form.Label>
            <Form.Control
              type="tel"
              autoComplete="off"
              placeholder="Exemplo: (99) 999-999-999"
              {...register("whatsapp", {
                required: true,
                onChange: handleInputChange,
              })}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button type="button" className="btn btn-light" disabled={isLoading} onClick={onClose}>
            Cancelar
          </Button>

          <Button type="submit" className={classBinding({ "btn-loading": isLoading}, "btn btn-primary")}>
            <i className="ti ti-link-plus fs-3 me-2" />
            Gerar Link
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

GenerateLinkModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default GenerateLinkModal;
