import React, { useEffect, useState, useRef } from "react";

import PermissionsGate from '@/components/permissions/PermissionsGate';
import IntlPhoneInput from "@/components/shared/IntlPhoneInput";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { can } from "@/lib/user-permission";

const MAX_PHONE_NUMBERS = 60;

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const MultiplePhones = ({ initialPhoneNumbers, updateDefinition }) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState(initialPhoneNumbers);
  const [errors, setErrors] = useState({});

  const intlPhoneRef = useRef();

  const hasError = (field) => !!errors[field];
  const getError = (field) => errors[field] || "";

  const resetAllState = () => {
    setErrors({});
  };

  useEffect(() => {
    window.initJqueryTooltip();
  })

  const getNewPhoneErrors = () => {
    const newPhoneErrors = {};

    if (phoneNumbers.length >= MAX_PHONE_NUMBERS) {
      newPhoneErrors["new_phone_number"] = I18n.t(
        "views.manage_flows.whatsapp_block.maximum_number_of_phone_numbers",
        { maxPhoneNumbers: MAX_PHONE_NUMBERS }
      );
    }

    if ((newPhoneNumber) == "" || (newPhoneNumber) == null ) {
      newPhoneErrors["new_phone_number"] = I18n.t("views.manage_flows.whatsapp_block.add_phone");
    }

    if (phoneNumbers.includes(newPhoneNumber)) {
      newPhoneErrors["new_phone_number"] = I18n.t("views.manage_flows.whatsapp_block.phone_already_registered");
    }

    return newPhoneErrors;
  };

  const handleAddButton = () => {
    if (!hasPermission) return;

    const newPhoneErrors = getNewPhoneErrors();

    if (!_.isEmpty(newPhoneErrors)) {
      return setErrors(newPhoneErrors);
    }

    const updatedPhoneNumbers = [...phoneNumbers, newPhoneNumber];

    intlPhoneRef.current.cleanNumber();
    setPhoneNumbers(updatedPhoneNumbers);
    updateDefinition(updatedPhoneNumbers);
    resetAllState();
  };

  const handleAddByEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddButton();
    }
  };

  const handleRemoveButton = (phoneToDelete) => {
    if (!hasPermission) return;

    const updatedPhoneNumbers = phoneNumbers.filter(
      (phone) => phone !== phoneToDelete
    );

    setPhoneNumbers(updatedPhoneNumbers);
    updateDefinition(updatedPhoneNumbers);
  };

  const ErrorInput = ({ fieldName }) => {
    if (hasError(fieldName)) {
      return (
        <div className="text-danger text-sm mt-2">
          {getError(fieldName)}
        </div>
      );
    }

    return <></>;
  };

  return (
    <fieldset className="form-fieldset mt-2">
      <div className="row g-2">
        <div className="col-md-6">
          <div className="mb-2">
            <label className="form-label">
              <i className="ti ti-brand-whatsapp me-1"></i>
              {I18n.t("views.manage_flows.whatsapp_block.number_do_whatsapp")}
            </label>

            <IntlPhoneInput
              disabled={!hasPermission}
              className={`form-control ${hasError("new_phone_number") && "is-invalid"}`}
              onUpdatePhoneNumber={setNewPhoneNumber}
              onKeyDown={handleAddByEnter}
              ref={intlPhoneRef}
            />

            <ErrorInput fieldName="new_phone_number" />
          </div>

          <PermissionsGate scope={PERMISSION_SCOPE}>
            <button className="btn btn-success float-end" onClick={(event) => {
                handleAddButton();
                event.preventDefault();
              }
            }>
              {I18n.t("general.actions.add")}
            </button>
          </PermissionsGate>
        </div>

        <div className="col-md">
          <div className="h4 mb-2">
            <i className="ti ti-phone-check me-1"></i>
            {I18n.t("views.manage_flows.whatsapp_block.phones")} {phoneNumbers.length}/{MAX_PHONE_NUMBERS}
          </div>

          {!phoneNumbers.length && (
            <div className="alert alert-warning">
              <h4 className="alert-title text-body">
                {I18n.t("views.manage_flows.whatsapp_block.no_registered_phone")}
              </h4>

              <div className="text-muted">
                {I18n.t("views.manage_flows.whatsapp_block.add_new_numbers")}
              </div>
            </div>
          )}

          <ul className="list-unstyled overflow-auto mb-0" style={{ maxHeight: 200 }}>
            {phoneNumbers.map((phone) => (
              <li className="mb-2 me-2" key={phone}>
                <PermissionsGate scope={PERMISSION_SCOPE}>
                  <OverlayTrigger overlay={<Tooltip>{I18n.t("general.actions.remove")}</Tooltip>}>
                    <button className="btn btn-icon me-1" onClick={() => handleRemoveButton(phone)}>
                      <i className="ti ti-trash-x icon"></i>
                    </button>
                  </OverlayTrigger>
                </PermissionsGate>

                <span>{phone}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </fieldset>
  );
};

export default MultiplePhones;
