import { useLocation } from "react-router-dom";

import * as S from "./styles";

export const Navigation = () => {
  const { pathname } = useLocation();

  const navigations = [
    {
      name: I18n.t("views.upsell.stepper.navigation.step_1"),
      path: "/upsell",
    },
    {
      name: I18n.t("views.upsell.stepper.navigation.step_2"),
      path: "/upsell/overview",
    },
    {
      name: I18n.t("views.upsell.stepper.navigation.step_3"),
      path: "/upsell/order",
    },
  ];

  const normalizedPathname = pathname.replace(/\/$/, ""); // remove last / from pathname
  const currentIndex = navigations.findIndex(item => normalizedPathname === item.path);

  return (
    <S.NavWrapper>
      {navigations.map((item, index) => (
        <S.NavGroup key={index} className={pathname === item.path ? "active" : ""}>
          {index < currentIndex || currentIndex === 2 ? (
            <div
              className="bg-green d-flex flex-column align-items-center justify-content-center rounded-circle fs-3"
              style={{ width: 25, height: 25 }}
            >
              <i className="ti ti-check text-white"></i>
            </div>
          ) : (
            <button className={`btn ${pathname.includes(item.path) ? "btn-primary" : "btn-outline-primary"} btn-pill`}>
              {index + 1}
            </button>
          )}
          <span className="text">{item.name}</span>
          {index + 1 !== 3 && <div className="line-dots" />}
        </S.NavGroup>
      ))}
    </S.NavWrapper>
  );
};

export default Navigation;
