import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useUpsellContext } from "../../../context";
import useAlertDefault from "@/components/shared/AlertDefault/hooks/useAlertDefault";

import CurrencyFormatter from "@/utils/formatter-currency";
import CalculatorUtils from "../../../utils/calculators";
import FormatterUtils from "../../../utils/formatter";

import * as S from "./styles";

const SuggestionCard = ({
  options,
  currentPLan,
  overdueInvoice,
  currentPlanPrice,
}) => {
  const { showAlert } = useAlertDefault((state) => state);
  const { saveProgressData } = useUpsellContext();
  const history = useHistory();

  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);

  const plan = options[selectedPlanIndex];
  const price = CalculatorUtils.calcPricebyPeriod(plan.price, plan.period);
  const access = FormatterUtils.formatAbbreviatedAccess(options[selectedPlanIndex].access_limit);
  const isChangePlan = currentPLan.period !== plan.period;

  const handleSelectPlan = () => {
    showAlert({
      icon: "WARNING",
      title: I18n.t("views.upsell.stepper.step_one.alert_select_plan.title"),
      description: `
        ${(!overdueInvoice && !isChangePlan) ?
          I18n.t("views.upsell.stepper.step_one.alert_select_plan.description_1_html", {
            current_plan: currentPLan.plan,
            current_period: I18n.t(`views.upsell.stepper.step_one.options_period.${currentPLan.period}`),
            new_plan: plan.plan,
            new_period: I18n.t(`views.upsell.stepper.step_one.options_period.${plan.period}`)
          }) : ''
        }

        ${(!overdueInvoice && isChangePlan) ?
          I18n.t("views.upsell.stepper.step_one.alert_select_plan.description_2_html", {
            current_plan: currentPLan.plan,
            current_period: I18n.t(`views.upsell.stepper.step_one.options_period.${currentPLan.period}`),
            new_plan: plan.plan,
            new_period: I18n.t(`views.upsell.stepper.step_one.options_period.${plan.period}`)
          }) : ''
        }

        ${(overdueInvoice) ?
          I18n.t("views.upsell.stepper.step_one.alert_select_plan.description_3_html", {
            current_plan: currentPLan.plan,
            current_period: I18n.t(`views.upsell.stepper.step_one.options_period.${currentPLan.period}`),
            new_plan: plan.plan,
            new_period: I18n.t(`views.upsell.stepper.step_one.options_period.${plan.period}`)
          }) : ''
        }
      `,
      buttons: [{
        text: I18n.t("views.upsell.stepper.step_one.alert_select_plan.btn"),
        action: () => {
          saveProgressData(prev => ({
            ...prev,
            idPlan: plan.plan_id,
            overdueInvoice,
          }))
          history.push("/upsell/overview")
        }
      }]
    });
  };

  const handleRefusePlan = () => {
    showAlert({
      icon: "WARNING",
      title: I18n.t("views.upsell.stepper.step_one.alert_refuse_plan.title"),
      description: I18n.t("views.upsell.stepper.step_one.alert_refuse_plan.description"),
      buttons: [{
        text: I18n.t("shared.actions.confirm"),
        action: () => handleOpenGuideModal()
      }],
      closeButton: {
        text: I18n.t("shared.actions.cancel"),
      }
    });
  };

  const handleOpenGuideModal = () => {
    showAlert({
      image: "/images/celebration/50leads.png",
      title: I18n.t("views.upsell.stepper.step_one.alert_guide_modal.title"),
      description: I18n.t("views.upsell.stepper.step_one.alert_guide_modal.description"),
      buttons: [{
        text: I18n.t("views.upsell.stepper.step_one.alert_guide_modal.btn_confirm"),
        action: () => window.location.href = "/"
      }],
      closeButton: {
        text: I18n.t("shared.actions.cancel"),
      }
    });
  };

  return (
    <S.Card className="card shadow-sm">
      <div className="card-header d-flex justify-content-between py-1">
        <p className="card-title col fw-bold">
          {plan.plan}
        </p>
        {options.length > 1 ? (
          <div className="dropdown m-0 p-0 col-6">
            <p
              data-bs-toggle="dropdown"
              className={`
                ${(plan.period === "semiannual" || plan.period === "yearly") && "bg-success text-white"}
                btn dropdown-toggle d-flex justify-content-between m-0 w-100 fs-5
              `}
            >
              {I18n.t(`views.upsell.stepper.step_one.options_period.${plan.period}`)} {" "}
              {plan.period === "semiannual" && I18n.t("views.upsell.stepper.step_one.save_15")}
              {plan.period === "yearly" && I18n.t("views.upsell.stepper.step_one.save_20")}
            </p>
            <div className="dropdown-menu">
              {options.map((option, index) => (
                <button
                  key={option.plan_id}
                  className="dropdown-item"
                  onClick={() => setSelectedPlanIndex(index)}
                >
                  {I18n.t(`views.upsell.stepper.step_one.options_period.${option.period}`)}
                </button>
              ))}
            </div>
          </div>
        ) : (
          <div
            className={`
              ${(plan.period === "semiannual" || plan.period === "yearly") && "bg-success text-white"}
              btn col-5 rounded px-3 py-2 text-center fs-5
            `}
          >
            {options[0].period}{" "}
            {plan.period === "semiannual" && "( Economize 15% )"}
            {plan.period === "yearly" && "( Economize 20% )"}
          </div>
        )}
      </div>
      <div className="card-body">
        <div>
          {I18n.t("views.upsell.stepper.step_one.price_from", {
            price: CurrencyFormatter.formatter(currentPlanPrice)
          })}
        </div>
        <p className="fw-bold h1 mt-2">
          {I18n.t("views.upsell.stepper.step_one.price_from", {
            price: CurrencyFormatter.formatter(price)
          })}
        </p>
        <div className="mt-1">
          {I18n.t("views.upsell.stepper.step_one.plan_access")}
          <b className="fw-bold">{access}</b>
        </div>
        <div className="d-flex gap-3 mt-4">
          <button
            className="btn btn-primary"
            onClick={handleSelectPlan}
          >
            {I18n.t("views.upsell.stepper.step_one.adjust_plan")}
          </button>
          <button
            className="btn btn-outline-primary"
            onClick={handleRefusePlan}
          >
            {I18n.t("views.upsell.stepper.step_one.not_for_now")}
          </button>
        </div>
      </div>
    </S.Card>
  );
};

export default SuggestionCard;
