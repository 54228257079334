import styled from "styled-components";

export const Fieldset = styled.fieldset`
  align-items: center;
  padding-top: 1.3rem;
  padding-bottom: 1rem;
  ${({ active }) => active && `
    padding-bottom: 0.3rem;
  `}
`;

export const Label = styled.div`
  font-weight: 700;
  .ti {
    padding: 8px;
  }
  .ti-user-share {
    color: #007DFF;
    background-color: #CCE2FB;
    border-radius: 5px;
  }
  .ti-plus, .ti-minus {
    float: right;
    margin-top: -0.3rem;
  }
`;

export const Notification = styled.div`
  background-color: #CCE2FB;
  .form-label {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .form-notification-content {
    width: 100%;
  }
  .ti-bulb {
    color: #007DFF;
    font-size: 1.5rem;
  }
`;
