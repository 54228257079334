import React from "react";

import { useAppState } from "@/components/onboard/AppState";

import TopLogo from "@/components/onboard/TopLogo";
import Selector from "@/components/onboard/Selector";
import OptionButton from "@/components/onboard/OptionButton";
import NavButtons from "@/components/onboard/NavButtons";

const ChooseBusinessModel = () => {
  const appState = useAppState();

  const allowedBusinessModels = [
    {
      key: 1,
      value: "B2B",
      name: I18n.t("views.onboard.business_model_step.options.b2b"),
      imageFile: "b2b.png",
    },
    {
      key: 2,
      value: "B2C",
      name: I18n.t("views.onboard.business_model_step.options.b2c"),
      imageFile: "b2c.png",
    },
  ];

  return (
    <div id="business-model">
      <TopLogo />

      <h1>{I18n.t("views.onboard.business_model_step.title")}</h1>
      <h2>{I18n.t("views.onboard.business_model_step.subtitle")}</h2>

      <Selector
        onSelect={(model) => appState.setBusinessModel(model)}
        checkIfSelected={(model) => model === appState.businessModel}
      >
        {allowedBusinessModels.map(({ key, value, name, imageFile }) => (
          <OptionButton key={key} value={value} name={name} imageFile={imageFile} />
        ))}
      </Selector>

      <NavButtons prev="/objective" next="/segment" />
    </div>
  );
};

export default ChooseBusinessModel;
