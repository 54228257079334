import React from "react";

export default function OptionColor(props) {
  const classes = [ "option-color" ];

  if (props.selected) {
    classes.push("selected");
  }

  return <div
           className={classes.join(" ")}
           onClick={() => props.onSelect(props.value)}
           style={{ backgroundColor: props.value }} />;
}
