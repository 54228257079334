import React from 'react';

import OptimizationStep from './OptimizationStep';
import OptimizationImage from '../OptimizationImage';
import StepSevenDescription from './StepSevenDescription';

import parse from 'html-react-parser';

import { Container, Header } from './styles';

const OptimizationContainer = ({ optimization,  openStep, percentage, image }) => {
  const linkUrl = I18n.t('shared.helpscout.smart_calls_setup');
  const highConvertCta = I18n.t('shared.helpscout.high_convert_cta');
  const editFlowOptions = I18n.t('shared.helpscout.edit_flow_options');
  const chatPersonalization = I18n.t('shared.helpscout.chat_personalization');
  const deviceFlowCustomization = I18n.t('shared.helpscout.device_flow_customization');
  const cardOpen = (number) => {
    return openStep === number;
  };

  return (
    <>
      <Header className="text-center bg-white">
        <div className="d-flex flex-column align-items-center">
          <OptimizationImage img={image} />

          <div className="h3 text-info mt-2 fw-bold text-uppercase">
            {I18n.t("customer_success.optimization_container.optimization_of_your_account")} <span className="optimization-percentage">{percentage}</span>
          </div>
        </div>

        <h1>{I18n.t("customer_success.optimization_container.performance_track")}</h1>

        <p className="mb-0 lead">
          {parse(I18n.t("customer_success.optimization_container.our_marketing_methodology_html"))}
        </p>
      </Header>

      <Container className="container">
        <div className="row d-flex flex-column">
          <OptimizationStep
            number={1}
            opened={cardOpen(1)}
            id={optimization.id}
            checked={optimization.step_one}
            name="step_one"
            key="step_one"
            nextStep="step_two"
            label={I18n.t("customer_success.optimization_container.activation_of_leadster_on_the_website")}
          />

          <OptimizationStep
            number={2}
            opened={cardOpen(2)}
            id={optimization.id}
            checked={optimization.step_two}
            name="step_two"
            key="step_two"
            nextStep="step_three"
            label={I18n.t("customer_success.optimization_container.how_to_create_calls_with_high_conversion_rates")}
            iframeUrl="https://www.youtube.com/embed/tPduuNHAeuo"
            description={I18n.t("customer_success.optimization_container.watch_our_class_and_learn_how_to_create_calls_html", { link: highConvertCta })}
          />

          <OptimizationStep
            number={3}
            opened={cardOpen(3)}
            id={optimization.id}
            checked={optimization.step_three}
            name="step_three"
            key="step_three"
            nextStep="step_four"
            label={I18n.t("customer_success.optimization_container.how_to_generate_more_leads_with_smart_calls")}
            iframeUrl="https://www.youtube.com/embed/EFSpoXt1zrI"
            description={I18n.t("customer_success.optimization_container.apply_smart_calls_html", { link: linkUrl })}
          />

          <OptimizationStep
            number={4}
            opened={cardOpen(4)}
            id={optimization.id}
            checked={optimization.step_four}
            name="step_four"
            key="step_four"
            nextStep="step_five"
            label={I18n.t("customer_success.optimization_container.how_to_qualify_your_leads")}
            iframeUrl="https://www.youtube.com/embed/WG0taidKKHY"
            description={I18n.t("customer_success.optimization_container.we_show_you_how_to_create_a_flow_html", { link: editFlowOptions })}
          />

          <OptimizationStep
            number={5}
            opened={cardOpen(5)}
            id={optimization.id}
            checked={optimization.step_five}
            name="step_five"
            key="step_five"
            nextStep="step_six"
            label={I18n.t("customer_success.optimization_container.how_to_apply_page_ersonalization_to_increase_your_results")}
            iframeUrl="https://www.youtube.com/embed/TQ5EK_Aa6HQ"
            description={I18n.t("customer_success.optimization_container.you_learn_to_approach_your_visitor_html", { link: chatPersonalization })}
          />

          <OptimizationStep
            number={6}
            opened={cardOpen(6)}
            id={optimization.id}
            checked={optimization.step_six}
            name="step_six"
            key="step_six"
            nextStep="step_seven"
            label={I18n.t("customer_success.optimization_container.how_to_chat_relevantly_across_devices")}
            iframeUrl="https://www.youtube.com/embed/JNluHoflMko"
            description={I18n.t("customer_success.optimization_container.use_mobile_and_desktop_personalization_html", { link: deviceFlowCustomization })}
          />

          <OptimizationStep
            number={7}
            opened={cardOpen(7)}
            id={optimization.id}
            checked={optimization.step_seven}
            name="step_seven"
            key="step_seven"
            label={I18n.t("customer_success.optimization_container.how_to_register_the_leads_generated_in_my_system")}
            description={I18n.t("customer_success.optimization_container.we_have_natively_integration")}
            componentDescription={<StepSevenDescription />}
          />
        </div>
      </Container>
    </>
  );
};

export default OptimizationContainer;
