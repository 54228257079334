import { useState } from "react";

const useListCheckable = (currentItemsList = []) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const currentItemsIds = currentItemsList.map((item) => item.id);
  const isAnySelected = isAllSelected || selectedItems.length > 0;
  const isCurrentPageSelected = currentItemsList.some((item) => selectedItems.includes(item.id));

  const isItemChecked = (item) => selectedItems.includes(item.id);

  const clearItemSelection = () => {
    setIsAllSelected(false);
    setSelectedItems([]);
  };

  const selectAllItems = () => {
    setIsAllSelected(true);
    selectAllFromCurrentPage();
  };

  const unselectAllItems = () => setIsAllSelected(false);

  const selectAllFromCurrentPage = () => {
    const updatedSelectedItems = _.uniq([...selectedItems, ...currentItemsIds]);
    setSelectedItems(updatedSelectedItems);
  };

  const unselectAllFromCurrentPage = () => {
    const updatedSelectedItems = selectedItems.filter((id) => !currentItemsIds.includes(id));
    setSelectedItems(updatedSelectedItems);
  };

  const onChangeItemCheck = (item, isChecked) => {
    unselectAllItems();

    if (!isChecked) {
      const updatedSelectedItems = selectedItems.filter((id) => id !== item.id);
      return setSelectedItems(updatedSelectedItems);
    }

    setSelectedItems([...selectedItems, item.id]);
  };

  const onChangeCurrentPageCheck = (event) => {
    unselectAllItems();

    if (event.target.checked) {
      return selectAllFromCurrentPage();
    }

    unselectAllFromCurrentPage();
  };

  return {
    selectedItems,
    clearItemSelection,
    isAllSelected,
    isAnySelected,
    selectAllItems,
    unselectAllItems,
    onChangeItemCheck,
    onChangeCurrentPageCheck,
    isItemChecked,
    isCurrentPageSelected,
  };
};

export default useListCheckable;
