import React from "react";

import useCurrentUserStore from "@/store/currentUserStore";

export default function ObjectiveSidebar() {
  const { applicationName } = useCurrentUserStore((state) => state.currentUser);

  return (
    <>
      <h1>{I18n.t("views.onboard.say_about_leadster", { application_name: applicationName })}</h1>

      <img
        style={{ marginTop: 12, maxWidth: 385 }}
        src={I18n.t("views.onboard.leadster_reviews_image")}
        alt={I18n.t("views.onboard.leadster_reviews_description")}
      />
    </>
  );
}
