import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html, body {
    overscroll-behavior-x: none;
  }
`;

export const Container = styled.div`
  overflow: hidden;

  .rec-dot {
    background-color: var(--tblr-gray-300);
    box-shadow: none;
  }

  .rec-dot_active {
    background-color: var(--tblr-info);
    box-shadow: none;
  }

  .rec-carousel-item {
    display: flex;
    align-items: stretch;
  }

  .BrainhubCarousel__dots {
    .BrainhubCarousel__dot:before {
      width: 8px;
      height: 8px;
      background-color: var(--tblr-gray-400);
    }

    .BrainhubCarousel__dot.BrainhubCarousel__dot--selected:before {
      background-color: var(--tblr-primary);
    }

    .BrainhubCarousel__dot {
      opacity: 1;
      padding: 5px;
    }

    button {
      background-color: transparent;
    }
  }
`;

export const StepSlider = styled.div`
  height: 100vh;
  width: 100vw;
  padding: 1.5rem;
`;

export const ImageLogo = styled.img`
  margin-bottom: 2rem;
  height: 35px;
`;

export const ImageStep = styled.img`
  height: 190px;
`;

export const Button = styled.button.attrs({
  className: "btn btn-lg text-center w-100"
})`
  i {
    margin-right: 8px;
  }
`
