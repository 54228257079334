import React from 'react';

import {
  Card,
  Title,
  Percentage
} from './styles';

const SmallBlock = () => {
  return (
    <Card className="card">
      <div className="card-body">
        <Percentage />
          <Title />
      </div>
    </Card>
  );
};

export default SmallBlock;
