import React from "react";

import ScriptFieldsContext from "./ScriptFieldsContext";

import { MentionsInput, Mention } from "react-mentions";
import { asNumber, guessType } from "react-jsonschema-form/lib/utils";

import Select from "react-select";

import { defaultStyle } from "substyle";

const isMobileSafari =
  typeof navigator !== "undefined" &&
  /iPhone|iPad|iPod/i.test(navigator.userAgent);

const styled = defaultStyle(
  {
    position: "relative",
    overflowY: "visible",

    input: {
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      boxSizing: "border-box",
      backgroundColor: "transparent",
      width: "inherit",
      fontFamily: "inherit",
      fontSize: "inherit",
      letterSpacing: "inherit"
    },

    "&multiLine": {
      input: {
        width: "100%",
        height: "100%",
        bottom: 0,
        overflow: "hidden",
        resize: "none",

        // fix weird textarea padding in mobile Safari (see: http://stackoverflow.com/questions/6890149/remove-3-pixels-in-ios-webkit-textarea)
        ...(isMobileSafari
          ? {
              marginTop: 1,
              marginLeft: -3
            }
          : null)
      }
    }
  },
  ({ singleLine }) => ({
    "&singleLine": singleLine,
    "&multiLine": !singleLine
  })
);

class CustomMentionsInputHOC extends MentionsInput.WrappedComponent {
  constructor(props) {
    super(props);
  }

  openBox() {
    this.props.onChange({
      target: { value: (this.props.value + " @").trim() }
    });
    this.inputRef.focus();
    this.inputRef.click();
  }
}

const CustomMentionsInput = styled(CustomMentionsInputHOC);

export const CustomTextWidget = props => {
  const {
    value,
    readonly,
    disabled,
    autofocus,
    onBlur,
    onFocus,
    options,
    schema,
    formContext,
    registry,
    rawErrors,
    ...inputProps
  } = props;
  inputProps.type = options.inputType || inputProps.type || "text";

  const _onChange = ({ target: { value } }) => {
    return props.onChange(value === "" ? options.emptyValue : value);
  };

  function selectDataForMention(fields) {
    const entries = Object.entries(fields).map(v => ({
      id: v[1],
      display: v[0]
    }))

    const entriesWithoutNullValues = entries.filter((entry) => {
      return !!entry.id;
    });

    return entriesWithoutNullValues;
  }

  let mentionsRef = React.createRef();

  return (
    <ScriptFieldsContext.Consumer>
      {fields => (
        <div className="row p-2 pt-0">
          <CustomMentionsInput
            singleLine={inputProps.type === "text"}
            allowSpaceInQuery={false}
            className="form-control col"
            readOnly={readonly}
            disabled={disabled}
            autoFocus={autofocus}
            value={value == null ? "" : value}
            {...inputProps}
            onChange={_onChange}
            onBlur={
              onBlur && (event => onBlur(inputProps.id, event.target.value))
            }
            onFocus={
              onFocus && (event => onFocus(inputProps.id, event.target.value))
            }
            ref={mentionsRef}
          >
            <Mention
              trigger={/(@(\w*))$/}
              data={selectDataForMention(fields)}
            />
          </CustomMentionsInput>
          <button
            type="button"
            className="personalized-btn-trigger-mention col-auto"
            onClick={() => mentionsRef.current.wrappedInstance.openBox()}
          />
        </div>
      )}
    </ScriptFieldsContext.Consumer>
  );
};

const nums = new Set(["number", "integer"]);

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
function processValue(schema, value) {
  // "enum" is a reserved word, so only "type" and "items" can be destructured
  const { type, items } = schema;
  if (value === "") {
    return undefined;
  } else if (type === "array" && items && nums.has(items.type)) {
    return value.map(asNumber);
  } else if (type === "boolean") {
    return value === "true";
  } else if (type === "number") {
    return asNumber(value);
  }

  // If type is undefined, but an enum is present, try and infer the type from
  // the enum values
  if (schema.enum) {
    if (schema.enum.every(x => guessType(x) === "number")) {
      return asNumber(value);
    } else if (schema.enum.every(x => guessType(x) === "boolean")) {
      return value === "true";
    }
  }

  return value;
}

function getValue(event, multiple) {
  if (multiple) {
    return [].slice
      .call(event.target.options)
      .filter(o => o.selected)
      .map(o => o.value);
  } else {
    return event.target.value;
  }
}

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center"
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span style={{ fontWeight: 700 }}>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

export const CustomSelectWidget = props => {
  const {
    schema,
    id,
    options,
    value,
    required,
    disabled,
    readonly,
    multiple,
    autofocus,
    onChange,
    onBlur,
    onFocus
  } = props;
  const { enumOptions } = options;

  const generateGroupedOptions = dynamicFields => {
    return [
      {
        label: I18n.t("views.integrations.custom_widget.predefined_values"),
        options: enumOptions
      },
      {
        label: I18n.t("views.integrations.custom_widget.user_input"),
        options: Object.entries(dynamicFields).map(v => ({
          value: `@[${v[0]}](${v[1]})`,
          label: v[0]
        }))
      }
    ];
  };

  return (
    <ScriptFieldsContext.Consumer>
      {fields => {
        let parsedValue = value;
        let availableOptions = generateGroupedOptions(fields);
        let allOptions = availableOptions[0].options.concat(
          availableOptions[1].options
        );
        if(schema.showCustomVariables === false){
          availableOptions = [availableOptions[0]]
        }

        if (typeof parsedValue === "string") {
          parsedValue = allOptions.find(k => k.value === parsedValue);
        }

        return (
          <Select
            options={availableOptions}
            id={id}
            isMulti={multiple}
            value={parsedValue}
            required={required}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            isClearable={true}
            isSearchable={true}
            placeholder={I18n.t("views.integrations.custom_widget.select_value")}
            formatGroupLabel={formatGroupLabel}
            onBlur={
              onBlur &&
              (event => {
                const newValue = getValue(event, multiple);
                onBlur(id, processValue(schema, newValue));
              })
            }
            onFocus={
              onFocus &&
              (event => {
                const newValue = getValue(event, multiple);
                onFocus(id, processValue(schema, newValue));
              })
            }
            onChange={event => {
              if (typeof event === "undefined" || event === null) {
                onChange(null);
                return;
              }
              const newValue = event.value;
              let v = allOptions.find(k => k.value === newValue);
              onChange(v === undefined ? null : v.value);
            }}
          />
        );
      }}
    </ScriptFieldsContext.Consumer>
  );
};
