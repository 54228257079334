import React, {useState} from "react";

import axios from "@/lib/axios";
import PropTypes from "prop-types";
import {classBinding} from "@/utils/helpers";

const RetryButton = ({ label, url }) => {
  const [isSearching, setIsSearching] = useState(false);
  const [retrySuccess, setRetrySuccess] = useState(false);

  const sendRetry = () => {
    setIsSearching(true);

    axios.get(url)
      .then(({ data }) => {
        setRetrySuccess(data.retrying);
      })
      .catch(() => {
        swal(
          I18n.t("views.page_rank.rank_table.retry_button.fail"),
          I18n.t("views.page_rank.rank_table.retry_button.fail_attempt")
        );
      })
      .then(() => setIsSearching(false))
  };

  if (url == null) return(<></>);

  if (!retrySuccess) {
    return(
      <button
        type={"button"}
        className={classBinding({"btn-loading disabled": isSearching}, "btn btn-primary btn-sm mb-1")}
        onClick={() => sendRetry()}>
        <i className="ti ti-rotate-clockwise"/> {label}!
      </button>
    );
  }

  return(
    <span className="badge bg-green mb-1 fs-5">
        <i className="ti ti-hourglass-empty"/> {I18n.t("views.page_rank.rank_table.retry_button.attempt")}
      </span>
  );
};

RetryButton.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default RetryButton;
