import styled from "styled-components";

export const LineCheckbox = styled.div`
  input[type="radio"] {
    display: none;

    &:not(:disabled) ~ label {
      cursor: pointer;
    }

    &:disabled ~ label {
      color: hsla(150, 5%, 75%, 1);
      border-color: hsla(150, 5%, 75%, 1);
      box-shadow: none;
      cursor: not-allowed;
    }

    &:checked + label {
      border-color: #3aa9ff;
      border-width: 2px;
      box-shadow: 0 10px 20px rgba(0,0,0,0.10), 0 6px 6px rgba(0,0,0,0.12);

      ::after {
        display: block;
        position: absolute;
        top: -10px;
        right: -10px;
        width: 25px;
        height: 25px;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        content: "✓";
        font-weight: 600;
        color: #fff;
        background: #3169e5;
        border-radius: 50%;
      }
    }
  }

  label {
    display: block;
    box-sizing: border-box;
    font-weight: 500;
    background: white;
    border-radius: 25px;
    padding: 0.5rem 1rem;
    margin-bottom: 1.2rem;
    line-height: 1.4rem;
    border: 1px solid rgba(0, 40, 100, 0.12);
    border-radius: 1.5rem;
    box-shadow: 0px 2px 5px -2px hsla(0, 0%, 0%, 0.25);
    position: relative;
    color: #4b4b4b;

    &:hover {
      box-shadow: 0 10px 20px rgba(0,0,0,0.05), 0 6px 6px rgba(0,0,0,0.12);
    }
  }
`;
