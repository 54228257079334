import CardValidators from "card-validator";
import { cnpj, cpf } from "cpf-cnpj-validator";

import { onlyNumber } from "@/utils/helpers";

export function validateCreditCard(cardNumber) {
  cardNumber = onlyNumber(cardNumber);

  if (cardNumber.length === 0) return;

  let validator = CardValidators.number(cardNumber);

  if (!validator.isValid){
    throw new Error(I18n.t("views.billings.components.cc.validates.number"));
  }
}

export function validateCardHolderName(name) {
  if (name.length === 0) return;

  if (name.split(" ").length < 2) throw new Error(I18n.t("views.billings.components.cc.validates.holder"))

  let validator = CardValidators.cardholderName(name);

  if (name.length < 3 || !validator.isValid){
    throw new Error(I18n.t("views.billings.components.cc.validates.holder_2"));
  }
}

export function validateExpirationDate(date) {
  if (date.length === 0) return;

  let validator = CardValidators.expirationDate(date);

  if (!validator.isValid){
    throw new Error(I18n.t("views.billings.components.cc.validates.expiry"));
  }
}

export function validateCvc(number, cardNumber) {
  if (number.length === 0) return;

  cardNumber = onlyNumber(cardNumber);

  let maxLen = 3;
  let validCard = CardValidators.number(cardNumber);

  if (validCard.card !== null) maxLen = validCard.card.code.size;

  let validator = CardValidators.cvv(number, maxLen);

  if (!validator.isValid){
    throw new Error(I18n.t("views.billings.components.cc.validates.cvc"));
  }
}

export function validateTaxVat(number) {
  number = onlyNumber(number);

  if (number.length === 0) return;

  if (number.length !== 11 && number.length !== 14) throw new Error(I18n.t("views.billings.components.cc.validates.tax_vat"));

  if (number.length === 11 && !cpf.isValid(number)) throw new Error(I18n.t("views.billings.components.cc.validates.tax_vat_cpf"));

  if (number.length === 14 && !cnpj.isValid(number)) throw new Error(I18n.t("views.billings.components.cc.validates.tax_vat_cnpj"));
}

