import { useEffect, useState } from "react";

import { useAssistantContext } from "@/contexts/AssistantContext";

import swal from "sweetalert";

import axios from "@/lib/axios";

const useFetchScripts = () => {
  const { assistant } = useAssistantContext();

  const [scripts, setScripts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchScripts();
  }, []);

  const fetchScripts = () => {
    setIsLoading(true);

    axios
      .get(`/ai/scripts/assistant-scripts/${assistant.id}`)
      .then(({ data }) => setScripts(data.scripts))
      .catch(() => {
        swal(
          I18n.t("shared.messages.request_feedback_alert.error.title"),
          I18n.t("shared.messages.request_feedback_alert.error.message"),
          "error"
        );
      })
      .then(() => setIsLoading(false));
  };

  return { scripts, isLoading };
};

export default useFetchScripts;
