import React, { useState, useCallback, useEffect } from 'react';
import axios from "@/lib/axios";
import swal from 'sweetalert'

const AddFirstBlockMessage = ({ isMobile }) => {
  const [show, setShow] = useState(false);
  const device = isMobile ? 'mobile' : 'desktop';
  const queryParams = `?device=${device}`;

  useEffect(() => {
    axios.get(`show-first-block-button/${queryParams}`).then((response) => {
      const { data } = response;
      setShow(data);
    });
  }, []);

  const addBlock = useCallback(async () => {
    const response = await axios.post(`add-first-block/${queryParams}`)
    await swal("", I18n.t("views.manage_flows.script_editor.first_block_successfully_added"), "success");

    window.location.reload();
  }, []);

  if (show) {
    return (
      <button
        type="button"
        style={{ margin: '0 auto', display: 'block' }}
        className="btn btn-sm btn-outline-primary"
        onClick={addBlock}>
        <i className="ti ti-plus"></i>
        {I18n.t("views.manage_flows.script_editor.add_first_block")}
      </button>
    );
  }

  return null;
};

export default AddFirstBlockMessage;
