import styled from 'styled-components';

export const Item = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #b5b5b6;
  margin-bottom: 10px;
`;

export const Icon = styled.i`
  margin-right: 10px !important;
`;
