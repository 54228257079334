import styled, {keyframes} from 'styled-components';

const moveFromLeft = keyframes`
    from { transform: translateX(-360px); }
    to { transform: translateX(0px); }
`;

export const Header = styled.div`
  background-color: var(--tblr-primary);
  justify-content: space-between;
  color: #FFF;
  padding: 25px;
  width: auto;
  display: flex;
  @media (max-width: 768px) {
    padding: 15px;
  }
`

export const Component = styled.div`
  width: 360px;
  overflow: auto;
  border-radius: 15px;
  position: fixed;
  display: block;
  bottom: 20px;
  left: 20px;
  z-index: 10;
  box-shadow: 0 0 1em #999;
  animation: ${moveFromLeft} 1s;
  @media (max-width: 600px) {
    left: 10px;
    width: calc(100% - 20px);
    bottom: 60px;
  }
`
export const Footer = styled.div`
  padding: 20px;
  padding-left: 25px;
  background-color: #EEE;
  color: #545057;
`

export const Body = styled.div`
  background-color: #FFF;
  color: #484F62;
  padding: 30px 15px 30px 15px;
  p {
    margin-bottom: 1.5em;
  }
`

export const CallToActionBotton = styled.button`
  width: 100%;
  padding: 10px;
  padding-bottom: 8px;
  color: #FFF;
  cursor: pointer;
  background-color: #FFA116;
  border-radius: 25px;
  font-size: 12px;
  font-weight: 400;
  border: 0;
  @media (max-width: 768px) {
    font-size: 10px;
  }
`
export const Icon = styled.img`
  float: left;
  padding: 0;
  width: 3rem;
  height: 3rem;
  margin-right: 15px;
  border: 1px solid rgba(0,0,0,.1);
`

export const Exit = styled.img`
  float: right;
  height: 20px;
  align-self: center;
  margin: auto;
  margin-right: 5px;
`

export const MobileBackgroud = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 5;
    background-color: #000;
    opacity: 20%;
  }
`
