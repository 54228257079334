import React, { useMemo, useReducer } from "react";

import { createContext } from "use-context-selector";
import templateState from "./state";

import reducer from "./reducer";
import { saveData } from "./actions";

const ScriptContext = createContext();

const ScriptContextProvider = ({ payloadState, children }) => {
  const initialState = Object.assign(
    {},
    templateState,
    ...Object.keys(templateState).map(
      (k) => k in payloadState && { [k]: payloadState[k] }
    )
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ScriptContext.Provider
      value={useMemo(() => ({
        state,
        dispatch,
        saveData: () => saveData(state, dispatch),
      }), [state, dispatch])}
    >
      {children}
    </ScriptContext.Provider>
  );
};

export { ScriptContextProvider, ScriptContext };
