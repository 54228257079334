import React, { useEffect } from "react";

import { Stack } from "react-bootstrap";

import CreateNewAssistant from "@/components/leadster_ia/cards/CreateNewAssistant";
import ResponseLimit from "@/components/leadster_ia/subpages/CreateYourAiAssistant/ResponseLimit";
import EmptyAssistantsInfo from "@/components/leadster_ia/cards/EmptyAssistantsInfo";
import AssistantList from "@/components/leadster_ia/cards/AssistantList";
import SkeletonLoader from "./SkeletonLoader";

import GuideModal from "@/components/shared/GuideModal";
import guideContents from "./guideContents";

import axios from "@/lib/axios";

import { useRouteMatch } from "react-router-dom";
import { useAssistantContext } from "@/contexts/AssistantContext";
import useLoadAssistants from "./hooks/useLoadAssistants";
import useCurrentUserStore from "@/store/currentUserStore";

const Home = () => {
  const {
    isAdministrator,
    plugins: { leadsterAi: { isTrialStarted, isTrialing } },
  } = useCurrentUserStore((state) => state.currentUser);

  const { assistants, updateSettings } = useAssistantContext();
  const { isLoading } = useLoadAssistants();
  const { url } = useRouteMatch();

  const startTrialEnabled = !isTrialStarted && !isTrialing && !isAdministrator;

  const startTrialButtonClick = () => {
    if (isAdministrator) {
      return navigateToAssistant();
    }

    axios
      .post("/ai/leadster/start-trial")
      .then(() => navigateToAssistant())
      .catch(() => {
        swal(I18n.t("request_feedback_alert.error.title"), I18n.t("request_feedback_alert.error.message"), "error");
      })
      .then(() => navigateToAssistant());
  };

  const navigateToAssistant = () => {
    window.location.href = `${url.replace(/\/$/, "")}/assistants`;
  };

  useEffect(() => {
    updateSettings({
      title: I18n.t("views.leadster_ai.page.home.title"),
      description: I18n.t("views.leadster_ai.page.home.subtitle"),
    });
  }, []);

  return (
    <div className="w-100 pt-5">
      <GuideModal
        enabled={startTrialEnabled}
        storageKey="leadster-ai-welcome"
        isClosable
        guideContents={guideContents}
        finishButtonText={I18n.t('guide_modal.leadster_ai.onboard.start_trial_btn')}
        finishButtonPosition="center"
        finishCallback={startTrialButtonClick}
      />

      <Stack className="w-100 align-items-start" direction="horizontal" gap={3}>
        <Stack direction="vertical" gap={3} style={{ maxWidth: 330, minWidth: 330 }}>
          <CreateNewAssistant />
          <ResponseLimit />
        </Stack>

        <div className="vr" style={{ minHeight: "260px", alignSelf: "flex-start" }}/>

        <div className="d-flex align-items-center justify-content-center w-100">
          {isLoading ? (
            <SkeletonLoader />
          ) : assistants.length > 0 ? (
            <AssistantList />
          ) : (
            <EmptyAssistantsInfo />
          )}
        </div>
      </Stack>
    </div>
  );
};

export default Home;
