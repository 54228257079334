import * as S from "./styles";

const CardBase = ({
  title,
  children,
  summaryNumber,
}) => {
  return (
    <S.Card className="card">
      <div className="card-header">
        <p className="card-title col fw-bold">
          {summaryNumber}
          <span className="px-3">{title}</span>
        </p>
      </div>
      <div className="card-body">
        {children}
      </div>
    </S.Card>
  );
};

export default CardBase;
