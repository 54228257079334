import styled from 'styled-components';

export const List = styled.div`
  background-color: #ddd;
  width: 360px;
  border-radius: 10px;
  box-shadow: 0 0 1em #999;
  overflow: auto;
  padding: 0px;
  top: 60px;
  right: 0;
  position: absolute;
  z-index: 5;
`

export const MobileList = styled.div`
  background-color: #FFF;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;
  left: 0;
  top: 0;
`

export const MobileListHeader = styled.div`
  padding: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: gray 2px;
  box-shadow: 0 3px 3px #ccc;
`

export const MobileListTitle = styled.div`
  font-size: 25px;
  font-weight: 600;
`

export const MobileItems = styled.div`
  overflow: auto;
  height: 100%;
`
