import React from "react";
import parse from "html-react-parser";

import {
  Block,
  TriangleTopLeft,
  FlexBlock,
  Label,
  Step,
  Image,
} from "./styles";

const ScriptBlockStep = ({ step, active, image, label, ...rest }) => {
  return (
    <Block className="card mb-0" active={active} {...rest}>
      <TriangleTopLeft active={active}>
        <Step>{step}</Step>
      </TriangleTopLeft>

      <FlexBlock>
        <Image src={image} alt={I18n.t("manage_flow.leadster_logo")} />

        <Label className="px-3" active={active}>{parse(label)}</Label>
      </FlexBlock>
    </Block>
  );
};

export default ScriptBlockStep;
