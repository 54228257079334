import React from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { useHistory, useRouteMatch } from "react-router-dom";
import { useAssistantContext } from "@/contexts/AssistantContext";

import { classBinding } from "@/utils/helpers";

const NavBarLink = ({ to, activeOnlyWhenExact, isDisabled, children }) => {
  const { assistant } = useAssistantContext();

  const history = useHistory();
  let { url } = useRouteMatch();

  const isMatchedRoute = useRouteMatch({
    path: url + to,
    exact: activeOnlyWhenExact,
  });

  const handleChangePage = (e) => {
    e.preventDefault();

    if (isDisabled) return;

    history.push(url + to, { id: assistant?.id });
  };

  return (
    <OverlayTrigger
      trigger={isDisabled ? ["hover", "focus"] : false}
      placement="top"
      overlay={
        <Tooltip>
          {I18n.t("views.leadster_ai.page.home.disabled_sidebar_tooltip")}
        </Tooltip>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <a
          href="#"
          disabled={isDisabled}
          onClick={handleChangePage}
          className={classBinding(
            {
              "active text-primary": isMatchedRoute && !isDisabled,
              "cursor-not-allowed text-muted": isDisabled,
            },
            "nav-link text-body fw-medium rounded-0 py-3 fs-3"
          )}
          {...triggerHandler}
        >
          <span ref={ref}>
            {children}
          </span>
        </a>
      )}
    </OverlayTrigger>
  );
};

export default NavBarLink;
