import styled from "styled-components";

export const Container = styled.div.attrs({
  className: "d-flex flex-column align-items-center justify-content-center rounded w-100",
})`
  min-height: 260px;
  background: linear-gradient(90deg, #C1E0FE, #D5FBF9);
`;

export const Icon = styled.img.attrs({
  className: "mb-2",
})`
  border-radius: 50%;
  width: 70px;
  height: 70px;
`;
