import { useState, useEffect } from "react";

import axios from "@/lib/axios";
import swal from 'sweetalert';
import useAnalyticsStore from '@/components/ScriptAnalytics/store';

const useScriptConversionFetcher = () => {
  const { scriptId, currentScriptVersionHash } = useAnalyticsStore((state) => state);

  const [scriptVersionHash, setScriptVersionHash] = useState(currentScriptVersionHash);
  const [allowedScriptVersions, setAllowedScriptVersions] = useState([]);
  const [chartType, setChartType] = useState();
  const [chartData, setChartData] = useState({});
  const [leadsCount, setLeadsCount] = useState("?");
  const [channel, setChannel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const endpoint = `/script/${scriptId}/analytics/allowed-versions-to-select`;

    axios.get(endpoint)
      .then(({ data }) => setAllowedScriptVersions(data.allowed_versions))
      .catch(() => {
        setAllowedScriptVersions([]);
        swal("", I18n.t("views.analytics.error.info_retrieval"), "error")
      })
  }, []);

  useEffect(() => {
    const endpoint = `/script/${scriptId}/analytics/script-conversion`;
    const params = {
      "script_version_hash": scriptVersionHash,
      "filters[channel]": channel,
    };

    setIsLoading(true);

    axios.get(endpoint, { params })
      .then(({ data }) => {
        setChartType(data.chart_type);
        setChartData(data.chart_data);
        setLeadsCount(data.leads_count);
      })
      .catch(() => {
        setChartType("funnel");
        setChartData({});
        setLeadsCount("?");
        swal("", I18n.t("views.analytics.error.info_retrieval"), "error")
      })
      .then(() => setIsLoading(false));
  }, [scriptVersionHash, channel]);

  return {
    chartData,
    chartType,
    leadsCount,
    allowedScriptVersions,
    scriptVersionHash,
    setScriptVersionHash,
    channel,
    setChannel,
    isLoading
  };
};

export default useScriptConversionFetcher;
