import { createContext, useContext } from "react";

const GlobalContext = createContext();

// Manages the entire app state
// The state structure can be created in the constructor
class AppState {
  constructor(storedState) {
    // Navigation
    this.path = "/objective";

    // dev
    this.spinning = true;

    // Sidebar
    this.sidebarAnimated = false;
    this.sidebarHeading = "";
    this.sidebarContent = "";
    this.image_url = "";

    // Configuration
    this.objective = "increase_lead_generation";

    this.businessModel = "B2B";
    this.segment = "Software";

    this.avatar = "https://cdn.leadster.com.br/neurolead/img/avatar/3.png";
    this.name = `Ana - ${storedState.companyName}`;

    // FIXME: maybe start with a real color? Maybe neurologic blue?
    this.color = "#2196F3";
    this.email = "";
    this.tooltip = "";

    this._fromStorageFormat(storedState);
  }

  //
  // Actions
  //

  setPath(path, number) {
    this.path = path;
  }

  setObjective(objective) {
    this.objective = objective;
  }

  setBusinessModel(businessModel) {
    this.businessModel = businessModel;
  }

  setSegment(segment) {
    this.segment = segment;
  }

  setAvatar(avatar, name) {
    this.avatar = avatar;
    this.name = name;
  }

  setName(name) {
    this.name = name;
  }

  setColor(color) {
    this.color = color;
  }

  setEmail(email) {
    this.email = email;
  }

  setTooltip(id) {
    this.tooltip = id;
  }

  setTooltipMessage(message) {
    this.tooltip = message;
  }

  setSpinning(state) {
    this.spinning = state;
  }

  showSidebarMessage(heading, content) {
    this.sidebarAnimated = true;
    this.sidebarHeading = heading;
    this.sidebarContent = content;
  }

  hideSidebarMessages() {
    this.sidebarAnimated = false;
    this.sidebarHeading = "";
    this.sidebarContent = "";
  }

  //
  // Saving/Loading state
  //

  // Save to database
  _toStorageFormat() {
    return { ...this };
  }

  // Restore from database
  _fromStorageFormat(storedObject) {
    for (let key in storedObject) {
      this[key] = storedObject[key];
    }
  }
}

// Update the object methods to call setState() after mutating the state and
// some other details
const autoSetState = (sourceClass) => {
  for (let methodName of Object.getOwnPropertyNames(sourceClass.prototype)) {
    const method = sourceClass.prototype[methodName];

    if (
      !methodName.startsWith("_") &&
      methodName !== "constructor" &&
      methodName.indexOf("tooltip") === -1 &&
      methodName.indexOf("Tooltip") === -1
    ) {
      sourceClass.prototype[methodName] = function (...args) {
        // Clone the state
        const clone = new AppState(this._toStorageFormat());

        // Mutate the cloned state (i.e.: keep old state for future black magic if needed)
        method.apply(clone, args);

        // Change react's state with the store state
        this._setState(clone);
      };
    }
  }
};

// Update the method definitions for AppState
autoSetState(AppState);

function useAppState() {
  const [store, setStore] = useContext(GlobalContext);

  store._setState = setStore;

  return store;
}

export { useAppState, AppState, GlobalContext };
