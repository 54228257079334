import React from "react";

import { FlexBlock, InvisibleBlock, VerticalLine } from "./styles";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';

const VerticalLineDiv = () => {
  const activeComponentIndex = useContextSelector(ScriptContext, ({ state }) => state.activeComponentIndex);

  return (
    <FlexBlock>
      <InvisibleBlock>
        <VerticalLine active={activeComponentIndex === 0} />
      </InvisibleBlock>

      <InvisibleBlock>
        <VerticalLine active={activeComponentIndex === 1} />
      </InvisibleBlock>

      <InvisibleBlock>
        <VerticalLine active={activeComponentIndex === 2} />
      </InvisibleBlock>
    </FlexBlock>
  );
};

export default VerticalLineDiv;
