import React from "react";

import SmartCallsContent from "./SmartCallsContent";
import ScriptCardBlockDesktopAndMobile from "../../ScriptCardBlockDesktopAndMobile";
import ScriptCardFooter from "../../ScriptCardFooter";

import { can } from "@/lib/user-permission";

import parse from "html-react-parser";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const SmartCalls = () => {
  const hasPermission = can(PERMISSION_SCOPE);

  const cardBody = {
    title: `${I18n.t("views.manage_flows.script_editor.smart_calls")}`,
    icon: `ti ti-bulb`,
    description: `${I18n.t("views.manage_flows.script_editor.add_call_variations")}`,
    desktopAndMobileData: {
      sameTabLabel: `${I18n.t("views.manage_flows.script_editor.same_smart_calls")}`,
      diffTabLabel: `${I18n.t("views.manage_flows.script_editor.custom_smart_calls")}`,
      label: `${I18n.t("views.manage_flows.script_editor.smart_calls")}`,
    },
  };

  const cardFooter = {
    title: `${I18n.t("views.manage_flows.script_editor.how_to_apply_smart_calls")}`,
    description: `${I18n.t("views.manage_flows.script_editor.smart_calls_additional_approaches")}`,
    secondDescription: `${I18n.t("views.manage_flows.script_editor.smart_calls_approaches_link_html")}`,
  };

  return (
    <>
      <ScriptCardBlockDesktopAndMobile
        title={cardBody.title}
        icon={cardBody.icon}
        description={cardBody.description}
        customMobileStateKey="hasCustomNotificationsMobile"
        desktopAndMobileData={cardBody.desktopAndMobileData}
        desktopComponent={<SmartCallsContent isMobile={false} />}
        mobileComponent={<SmartCallsContent isMobile={true} />}
        tutorialUrl={I18n.t('shared.helpscout.smart_calls_setup')}
        hasPermission={hasPermission}
      />

      <ScriptCardFooter title={cardFooter.title}>
        <p className="mb-2 tracking-wide">{parse(cardFooter.description)}</p>
        <p className="mb-0 tracking-wide">{parse(cardFooter.secondDescription)}</p>
      </ScriptCardFooter>
    </>
  );
};

export default SmartCalls;
