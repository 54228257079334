import { useState } from "react";

import { useUpsellContext } from "../../../context";
import useAlertDefault from "@/components/shared/AlertDefault/hooks/useAlertDefault";

import axios from "@/lib/axios";

const useLogic = () => {
  const { showAlert } = useAlertDefault((state) => state);
  const { saveProgressData } = useUpsellContext();
  const [isLoadingValidateCode, setIsLoadingValidateCoupon] = useState(false)

  const validateCoupon = async (code) => {
    setIsLoadingValidateCoupon(true);

    try {
      const response = await axios.post(
        `/nothing`, { coupon: code }
      );

      saveProgressData(prev => ({
        ...prev,
        coupomTotalAmount: response.data.coupon
      }));

      showAlert({
        icon: "SUCCESS",
        title: "Cupom adicionado",
        description: `
          O cupom que você adiicionou te dá R$ 30 de deconto
          sobre o valor total de sua compra
        `,
        buttons: [],
        closeButton: {
          text: "Fechar",
        }
      });

    } catch (err) {
      showAlert({
        icon: "WARNING",
        title: "Cupom inválido",
        description: `
          O cupom que você inseriu expirou ou não existe em nossa base de cupons
        `,
        buttons: [],
        closeButton: {
          text: "Fechar",
        }
      });
    }

    setIsLoadingValidateCoupon(false);
  };

  return {
    data: {
      isLoadingValidateCode
    },
    methods: {
      validateCoupon
    }
  };
};

export default useLogic;
