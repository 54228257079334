export const selectAllLabels = () => {
  const fields = window.neurolead.convoScript.script;

  const labels = fields.map((field) => {
    if (field.hasOwnProperty("if")) {
      if (field["then"].hasOwnProperty("save")) {
        return field["then"]["save"];
      }

      if (field["then"].hasOwnProperty("label")) {
        return field["then"]["label"];
      }
    }

    if (field.hasOwnProperty("label")) {
      return field["label"];
    }

    if (field.hasOwnProperty("save")) {
      return field["save"];
    }

    return false;
  });

  return labels.filter((label) => !!label);
};

export const selectNewUniqueLabel = (newLabel) => {
  const allLabels = selectAllLabels();

  if (!allLabels.includes(newLabel)) {
    return newLabel;
  }

  const labelWithoutNumber = newLabel.replace(/[0-9]/g, "").trim();

  const selectUniqueLabel = (counter) => {
    let uniqueLabel = `${labelWithoutNumber} ${counter}`;

    if (allLabels.includes(uniqueLabel)) {
      return selectUniqueLabel(counter + 1);
    }

    return uniqueLabel;
  };

  return selectUniqueLabel(1);
};

export const setBlockUniqueLabel = (block) => {
  if (block.hasOwnProperty("save")) {
    block["save"] = selectNewUniqueLabel(block["save"]);
  }

  if (block.hasOwnProperty("whatsapp")) {
    block["label"] = selectNewUniqueLabel(block["label"]);
  }

  if (block.hasOwnProperty("scheduler")) {
    block["label"] = selectNewUniqueLabel(block["label"]);
  }

  if (block.hasOwnProperty("iaInteraction")) {
    block["label"] = selectNewUniqueLabel(block["label"]);
  }
};
