import styled from "styled-components";


export const NewStyle = styled.div`
  display: flex;
  justify-content: center;
  width: 157px;
  font-size: 0.850rem;
  border-width: 2px;
  border-style: solid;
  border-color: #006CED;
  border-radius: 3px;
  padding: 3px;
  color: #006CED;

  div {
    margin: 0 7px 0 7px;
  }
`
