import React from "react";
import useCurrentUserStore from "@/store/currentUserStore";

export default function BusinessModelSidebar() {
  const { applicationName } = useCurrentUserStore((state) => state.currentUser);
  return (
    <>
      <h1>{I18n.t("views.onboard.business_model_step.sidebar_info", { application_name: applicationName })}</h1>

      <img
        style={{ maxWidth: 320 }}
        src={I18n.t("views.onboard.sidebar_b2c_image")}
        alt={I18n.t("views.onboard.sidebar_b2c_description")}
      />

      <img
        style={{ maxWidth: 320 }}
        src={I18n.t("views.onboard.sidebar_b2b_image")}
        alt={I18n.t("views.onboard.sidebar_b2b_description")}
      />
    </>
  );
}
