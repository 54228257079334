import React from "react";
import PropTypes from "prop-types";

const ChartFooter = ({ values }) => {
  return (
    <div className="chart-footer svg-funnel-js pt-4">
      <div className="svg-funnel-js__labels">
        {values.map((currentVal, key) => {
          const previousValue = _.get(values, (key - 1), values[key]);
          const lostConversion = previousValue - currentVal;
          const percent = ((lostConversion / (previousValue ? previousValue : 1)) * 100 * -1).toFixed(1);

          return (
            <div key={key} className="svg-funnel-js__label funnel-danger">
              <div className="label__value">{lostConversion}</div>
              <div className="label__percentage">{percent}%</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

ChartFooter.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ChartFooter;
