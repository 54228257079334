import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin: 0;
    font-size: 2rem
  }

  span {
    margin: 16px
  }
`

export const IframeSchedule = styled.iframe`
  min-width: 500px;
  max-width: 500px;
  height: 500px;
  overflow: hidden !important;
  overflow-y: auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100px;
  background-image: url('/images/loader.gif');

  @media(max-width: 1366px) {
    height: 585px;
  }
  @media(max-width: 768px) {
    min-width:100%;
    max-width: 100%;
    height: 400px;
  }
`;

export const Button = styled.button.attrs({
  className: "btn btn-lg text-center"
})`
  margin-left: 8px;
  max-height: 40px;
  font-size: 1rem;

  i {
    margin-right: 8px;
  }
`
