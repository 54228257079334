import styled from "styled-components";

export const Container = styled.div`
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-top: none;
  border-radius: 8px;
  background: #f9f6f7;
  padding: 20px 30px;
`;
