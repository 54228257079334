import styled, { keyframes } from 'styled-components';

export const Card = styled.div`
  background: white;
  border-radius: .25rem;
`;

const loading = keyframes`
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.8;
  }
`;

export const Percentage = styled.div`
  background-color: rgba(0, 230, 64, .4);
  width: 10px;
  height: 4px;
  display: block;
  margin: 50px auto;

  animation: ${loading} 1.5s infinite linear;
`;

export const Title = styled.div`
  background-color: #E2E2E2;
  max-width: 50%;
  height: 22px;
  margin-top: 3px;

  animation: ${loading} 1.5s infinite linear;
`;

export const FirstDescription = styled.div`
  background-color: #E2E2E2;
  width: 30%;
  height: 15px;

  animation: ${loading} 1.5s infinite linear;
`;

export const SecondDescription = styled.div`
  background-color: #E2E2E2;
  width: 35%;
  height: 12px;

  animation: ${loading} 1.5s infinite linear;
`;
