import styled from "styled-components";

export const Container = styled.div`
  display: inline-block;

  img {
    height: 24px;
  }

  .cursor-pointer{
    cursor: pointer;
  }

  .list {
    .item {
      width: 190px;
      min-height: 90px;
      box-shadow: 1px 1px 3px #ebebeb;
      border-radius: 5px;
    }
  }
`;
