export default [
  {
    title: I18n.t("guide_modal.leadster_ai.finish.title_01"),
    content: I18n.t("guide_modal.leadster_ai.finish.subtitle_01_html"),
    media: {
      type: I18n.t("guide_modal.leadster_ai.finish.type_01"),
      url: I18n.t("guide_modal.leadster_ai.finish.media_url_01"),
    },
  },
];
