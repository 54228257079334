import React, { useRef } from "react";

import parse from 'html-react-parser';

const InfoModal = () => {
  const modal = useRef()
  const openModal = () => $(modal.current).modal('show');

  return (
    <div className='d-inline-block'>
      <span onClick={openModal} className="form-help ms-2" data-bs-toggle="tooltip" data-bs-placement="top" title={I18n.t("general.notification.click_to_learn_more")}>
        <i className="fa fa-info"></i>
      </span>

      <div ref={modal} className="modal modal-blur fade" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

            <div className="modal-body">
              <h2 className="text-center mb-3">
                {I18n.t("views.dashboard.modal.leads_for_whatsapp.title")}
              </h2>
              {parse(I18n.t("views.dashboard.modal.leads_for_whatsapp.description_html"))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoModal;
