import { Button, Icon } from "./styles";

const TutorialButton = ({ tutorialUrl }) => {
  const openTutorial = () => {
    window.open(tutorialUrl, '_blank');
  };

  return(
      <Button
        type='button'
        className="btn btn-sm btn-info py-1 px-2 ms-2 fw-bold"
        onClick={openTutorial}
      >
        <Icon className="ti ti-playstation-triangle me-1" />
        {I18n.t("views.manage_flows.script_editor.tutorial")}
      </Button>
  );
}

export default TutorialButton;
