import React, { useRef, useState, useEffect } from 'react';

import parse from "html-react-parser";
import axios from "@/lib/axios";

import swal from "sweetalert";
import Carousel, { Dots } from '@brainhubeu/react-carousel';

import '@brainhubeu/react-carousel/lib/style';
import firstSlideImg from "./images/slide-1.png";
import secondSlideImg from "./images/slide-2.png";
import thirdSlideImg from "./images/slide-3.png";

import thirdSlideOneImg from "./images/slide-3.1.png";
import { GlobalStyle, Container, ImageLogo, ImageStep, StepSlider, Button } from "./styles";

const MobileCarousel = ({
  scriptVersion,
  clientScriptUrl,
  qualifiedScheduling,
  scriptScheduling,
  dataLeadScheduling,
  redirectUrl,
}) => {
  const [stepIndex, setStepIndex] = useState(0);

  const [email, setEmail] = useState("");
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const mailInput = useRef();

  useEffect(() => {
    const messageEventListener = (event) => {
      if (event.data.kind == 'finished') {
        sendDataZapier(event.data.data);
      }
    }

    preventHistoryBackSaffary();
    window.addEventListener("message", messageEventListener, false);

    return () => {
      window.removeEventListener("message", messageEventListener);
    }
  }, []);

  useEffect(() => {
    if (stepIndex === 0) {
      loadChatDemo(scriptVersion);
    }
  }, [stepIndex]);

  const checkIsValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const preventHistoryBackSaffary = () => {
    if (window.safari) {
      history.pushState(null, null, location.href);
      window.onpopstate = function(event) {
        history.go(1);
      };
    }
  };

  const clearInputFocus = () => {
    mailInput.current.blur()
  };

  const forwardStep = () => {
    setStepIndex(stepIndex + 1);
    unloadChatDemo();
    clearInputFocus();
  };

  const handleSendEmail = (e) => {
    if (!checkIsValidEmail(email)) {
      setIsInvalidEmail(true);
      swal("", I18n.t("views.onboard.hello.notification.invalid_email"), "error");

      return ;
    }

    axios.post('/implementation/send_activation_email', { email: email })
      .then((response) => {
        swal("", response.data.message, "success");
        forwardStep();
      })
      .catch(() => {
        swal("", I18n.t("views.onboard.hello.notification.error_sending_email"), "error")
      })
      .finally(() => {
        setIsInvalidEmail(false);
        setEmail('');
    });
  };

  const sendDataZapier = (data) => {
    const payload = {
      name: dataLeadScheduling.userName,
      email: dataLeadScheduling.email,
      boot_data: data
    }

    axios.post("/onboard/schedule_onboard_zapier", payload);

    window.postMessage({
      say: I18n.t("views.onboard.hello.scheduling_demo.finished")
    }, "*");

    setTimeout(() => {
      window.location.href = "/"
    }, 5000);
  }

  const loadChatDemo = (script, scheduling = false) => {
    let scriptContent = JSON.parse(script.content);
    scriptContent.alwaysAutoOpen = scheduling;

    if (scheduling) {
      scriptContent = JSON.stringify(scriptContent)
        .replace("nldAPI_Neurologic_PostMessage", "nldAPI_Neurologic_PostMessage_Current_Window");

      scriptContent = JSON.parse(scriptContent);
    }

    window.neuroleadDefaultScript = scriptContent;

    (function(a,b,c,d){try{var e=b.head||b.getElementsByTagName("head")[0];var f=b.createElement("script");f.setAttribute("src",c);f.setAttribute("charset","UTF-8");f.defer=true;a.neuroleadId=d;e.appendChild(f)}catch(g){}})(window,document,clientScriptUrl,"-1")
  }

  const unloadChatDemo = () => {
    if (!window.neurolead) {
      return;
    }

    delete window.neurolead;

    const chatBotElements = window.document.getElementsByClassName('nld-chatbot');
    for (var i = 0; i < chatBotElements.length; ++i) {
      chatBotElements[i].remove();
    }
  }

  return (
    <Container>
      <GlobalStyle />

      <Carousel
        value={stepIndex}
        itemPadding={[10, 50]}
        onChange={(stepIndex) => {
          setStepIndex(stepIndex);
          unloadChatDemo();
        }}
      >
        <StepSlider>
          <div className="d-flex flex-column align-items-center mb-3">
            <ImageLogo src="/images/leadster_logo.svg" alt={I18n.t("shared.actions.leadster_logo")}/>
            <ImageStep src={firstSlideImg} className="img-fluid d-block mb-3" alt={I18n.t("views.onboard.hello.carousel.first_step.alt")} />
            <Dots value={stepIndex} onChange={(stepIndex) => setStepIndex(stepIndex)} number={3} />
          </div>

          <div className="fs-2 fw-bold mb-2">
            {I18n.t("views.onboard.hello.carousel.first_step.title")}
          </div>

          <p className="lead mb-3">
            {I18n.t("views.onboard.hello.carousel.first_step.description")}
          </p>

          <button type="button" className="btn btn-success w-100 btn-lg" onClick={() => forwardStep()}>
            {I18n.t("shared.actions.next")}
          </button>
        </StepSlider>

        <StepSlider>
          <div className="d-flex flex-column align-items-center mb-2">
            <ImageLogo src="/images/leadster_logo.svg" alt={I18n.t("shared.actions.leadster_logo")}/>
            <ImageStep src={secondSlideImg} className="img-fluid d-block mb-3" alt={I18n.t("views.onboard.hello.carousel.second_step.alt")} />
            <Dots value={stepIndex} onChange={(stepIndex) => setStepIndex(stepIndex)} number={3} />
          </div>

          <div className="fs-2 fw-bold mb-3">
            {I18n.t("views.onboard.hello.carousel.second_step.title")}
          </div>

          <label className="form-label fw-normal fs-3">
            {I18n.t("views.onboard.hello.carousel.second_step.description")}
          </label>

          <div className='mb-3'>
            <div className="row g-2">
              <div className="col">
                <input
                  type="email"
                  ref={mailInput}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`form-control form-control-lg fs-3 ${
                    isInvalidEmail ? "is-invalid" : ""
                  }`}
                  placeholder={I18n.t("views.onboard.hello.carousel.second_step.your_email")}
                  onClick={(e) => e.target.focus()}
                />
              </div>

              <button
                type="button"
                className="btn btn-primary btn-lg col-auto"
                onClick={handleSendEmail}
                disabled={!email.length}
              >
                <i className="ti ti-send"></i>
              </button>
            </div>

            {(isInvalidEmail) && (
              <div className="text-danger fs-4 mt-2">
                {I18n.t("views.onboard.hello.notification.invalid_email")}
              </div>
            )}
          </div>

          <button type="button" className="btn btn-success btn-lg w-100" onClick={() => forwardStep()}>
            {I18n.t("shared.actions.next")}
          </button>
        </StepSlider>

        {!qualifiedScheduling ? (
          <StepSlider>
            <div className="d-flex flex-column align-items-center mb-2">
              <ImageLogo src="/images/leadster_logo.svg" alt={I18n.t("shared.actions.leadster_logo")}/>
              <ImageStep src={thirdSlideImg} className="img-fluid d-block mb-3" alt={I18n.t("views.onboard.hello.carousel.third_step.alt")} />
              <Dots value={stepIndex} onChange={(stepIndex) => setStepIndex(stepIndex)} number={3} />
            </div>

            <div className="fs-2 fw-bold mb-2">
              {I18n.t("views.onboard.hello.carousel.third_step.title")}
            </div>

            <p className="lead mb-2">
              {I18n.t("views.onboard.hello.carousel.third_step.description")}
            </p>

            <p className="lead mb-3">
              {I18n.t("views.onboard.hello.carousel.third_step.text")}
            </p>

            <div className="d-grid gap-2">
              <a href={redirectUrl} className="btn btn-success btn-lg w-100" onClick={() => forwardStep()}>
                {I18n.t("views.onboard.hello.carousel.third_step.meet_our_platform")}
                <i className="ti ti-confetti ms-2" />
              </a>
            </div>
          </StepSlider>
        ) : (
          <StepSlider>
            <div className="d-flex flex-column align-items-center mb-2">
              <ImageLogo src="/images/leadster_logo.svg" alt={I18n.t("shared.actions.leadster_logo")}/>
              <ImageStep src={thirdSlideOneImg} className="img-fluid d-block mb-3" alt={I18n.t("views.onboard.carousel.hello.third_step_1.alt")} />
              <Dots value={stepIndex} onChange={(stepIndex) => setStepIndex(stepIndex)} number={3} />
            </div>

            <div className="fs-2 fw-bold d-flex flex-column align-items-center mb-2">
              <strong>{I18n.t("views.onboard.hello.carousel.third_step_1.title")}</strong>
            </div>

            <p className="lead d-flex flex-column align-items-center mb-2">
              {parse(I18n.t("views.onboard.hello.carousel.third_step_1.description_html"))}
            </p>

            <div className="row d-grid gap-2">
              <Button
                className="btn-primary"
                type="button"
                onClick={() => loadChatDemo(scriptScheduling, true)}
              >
                <i className="ti ti-message-2-star" />
                {I18n.t("views.onboard.hello.scheduling_demo.btn_talk_specialist")}
              </Button>

              <Button
                as="a"
                href={redirectUrl}
                className="btn-outline-secondary"
                type="button"
              >
                <i className="ti ti-compass" />
                {I18n.t("views.onboard.hello.scheduling_demo.btn_explore_platform")}
              </Button>
            </div>
          </StepSlider>
        )}
      </Carousel>
    </Container>
  );
};

export default MobileCarousel;
