import React, { useCallback } from "react";
import axios from "@/lib/axios";
import swal from 'sweetalert'

const RevokeAdminAccess = ({url}) => {
  const revokeAdmin = useCallback(async (e) => {

    const willDelete = await swal({
      title: I18n.t("multiple_access.are_you_sure"),
      text: I18n.t("multiple_access.revoke_admin_level_access"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (willDelete) {
      try {
        await axios.post(url);
        MixpanelService.track('multiple_accesses_revoke_admin');
        window.location.reload();
      } catch {
        swal(I18n.t("multiple_access.erro"), I18n.t("multiple_access.it_was_not_possible_to_proceed"), 'error');
      }
    }
  }, []);

  return (
    <button type="button" className="btn btn-sm btn-warning" onClick={revokeAdmin}>
      {I18n.t("multiple_access.revoke_admin")}
    </button>
  );
}

export default RevokeAdminAccess;
