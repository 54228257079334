import React, { useCallback } from "react";
import axios from "@/lib/axios";
import swal from 'sweetalert'

const DisableTwoFactorAuth = ({ label, url }) => {

  const deleteAccount = useCallback(async (e) => {
    const willDelete = await swal({
      title: I18n.t("views.users.edit.2fa_authy.really_want_to_disable"),
      text: I18n.t("views.users.edit.2fa_authy.you_will_lose_a_layer_of_security"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (willDelete) {
      try {
        await axios.delete(url);
      } catch (err) {
        const { response } = err;
        const { data } = response;
        swal(data.message, { icon: "error" });
      }

      window.location.reload();
    }
  }, []);

  return (
    <button type={"button"} className="btn btn-danger fs-3" onClick={deleteAccount}>
      { label }
    </button>
  );
}

export default DisableTwoFactorAuth;
