import React from "react";

const Placeholder = ({
  parent,
  item,
  insert,
  targeted,
  single,
  onDragOver,
  onDrop,
  isOnly,
}) => {
  return (
    <div
      onDrop={(e) => onDrop(e, item, insert)}
      onDragOver={(e) => onDragOver(e, item, insert)}
      className={`placeholder-drop d-flex justify-content-center align-items-center ${targeted ? "targeted" : ""}`}
    >
      <div className="fs-3 text-muted fw-bold">
        {I18n.t("manage_flow.script_editor.drop_the_block_here")}
      </div>
    </div>
  );
};

export default Placeholder;
