import React, { useEffect, useRef } from 'react'
import PropTypes from "prop-types";

import { Container } from './styles';

const getDeviceIndex = () => {
  const filterMobileVal = $("input[name=mobile]:checked").val();

  if (filterMobileVal === 'true') return 'mobile';
  if (filterMobileVal === 'false') return 'desktop';

  return 'general';
}

const BarComparison = ({ segment, dataIndex, value, conversionAnalytics }) => {
  const segmentBar = useRef();
  const clientBar = useRef();
  const deviceIndex = getDeviceIndex();

  const segmentValue = _.get(conversionAnalytics, `${segment}.${dataIndex}.${deviceIndex}`, 0);

  useEffect(() => {
    const segmentBarValue = 25 + (segmentValue * 2);
    const clientBarValue = segmentBarValue + (((((value / segmentValue) * 100) - 100) / 100) * segmentBarValue);

    segmentBar.current.style.width = `${segmentBarValue}%`;
    clientBar.current.style.width = `${Math.min(Math.max(clientBarValue, 8), 75)}%`;
  }, [segment, value]);

  return (
    <Container>
      <div className="mb-2">
        <div className="text-primary fw-medium mb-1">
          {I18n.t(`shared.segment.${segment}`)}
        </div>
        <div className="d-flex flex-col align-items-center">
          <div className="progress me-2" ref={segmentBar}>
            <div className="progress-bar bg-primary w-100"></div>
          </div>
          <div className="fw-bold text-primary">
            {segmentValue}%
          </div>
        </div>
      </div>

      <div>
        <div className="d-flex flex-col align-items-center">
          <div className="progress me-2" ref={clientBar}>
            <div className="progress-bar bg-info w-100"></div>
          </div>
          <div className="fw-bold text-info">
            {value}%
          </div>
        </div>
        <div className="text-info fw-medium mt-1">
          {I18n.t("views.dashboard.comparison_with_segment.text_2")}
        </div>
      </div>
    </Container>
  )
}

BarComparison.propTypes = {
  segment: PropTypes.string.isRequired,
  dataIndex: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  conversionAnalytics: PropTypes.object.isRequired,
};

export default BarComparison;
