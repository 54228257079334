import React from "react";

import parse from "html-react-parser";

import * as S from "./styles";

import animatedCoffeeGif from "@/../assets/images/ai_assistant/hot-cup-of-coffee-animated.gif";

const EmptyAssistantsInfo = () => {
  return (
    <S.Container>
      <S.Icon src={animatedCoffeeGif} alt="icon" />

      <div className="h4">
        {I18n.t("views.leadster_ai.page.home.work_hours")}
      </div>

      <p>
        {parse(I18n.t("views.leadster_ai.page.home.click_create_assistant_html", {
          link_text: I18n.t("views.leadster_ai.page.home.create_new_assistant"),
        }))}
      </p>
    </S.Container>
  );
};

export default EmptyAssistantsInfo;
