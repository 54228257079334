import React, { useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";

const MapLeadModal = ({ lat, lng, width, height }) => {
  const mapContainerRef = useRef(null);

  const initMap = async () => {
    if (!mapContainerRef.current) return;

    const loader = new Loader({
      apiKey: process.env.GOOGLE_MAPS_API,
      version: "weekly",
    });

    try {
      await loader.load();
      const { Map } = await window.google.maps.importLibrary("maps");

      const myLatLng = { lat, lng };
      const map = new Map(mapContainerRef.current, {
        zoom: 12,
        center: myLatLng,
        disableDefaultUI: true,
        mapId: "LEAD_MAP_ID",
      });

      const marker = await showMarker(map);
    } catch (error) {}
  };

  const showMarker = async (map) => {
    const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
    return new AdvancedMarkerElement({
      map,
      position: { lat, lng },
    });
  };

  useEffect(() => {
    initMap();

    return () => {
      const map = mapContainerRef.current;
      if (map) {
        map.remove();
      }
    };
  }, [lat, lng]);

  return (
    <div ref={mapContainerRef} style={{ height: height, width: width }} />
  );
};

export default MapLeadModal;
