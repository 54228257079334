import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import RetryButton from "@/components/PageRankUrls/RankTable/PageData/RetryButton";

const ConversionCallAlert = ({ callStatus, errorInline }) => {
  const messagePending = I18n.t("views.chatgpt.info.pending_generate_call");

  const messageAlert = () => {
    return errorInline.message_html;
  }

  if (callStatus === "pending") {
    return (<div>{messagePending} ⌛</div>)
  } else {
    return(
      <div style={{fontSize: 12}}>
        {parse(messageAlert())}

        <RetryButton
          label={errorInline.button_label}
          url={errorInline.help_url}/>
      </div>
    )
  }
}

ConversionCallAlert.propTypes = {
  callStatus: PropTypes.string.isRequired,
  errorInline: PropTypes.object.isRequired,
};

export default ConversionCallAlert;
