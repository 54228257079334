import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(90deg, #FFFFFF 0%, #D9F0FF 100%);
    padding: 16px;
    min-height: 640px;
`;

export const Icon = styled.img`
    width: 70px;
    height: 70px;
    border-radius: 50%;
`;

export const Paragraph = styled.p`
    max-width: 415px;
`;
