import { useState } from "react";

import { Button } from "react-bootstrap";
import StartNewTrialModal from "@/components/leadster_ia/shared/StartNewTrialModal";

const StartNewTrialButton = ({ variant = "primary" }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);

  return(
    <>
      <Button variant={variant} onClick={handleOpenModal}>
        <span>{I18n.t("views.leadster_ai.page.response_limit.allowed_trial.text_4")}</span>
      </Button>

      <StartNewTrialModal
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default StartNewTrialButton;
