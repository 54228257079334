import { useRef, useState } from "react";

import { Overlay, Tooltip } from "react-bootstrap";

const DragAndDropButton = ({ onDragStart, onDragEnd }) => {
  const [show, setShow] = useState(false);
  const target = useRef();

  const showTooltip = () => setShow(true);
  const hideTooltip = () => setShow(false);

  return (
    <>
      <button
        ref={target}
        type="button"
        className="btn btn-icon cursor-move me-2 d-none d-md-block col-auto"
        draggable={true}
        onMouseOver={showTooltip}
        onMouseOut={hideTooltip}
        onMouseDown={hideTooltip}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <i className="ti ti-menu-order icon" />
      </button>

      <Overlay target={target.current} show={show} transition={false} placement="top">
        {(props) => (
          <Tooltip {...props}>{I18n.t("views.manage_flows.script_editor.move_block")}</Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default DragAndDropButton;
