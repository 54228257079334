import { useState, useRef } from "react";

import { Form } from "react-bootstrap";

import Characters from "./components/Characters";

const GroupUrls = ({ group, onChange }) => {
  const itens = useRef(null);
  const [checkAll, setCheckAll] = useState(true);

  // TODO: Refactor this function
  const handleCheckAll = () => {
    setCheckAll(!checkAll);
    itens.current.querySelectorAll("input[type=checkbox]").forEach((checkbox) => {
      if (checkbox.disabled) return;

      checkbox.checked = checkAll;

      if (checkAll) {
        checkbox.parentElement.parentElement.classList.add("active");
      }
    });
    onChange();
  };

  return (
    <div className="list-group-item py-2 text-dark" ref={itens}>
      <div className={`row align-items-center ${group.group_urls.length == 1 ? "d-none" : ""}`}>
        <div className="col-auto d-flex justify-content-between">
          <Form.Label className="fs-3 my-2 fw-bold">
            {I18n.t("views.leadster_ai.page.add_content.add_links.subdirectory")}{" "}
            <div className="text-primary d-inline-block">/{group.group_name}</div> ({group.group_urls.length}{" "}
            {I18n.t("shared.actions.pages")})
          </Form.Label>
        </div>
      </div>
      <div className={`row align-items-center ${group.group_urls.length == 1 ? "d-none" : ""}`}>
        <div className="col-auto d-flex justify-content-between">
          <Form.Check
            type="checkbox"
            label={I18n.t("views.leadster_ai.page.add_content.add_links.uncheck_all")}
            className="mt-2"
            defaultChecked={checkAll}
            onChange={handleCheckAll}
          />
        </div>
      </div>
      {group.group_urls.map((groupUrls, key) => (
        <div className="row align-items-center" key={key}>
          <div className="col-auto d-flex justify-content-between w-100">
            <Form.Check
              key={groupUrls.id}
              className="mt-2 d-inline-block text-truncate"
              type="checkbox"
              label={groupUrls.url}
              defaultChecked={checkAll}
              onChange={onChange}
              style={{ maxWidth: "calc(100% - 10em)" }}
            />
            <Characters
              value={groupUrls.characters}
              alreadyInUse={groupUrls.already_in_use}
              createdAt={groupUrls.created_at}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default GroupUrls;
