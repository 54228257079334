import React from 'react';
import {
  Component,
  Avatar,
  Header,
  Body,
  Title,
  Decoration,
  Date,
} from './styles'

import {notificationClick} from '../../notification_events';

const MobileItem = ({notification}) => {
  return (
    <Component
      style={{
        boxShadow: (notification.clicked ? "0" : "0 5px 5px #ccc" ),
        opacity: (notification.clicked ? 0.5 : 1),
      }}
      onClick={ () => notificationClick(notification, "list") }
    >
      <Decoration
        style={{ backgroundColor: (notification.clicked ? "white" : "var(--tblr-primary)")}}
      ></Decoration>
        <Avatar
          className="rounded-circle"
          src={notification.icon}
        ></Avatar>

      <Body>
        <Title> { notification.title } </Title>
        { notification.mobileText }
        <a style={{marginLeft: '5px'}} href={notification.link}>
          { notification.callToAction }
        </a>
        <Date>{ notification.createdAt }</Date>
      </Body>
    </Component>
  )
}

export default MobileItem;

