import React from "react";

import { ImageStep, CardContainer } from "./styles";

const Second = () => {
  return (
    <div
      className="card card-sm shadow-sm col"
      data-bs-toggle="popover"
      data-bs-placement="bottom"
      data-bs-html="true"
      data-bs-trigger="hover"
      data-bs-content={`<div class="fw-medium">
        ${I18n.t("views.onboard.partials.steps.second.you_created_your_first_script")}
      </div>`}
    >
      <div className="d-flex">
        <CardContainer className="col-7 col-lg-6 p-3 pe-1 h-100">
          <span className="bg-success text-white avatar avatar-rounded shadow mb-3">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M5 12l5 5l10 -10"></path>
            </svg>
          </span>

          <div className="fs-3 fw-bold text-nowrap">
            {I18n.t("views.onboard.partials.steps.second.first_script_created.text01")}<br/>
            {I18n.t("views.onboard.partials.steps.second.first_script_created.text02")}
          </div>
        </CardContainer>

        <ImageStep className="col-5 col-lg-6" />
      </div>
    </div>
  );
};

export default Second;
