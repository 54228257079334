import React from "react";

import { Stack } from "react-bootstrap";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import AssistantProvider from "@/contexts/AssistantContext";

import Header from "@/components/leadster_ia/Header";
import Footer from "@/components/leadster_ia/Footer";

import HomeAiAssistant from "./subpages/Home";
import CreateYourAiAssistant from "./subpages/CreateYourAiAssistant";
import Analytics from "./subpages/Analytics";
import ErrorPage from "../ErrorPage";

const AiAssistant = () => {
  let { path } = useRouteMatch();

  const matchWithHomePage = useRouteMatch({
    path: "/ai/leadster",
    exact: true,
  });

  return (
    <AssistantProvider>
      <Stack gap={3}>
        <Header />

        <div className="d-flex" style={{ minHeight: "56vh" }}>
          <Switch>
            <Route exact path={path} component={HomeAiAssistant} />
            <Route path={`${path}/assistants/:assistantId?`} component={CreateYourAiAssistant} />
            <Route path={`${path}/analytics/:assistantId?`} component={Analytics} />
            <Route path="*" component={ErrorPage} />
          </Switch>
        </div>

        {matchWithHomePage && <Footer />}
      </Stack>
    </AssistantProvider>
  );
};

export default AiAssistant;
