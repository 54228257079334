import { useState } from "react";

import ModalCreditCard from "@/components/billing/Modals/ModalCreditCard";

const ButtonCreateCc = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);

  return(
    <>
      <button className="billing-btn" onClick={handleOpenModal}>
        {I18n.t("billing.plans.credit_card")}
      </button>

      <ModalCreditCard
        title={I18n.t("billing.modal.payment.title")}
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default ButtonCreateCc;
