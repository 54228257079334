import { useRef } from "react";

import { captureException } from "@sentry/react";

const OAuthPopup = ({ title, url, width, height, onClose, onCode, children }) => {
  let code = null;
  const externalWindow = useRef(null);
  const checkInterval = useRef(null);

  const createPopup = () => {
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;

    externalWindow.current = window.open(
      url,
      title,
      `width=${width},height=${height},left=${left},top=${top}`
    );

    const parseQueryString = () => {
      try {
        const query = decodeURIComponent(externalWindow.current.location.search.slice(1));
        const partes = query.split("&");
        const data = {};
        partes.forEach(parte => {
          const keyValue = parte.split("=");
          var key = keyValue[0];
          var value = keyValue[1];
          data[key] = value;
        });
        return data;
      } catch (e) {
        return {};
      }
    }

    checkInterval.current = setInterval(() => {
      try {
        const params = new URLSearchParams(externalWindow.current.location.search);
        code = params.get('code') || params.get('data[code]') || null;

        if (!!code) {
          const query = parseQueryString();
          onCode(code, query);
          externalWindow.current.close();
        }

        if (externalWindow.current.closed) {
          clearInterval(checkInterval.current);
        }
      } catch (error) {
        if (error.name === "SecurityError" && error.message.includes("Blocked a frame with origin")) {
          // TODO - Update this component to avoid this security error
        } else {
          captureException(error);
          externalWindow.current.close();
          clearInterval(checkInterval.current);
        }
      }
    }, 400);
  }

  return (
    <div onClick={createPopup}>{children}</div>
  );
};

OAuthPopup.defaultProps = {
  width: 500,
  height: 500,
  url: "",
  title: "",
  onClose: () => { }
};

export default OAuthPopup;
