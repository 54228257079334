import React from "react";
import { Card } from "react-bootstrap";

import NavBarLink from "./components/NavBarLink/NavBarLink";

const NavBar = ({ routes, icons }) => {
  return (
    <div className="card nav flex-column nav-pills" style={{ minWidth: 340, maxWidth: 340 }}>
      {routes.map((route, index) => (
        <NavBarLink key={index} to={route.path} activeOnlyWhenExact={route.path === ""}>
          {icons[index] ? icons[index] : ''} {route.name}
        </NavBarLink>
      ))}
    </div>
  )
}

export default NavBar;
