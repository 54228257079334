import posed, { PoseGroup } from 'react-pose';
import { createBrowserHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';

import StepTwo from './components/StepTwo';
import StepOne from "./components/StepOne";
import StepThree from './components/StepThree';
import Navigation from './components/Navigation';
import AlertDefault from '@/components/shared/AlertDefault';

import UpsellStepperProvider from './context';

const history = createBrowserHistory();

const UpsellStepper = () => {
  const RouteContainer = posed.div({
    enter: { opacity: 1, delay: 300, beforeChildren: true },
    exit: { opacity: 0 },
  });

  return (
    <UpsellStepperProvider>
      <Router history={history}>
        <Route
          render={({ location }) => (
            <>
              <Navigation />
              <PoseGroup>
                <RouteContainer key={location.pathname}>
                  <Switch location={location}>
                    <Route
                      exact
                      path="/upsell/"
                      component={StepOne}
                    />
                    <Route
                      exact
                      path="/upsell/overview"
                      component={StepTwo}
                    />
                    <Route
                      exact
                      path="/upsell/order"
                      component={StepThree}
                    />
                  </Switch>
                </RouteContainer>
              </PoseGroup>
            </>
          )}
        />
      </Router>

      <AlertDefault />
    </UpsellStepperProvider>
  );
};

export default UpsellStepper;
