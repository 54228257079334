import { useState } from "react";

import HelpPopover from "@/components/shared/HelpPopover";
import useUpdateEffect from "@/hooks/useUpdateEffect";

const AdvancedSettings = ({ definition, updateDefinition }) => {
  const [incrementTime, setIncrementTime] = useState(definition.increment_time);
  const [maxBookingDays, setMaxBookingDays] = useState(definition.max_booking_days);
  const [toleranceTime, setToleranceTime] = useState(definition.tolerance_time);
  const [useBreakBefore, setUseBreakBefore] = useState(definition.use_break_before);
  const [timeBreakBefore, setTimeBreakBefore] = useState(definition.time_break_before);
  const [useBreakAfter, setUseBreakAfter] = useState(definition.use_break_after);
  const [timeBreakAfter, setTimeBreakAfter] = useState(definition.time_break_after);

  useUpdateEffect(() => {
    updateDefinition({
      increment_time: incrementTime,
      max_booking_days: maxBookingDays,
      tolerance_time: toleranceTime,
      use_break_before: useBreakBefore,
      time_break_before: timeBreakBefore,
      use_break_after: useBreakAfter,
      time_break_after: timeBreakAfter,
    });
  }, [
    incrementTime,
    maxBookingDays,
    toleranceTime,
    useBreakBefore,
    timeBreakBefore,
    useBreakAfter,
    timeBreakAfter,
  ]);

  return (
    <fieldset className="form-fieldset mt-3">
      <div className="row g-3 mb-3">
        <div className="form-group col-lg-4 col-md-6">
          <div className="form-label">
            {I18n.t("views.manage_flows.scheduler_block.available_times_frequency")}
            <HelpPopover
              className="ms-2"
              content={I18n.t("views.manage_flows.scheduler_block.availability_start_intervals")}
              placement="top"
            />
          </div>

          <select
            className="form-select"
            value={incrementTime}
            onChange={(e) => setIncrementTime(e.target.value)}
          >
            {[15, 20, 30, 45, 60].map((minutes) => {
              return (
                <option value={minutes} key={minutes}>
                  {I18n.t("views.manage_flows.scheduler_block.minutes", { count: minutes })}
                </option>
              );
            })}
          </select>
        </div>

        <div className="form-group col-lg-4 col-md-6">
          <div className="form-label">
            {I18n.t("views.manage_flows.scheduler_block.max_booking_days_label")}
            <HelpPopover
              className="ms-2"
              content={I18n.t("views.manage_flows.scheduler_block.max_booking_days_hint")}
              placement="top"
            />
          </div>

          <select
            className="form-select"
            value={maxBookingDays}
            onChange={(e) => setMaxBookingDays(e.target.value)}
          >
            {[7, 14, 30, 45, 60, 90].map((days) => {
              return (
                <option value={days} key={days}>
                  {I18n.t("views.manage_flows.scheduler_block.days", { count: days })}
                </option>
              );
            })}
          </select>
        </div>

        <div className="form-group col-lg-4 col-md-6">
          <div className="form-label">
            {I18n.t("views.manage_flows.scheduler_block.tolerance_label")}
            <HelpPopover
              className="ms-2"
              content={I18n.t("views.manage_flows.scheduler_block.tolerance_hint")}
              placement="top"
            />
          </div>

          <select
            className="form-select"
            value={toleranceTime}
            onChange={(e) => setToleranceTime(e.target.value)}
          >
            {[15, 30, 45].map((minutes) => {
              return (
                <option value={minutes} key={minutes}>
                  {I18n.t("views.manage_flows.scheduler_block.minutes", { count: minutes })}
                </option>
              );
            })}

            {[60, 120, 240, 480, 720].map((minutes) => {
              return (
                <option value={minutes} key={minutes}>
                  {I18n.t("views.manage_flows.scheduler_block.hours", { count: minutes / 60 })}
                </option>
              );
            })}

            {[1440, 2880].map((minutes) => {
              return (
                <option value={minutes} key={minutes}>
                  {I18n.t("views.manage_flows.scheduler_block.days", { count: minutes / 1440 })}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="row g-3">
        <div className="form-group">
          <div className="form-label">
            {I18n.t("views.manage_flows.scheduler_block.add_time_before_after_events")}
            <HelpPopover
              className="ms-2"
              content={I18n.t("views.manage_flows.scheduler_block.block_time_around_events")}
              placement="top"
            />
          </div>

          <div className="row g-3">
            <div className="col-md-4">
              <label className="form-check form-switch d-flex">
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  checked={useBreakBefore}
                  onChange={(e) => setUseBreakBefore(e.target.checked)}
                />

                <span className="form-check-label">
                  {I18n.t("views.manage_flows.scheduler_block.before_event")}
                </span>
              </label>

              <select
                className="form-select"
                value={timeBreakBefore}
                disabled={!useBreakBefore}
                onChange={(e) => setTimeBreakBefore(e.target.value)}
              >
                {[5, 10, 15, 30, 45, 60].map((minutes) => {
                  return (
                    <option value={minutes} key={minutes}>
                      {I18n.t("views.manage_flows.scheduler_block.minutes", { count: minutes })}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-md-4">
              <label className="form-check form-switch d-flex">
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  checked={useBreakAfter}
                  onChange={(e) => setUseBreakAfter(e.target.checked)}
                />

                <span className="form-check-label">
                  {I18n.t("views.manage_flows.scheduler_block.after_event")}
                </span>
              </label>

              <select
                className="form-select"
                value={timeBreakAfter}
                disabled={!useBreakAfter}
                onChange={(e) => setTimeBreakAfter(e.target.value)}
              >
                {[5, 10, 15, 30, 45, 60].map((minutes) => {
                  return (
                    <option value={minutes} key={minutes}>
                      {I18n.t("views.manage_flows.scheduler_block.minutes", { count: minutes })}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default AdvancedSettings;
