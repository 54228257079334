import React, { useRef, useState } from "react";

import useAnalyticsStore from '@/components/ScriptAnalytics/store';

import ModalLeadView from "@/components/lead/ModalLeadView"
import QualificationIcon from "./QualificationIcon"
import ReactPortal from '@/components/ReactPortal';

import axios from "@/lib/axios";
import swal from "sweetalert";

import { classBinding } from "@/utils/helpers";

import { TableContainer } from "./styles";

const ModalListLeads = ({ blockData, phoneNumber, period, channel }) => {
  const { scriptId } = useAnalyticsStore((state) => state);

  const [listLeads, setListLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const modalLeadsRef = useRef();

  const fetchLeads = () => {
    const endpoint = `/script/${scriptId}/analytics/whatsapp-list-leads`;

    const params = {
      phone: phoneNumber,
      block_uid: blockData.uid,
      block_version_hash: blockData.version_hash,
      "filters[period]": period,
      "filters[channel]": channel,
    };

    setIsLoading(true);

    axios.get(endpoint, { params })
      .then(({ data }) => setListLeads(data.leads))
      .catch(() => {
        setListLeads([]);
        swal(I18n.t("views.analytics.error.ops"), I18n.t("views.analytics.error.info_retrieval"), "error");
      }).then(() => setIsLoading(false))
  }

  const handleClickShowLeads = () => {
    $(modalLeadsRef.current).modal("show");
    fetchLeads();
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-pill btn-sm btn-primary"
        onClick={handleClickShowLeads}
      >
        {I18n.t("views.analytics.modal_leads.show_leads")}
      </button>

      <ReactPortal>
        <div ref={modalLeadsRef} className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false">
          <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div className={classBinding({ "content-loading": isLoading }, "modal-content")}>
              <div className="modal-header">
                <h5 className="modal-title">
                  {I18n.t("views.analytics.modal_leads.leads_forwarded")}{" "}
                  <strong>{phoneNumber}</strong>
                </h5>

                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>

              <TableContainer>
                <table className="table table-striped table-bordered align-middle">
                  <thead className='text-center'>
                    <tr>
                      <th style={{ width: "25%" }}>{I18n.t("views.analytics.modal_leads.table.data")}</th>
                      <th style={{ width: "20%" }}>{I18n.t("views.analytics.modal_leads.table.flow")}</th>
                      <th style={{ width: "10%" }}>{I18n.t("views.analytics.modal_leads.table.created_at")}</th>
                      <th style={{ width: "10%" }}>{I18n.t("views.analytics.modal_leads.table.type")}</th>
                      <th style={{ width: "10%" }}>{I18n.t("views.analytics.modal_leads.table.qualification")}</th>
                      <th style={{ width: "10%" }}>{I18n.t("views.analytics.modal_leads.table.actions")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {listLeads.map((lead) => (
                      <tr key={lead.id}>
                        <td>{lead.preview_data}</td>
                        <td>{lead.script_name}</td>
                        <td className="text-center">{lead.created_at}</td>
                        <td className="text-center">
                          {lead.complete ? (
                            <span className="badge fs-5 bg-green">
                              <i className="ti ti-square-check icon align-text-bottom" />
                              {I18n.t("views.analytics.modal_leads.complete")}
                            </span>
                          ) : (
                            <span className="badge fs-5 bg-muted-lt text-body">
                              <i className="ti ti-square icon align-text-bottom"></i>
                              {I18n.t("views.analytics.modal_leads.incomplete")}
                            </span>
                          )}
                        </td>
                        <td className="text-center">
                          <QualificationIcon score={lead.score} />
                        </td>
                        <td className="text-center">
                          {!lead.deleted_at && <ModalLeadView id={lead.id} />}
                        </td>
                      </tr>
                    ))}

                    {!listLeads.length && (
                      <tr>
                        <td colSpan="6" className="text-center">
                          {I18n.t("views.analytics.modal_leads.no_records_found")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </TableContainer>

              <div className="text-end small text-muted px-4">
                {I18n.t("views.analytics.modal_leads.limited_to_last_50_leads")}
              </div>

              <div className="modal-footer py-2">
                <button type="button" className="btn" data-bs-dismiss="modal">
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactPortal>
    </>
  )
}

export default ModalListLeads;
