import React, { useState, useEffect, useRef } from 'react';

import Item from './item';
import MobileItem from './mobile_item';

import {
  List,
  MobileList,
  MobileListHeader,
  MobileListTitle,
  MobileItems,
 } from './styles'

 import { setListNotificationsOpening } from '../notification_events'

const setFalseOutside = (ref, setFunction) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setFunction(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Notifications = ({ notifications, listNotificationsCount, dateTime }) => {
  const [showBadge, setShowBadge] = useState(!!notifications);
  const [showList, setShowList] = useState(false);
  const isMobile = window.innerWidth <= 600;
  const wrapperRef = useRef(null);

  setFalseOutside(wrapperRef, setShowList);

  const toggleNotification = (e) => {
    e.preventDefault();

    if(showBadge) { setListNotificationsOpening(dateTime); }
    MixpanelService.track("notificantion_open_list", {});
    setShowBadge(false);
    setShowList(!showList);
  }

  const calcHeight = (count) => {
    const calcLength = count <= 0 ? 0 : 10*(count-1)+185*(count);
    return Math.min(calcLength, 470)+"px";
  }

  return (
    <div ref={wrapperRef}>
      <a href="#" className="nav-link px-0" tabIndex="-1" aria-expanded="false" onClick={toggleNotification} >
        <i className="ti ti-bell text-primary icon"></i>
        { showBadge && (listNotificationsCount != 0) && (<span className="badge bg-danger fs-6">{listNotificationsCount}</span>) }
      </a>

      { !isMobile && showList && (
        <List style={{ height: calcHeight(notifications.length) }}>
          { notifications.map(
            (i) => (<Item notification={i} key={i.id} ></Item>)
          )}
        </List>
      )}

      { isMobile && showList && (
        <MobileList>
          <MobileListHeader>
            <MobileListTitle>
              {I18n.t("customer_success.notifications")}
            </MobileListTitle>
            <img src='/images/notification/gray_exit.svg' onClick={() => setShowList(false)} />
          </MobileListHeader>

          <MobileItems>
            { notifications.map(
              (i) => (<MobileItem notification={i} key={i.id}></MobileItem>)
            )}
          </MobileItems>
        </MobileList>
      )}
    </div>
  );
}

export default Notifications;
