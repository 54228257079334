import React, { useEffect } from "react"

import parse from "html-react-parser";
import axios from "@/lib/axios";

import { IframeSchedule, ModalContent } from "./styles";

const ScheduleDemo = ({ dataLeadScheduling, scheduleDemoUrl }) => {
  useEffect(() => {
    const messageEventListener = (event) => {
      if (event.data.kind == 'finished') {
        sendDataZapier(event.data.data)
      }
    }

    window.addEventListener("message", messageEventListener, false);

    return () => {
      window.removeEventListener("message", messageEventListener);
    }
  }, [])

  const sendDataZapier = (data) => {
    const payload = {
      name: dataLeadScheduling.userName,
      email: dataLeadScheduling.email,
      boot_data: data
    }

    axios.post("/onboard/schedule_onboard_zapier", payload);

    document.getElementById("chat-scheduling").contentWindow.postMessage({
      say: I18n.t("views.onboard.hello.scheduling_demo.finished")
    }, "*");

    setTimeout(() => {
      window.location.href = "/"
    }, 5000);
  }

  return (
    <ModalContent>
      <h1 className="text-primary">{I18n.t("views.onboard.hello.scheduling_demo.modal.title")}</h1>
      <h1 className="text-primary">{I18n.t("views.onboard.hello.scheduling_demo.modal.title_01")}</h1>
      <span>{parse(I18n.t("views.onboard.hello.scheduling_demo.modal.text_html"))}</span>
      <IframeSchedule
        id='chat-scheduling'
        src={scheduleDemoUrl}
        className="w-100 d-block"
        scrolling="no">
      </IframeSchedule>
    </ModalContent>
  )
}

export default ScheduleDemo
