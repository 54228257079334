import styled from "styled-components";

export const ChartContainer = styled.div`
  .chart-container {
    height: 300px;
    width: 100%;
  }

  .chart-footer {
    width: 100%;
    margin-top: -8px;
  }

  .svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label {
    padding-left: 12px;

    &:not(:first-child) {
      border-left: 1px solid #bfbfbf;
    }
  }

  .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label {
    .label__value {
      font-size: 22px;
      color: var(--tblr-dark-gray);
    }

    .label__title {
      color: var(--tblr-primary);
    }

    .label__percentage {
      color: var(--tblr-success);
    }

    &.funnel-danger {
      .label__percentage {
        color: var(--tblr-danger);
      }
    }
  }
`;
