import AddContent from "@/components/leadster_ia/cards/CreateYourAiAssistant/AddContent";
import SetAssistant from "@/components/leadster_ia/cards/CreateYourAiAssistant/SetAssistant";
import TestAssistant from "@/components/leadster_ia/cards/CreateYourAiAssistant/TestAssistant";
import ActivateAssistant from "@/components/leadster_ia/cards/CreateYourAiAssistant/ActivateAssistant";
import MessageHistory from "@/components/leadster_ia/cards/Analytics/MessageHistory";
import ServiceAnalytics from "@/components/leadster_ia/cards/Analytics/ServiceAnalytics";
import ErrorPage from "@/components/leadster_ia_pages/ErrorPage";

export const ROUTES = {
  createAssistant: [
    {
      path: "",
      component: SetAssistant,
      name: I18n.t("views.leadster_ai.page.create_assistant.name"),
      title: I18n.t("views.leadster_ai.page.create_assistant.title"),
      description: I18n.t("views.leadster_ai.page.create_assistant.subtitle"),
      iconClass: "mood-smile-beam",
      requireAssistant: false,
    },
    {
      path: "/content",
      component: AddContent,
      name: I18n.t("views.leadster_ai.page.add_content.name"),
      title: I18n.t("views.leadster_ai.page.add_content.title"),
      description: I18n.t("views.leadster_ai.page.add_content.subtitle"),
      iconClass: "cloud-upload",
      requireAssistant: true,
    },
    {
      path: "/test",
      component: TestAssistant,
      name: I18n.t("views.leadster_ai.page.test_assistant.name"),
      title: I18n.t("views.leadster_ai.page.test_assistant.title"),
      description: I18n.t("views.leadster_ai.page.test_assistant.subtitle"),
      iconClass: "messages",
      requireAssistant: true,
    },
    {
      path: "/activation",
      component: ActivateAssistant,
      name: I18n.t("views.leadster_ai.page.activate_assistant.name"),
      title: I18n.t("views.leadster_ai.page.activate_assistant.title"),
      description: I18n.t("views.leadster_ai.page.activate_assistant.subtitle"),
      iconClass: "share",
      requireAssistant: true,
    },
    {
      path: "*",
      component: ErrorPage,
      name: I18n.t("views.leadster_ai.page.error_page.name"),
      title: I18n.t("views.leadster_ai.page.error_page.title"),
      description: I18n.t("views.leadster_ai.page.error_page.subtitle"),
      iconClass: "",
      requireAssistant: false,
    },
  ],
  analytics: [
    {
      path: "/dashboard",
      component: ServiceAnalytics,
      name: I18n.t("views.leadster_ai.page.analytics.dashboard.name"),
      title: I18n.t("views.leadster_ai.page.analytics.dashboard.title"),
      description: I18n.t("views.leadster_ai.page.analytics.dashboard.subtitle"),
    },
    {
      path: "/history",
      component: MessageHistory,
      name: I18n.t("views.leadster_ai.page.analytics.history.name"),
      title: I18n.t("views.leadster_ai.page.analytics.history.title"),
      description: I18n.t("views.leadster_ai.page.analytics.history.subtitle"),
    },
    {
      path: "*",
      component: ErrorPage,
      name: I18n.t("views.leadster_ia.page.error_page.name"),
      title: I18n.t("views.leadster_ia.page.error_page.title"),
      description: I18n.t("views.leadster_ia.page.error_page.subtitle"),
    },
  ],
};

export const getRoutesWithoutErrorPage = (routes) => routes.filter((_, index) => index !== (routes.length - 1));

export const getLinkProps = (path = "", routeBase = "createAssistant") => ROUTES[routeBase].find(route => route.path === path);
