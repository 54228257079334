import React, { useEffect, useRef, useState } from "react";

import Assistant from "./Assistant";

import Alert from "react-bootstrap/Alert"

import useAssistantsAll from "./hooks/useAssistantsAll";
import useAssistantFindById from "./hooks/useAssistantFindById";
import useAssistantObjectById from "./hooks/useAssistantObjectById";
import useConcatListUniqueById from "./hooks/useConcatListUniqueById";
import useScroll from "./hooks/useScroll";

import * as Styled from "./styles";

const AssistantSelector = ({ onDefinition, hasPermission, assistantId }) => {
  const scrollRef = useRef(null);

  const [assistents, setAssistents] = useState([]);
  const [currentAssistant, setCurrentAssistant] = useState(null);
  const [nextPage, setNextPage] = useState(1);
  const [loadingAssistants, setLoadingAssistants] = useState(false);
  const [loadingAssistantsById, setLoadingAssistantById] = useState(false);
  const [linkedAssistantAlert, setlinkedAssistantAlert] = useState(!!assistantId);

  const handleRequestAssistants = async () => {
    setLoadingAssistants(true);
    const response = await useAssistantsAll(nextPage);
    setAssistents(useConcatListUniqueById(assistents, response?.assistants || []));
    setNextPage(response?.next_page || nextPage || 1);
    setLoadingAssistants(false);
  };

  const handleRequestAssistantById = async (id) => {
    setLoadingAssistantById(true);
    const response = await useAssistantFindById(id);
    setCurrentAssistant(response?.assistant);
    setLoadingAssistantById(false);
  };

  const handleAssistentChange = (event) => {
    MixpanelService.track("leadster_ai_block_select_assistant");

    const assistent = useAssistantObjectById(event.target.value, assistents || []);
    setlinkedAssistantAlert(!!assistent?.id);

    if (assistent?.id) { onDefinition({ botId: assistent.id }); }
  };

  useEffect(() => {
    handleRequestAssistantById(assistantId);
    setlinkedAssistantAlert(!!assistantId);
  }, [assistantId]);

  useEffect(() => {
    handleRequestAssistants();
  }, []);

  return (
    <>
      <div className='form-group'>
        <div className="row g-3 mb-3">
          <div className="col-lg-6">
            <div className="list-group list-group-flush border rounded">
              <div className="list-group-header px-2">
                <div className="row">
                  <Styled.Subtitle className="col p-2 mx-2">
                    {I18n.t("views.manage_flows.script_editor.ia_interaction.subtitle_1")}
                  </Styled.Subtitle>
                </div>
              </div>

              <div className="list-group-item px-2">
                {(linkedAssistantAlert && assistents.length > 0) &&
                  <Alert variant="info">
                    <Alert.Heading className="fw-bold">
                      {I18n.t("views.manage_flows.script_editor.ia_interaction.linked_assistant.title")}
                    </Alert.Heading>

                    <p className="text-body mb-0">
                      {I18n.t("views.manage_flows.script_editor.ia_interaction.linked_assistant.message")}
                    </p>
                  </Alert>
                }

                {(!linkedAssistantAlert && assistents.length == 0) &&
                  <Alert variant="danger">
                    <Alert.Heading className="fw-bold">
                      {I18n.t("views.manage_flows.script_editor.ia_interaction.assistant_not_created.title")}
                    </Alert.Heading>

                    <p className="text-body mb-0">
                      {I18n.t("views.manage_flows.script_editor.ia_interaction.assistant_not_created.message")}
                    </p>
                  </Alert>
                }

                <Styled.Scroll
                  ref={scrollRef}
                  className="overflow-scroll"
                  onScroll={() => useScroll(scrollRef, loadingAssistants, handleRequestAssistants)}
                >
                  {assistents.map((assistent, index) => {
                    return (
                      <Assistant
                        key={index}
                        nameGroup="assistant-checked"
                        onChange={handleAssistentChange}
                        value={assistent.id}
                        name={assistent.name}
                        defaultChecked={assistent.id == currentAssistant?.id}
                        disabled={!hasPermission}
                        hidden={assistent.id == currentAssistant?.id}
                        typeOfAssistant={assistent.type_of_assistant}
                      />
                    )}
                  )}

                  {loadingAssistants &&
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  }
                </Styled.Scroll>
              </div>

              <Styled.NewAssistent className="list-group-footer px-2">
                <a href={`${window.location.origin}/ai/leadster/assistants`} target="_blank">
                  <i className="ti ti-plus me-2" /> {I18n.t("views.manage_flows.script_editor.ia_interaction.create_ai_assistant")}
                </a>
              </Styled.NewAssistent>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="list-group list-group-flush border rounded">
              <div className="list-group-header px-2">
                <div className="row">
                  <Styled.Subtitle className="col p-2 mx-2">
                    {I18n.t("views.manage_flows.script_editor.ia_interaction.subtitle_2")}
                  </Styled.Subtitle>
                </div>
              </div>
              <div className="list-group-item px-2">
                {currentAssistant?.id &&
                  <Assistant
                    value={currentAssistant.id}
                    name={currentAssistant.name}
                    defaultChecked={true}
                    disabled={true}
                    typeOfAssistant={currentAssistant.type_of_assistant}
                    loading={loadingAssistantsById}
                  />
                }

                {!linkedAssistantAlert &&
                  <Alert variant="danger">
                    <Alert.Heading className="fw-bold">
                      {I18n.t("views.manage_flows.script_editor.ia_interaction.unlinked_assistant.title")}
                    </Alert.Heading>

                    <div className="text-body">
                      <p>{I18n.t("views.manage_flows.script_editor.ia_interaction.unlinked_assistant.message_01")}</p>
                      <p className="text-body mb-0">{I18n.t("views.manage_flows.script_editor.ia_interaction.unlinked_assistant.message_02")}</p>
                    </div>
                  </Alert>
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default AssistantSelector;
