import React from 'react';

import TutorialButton from "@/components/guided_tutorial/TutorialButton";

const ScriptCardBlock = ({
  title,
  description,
  icon,
  cardBody = true,
  tutorialUrl,
  children
}) => {
  return (
    <div className="card">
      <div className="card-header d-flex flex-column align-items-start py-3">
        <div className="h2 inline-block">
          <i className={`${icon} text-primary me-2`} />
          {title}
          {tutorialUrl && (<TutorialButton tutorialUrl={tutorialUrl} />)}
        </div>

        <p className='mb-0'>{description}</p>
      </div>

      <div className={cardBody ? 'card-body' : ''}>
        {children}
      </div>
    </div>
  )
};

export default ScriptCardBlock;
