import React, { useCallback, useState } from "react";

import parse from "html-react-parser";

import * as S from "./styles";

const I18N_SCOPE = "views.manage_flows.script_editor.ia_interaction";

const AssistantSettings = ({ onDefinition, blockDefinitions }) => {
  const [endConversationLabel, setEndConversationLabel] = useState(blockDefinitions.endConversationLabel);

  const handleChangeDefaultLabel = (event) => {
    setEndConversationLabel(event.target.value);
    onDefinition({ endConversationLabel: event.target.value });
    trackUpdateEndConversationLabel();
  };

  const trackUpdateEndConversationLabel = useCallback(
    _.throttle(() => {
      MixpanelService.track("leadster_ai_block_change_copy_btn");
    }, 60000
  ), []);

  return (
    <>
      <S.Fieldset className="form-fieldset mt-3">
        <div className="form-group">
          <S.Label className="form-label">
            <i className="ti ti-user-share me-2" />
            {I18n.t(`${I18N_SCOPE}.settings.title`)}
          </S.Label>

          <S.Label className="form-label fw-medium">
            {I18n.t(`${I18N_SCOPE}.settings.subtitle`)}
          </S.Label>
        </div>

        <div className="form-group">
          <input
            type="text"
            className="form-control"
            maxLength={40}
            onChange={handleChangeDefaultLabel}
            value={endConversationLabel}
          />

          <S.Notification className="form-text rounded p-3 mt-3">
            <S.Label className="form-label fw-light text-dark mb-0">
              <div className="form-notification-content">
                {parse(I18n.t(`${I18N_SCOPE}.settings.notification_html`))}
              </div>
            </S.Label>
          </S.Notification>
        </div>
      </S.Fieldset>

      <S.Fieldset className="form-fieldset mt-3 p-3">
        <div className="form-grou">
          <S.Label
            className="form-label fw-medium mb-0"
            onClick={() => MixpanelService.track("leadster_ai_block_learn_more")}
          >
            {parse(I18n.t(`${I18N_SCOPE}.settings.advanced_settings`))}
          </S.Label>
        </div>
      </S.Fieldset>
    </>
  );
};

export default AssistantSettings;
