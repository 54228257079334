export default {
  on(event, callback, element = "nld-event-bus") {
    document.getElementById(element).addEventListener(event, (e) => callback(e.detail));
  },
  dispatch(event, data, element = "nld-event-bus") {
    document.getElementById(element).dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event, callback, element = "nld-event-bus") {
    document.getElementById(element).removeEventListener(event, callback);
  },
};
