import useCurrentUserStore from "@/store/currentUserStore";

const useAllowedInputTypes = () => {
  const { isNational } = useCurrentUserStore((state) => state.currentUser);

  const inputGroupList = [
    {
      groupLabel: I18n.t("views.manage_flows.script_editor.data_group.date"),
      inputTypes: [
        {
          id: "birthday",
          enabled: true,
          label: I18n.t("views.manage_flows.script_editor.data_group.birthday"),
        },
        {
          id: "date",
          enabled: true,
          label: I18n.t("views.manage_flows.script_editor.data_group.date"),
        },
        {
          id: "time",
          enabled: true,
          label: I18n.t("views.manage_flows.script_editor.data_group.time"),
        },
      ],
    },
    {
      groupLabel: I18n.t("views.manage_flows.script_editor.documents_group.title"),
      inputTypes: [
        {
          id: "cnpj",
          enabled: isNational,
          label: I18n.t("views.manage_flows.script_editor.documents_group.cnpj"),
        },
        {
          id: "cpf",
          enabled: isNational,
          label: I18n.t("views.manage_flows.script_editor.documents_group.cpf"),
        },
        {
          id: "cpf/cnpj",
          enabled: isNational,
          label: I18n.t("views.manage_flows.script_editor.documents_group.cpf_or_cnpj"),
        },
      ],
    },
    {
      groupLabel: I18n.t("views.manage_flows.script_editor.addresses_group.title"),
      inputTypes: [
        {
          id: "cep",
          enabled: isNational,
          label: I18n.t("views.manage_flows.script_editor.addresses_group.cep"),
        },
        {
          id: "city",
          enabled: isNational,
          label: I18n.t("views.manage_flows.script_editor.addresses_group.city"),
        },
        {
          id: "state_city",
          enabled: isNational,
          label: I18n.t("views.manage_flows.script_editor.addresses_group.state_city"),
        },
        {
          id: "state",
          enabled: isNational,
          label: I18n.t("views.manage_flows.script_editor.addresses_group.state"),
        },
      ],
    },
    {
      groupLabel: I18n.t("views.manage_flows.script_editor.currency_group.title"),
      inputTypes: [
        {
          id: "dollar",
          enabled: true,
          label: I18n.t("views.manage_flows.script_editor.currency_group.dollar"),
        },
        {
          id: "euro",
          enabled: true,
          label: I18n.t("views.manage_flows.script_editor.currency_group.euro"),
        },
        {
          id: "real",
          enabled: true,
          label: I18n.t("views.manage_flows.script_editor.currency_group.real"),
        },
      ],
    },
    {
      groupLabel: I18n.t("views.manage_flows.script_editor.others_group.title"),
      inputTypes: [
        {
          id: "email",
          enabled: true,
          label: I18n.t("views.manage_flows.script_editor.others_group.email"),
        },
        {
          id: "email_corp",
          enabled: true,
          label: I18n.t("views.manage_flows.script_editor.others_group.email_corp"),
        },
        {
          id: "name",
          enabled: true,
          label: I18n.t("views.manage_flows.script_editor.others_group.name"),
        },
        {
          id: "number",
          enabled: true,
          label: I18n.t("views.manage_flows.script_editor.others_group.number"),
        },
        {
          id: "phone",
          enabled: isNational,
          label: I18n.t("views.manage_flows.script_editor.others_group.phone"),
        },
        {
          id: "intl_phone",
          enabled: true,
          label: I18n.t("views.manage_flows.script_editor.others_group.intl_phone"),
        },
        {
          id: "text",
          enabled: true,
          label: I18n.t("views.manage_flows.script_editor.others_group.text"),
        },
      ],
    },
  ];

  const filteredInputGroup = inputGroupList
    .map((inputGroup) => {
      inputGroup.inputTypes = inputGroup.inputTypes.filter((inputTpe) => inputTpe.enabled);

      return inputGroup;
    })
    .filter((inputGroup) => !!inputGroup.inputTypes.length);

  return filteredInputGroup;
};

export default useAllowedInputTypes;
