import styled from "styled-components";

export const ImageStep = styled.div`
  background-image: url("/images/implementation/first-flow.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
`;

export const CardContainer = styled.div`
  min-height: 150px;
`;
