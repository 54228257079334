import React from "react";

import ScriptBlockSelector from "./ScriptBlockSelector";

const ScriptBlockMessagesSelector = (props) => {
  const blockSelector = React.createElement(ScriptBlockSelector, {
    definition: props.definition,
    key: props.definition.id,
    node: props.node,
    treeAdd: props.treeAdd,
    treeRemove: props.treeRemove,
    treeUpdate: props.treeUpdate,
    isFirstBlock: true,
    onDelete: props.onDelete,
    setDefinition: props.setDefinition,
    globalKeySet: props.globalKeySet,
    globalKeyGet: props.globalKeyGet,
    mapAttributesName: props.mapAttributesName,
  });

  return <div className="mb-3">{blockSelector}</div>;
};

export default React.memo(ScriptBlockMessagesSelector, () => true);
