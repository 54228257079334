import React from "react";

import { Container } from "./styles";

const ScriptCardFooter = ({ title, children, ...rest }) => {
  const leadsterIconPath = "https://storage.googleapis.com/neuro-cdn/new-editor/leadster.png";

  return (
    <Container {...rest} className="d-none d-md-block">
      <div className="d-flex align-items-start">
        <img src={leadsterIconPath} alt="Leadster logo" className="me-3" />

        <div>
          <div className='h3 text-body fw-medium'>{title}</div>
          <div>{children}</div>
        </div>
      </div>
    </Container>
  );
};

export default ScriptCardFooter;
