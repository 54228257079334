import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import useCurrentUserStore from "@/store/currentUserStore";
import useAllowedInputTypes from "./hooks/useAllowedInputTypes";

const InputTypeSelector = ({ defaultType = "text", onSelectType = () => {} }) => {
  const { isInternalAccount } = useCurrentUserStore((state) => state.currentUser);

  const allowedInputs = useAllowedInputTypes();
  const [defaultTypeHistory, setDefaultTypeHistory] = useState([defaultType]);

  useEffect(() => {
    setDefaultTypeHistory((prevHistory) => [...prevHistory, defaultType]);
  }, [defaultType]);

  return (
    <select
      className="form-select"
      defaultValue={defaultType}
      onChange={(e) => onSelectType(e.target.value)}
    >
        {allowedInputs.map((inputGroup) => (
          <optgroup label={inputGroup.groupLabel} key={inputGroup.groupLabel}>
            {inputGroup.inputTypes
              .filter((inputType) => {
                if (defaultTypeHistory.includes("birthday")){
                  return true;
                }
                return inputType.id !== "birthday";
              })
              .map((inputType) => (
                <option value={inputType.id} key={inputType.id}>
                  {inputType.label}
                </option>
              ))}
          </optgroup>
        ))}

      {isInternalAccount && (
        <optgroup label={I18n.t("views.manage_flows.script_editor.exclusive_leadster")}>
          <option value="unique-leadster-email">
            {I18n.t("views.manage_flows.script_editor.single_email")}
          </option>

          <option value="password">
            {I18n.t("views.manage_flows.script_editor.password")}
          </option>
        </optgroup>
      )}
    </select>
  );
};

InputTypeSelector.propTypes = {
  defaultType: PropTypes.string.isRequired,
  onSelectType: PropTypes.func,
};

export default InputTypeSelector;
