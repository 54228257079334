import axios from "@/lib/axios";

const saveData = (state, dispatch) => {
  let desktopScript = state.scriptVersion.desktopContent;
  let mobileScript = state.scriptVersion.mobileContent;

  if (!hasValidRules(state)) {
    const invalidRulesMessage = `
      ${I18n.t("views.manage_flows.duplicate.invalid_rules.alert.info_01")}
      ${I18n.t("views.manage_flows.duplicate.invalid_rules.alert.info_02")}

      ${I18n.t("views.manage_flows.duplicate.invalid_rules.alert.info_03")}
    `;

    dispatch({ type: "SET_ALL_ACTIVE_COMPONENT_INDEX", payload: 0 });
    return new Promise((resolve, reject) => reject(invalidRulesMessage));
  }

  const getFilledNotifications = (script) => {
    if (script.notifications === undefined) {
      return [];
    }

    return script.notifications.filter((notification) => {
      return notification.template !== "";
    });
  };

  desktopScript.notifications = getFilledNotifications(desktopScript);
  desktopScript.notificationsEnabled = desktopScript.notifications.length ? desktopScript.notificationsEnabled : false;

  mobileScript.notifications = getFilledNotifications(mobileScript);
  mobileScript.notificationsEnabled = mobileScript.notifications.length ? mobileScript.notificationsEnabled : false;

  if (!state.scriptVersion.hasCustomDesignMobile) {
    const customDesignProperties = (({
      userMainColor, robotMainColor,
      title,
      waitTime, marginBottom, side,
      avatar,
      modalMode, alwaysAutoOpen, disable1stPartyBranding,
      disableChatbotAfterConversion, lang, chatRestartAtEnd
    }) => ({
      userMainColor, robotMainColor,
      title,
      waitTime, marginBottom, side,
      avatar,
      modalMode, alwaysAutoOpen, disable1stPartyBranding,
      disableChatbotAfterConversion, lang, chatRestartAtEnd
    }))(desktopScript);

    mobileScript = { ...mobileScript, ...customDesignProperties };
  }

  if (!state.scriptVersion.hasCustomNotificationsMobile) {
    mobileScript.notifications = getFilledNotifications(desktopScript);
    mobileScript.notificationsEnabled = desktopScript.notificationsEnabled;
    mobileScript.notificationsDisplayTime = desktopScript.notificationsDisplayTime;
  }

  if (!state.scriptVersion.hasCustomScriptMobile) {
    mobileScript.tooltipMessage = desktopScript.tooltipMessage;
    mobileScript.requiredFields = desktopScript.requiredFields;
    mobileScript.script = desktopScript.script;
  }

  const payload = {
    name: state.name,
    rule_ast: JSON.stringify(state.ast),
    is_default: state.default,
    desktop_content: JSON.stringify(desktopScript),
    mobile_content: JSON.stringify(mobileScript),
    has_custom_design_mobile: state.scriptVersion.hasCustomDesignMobile,
    has_custom_notifications_mobile: state.scriptVersion.hasCustomNotificationsMobile,
    has_custom_script_mobile: state.scriptVersion.hasCustomScriptMobile,
  };

  return axios.put(`/scripts/${state.id}`, payload);
};

const hasValidRules = (state) => {
  let isValid = true;

  const isBlank = (str) => {
    return (!str || /^\s*$/.test(str));
  }

  if (state.default) {
    return isValid;
  }

  state.rulesList.forEach((ruleItem) => {
    if (['v2_home', 'v3_google_ads_match', 'v3_facebook_ads_match', 'v3_linkedin_ads_match'].includes(ruleItem.kind)) {
      return;
    }

    if (ruleItem.kind === 'v3_param_match') {
      const [param, value] = ruleItem.rule.split('=');

      if (isBlank(param) || isBlank(value)) {
        isValid = false;
        return;
      }
    }

    if (isBlank(ruleItem.rule)) {
      isValid = false;
      return;
    }
  });

  return isValid;
};

export { saveData };
