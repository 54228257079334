import React, { useState } from 'react';

import CheckedItem from './CheckedItem';
import UncheckedItem from './UncheckedItem';

import {
  Container,
  Image,
  Title,
  Minimize,
  MinimizeContainer,
  MinimizedContainer
} from './styles';

const OnboardChecklist = ({ checklist, img, show, not_open, action_implementation }) => {
  const [minimized, setMinimized] = useState(not_open);

  if (show) {
    return (
    <>
      <Container className="card fade-in" out={minimized}>
        <MinimizeContainer onClick={() => setMinimized(true)}>
          <Minimize />
        </MinimizeContainer>

        <Image src={img} />

        <Title className="fw-medium text-black">
          {I18n.t('views.onboard.next_step')}
        </Title>

        <div className="text-nowrap">
          {checklist.map((item) => {
            item.action_implementation = action_implementation;

            return ((item.checked)
              ? <CheckedItem item={item} key={item.label} />
              : <UncheckedItem item={item} key={item.label} />
            );
          })}
        </div>
      </Container>

      {minimized && (
        <MinimizedContainer onClick={() => setMinimized(false)} out={!minimized}>
          <Image src={img} />
        </MinimizedContainer>
      )}
    </>
    );
  }

  return null;
};

export default OnboardChecklist;
