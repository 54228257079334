import React, { useRef } from "react";
import * as S from "./styles";

import useScriptFlow from "./hooks/useScriptFlow";
import useChatInteractionTracker from "./hooks/useChatInteractionTracker";

const ChatPreview = ({ hideButtonClose, hideBrand }) => {
  const elementContainerChatRef = useRef();

  useScriptFlow();
  useChatInteractionTracker(elementContainerChatRef);

  return <S.chatbotContainer ref={elementContainerChatRef} hideButtonClose={hideButtonClose} hideBrand={hideBrand} />;
};

export default ChatPreview;
