import React, { useEffect } from "react";

import { ImageStep, CardContainer } from "./styles";

const First = () => {
  useEffect(() => {
    window.initJqueryPopover();
  }, []);

  return (
    <div
      className="card card-sm shadow-sm col"
      data-bs-toggle="popover"
      data-bs-placement="bottom"
      data-bs-html="true"
      data-bs-trigger="hover"
      data-bs-content='<div class="fw-medium">
        <strong>O seu número já foi cadastrado na criação de conta!</strong>
        Ele será usado como o seu número vinculado ao seu Botão do WhatsApp da Leadster e
        os seus visitantes irão interagir diretamente nesse canal.
      </div>'
    >
      <CardContainer className="d-flex h-100">
        <div className="col-6 p-3">
          <span className="bg-success text-white avatar avatar-rounded shadow shadow mb-3">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-checks" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M7 12l5 5l10 -10"></path>
              <path d="M2 12l5 5m5 -5l5 -5"></path>
            </svg>
          </span>

          <div className="fs-3 fw-bold">
            Botão do WhatsApp criado
          </div>
        </div>

        <ImageStep className="col-5 col-lg-6" />
      </CardContainer>
    </div>
  );
};

export default First;
