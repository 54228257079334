import "funnel-graph-js/dist/css/main.min.css";
import "funnel-graph-js/dist/css/theme.min.css";

import { useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";

import ChartFooter from './ChartFooter';
import InsufficientDataAlert from "../../Alerts/InsufficientDataAlert";

import FunnelGraph from "funnel-graph-js";

import { shortUID } from "@/utils/helpers";

import parse from "html-react-parser";

import * as S from "./styles";

const Funnel = ({ values, labels }) => {
  const containerClassRef = useRef(`funnel-${shortUID()}`);

  const checkHasInvalidData = useCallback(() => {
    if (!values.length || !labels.length)  {
      return true;
    }

    const sumOfValues = values.reduce((partialSum, a) => partialSum + a, 0);

    if (sumOfValues <= 0) {
      return true;
    }

    return false;
  }, [values, labels]);

  const hasInvalidData = checkHasInvalidData();

  const drawFunnelGraph = () => {
    $(`.${containerClassRef.current}`).empty();

    if (hasInvalidData) return;

    new FunnelGraph({
      container: `.${containerClassRef.current}`,
      gradientDirection: "horizontal",
      data: {
        labels,
        values,
        colors: ["#00C9FF", "#156CE7"],
      },
      displayPercent: true,
      direction: "horizontal",
    }).draw();
  };

  useEffect(drawFunnelGraph, [values, labels]);

  return (
    <div className="card overflow-auto">
      <div className="card-header flex-column align-items-start">
        <h3 className="card-title d-flex align-items-center mb-2">
          <i className="ti ti-trending-up text-success icon me-2"></i>
          <span>{I18n.t("views.analytics.conversion_by_question")}</span>
        </h3>

        <div className="text-muted mb-0">
          {parse(I18n.t("views.analytics.percentage_leads_respond_html"))}
        </div>
      </div>

      <div className="card-body p-0">
        <div className="py-3">
          {hasInvalidData && <InsufficientDataAlert />}

          {!hasInvalidData && (
            <S.ChartContainer>
              <div className={`chart-container ${containerClassRef.current}`} />
              <ChartFooter values={values} />
            </S.ChartContainer>
          )}
        </div>
      </div>

      <div className="card-header border-top flex-column align-items-start">
        <h3 className="card-title d-flex align-items-center mb-2">
          <i className="ti ti-trending-down text-danger icon me-2"></i>
          <span>{I18n.t("views.analytics.loss_by_question")}</span>
        </h3>

        <div className="text-muted mb-0">
          {parse(I18n.t("views.analytics.percentage_leads_dropout_html"))}
        </div>
      </div>
    </div>
  );
};

Funnel.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Funnel;
