import { useEffect } from "react";
import { useUpsellContext } from "../../context";

import CardBase from "../CardBase";
import CurrencyFormatter from "@/utils/formatter-currency";
import CalculatorUtils from "../../utils/calculators";

import * as S from "./styles";

const StepThree = () => {
  const { progressData, saveProgressData } = useUpsellContext();

  const isPeriodPlanChanged = progressData.isPeriodPlanChanged;
  const totalPriceByInstallment = CalculatorUtils.calcPriceByInstallments(progressData.payment.amount, progressData.payment.installment)

  useEffect(() => {
    saveProgressData(prev => ({ ...prev, idPlan: undefined }))
  }, []);

  return (
    <S.Wrapper className="mt-4 gap-3">
      <div className="card py-3">
        <div className="card-body d-flex align-items-center gap-4">
          <S.Icon className="ti ti-circle-check"></S.Icon>
          <div>
            <h2 class="page-title">
              {I18n.t("views.upsell.stepper.step_three.confirmation_card.title")}
            </h2>

            <p className="fs-5 mt-3">
              {I18n.t("views.upsell.stepper.step_three.confirmation_card.text_1_html", {
                current_plan: progressData.currentPlan.plan,
                new_plan: progressData.selectedPlan.plan
              })}
            </p>

            <div
              className="p-3 rounded fs-5"
              style={{ background: 'rgba(var(--tblr-info-rgb), 0.4)' }}
            >
              {I18n.t("views.upsell.stepper.step_three.confirmation_card.text_2")}

            </div>

            <p className="fs-5 mt-3">
              {I18n.t("views.upsell.stepper.step_three.confirmation_card.text_3")}
            </p>

            <div className="d-flex align-items-center gap-2">
              <a href="/" className="btn btn-primary gap-2">
                {I18n.t("views.upsell.stepper.step_three.confirmation_card.btn_dashboard")}
              </a>
              <a href="/billing" className="btn btn-outline-primary">
                {I18n.t("views.upsell.stepper.step_three.confirmation_card.btn_billing")}
              </a>
            </div>
          </div>
        </div>
      </div>

      <CardBase
        title={I18n.t("views.upsell.stepper.step_three.summary_card.title")}
      >
        <div>
          <div className="d-flex align-items-center justify-content-between pt-2 pb-3">
            <span>
              {isPeriodPlanChanged
                ? `${progressData.selectedPlan.plan}`
                : I18n.t("views.upsell.stepper.step_three.summary_card.billing")
              }
            </span>
            <span className="fw-bold m-0">
              {CurrencyFormatter.formatter(progressData.payment.amount)}
            </span>
          </div>

          <div className="d-flex align-items-center justify-content-between py-3 border-top border-bottom">
            <span>{I18n.t("views.upsell.stepper.step_three.summary_card.payment_method")}</span>
            <span className="fw-bold m-0">
              {progressData.payment.method}
            </span>
          </div>

          <div className="d-flex align-items-center justify-content-between pb-2 pt-3">
            <h3 className="fw-bold m-0">{I18n.t("views.upsell.stepper.step_three.summary_card.total")}</h3>
            <div>
              <div className="h1 mb-0 fw-bold text-primary">
                {progressData.payment.installment > 1 &&
                I18n.t("views.upsell.stepper.step_three.summary_card.installment", {
                  installment: progressData.payment.installment
                })}
                {CurrencyFormatter.formatter(totalPriceByInstallment)}
              </div>
              <div className="fs-5 text-end">
                {I18n.t("views.upsell.stepper.step_three.summary_card.total_price", {
                  price: CurrencyFormatter.formatter(progressData.payment.amount)
                })}
              </div>
            </div>
          </div>
        </div>
      </CardBase>
    </S.Wrapper>
  );
};

export default StepThree;
