import React, { useEffect, useState, useCallback } from 'react';
import axios from "@/lib/axios";

import CompanyLineChartCard from '../CompanyLineChartCard';

const CompanyAnalytics = ({company_id, isReseller}) => {
  const [generatedLeadsTotal, setGeneratedLeadsTotal] = useState(0);
  const [generatedLeadsChart, setGeneratedLeadsChart] = useState([]);
  const [generatedLeadsVariation, setGeneratedLeadsVariation] = useState(0);

  const [bestChannelName, setBestChannelName] = useState("");
  const [bestChannelLeadsChart, setBestChannelLeadsChart] = useState([]);
  const [bestChannelVariation, setBestChannelVariation] = useState(0);

  const [custom, setCustom] = useState(0);
  const [customChart, setCustomChart] = useState([]);
  const [customPercentage, setCustomPercentage] = useState(0);
  const [customName, setCustomName] = useState("");

  const [ownershipValue, setOwnershipValue] = useState(0);
  const [ownershipType, setOwnershipType ] = useState("");

  const updateData = useCallback(async (dataFilter) => {
    const response = await axios.get(`/owners/company-metrics/${company_id}`, { params: dataFilter });
    const { data } = response;

    setGeneratedLeadsTotal(data.generated_leads_total);
    setGeneratedLeadsChart(data.generated_leads_chart);
    setGeneratedLeadsVariation(data.generated_leads_variation);

    setBestChannelLeadsChart(data.best_channel_chart);
    setBestChannelName(data.best_channel_name);
    setBestChannelVariation(data.best_channel_variation);

    setCustom(data.custom_total);
    setCustomChart(data.custom_chart);
    setCustomPercentage(data.custom_variation);
    setCustomName(data.custom_name);

    setOwnershipValue(data.ownership_value);
    setOwnershipType(data.ownership_type);
  });

  const updateGeneral = async () => {
    updateData();
  }

  useEffect(() => {
    updateGeneral()
  }, []);

  return (
    <div className="row row-deck row-cards">
      <div className="col-6 col-md-3">
        <div className='mx-2'>
          <CompanyLineChartCard
            data={generatedLeadsChart}
            value={generatedLeadsTotal}
            percentage={generatedLeadsVariation}
            label={I18n.t("views.dashboard.all_access_info.generated_leads")} />
          </div>
      </div>
      <div className="col-6 col-md-3">
        <div className='mx-2'>
          <CompanyLineChartCard
            data={bestChannelLeadsChart}
            value={bestChannelName}
            percentage={bestChannelVariation}
            label={I18n.t("views.dashboard.all_access_info.complete_leads")} />
        </div>
      </div>
      <div className="col-6 col-md-3">
        <div className='mx-2'>
        <CompanyLineChartCard
          data={customChart}
          value={custom}
          percentage={customPercentage}
          label={customName}
        />
        </div>
      </div>
      {!isReseller && (
        <div className="col-6 col-md-3">
          <div className='mx-2 w-100'>
            <div className="card h-100">
              <div className="d-flex card-body flex-column justify-content-between">
                <div>
                <h4>
                  {I18n.t(`owners.ownership_modal.${ownershipType}.title`)}
                </h4>
                </div>
                <div>
                  <h1 className='d-inline'>{ownershipValue}</h1>
                  <h4 className='d-inline mx-1'>mês</h4>
                </div>
                <div>
                  <p className="align-content-end fs-6 mb-0">
                    {I18n.t(`owners.ownership_modal.${ownershipType}.description`)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyAnalytics;
