export const linkLeadsterAiGuide = [
  {
    title: I18n.t("guide_modal.script_flow.link_leadster_ai.title_01"),
    content: I18n.t("guide_modal.script_flow.link_leadster_ai.subtitle_01_html"),
    media: {
      type: I18n.t("guide_modal.script_flow.link_leadster_ai.type_01"),
      url: I18n.t("guide_modal.script_flow.link_leadster_ai.media_url_01"),
    },
  },
];
