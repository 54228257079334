import React from "react";

import RichTextEditor from '@/components/shared/RichTextEditor';

import { replaceTag, trimHtml } from '../../utils/helpers'

export default class NotificationEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tiny_content : this.props.data.template
    }
    this.userNames = [ "João", "Maria", "José", "Júlia" ];
    this.namePlaceholder = "{nome}";
    this.maxTemplateLength = 100;
    this.handleEditorChange = this.handleEditorChange.bind(this)
  }

  randomName() {
    return this.userNames[Math.floor(Math.random()*this.userNames.length)];
  }

  randomMessage(template) {
    let temp = undefined
    if(template !== undefined){
      temp = template;
    }else{
      temp = this.props.data.template;
    }

    return temp.replace(this.namePlaceholder, this.randomName());
  }

  updateTemplate(el) {
    const template = $(el).val().slice(0, this.maxTemplateLength);

    if (template !== this.props.data.template) {
      this.props.update({
        ...this.props.data,
        template: template
      });

      this.updatePreview(this.randomMessage(template));
    }
  }

  removeTemplate(e, id) {
    e.preventDefault();
    this.props.remove(id);
  }

  onFocus() {
    this.updatePreview(this.randomMessage());
  }

  updatePreview(message) {
    if (this.props.callback) {
      this.props.callback(message);
    }
  }

  countCharacter (content) {
    var tx = content;
    var txt = document.createElement("textarea");
    txt.innerHTML = tx;
    var decoded = txt.value;
    var decodedStripped = decoded.replace(/(<([^>]+)>)/ig, "").trim();
    txt.remove()
    return decodedStripped.length;
  };

  handleEditorChange(tiny_content) {
    this.setState({ tiny_content });

    const dataReplacedBold = replaceTag(tiny_content, 'strong', 'b');
    const dataReplacedItalic = replaceTag(dataReplacedBold, 'em', 'i');

    const txt = document.createElement("textarea");
    txt.innerHTML = dataReplacedItalic;

    const decoded = txt.value;
    txt.remove();

    const txtTrim = trimHtml(decoded,{ limit: this.maxTemplateLength })
    const template = txtTrim.html;

    if (template !== this.props.data.template) {
      this.props.update({ ...this.props.data, template });
      this.updatePreview(this.randomMessage(template));
    }
  }

  render() {
    const { id } = this.props.data;

    return <div className="row g-3 mb-3">
      <div className="col form-group fixed-tooltip tooltip-error">
        {(this.countCharacter(this.state.tiny_content) > this.maxTemplateLength) &&
          <span className="tooltiptext">{I18n.t("views.manage_flows.script_editor.you_have_exceeded_the_character_limit")}</span>
        }

        <RichTextEditor
          value={this.state.tiny_content}
          inline={true}
          init={{
            selector: "",
            plugins: "emoticons",
            menubar: false,
            toolbar: "bold italic underline | emoticons",
            smart_paste: false,
          }}
          onEditorChange={this.handleEditorChange}
        />

      </div>

      <div className="col-auto">
        <button type="button" className="btn btn-danger btn-icon" onClick={(e) => this.removeTemplate(e, id)}>
          <i className="ti ti-trash-x icon"></i>
        </button>
      </div>
    </div>;
  }
}
