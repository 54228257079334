import React, { useState } from "react";
import PropTypes from "prop-types";

import GoogleLogin from '../GoogleLogin';

import { SuccessIcon } from "./styles";
import parse from "html-react-parser"

const SuccessFeedback = () => {
  return (
    <>
      <div class="card-status-top bg-green"></div>
      <div className="card-body text-center py-4">
        <div className='mb-3'>
          <SuccessIcon className="ti ti-circle-check" />
        </div>

        <h1 className="text-black mb-3">
          {I18n.t("views.users.edit.google_calendar.feedback.permission_granted")}
        </h1>

        <p className="lead">
          {parse(I18n.t("views.users.edit.google_calendar.feedback.google_account_linked_html"))}
        </p>

        <p className="lead fw-medium mb-0">
          {I18n.t("views.users.edit.google_calendar.feedback.you_can_close_window")}
        </p>
      </div>
    </>
  );
}

const Invite = ({ companyName, companyId, allowCreation }) => {
  const [hasSuccess, setHasSuccess] = useState(false);

  return (
    <div className="card card-md shadow">
      {hasSuccess ? <SuccessFeedback /> : (
        <>
          <div className="ribbon ribbon-top bg-google d-none d-sm-inline-flex">
            <i className="ti ti-brand-google icon" />
          </div>

          <div className="card-body text-center py-3">
            <h2 className="text-black">
              {I18n.t("views.users.edit.google_calendar.invite.title")}
            </h2>
          </div>

          <div className="card-body text-center lead px-3">
            <p className="lh-lg">
              {parse(I18n.t("views.users.edit.google_calendar.invite.invited_to_link_html", {companyName: companyName}))}
            </p>

            <p className="lh-lg mb-0">
            {I18n.t("views.users.edit.google_calendar.invite.link_button_description")}
            </p>
          </div>

          <div className="card-body p-3">
            {allowCreation ? (
              <GoogleLogin
                queryParamsCallbackUrl={{ company: companyId }}
                className='btn-lg btn-primary fs-3 w-100'
                text={I18n.t("views.users.edit.google_calendar.invite.link_button_text")}
                enabled={allowCreation}
                onSuccessCallback={() => setHasSuccess(true)}
              />
            ) : (
              <div className="alert alert-danger text-center">
                <div className="alert-title mb-2">
                  {I18n.t("views.users.edit.google_calendar.invite.max_accounts_reached")}
                </div>

                <p className="text-muted mb-0">
                  {I18n.t("views.users.edit.google_calendar.invite.contact_company_manager")}
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

Invite.propTypes = {
  companyId: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
};

export default Invite;

