import "dayjs/locale/en";
import "dayjs/locale/pt-br";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/esm/plugin/localizedFormat";
import useCurrentUserStore from "@/store/currentUserStore";

const {
  locale: userLocale,
  timeZone: userTimezone
} = useCurrentUserStore.getState().currentUser;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

dayjs.locale(userLocale.toLowerCase());
dayjs.tz.setDefault(userTimezone);

const timezonedDayjs = (...args) => {
  return dayjs(...args).tz();
};

const timezonedUnix = (value) => {
  return dayjs.unix(value).tz();
};

timezonedDayjs.unix = timezonedUnix;
timezonedDayjs.duration = dayjs.duration;

export default timezonedDayjs;
