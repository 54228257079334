import React from "react";

export default function OptionAvatar(props) {
  const classes = ["option-avatar"];

  if (props.selected) {
    classes.push("selected");
  }

  return (
    <div
      className={classes.join(" ")}
      onClick={() => props.onSelect(props.avatar)}
    >
      <img src={props.avatar.value} />
    </div>
  );
}
