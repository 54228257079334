import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  gap: 20px;

  @media (max-width: 1080px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 699px) {
    grid-template-columns: 1fr;
  }
`;
export const Card = styled.div`
  overflow: hidden;
  transition: 400ms;

  .card-header{
    min-height: 56px;
    width: 100%;
    margin: 0;
  }

  .card-header, .card-body{
    transition: 400ms;
  }
`;
