import React from "react";

import TopLogo from "@/components/onboard/TopLogo"
import NavButtons from "../NavButtons";

import useCurrentUserStore from "@/store/currentUserStore";

import { useAppState } from "../AppState";
import { LineCheckbox } from "./styles.js";

const ChooseObjective = () => {
  const appState = useAppState();

  const avaliableObjectives = [
    { value: "increase_lead_generation", label: I18n.t("views.onboard.objective.increase_lead_generation") },
    { value: "qualify_leads", label: I18n.t("views.onboard.objective.qualify_leads") },
    { value: "distribute_and_direct_leads", label: I18n.t("views.onboard.objective.distribute_and_direct_leads") },
    { value: "send_leads_data_to_whatsapp", label: I18n.t("views.onboard.objective.send_leads_data_to_whatsapp") },
    { value: "automate_service", label: I18n.t("views.onboard.objective.automate_service") },
    { value: "other", label: I18n.t("views.onboard.objective.other") },
  ];

  const { applicationName } = useCurrentUserStore((state) => state.currentUser);

  return (
    <div id="choose-objective">
      <TopLogo />

      <h1>{I18n.t("views.onboard.main_objectives", { application_name: applicationName })}</h1>
      <h2>{I18n.t("views.onboard.goals_with_our_platform")}</h2>

      <section style={{marginTop: '1rem'}}>
        {avaliableObjectives.map((objective, index) => {
          return (
            <LineCheckbox key={index} id={`objective_${index}`}>
              <input
                type="radio"
                name={`objective_${index}`}
                value={objective.value}
                checked={objective.value === appState.objective}
                onChange={() => appState.setObjective(objective.value)}
              />

              <label
                htmlFor={`objective_${index}`}
                onClick={() => appState.setObjective(objective.value)}
              >
                {objective.label}
              </label>
            </LineCheckbox>
          );
        })}
      </section>

      <NavButtons next="/business-model" />
    </div>
  );
};

export default ChooseObjective;
