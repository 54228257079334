import React from "react";

import { AvatarSpan } from "./styles";

const AvatarImage = ({
  url = "",
  selected = false,
  disabled = false,
  ...rest
}) => {
  return (
    <>
      <div
        className="cursor-pointer me-2 mb-2"
        onClick={(e) => e.preventDefault()}
        {...rest}
      >
        <AvatarSpan
          url={url}
          selected={selected}
          disabled={disabled}
          className="avatar avatar-lg bg-gray rounded-circle"
        >
          {!url && (
            <i className="h1 icon ti ti-plus" style={{ color: "#467fcf" }}></i>
          )}
        </AvatarSpan>
      </div>

      <input type="hidden" defaultValue={url} />
    </>
  );
};

export default AvatarImage;
