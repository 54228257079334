import styled from "styled-components";

export const IconUp = styled.i.attrs((props) => ({
  className: `ti ti-thumb-up${props.like == true ? '-filled' : ''} m-2 mx-1`,
}))`
  ${props => props.like == true && 'color: #007dff;'}
  cursor: pointer;
`;

export const IconDown = styled.i.attrs((props) => ({
  className: `ti ti-thumb-down${props.like == false ? '-filled' : ''} m-2 mx-1`,
}))`
  ${props => props.like == false && 'color: #2C3E50;'}
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
`;
