import React from "react";

const SkeletonLoader = () => {
  return (
    <div className="card placeholder-glow w-100">
      <div className="card-body">
        <div className="placeholder col-6 mb-2"/>
        <div className="placeholder placeholder-sm col-10"/>
      </div>
      <div className="card-body">
        <div className="placeholder placeholder-sm col-6"/>
        <div className="placeholder placeholder-xs col-12"/>

        <div className="placeholder placeholder-sm col-6 mt-3"/>
        <div className="placeholder placeholder-xs col-12"/>

        <div className="placeholder placeholder-sm col-6 mt-3"/>
        <div className="placeholder placeholder-xs col-12"/>

        <div className="placeholder placeholder-sm col-6 mt-3"/>
        <div className="placeholder placeholder-xs col-12"/>

        <div className="placeholder placeholder-sm col-6 mt-3"/>
        <div className="placeholder placeholder-xs col-12"/>

        <div className="placeholder placeholder-sm col-6 mt-3"/>
        <div className="placeholder placeholder-xs col-12"/>

        <div className="placeholder placeholder-sm col-6 mt-3"/>
        <div className="placeholder placeholder-xs col-12"/>

        <div className="mt-3 text-end">
          <div className="btn btn-primary disabled placeholder placeholder-lg col-3"/>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
