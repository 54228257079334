import useLogic from "./useLogic";

import Skeleton from "./Skeleton";
import SuggestionCard from "./SuggestionCard";

import CurrencyFormatter from "@/utils/formatter-currency";
import FormatCurrencyUtils from "../../utils/calculators";
import FormatterUtils from "../../utils/formatter";

import * as S from "./styles";

const StepOne = () => {
  const { data } = useLogic();

  if (data.isLoading) {
    return <Skeleton />;
  };

  const { currentPlan, secondPlan, thirdPlan, overdueInvoice } = data;

  if (!secondPlan || !thirdPlan) {
    return;
  }

  const expiresIn = FormatterUtils.formatDate(currentPlan.expires);
  const currentPrice = FormatCurrencyUtils.calcPricebyPeriod(currentPlan.price, currentPlan.period);

  return (
    <S.Wrapper className="mt-4">

      {/* Currenct Plan */}
      <S.Card className="card bg-transparent">
        <div className="card-header d-flex justify-content-between py-1">
          <p className="card-title fw-bold col">{currentPlan.plan}</p>
        </div>
        <div className="card-body d-flex flex-column">
          <span>{I18n.t("views.upsell.stepper.step_one.plan_expires")} <b>{expiresIn}</b></span>
          <p className="fw-bold h1 mt-2">
            {CurrencyFormatter.formatter(currentPrice)}
            /mês
          </p>
          <div className="mt-1">
            {I18n.t("views.upsell.stepper.step_one.plan_access")}
            <b className="fw-bold">
              {FormatterUtils.formatAbbreviatedAccess(currentPlan.access_limit)}
            </b>
          </div>
          <div className="mx-auto text-center mt-4">{I18n.t("views.upsell.stepper.step_one.current_plan")}</div>
        </div>
      </S.Card>

      {/* Suggestions Plans */}
      <SuggestionCard
        options={secondPlan.plans}
        currentPLan={currentPlan}
        overdueInvoice={overdueInvoice}
        currentPlanPrice={currentPrice}
      />
      <SuggestionCard
        options={thirdPlan.plans}
        currentPLan={currentPlan}
        overdueInvoice={overdueInvoice}
        currentPlanPrice={currentPrice}
      />
    </S.Wrapper>
  );
};

export default StepOne;
