import { createContext, useContext, useState } from 'react';

const defaultData = {
  idPlan: undefined,
  overdueInvoice: 0,
  coupomTotalAmount: 0,
  isPeriodPlanChanged: false,
  currentPlan: undefined,
  selectedPlan: undefined,
  financialEmail: {
    value: '',
    error: '',
  },
  payment: {
    amount: 0,
    method: '',
    installment: 1,
    amountByInstallment: 0,
  }
}

const UpsellStepperContext = createContext(defaultData);

const UpsellStepperProvider = ({ children }) => {
  const [progressData, setProgressData] = useState(defaultData);

  const resetPaymentProgressData = () => {
    setProgressData(defaultData)
  }

  return (
    <UpsellStepperContext.Provider
      value={{
        progressData,
        saveProgressData: setProgressData,
        resetPaymentProgressData
      }}
    >
      {children}
    </UpsellStepperContext.Provider>
  )
}

export const useUpsellContext = () => {
  const context = useContext(UpsellStepperContext);

  if (!context) {
    throw new Error('the funcion "useUpsellContext" should be use inside "UpsellStepperProvider"!')
  }

  return context;
};

export default UpsellStepperProvider;
