import React from "react";
import ListEditor from "./ListEditor";

export default class NotificationsListEditor extends ListEditor {
  renderFooter(syncing) {
    window.neuroleadNotificationsEditor = this;

    return <div className="row">
      { this.renderAdd() }
    </div>
  }

  renderAdd() {
    return <div className="col text-start">
      <button className="btn btn-primary" type="button" onClick={() => this.add()}>
        <i className="ti ti-plus icon"></i>
        {I18n.t("views.manage_flows.script_editor.add_variation")}
      </button>
    </div>
  }

  // Returns a list of notifications ready
  // to be inserted in the ScriptVersion
  // content
  getNotifications() {
    return this.state.list.map(notification => {
      return {
        id: notification.data.id,
        template: notification.data.template,
      }
    });
  }
}
