export default function reducer(state, action) {
  let key, value = null;

  switch (action.type) {
    case "SET_ALL_CURRENT_VALUES":
      return {
        ...state,
        ...action.payload,
        scriptVersion: {
          ...state.scriptVersion,
          ...action.payload.scriptVersion,
        },
      };

    case "SET_IS_DEFAULT":
      return { ...state, default: action.payload };

    case "SET_DISABLE_CHAT":
      return { ...state, disablePreviewChat: action.payload };

    case "SET_RULES_LIST":
      return { ...state, rulesList: action.payload };

    case "SET_AST":
      return { ...state, ast: action.payload };

    case "SET_NAME":
      return { ...state, name: action.payload };

    case "SET_CUSTOM_MOBILE_BY_KEY":
      ({ key, value } = action.payload);
      return {
        ...state,
        scriptVersion: {
          ...state.scriptVersion,
          [key]: value,
        },
      };

    case "SET_PREVIEW_CONVOSCRIPT_VALUE":
      ({ key, value } = action.payload);
      return setConvoScriptValueByType(key, value, "previewContent");

    case "SET_DESKTOP_CONVOSCRIPT_VALUE":
      ({ key, value } = action.payload);
      return setConvoScriptValueByType(key, value, "desktopContent");

    case "SET_MOBILE_CONVOSCRIPT_VALUE":
      ({ key, value } = action.payload);
      return setConvoScriptValueByType(key, value, "mobileContent");

    case "SET_DESKTOP_AND_MOBILE_CONVOSCRIPT_VALUE":
      ({ key, value } = action.payload);
      return setAllConvoScriptValue(key, value);

    case "SET_ALL_ACTIVE_COMPONENT_INDEX":
      return { ...state, activeComponentIndex: action.payload };

    case "SET_DYNAMIC_FIELD":
      ({ key, value } = action.payload);
      return setDynamicField(key, value);

    case "REMOVE_DYNAMIC_FIELD":
      const fieldKey = action.payload;
      return removeDynamicField(fieldKey);

    case "SET_REPRESENTATIVES":
      return { ...state, representatives: action.payload };

    case "SET_ALLOW_CONNECT_GOOGLE":
      return { ...state, allowConnectionToGoogle: action.payload };

    default:
      throw new Error();
  }

  function setConvoScriptValueByType(key, value, scriptTypeKey = "desktopContent") {
    return {
      ...state,
      scriptVersion: {
        ...state.scriptVersion,
        [scriptTypeKey]: {
          ...state.scriptVersion[scriptTypeKey],
          [key]: value,
        },
      },
    };
  }

  function setAllConvoScriptValue(key, value) {
    return {
      ...state,
      scriptVersion: {
        ...state.scriptVersion,
        desktopContent: {
          ...state.scriptVersion.desktopContent,
          [key]: value,
        },
        mobileContent: {
          ...state.scriptVersion.mobileContent,
          [key]: value,
        },
      },
    };
  }

  function setDynamicField(key, value) {
    return {
      ...state,
      dynamicFields: {
        ...state.dynamicFields,
        [key]: { text: value, value: `{{${value}}}` },
      },
    };
  }

  function removeDynamicField(key) {
    delete state.dynamicFields[key];
    return {
      ...state,
      dynamicFields: state.dynamicFields,
    };
  }
}
