import { OverlayTrigger, Tooltip } from "react-bootstrap";

import parse from "html-react-parser";

const LinkedScriptList = ({ script }) => {
  return (
    <>
      <div className="card flow-item mb-3">
        <div className="card-body row align-items-center row-cards">
          <div className="col-lg-auto">
            <OverlayTrigger overlay={<Tooltip>{script.name}</Tooltip>}>
              <span className="badge bg-muted-lt text-body fw-medium p-2">
                {_.truncate(script.name, { length: 30 })}
              </span>
            </OverlayTrigger>
          </div>

          <div className="col-lg-6 d-flex align-items-center">
            <div
              className="avatar avatar-sm avatar-rounded me-2"
              style={{ backgroundImage: `url(${script.avatar})`}}
            >
              <span className="badge bg-success" />
            </div>

            <div className="d-flex align-items-center text-truncate text-muted">
              <i className="ti ti-devices me-1" />
              {parse(script.conversion_call)}
            </div>
          </div>

          <div className="col-lg-auto ms-auto">
            <a href={`/scripts/${script.id}/edit`} className="me-0 me-sm-1 btn-primary btn" target="_blank">
              <i className="ti ti-edit icon me-1"></i>{I18n.t("general.actions.edit")}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkedScriptList;
