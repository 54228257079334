const SkeletonLoader = () => {
  return (
    new Array(3).fill(0).map((_, key) => (
      <div className="row w-100" key={key}>
        <div className="card placeholder-wave mb-3">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="avatar avatar-rounded placeholder"></div>
              </div>

              <div className="col-7 text-start">
                <div className="placeholder placeholder-xs col-9"></div>
                <div className="placeholder placeholder-xs col-7"></div>
              </div>

              <a href="#" className="btn btn-primary disabled placeholder col-2 ms-auto text-end" />
            </div>
          </div>
        </div>
      </div>
    ))
  );
};

export default SkeletonLoader;
