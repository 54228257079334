import React from "react";

import ScriptCardBlockDesktopAndMobile from "../../ScriptCardBlockDesktopAndMobile";
import ScriptCardFooter from "../../ScriptCardFooter";
import ConversationFlowContent from "./ConversationFlowContent";

import { can } from "@/lib/user-permission";

import parse from "html-react-parser";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const ConversationFlow = () => {
  const hasPermission = can(PERMISSION_SCOPE);

  const cardBody = {
    title: I18n.t("views.manage_flows.script_editor.flow_of_questions_and_answers"),
    icon: `ti ti-message`,
    description: I18n.t("views.manage_flows.script_editor.flow_of_questions_and_answers_description"),
  };

  const cardFooter = {
    title: I18n.t("views.manage_flows.script_editor.best_practices_in_question_flow"),
    description: I18n.t("views.manage_flows.script_editor.best_practices_in_question_description"),
    secondDescription: I18n.t("views.manage_flows.script_editor.best_practices_in_question_second_description"),
  };

  return (
    <>
      <ScriptCardBlockDesktopAndMobile
        title={cardBody.title}
        icon={cardBody.icon}
        description={cardBody.description}
        customMobileStateKey="hasCustomScriptMobile"
        desktopComponent={<ConversationFlowContent isMobile={false} />}
        mobileComponent={<ConversationFlowContent isMobile={true} />}
        cardBodyClass=""
        hasPermission={hasPermission}
      />

      <ScriptCardFooter title={cardFooter.title}>
        <p className="mb-2 tracking-wide">{parse(cardFooter.description)}</p>
        <p className="mb-0 tracking-wide">{parse(cardFooter.secondDescription)}</p>
      </ScriptCardFooter>
    </>
  );
};

export default ConversationFlow;
