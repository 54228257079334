import React from "react";
import PropTypes from "prop-types";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import parse from "html-react-parser";

const HelpPopover = ({ content, placement = "auto", className = "", ...props }) => {
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement={placement}
      overlay={
        <Popover>
          <Popover.Body>{parse(content)}</Popover.Body>
        </Popover>
      }
    >
      <span
        className={`form-help d-none d-sm-inline-block ${className}`}
        {...props}
      >
        ?
      </span>
    </OverlayTrigger>
  );
};

HelpPopover.propTypes = {
  content: PropTypes.string.isRequired,
  placement: PropTypes.string,
  className: PropTypes.string,
};

export default HelpPopover;
