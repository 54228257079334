import React from "react";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

import { MemoryRouter as Router, Route, Switch } from "react-router-dom";
import posed, { PoseGroup } from "react-pose";

import {
  StateMachineProvider,
  createStore,
  setStorageType
} from "little-state-machine";

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 }
});

const IntegrationEditor = props => {
  // Initial Store Data
  let initialStore = { data: {} };
  if (typeof props.integration !== "undefined" && props.integration !== null) {
    // Cross-reference `allowed_scripts` and `scripts`
    let integrationScripts = props.scripts
      .filter(
        k => props.integration.allowed_scripts.indexOf(k.id.toString()) !== -1
      )
      .map(k => ({
        value: k.id,
        label: k.name
      }));

    initialStore = {
      data: {
        config: props.integration.config,
        integration_blueprint_id: props.integration.integration_blueprint_id,
        allowed_scripts: integrationScripts,
        allowed_events: props.integration.allowed_events[0]
      }
    };
  }

  // Disable the session storage
  setStorageType({
    getItem: payload => payload,
    setItem: payload => payload,
    clear: () => {},
    length: 0,
    key: payload => payload.toString(),
    removeItem: () => {}
  });
  createStore(initialStore);

  return (
    <StateMachineProvider>
      <Router>
        <Route
          render={({ location }) => (
            <PoseGroup>
              <RouteContainer key={location.pathname}>
                <Switch location={location}>
                  <Route
                    exact
                    path="/"
                    render={p => (
                      <Step3
                        {...p}
                        blueprints={props.blueprints}
                        blueprints_oauth={props.blueprints_oauth}
                      />
                    )}
                  />

                  <Route exact path="/step2" render={p => <Step1 {...p} />} />

                  <Route
                    exact
                    path="/step3"
                    render={p => <Step2 {...p} scripts={props.scripts} />}
                  />

                  <Route
                    exact
                    path="/step4"
                    render={p => (
                      <Step4 {...p} integration={props.integration} />
                    )}
                  />
                </Switch>
              </RouteContainer>
            </PoseGroup>
          )}
        />
      </Router>
    </StateMachineProvider>
  );
};

export default IntegrationEditor;
