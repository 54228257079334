// Renders an editable list linked with a diff-supporting
// backend endpoint.
//
// Elements must contain an id property.
//
// <ListEditor list={[ {id: 0}, ... ]}
//             component={Element}
//             defaultElementData={{ kind: 1, rule: "" }}
//             endpoint="https://..." />
//
// Children of the type `Element` are created and will receive as props:
//   - data
//   - update(newData)
//   - remove(data)
//
// Extending:
// - renderSync(syncing) -> can be implemented to render a sync indicator
// - renderAdd() -> can be impemented to render the "add new" button
// - renderFooter(syncing) -> by default calls renderSync() & renderAdd(), use to add formatting
import React from "react";

export default class ListEditor extends React.Component {
  constructor(props) {
    super(props);

    const initialList = this.initialList();

    // Clone the list from the props
    const list = JSON.parse(JSON.stringify(initialList));

    // Save the cloned list into the state
    this.state = {
      initialList: initialList,
      list: list,
      syncing: false,
    };

    // From the given list
    this.maxId = list.length?
                 Math.max.apply(null, list.map(el => el.data.id)) : 0;
  }

  preprocessList(list) {
    return list
      .sort((a, b) => a.id - b.id)
      .map(el => ({ data: el }));
  }

  initialList() {
    return this.preprocessList(this.props.list);
  }

  unwrapList(list) {
    return list.map(el => el.data);
  }

  currentList() {
    return this.unwrapList(this.state.list);
  }

  // Sends a diff to the given endpoint
  sendToEndpoint(diff, callback, backoff = 1000) {
    // TODO: add globally
    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    });

    $.ajax({
      url: this.props.endpoint,
      data: JSON.stringify(diff),
      contentType: "application/json",
      type: "POST"
    }).done((data, status, xhr) => {
      const list = this.preprocessList(data.list);

      this.setState({
        initialList: list,
        list: JSON.parse(JSON.stringify(list)),
        syncing: false
      });

      if (callback) {
        callback();
      }
    }).fail((data, status, xhr) => {
      // Try again
      setTimeout(() => this.sendToEndpoint(diff, callback, backoff>=60000? backoff : 2*backoff), backoff);
    });

    // Syncing while we don't get a response
    this.setState({ syncing: true })
  }

  diff() {
    const initialList = this.state.initialList;
    const currentList = this.state.list;

    // Diff
    let deleted = {};
    let added = {};
    let updated = {};

    for (let i of initialList) {
      deleted[i.data.id] = true;
    }

    for (let c of currentList) {
      if (deleted[c.data.id]) {
        if (c.dirty) {
          updated[c.data.id] = c.data;
        }
      }

      delete deleted[c.data.id];
      added[c.data.id] = c.data;
    }

    for (let i of initialList) {
      delete added[i.data.id];
    }

    return { deleted, added, updated };
  }

  // Hack when using as a controlled component
  clean() {
    this.setState({
      list: this.state.list.map(el => ({ ...el, dirty: false }))
    });
  }

  // For when using as a controlled component
  callOnChange() {
    if (this.props.onChange) {
      this.props.onChange(this.state.list);
    }
  }

  // Sync the current state with the backend
  sync(callback) {
    this.sendToEndpoint(this.diff(), callback);
  }

  // Remove an element from the current state
  remove(id) {
    this.setState(
      { list: this.state.list.filter((el) => el.data.id !== id) },
      () => this.callOnChange()
    );

    this.callOnChange();
  }

  addWithCustomData(kind, rule) {
    const data = { kind, rule };

    // Create a new element
    const el = { data: { id: ++this.maxId, ...data } };

    // Insert into the state's list
    this.setState(
      { list: this.state.list.concat(el) },
      () => this.callOnChange()
    );

    this.callOnChange();
  }

  add() {
    const data = JSON.parse(JSON.stringify(this.props.defaultElementData));

    // Create a new element
    const el = { data: { id: ++this.maxId, ...data } };

    // Insert into the state's list
    this.setState(
      { list: this.state.list.concat(el) },
      () => this.callOnChange()
    );
  }

  // Update a rule in the current state
  async update(newEl) {
    // Create a copy of newEl with a dirty flag set
    const newDirtyEl = { dirty: true, data: newEl };

    // Swap out the previous element
    await this.setState({
      list: this.state.list.map(el => (el.data.id === newEl.id)? newDirtyEl : el)
    });

    this.callOnChange();
  }

  renderSync(syncing) {
    return null;
  }

  renderAdd() {
    return null;
  }

  renderFooter(syncing) {
    return <div>
      { this.renderAdd() }
      { this.renderSync(syncing) }
    </div>
  }

  render() {
    const { dynamicElementData } = this.props;
    const { list, syncing } = this.state;
    const Component = this.props.component;

    return (
      <div>
        {list.map((el, i) => (
          <Component
            data={el.data}
            key={el.data.id}
            updateRulesCount={this.props.updateRulesCount}
            rulesCount={this.props.rulesCount}
            remove={this.remove.bind(this)}
            update={this.update.bind(this)}
            dynamicData={dynamicElementData}
            index={i}
            callback={this.props.callback}
          />
        ))}

        {this.renderFooter(syncing)}
      </div>
    );
  }
}
