import styled from "styled-components";

export const Container = styled.div`
  .progress {
    border-radius: 12px;
    width: 5%;
    transition: width 0.6s ease;
    height: 1rem;
  }

  .progress-bar.bg-info {
    background-color: #00cffb !important;
  }
`;

export const SegmentSelector = styled.div`
  display: inline-block;

  & > button {
    font-weight: bold;
    background: transparent;
    outline: none;
    border: none;
  }
`;
