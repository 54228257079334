import Encryptor from "@/lib/encryptor";
import axios from "@/lib/axios";

import useStep2Store from "@/components/billing/store/useStep2Store";

import swal from "sweetalert";

export async function gatewayConfigData(store_data) {
  return await axios.get("/payments/gateway-config-data",
    {
      params: {
        gateway_name: "vindi",
        event_type: store_data.event_type,
      },
    }
  ).then((response) => {
    return Encryptor.decrypt(response.data.config)
  }).catch(() => {
    throw new Error(I18n.t("views.billings.components.payment.validates.config_data"));
  });
}

export async function saveCreditCardAndRedirect(data) {
  await axios.post(data.config.event_cc_url, {
    company_id: data.config.company_id,
    plan_price_id: data.store_data.plan_id,
    registry_code: data.form.get("tax_vat"),
    payment_token: data.profile_token,
    magic_key: data.config.magic_key,
  }).then(() => {
    if (data.store_data.event_type === "create") return window.location = "/billing/success";

    swal(
      I18n.t("views.billings.components.payment.ready"),
      I18n.t("views.billings.components.payment.all_ready"),
      "success").then(() => {
        window.location = "/billing"
      });
  }).catch(() => {
    throw new Error(I18n.t("views.billings.components.payment.validates.save_cc"));
  });
}

export function loadStoreData() {
  const stateData = useStep2Store.getState();

  if (stateData.plan_id === null && stateData.event_type === "") {
    return {
      plan_id: null,
      event_type: "update"
    }
  }

  return stateData;
}
