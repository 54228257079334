import React from 'react';

const StepSevenDescription = () => {
  const webhook = I18n.t("shared.helpscout.webhook_integration");
  const zapier = I18n.t("shared.helpscout.zapier_integration");
  const hubspot = I18n.t("shared.helpscout.hubspot_integration");
  const otherPlatforms = I18n.t("shared.helpscout.integration_search");
  const tutorials = [
    {
      tutorial: I18n.t("customer_success.optimization_container.step_seven_description.rd_station.title"),
      label: I18n.t("customer_success.optimization_container.step_seven_description.label01"),
      link: I18n.t("customer_success.optimization_container.step_seven_description.rd_station.link"),
    },
    {
      tutorial: I18n.t("customer_success.optimization_container.step_seven_description.webhook.title"),
      label: I18n.t("customer_success.optimization_container.step_seven_description.label01"),
      link: I18n.t("customer_success.optimization_container.step_seven_description.webhook.link", { link: webhook }),
    },
    {
      tutorial: I18n.t("customer_success.optimization_container.step_seven_description.zapier.title"),
      label: I18n.t("customer_success.optimization_container.step_seven_description.label01"),
      link: I18n.t("customer_success.optimization_container.step_seven_description.zapier.link", { link: zapier }),
    },
    {
      tutorial: I18n.t("customer_success.optimization_container.step_seven_description.hubspot.title"),
      label: I18n.t("customer_success.optimization_container.step_seven_description.label01"),
      link: I18n.t("customer_success.optimization_container.step_seven_description.hubspot.link", { link: hubspot }),
    },
    {
      tutorial: I18n.t("customer_success.optimization_container.step_seven_description.piperun.title"),
      label: I18n.t("customer_success.optimization_container.step_seven_description.label01"),
      link: I18n.t("customer_success.optimization_container.step_seven_description.piperun.link"),
    },
    {
      tutorial: I18n.t("customer_success.optimization_container.step_seven_description.other_platforms.title"),
      label: I18n.t("customer_success.optimization_container.step_seven_description.label02"),
      link: I18n.t("customer_success.optimization_container.step_seven_description.other_platforms.link", { link: otherPlatforms }),
    },
  ];

  return (
    <div className="mb-3">
      <p className="m-0">
        {I18n.t("customer_success.optimization_container.step_seven_description.follow_the_tutorial_for_the_main_integrated_platforms")}
      </p>

      {tutorials.map((tutorial, index) => {
        return (
          <p key={index} className="m-0">
            <a href={tutorial.link} target="_blank">{tutorial.tutorial}</a>
          </p>
        );
      })}
    </div>
  );
};

export default StepSevenDescription;
