const useIconByInputType = (type) => {
  const typeIcons = {
    text: "letter-case-upper",
    phone: "phone",
    intl_phone: "phone",
    cpf: "id",
    cnpj: "id",
    cep: "pin",
    email: "at",
    "unique-leadster-email": "at",
    email_corp: "at",
    name: "user-check",
    birthday: "calendar-event",
    city: "pin",
    date: "calendar-event",
    time: "clock",
    password: "lock",
    number: "123",
    "cpf/cnpj": "id",
    real: "currency-real",
    dollar: "currency-dollar",
    euro: "currency-euro",
    state: "pin",
    state_city: "pin",
  };

  return _.get(typeIcons, type, "letter-case-upper");
};

export default useIconByInputType;
