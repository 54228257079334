import React, { useEffect, useRef } from "react";
import { useInterpolatedRef, CubicOut } from "./interpolation";

const requestAnimationFrame = requestAnimationFrame || (fn => setTimeout(fn, 1/30));
const cancelAnimationFrame = cancelAnimationFrame || clearTimeout;

// In vh
const STEP_DISTANCE = 0.085;
// In ms
const WAVE_TIME = 3000;
const TRAIL_TIME = 1000;
const CAMERA_TIME = 500;

const t = () => +new Date();

// Paint the line in the canvas
const paint = (frameRequestRef, canvas, ctx, state, props) => {
  // Request the next animation frame
  frameRequestRef.current = requestAnimationFrame(() => {
    paint(frameRequestRef, canvas, ctx, state, props);
  });

  const { amplitudeRef, trailRef, cameraRef } = state;

  // Avoid unecessary painting
  if (amplitudeRef.done() && trailRef.done() && cameraRef.done()) return;

  // Adjust the canvas size (i.e.: make it responsive)
  const w = canvas.clientWidth;
  const h = canvas.clientHeight;

  canvas.width = w;
  canvas.height = h;

  const [ amplitude, trail, camera ] = [
    amplitudeRef.get(),
    trailRef.get(),
    cameraRef.get(),
  ];

  const lineWidth = 4;
  const resolution = 30;
  const segmentH = 1/resolution;
  const margin = lineWidth/2;
  const availableW = w-lineWidth*4;

  ctx.lineWidth = lineWidth;
  ctx.clearRect(0, 0, w, h);

  ctx.beginPath();
  ctx.moveTo(w/2, -segmentH);

  function wave(x) {
    return Math.sin(x*6+t()/100)*availableW/2*amplitude+w/2;
  }

  // Draw the filled area to the right of the line
  let line = 0;
  let step = -camera;
  let split = trail-camera;

  while (line < 1) {
    if (line < step && line < split) {
      ctx.lineTo(wave(line), line*h);
      line += segmentH;
    } else if (split < step && split < line) {
      ctx.lineTo(wave(split), split*h);
      split = 2;
    } else {
      ctx.lineTo(wave(step), step*h);
      step += STEP_DISTANCE;
    }
  }

  ctx.lineTo(w, h);
  ctx.lineTo(w, 0);
  ctx.fillStyle = props.inside;
  ctx.fill();

  // Draw the line (maybe can be merged with the loop above
  // if the drawing code is separated)
  line = 0;
  step = -camera;
  split = trail-camera;

  ctx.beginPath();
  ctx.moveTo(w/2, -segmentH);

  while (line < 1) {
    if (line < step && line < split) {
      ctx.lineTo(wave(line), line*h);
      line += segmentH;
    } else if (split < step && split < line) {
      ctx.lineTo(wave(split), split*h);
      ctx.strokeStyle = props.highlight;
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(wave(split), split*h);

      split = 2;
    } else {
      ctx.lineTo(wave(step), step*h);
      step += STEP_DISTANCE;
    }
  }

  ctx.strokeStyle = props.line || "#d6d2d0";
  ctx.stroke();

  // Draw the step points
  for (let i=0;i<props.steps;i++) {
    const p = ((i+1)*STEP_DISTANCE-camera);

    ctx.beginPath();
    ctx.arc(wave(p), p*h, lineWidth*2, 0, Math.PI*2);

    const threshold = (i+0.5)*STEP_DISTANCE;

    if (trail > threshold) {
      const opacity = CubicOut(Math.min(1, (trail-threshold)/(0.5*STEP_DISTANCE)));

      ctx.fillStyle = props.highlight;
      ctx.globalAlpha = opacity;
      ctx.fill();
      ctx.globalAlpha = 1;
    } else {
      ctx.fillStyle = props.line;
      ctx.fill();
    }
  }
};

export default function AnimatedLoadingLine(props) {
  const canvasRef = useRef(null);
  const frameRequestRef = useRef(null);

  const amplitudeRef = useInterpolatedRef(0);
  const trailRef = useInterpolatedRef(0);
  const cameraRef = useInterpolatedRef(0);

  const state = { amplitudeRef, trailRef, cameraRef };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    frameRequestRef.current = requestAnimationFrame(() => {
      paint(frameRequestRef, canvas, ctx, state, props);
    });

    return () => {
      cancelAnimationFrame(frameRequestRef.current);
    };
  });

  useEffect(() => {
    trailRef.set(props.step*STEP_DISTANCE, TRAIL_TIME);

    // Last step
    if (props.step == props.steps) {
      cameraRef.set(props.step*STEP_DISTANCE-0.5, CAMERA_TIME);
    } else {
      cameraRef.set(props.step*STEP_DISTANCE-0.5, CAMERA_TIME);
    }

    if (!trailRef.done()) {
      amplitudeRef.set(1, 0);
      amplitudeRef.set(0, WAVE_TIME);
    }
  });

  return <canvas className="animated-loading-line" ref={canvasRef}>
    TODO: Canvas not supported!!!
  </canvas>;
}
