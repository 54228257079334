import React from "react";

import parse from 'html-react-parser';

import useCurrentUserStore from "@/store/currentUserStore";

const AlertPlaceholder = () => {
  const { alreadyUsedTrial, isImplemented } = useCurrentUserStore((state) => state.currentUser);

  return (
    <div className="info-placeholder">
      <div className="fs-3 fw-bold mb-2">
        🔒 {I18n.t("shared.messages.block.leadster_complete")}
      </div>

      <p className='mb-0'>
        {!isImplemented ? (
          <>
            {parse(I18n.t("shared.messages.activate_whatsapp_html"))}
          </>
        ) : (
            alreadyUsedTrial ? (
              <>
                {parse(I18n.t("shared.messages.full_signature_html"))}
              </>
            ) : (
              <>
                {parse(I18n.t("shared.messages.free_trial_html"))}
              </>
            )
        )}

      </p>
    </div>
  );
};

export default AlertPlaceholder;
