import React from 'react';

const IntegrationBlueprintsIcon = ({iconUrl, friendlyName, height = 32, width = 32}) => {
  const iconDefault = "https://cdn.leadster.com.br/dashboard/integrations/icons/Webhook.png";

    return (
      <>
        <img src={ iconUrl == null ? iconDefault : iconUrl }
          alt={ friendlyName }
          title={ friendlyName }
          height={ height }
          width={ width } />
      </>
    )
  }

export default (IntegrationBlueprintsIcon);
