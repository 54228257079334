const useActiveTab = (chartData) => {
  const series = chartData.map((value) => value.conversion.total);
  const labels = chartData.map((value) => value.option);

  const hasInvalidData = _.isEmpty(_.filter(series)) || _.isEmpty(labels);

  const formattedPercentual = (value, total) => {
    const percentual = ((value / (total || 1)) * 100);
    const formattedPercentual = percentual.toFixed(2).replace(/\.?0+$/, '');

    return `${formattedPercentual}%`;
  }

  const chartConfig = {
    series: [{ data: series }],
    options: {
      labels,
      chart: {
        type: "bar",
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: { categories: labels },
      tooltip: {
        custom: _.throttle(({ dataPointIndex }) => {
          const selectedData = chartData[dataPointIndex];
          const { option, conversion } = selectedData;

          return `
            <div class="bg-white text-dark shadow">
              <div class="fw-bold text-center my-2">${I18n.t("views.analytics.source_channel")}</div>

              <table class="table table-sm mb-0">
                <thead>
                  <tr>
                    <th class="text-center text-truncate" colspan="3" style="max-width: 200px;">
                      ${option}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="px-3 border-right">Meta Ads</td>
                    <td class="px-3 text-primary border-right">${conversion.facebook}</td>
                    <td class="px-3 text-primary">${formattedPercentual(conversion.facebook, conversion.total)}</td>
                  </tr>
                  <tr>
                    <td class="px-3 border-right">Google Ads</td>
                    <td class="px-3 text-primary border-right">${conversion.google}</td>
                    <td class="px-3 text-primary">${formattedPercentual(conversion.google, conversion.total)}</td>
                  </tr>
                  <tr>
                    <td class="px-3 border-right">LinkedIn Ads</td>
                    <td class="px-3 text-primary border-right">${conversion.linkedin}</td>
                    <td class="px-3 text-primary">${formattedPercentual(conversion.linkedin, conversion.total)}</td>
                  </tr>
                  <tr>
                    <td class="px-3 border-right">${I18n.t("shared.actions.others")}</td>
                    <td class="px-3 text-primary border-right">${conversion.others}</td>
                    <td class="px-3 text-primary">${formattedPercentual(conversion.others, conversion.total)}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="fw-bold bg-light">
                    <td class="px-3 border-right">${I18n.t("shared.actions.total")}</td>
                    <td class="px-3 text-primary">${conversion.total}</td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          `;
        }, 250),
      }
    },
  };

  return [chartConfig, hasInvalidData];
};

export default useActiveTab;
