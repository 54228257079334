import React, { useState, useCallback } from "react";
import CurrencyFormater from "react-currency-format";
import parse from "html-react-parser";

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
    this.onChange = this.onChange.bind(this);
  };

  onChange(e) {
    if (e.target.value === '') {
      this.setState({ value: true });
    } else {
      this.setState({ value: '' });
    }
  };

  render() {

    const agreeTermsStyle = {
      border: "1px solid darkgrey",
      textAlign: "center",
      padding: "20px",
      backgroundColor: "#f5f5f5",
      borderRadius: "10px",
    };

    return (
      <div className="step1">
        <h3>{ this.props.plan['full_name'] }</h3>
        <div className="billing_period" onClick= {event => this.props.changeBillingPeriod() }>
          <span className={`month ${this.props.billingPeriod === 'month' ? 'active' : ''}`}>{I18n.t("billing.plans.monthly")}</span>
          <span className={`year ${this.props.billingPeriod === 'year' ? 'active' : ''}`}>{I18n.t("billing.plans.yearly")}</span>
          <span className={`selected ${this.props.billingPeriod}_active`}/>
        </div>
        <div className="price text-blue">
          <span className="price_complement block">
            { this.props.plan.period === 'monthly' ? "" : I18n.t("billing.plans.annual_price_discount") }
          </span>
          <CurrencyFormater value={this.props.plan.period === 'monthly' ? this.props.plan.price : (this.props.plan.price/12)}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            fixedDecimalScale={true}
                            prefix={I18n.t("number.currency.format.unit")}
                            isNumericString={true}
                            decimalSeparator={','}
                            decimalScale={2}/>

          <span className="price_complement">&nbsp; /{I18n.t("billing.plans.month")}</span>
        </div>

        <div className="plan-info">
          {I18n.t("billing.plans.unique_monthly_hits.text01")}&nbsp;
          <CurrencyFormater value={this.props.plan.access_limit}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            fixedDecimalScale={true}
                            prefix={''}
                            isNumericString={true}
                            decimalSeparator={''}
                            decimalScale={0}/>
          &nbsp;{I18n.t("billing.plans.unique_monthly_hits.text02")}
        </div>

        <ul className="plan-info">
          <li>{I18n.t("billing.plans.items.item01")}</li>
          <li>{I18n.t("billing.plans.items.item02")}</li>
          <li>{I18n.t("billing.plans.items.item03")}</li>
          <li>{I18n.t("billing.plans.items.item04")}</li>
          <li>{I18n.t("billing.plans.items.item05")}</li>
        </ul>


        <div style={agreeTermsStyle}>
          <label htmlFor="agree-terms">Eu aceito os
            <a href={this.props.agree_terms_pdf} target="_blank"><b> Termos de Uso e Pagamento</b></a> &nbsp;
          </label>
          <input type="checkbox" id="agree-terms"  onChange={this.onChange} value={this.state.value}  />

          <hr/>

          <p>{ parse(I18n.t("billing.step1.agree_terms.html")) }</p>

          <hr/>

          <button className="billing-btn" onClick={ event => this.props.setStep(2) } disabled={!this.state.value}>
            {I18n.t("billing.plans.choose_plan")} <i className="fe fe-arrow-right"/>
          </button>
        </div>

      </div>
    );
  }
}

export default Step1;
