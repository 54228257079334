export default [
  {
    title: I18n.t("guide_modal.script_flow.ai_block.title_01"),
    content: I18n.t("guide_modal.script_flow.ai_block.subtitle_01_html"),
    media: {
      type: I18n.t("guide_modal.script_flow.ai_block.type_01"),
      url: I18n.t("guide_modal.script_flow.ai_block.media_url_01"),
    },
  },
  {
    title: I18n.t("guide_modal.script_flow.ai_block.title_02"),
    content: I18n.t("guide_modal.script_flow.ai_block.subtitle_02_html"),
    media: {
      type: I18n.t("guide_modal.script_flow.ai_block.type_02"),
      url: I18n.t("guide_modal.script_flow.ai_block.media_url_02"),
    },
  },
  {
    title: I18n.t("guide_modal.script_flow.ai_block.title_03"),
    content: I18n.t("guide_modal.script_flow.ai_block.subtitle_03_html"),
    media: {
      type: I18n.t("guide_modal.script_flow.ai_block.type_03"),
      url: I18n.t("guide_modal.script_flow.ai_block.media_url_03"),
    },
  },
];
