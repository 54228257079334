import React, { useCallback } from "react";
import axios from "@/lib/axios";
import swal from 'sweetalert'

const RemoveUserAccess = ({url}) => {
  const removeUser = useCallback(async (e) => {

    const willDelete = await swal({
      title: I18n.t("multiple_access.are_you_sure"),
      text: I18n.t("multiple_access.removing_user_from_account"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (willDelete) {
      try {
        await axios.delete(url);
        MixpanelService.track('multiple_accesses_access_remove_user');
        window.location.reload();
      } catch {
        swal(I18n.t("multiple_access.erro"), I18n.t("multiple_access.could_not_remove"), "error");
      }
    }
  }, []);

  return (
    <button type='button' className="btn btn-sm btn-outline-danger" onClick={removeUser}>
      {I18n.t("general.actions.remove")}
    </button>
  );
}

export default RemoveUserAccess;
