import React from "react";

import { ROUTES } from "@/components/leadster_ia_pages/AiAssistant/subpages/routes";

import NavBarLink from "./components/NavBarLink/NavBarLink";

import { useAssistantContext } from "@/contexts/AssistantContext";

const ASSISTANT_ROUTES = ROUTES.createAssistant;

const NavBar = () => {
  const { assistant } = useAssistantContext();

  return (
    <>
      <div className="card nav flex-column nav-pills">
        {ASSISTANT_ROUTES
          .filter((_, index) => index !== ASSISTANT_ROUTES.length - 1)
          .map((route, index) => (
            <NavBarLink
              key={index}
              to={route.path}
              activeOnlyWhenExact={route.path === ""}
              isDisabled={route.requireAssistant && !assistant}
            >
              <i className={`ti ti-${route.iconClass} icon text-primary me-2`} />
              {route.name}
            </NavBarLink>
          ))
        }
      </div>
    </>
  );
};

export default NavBar;
