import "./styles.scss"

import React, { useEffect } from "react";

import parse from "html-react-parser";

import Box from "../Box";
import segmentConversionCalls from "../../data/segment-conversion-calls";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';

const Modal = React.forwardRef(({ isMobile }, ref) => {
  const avatar = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.previewContent.avatar);

  useEffect(() => {
    $(document).on('focusin', function(e) {
      if ($(e.target).closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length) {
        e.stopImmediatePropagation();
      }
    });
  })

  const showHowItWorks = (e) => {
    e.preventDefault();

    $('#screen-models').hide();
    $('#screen-information').show();

    $("#segment_list").find('.active').removeClass('active');
    $("#how_it_works li a").first().addClass('active');
  };

  const toggleActiveClass = (name) => {
    $("#segment_list, #how_it_works").find('.active').removeClass('active');
    $("#" + name).addClass('active');
  }

  const toggleSegmentTitle = (title) => {
    if (title === '') {
      $("#segment-title").text(I18n.t("shared.actions.everything"));
    } else {
      $("#segment-title").text(title);
    }
  }

  const toggleScreens = () => {
    let models = $('#screen-models');

    if (models.is(':hidden')) {
      models.show();
      $('#screen-information').hide();
    }
  }

  const toggleBoxes = (box) => {
    let boxes = $('.boxes')

    if (box === '') {
      boxes.each(function() {
        $(this).show();
      });

      return;
    }

    $.when(
      boxes.each(function() {
        $(this).hide();
      })
    ).then(function() {
      $("#" + box).show();
    });
  }

  const showSegments = (e, name, title = '', box = '') => {
    e.preventDefault();

    toggleActiveClass(name);
    toggleSegmentTitle(title);
    toggleScreens();
    toggleBoxes(box);
  };

  return (
    <div className="modal modal-blur fade" ref={ref} id="conversion-call-options" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content d-block">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

          {/*Bloco esquerdo*/}
          <div className="col-4 links float-start">
            <div style={{ padding: "20px" }}>
              <div className="h3">{I18n.t("views.manage_flows.call_models.title")}</div>
              <span className="subtitle">{I18n.t("views.manage_flows.call_models.subtitle")}</span>

              <ul className="list-unstyled segment" id={"segment_list"}>
                <li><a href="#" onClick={ (e) => showSegments(e, 'show_all') } className="active" id="show_all">{I18n.t("shared.actions.everything")}</a></li>

                {
                  Object.keys(segmentConversionCalls).map((item, index) => {
                    let key = index + '_' + item;
                    let box = "boxes_" + index;
                    let title = segmentConversionCalls[item]['title'];

                    return (
                      <li key={index}>
                        <a href="#" id={ key } onClick={ (e) => showSegments(e, key, I18n.t(`shared.segment.${title}`), box) }> {I18n.t(`shared.segment.${title}`)}</a>
                      </li>
                    )
                  })
                }
              </ul>

              <span className="subtitle">{I18n.t("views.manage_flows.call_models.how_does_it_work")}</span>
              <ul className="list-unstyled segment" id="how_it_works">
                <li><a href="#" onClick={ (e) => showHowItWorks(e) }>{I18n.t("views.manage_flows.call_models.what_is_conversion_call")}</a></li>
              </ul>
            </div>
          </div>

          {/*Bloco direito*/}
          <div className="col-8 float-start">
            <div id="screen-models">
              <div className="h3">{I18n.t("views.manage_flows.call_models.segment")} <span id="segment-title">{I18n.t("shared.actions.all")}</span></div>
              <p className="mb-0">{parse(I18n.t("views.manage_flows.call_models.calls_with_the_best_conversions_html"))}</p>

              <div className="box-container-shadow">
                <div className="boxes-container">
                  { Object.keys(segmentConversionCalls).map((item, index) => {
                    let key = "boxes_" + index;
                    return (
                      <Box
                        key={key}
                        isMobile={isMobile}
                        identifier={key}
                        avatar={avatar}
                        calls={segmentConversionCalls[item]["calls"]}
                      />
                    );
                    })
                  }
                </div>
              </div>
            </div>

            <div id="screen-information" className="p-3" style={{display: "none"}}>
              <div className="h2">{I18n.t("views.manage_flows.call_models.conversion_call_tips")}</div>

              <div className="info-body">
                {parse(I18n.t("views.manage_flows.call_models.conversion_call_tips_description_html"))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default React.memo(Modal);
