import { useCallback, useState } from "react";

import useUpdateEffect from "./useUpdateEffect";

export function useLocalStorage(storageKey) {
  return useStorage(storageKey, window.localStorage);
}

export function useSessionStorage(storageKey) {
  return useStorage(storageKey, window.sessionStorage);
}

function useStorage(storageKey, storageObject) {
  const [value, setValue] = useState(() => {
    const jsonValue = storageObject.getItem(storageKey);

    if (jsonValue !== null) {
      try {
        return JSON.parse(jsonValue);
      } catch (error) {
        return null;
      }
    }

    return null;
  });

  useUpdateEffect(() => {
    if (value === null) {
      return storageObject.removeItem(storageKey);
    }

    storageObject.setItem(storageKey, JSON.stringify(value));
  }, [storageKey, JSON.stringify(value), storageObject]);

  const remove = useCallback(() => {
    setValue(null);
  }, []);

  return { value, setValue, remove };
}
