import PropTypes from "prop-types";

import useCustomVariableStore from "@/store/useCustomVariableStore";

import Encryptor from "@/lib/encryptor";

const CustomVariables = ({ encryptedData }) => {
  const setCustomVariables = useCustomVariableStore((state) => state.setCustomVariables);

  setCustomVariables(Encryptor.decrypt(encryptedData));

  return null;
};

CustomVariables.propTypes = {
  encryptedData: PropTypes.string.isRequired,
};

export default CustomVariables;
