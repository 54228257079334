import TopMenu from "./TopMenu";
import Editor from "./Editor";

import { ScriptContextProvider } from "@/contexts/ScriptContext";
import { buildPayloadBySerializer } from "@/contexts/ScriptContext/utils";

import AlertHandler from "./AlertHandler";

const ScriptEditor = ({ scriptEditorSerializer }) => {
  const payloadState = buildPayloadBySerializer(scriptEditorSerializer);

  return (
    <ScriptContextProvider payloadState={payloadState}>
      <TopMenu />
      <AlertHandler />

      <div className="container-xxl">
        <Editor />
      </div>
    </ScriptContextProvider>
  );
};

export default ScriptEditor;
