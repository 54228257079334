export default [
  {
    title: I18n.t("guide_modal.leadster_ai.config.title_01"),
    content: I18n.t("guide_modal.leadster_ai.config.subtitle_01_html"),
    media: {
      type: I18n.t("guide_modal.leadster_ai.config.type"),
      url: I18n.t("guide_modal.leadster_ai.config.media_url_01"),
    },
  },
];
