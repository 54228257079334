export default (editor, dynamicFields) => {
  const customFields = [
    { text: I18n.t("views.manage_flows.scripts.conversion_url"), value: "{{$url-conversao}}" }
  ];

  customFields.forEach((customField) => {
    dynamicFields[customField.value] = customField;
  });

  const getMatchedFields = (pattern = "") => {
    return Object.entries(dynamicFields).filter((dynamicField) => {
      const fieldDescription = dynamicField[1].text.toLowerCase().trim();

      if (fieldDescription === "") {
        return false;
      }

      return fieldDescription.indexOf(pattern.toLowerCase()) !== -1;
    });
  };

  editor.ui.registry.addAutocompleter("specialFields", {
    ch: "@",
    minChars: 0,
    matches: (_) => true,
    columns: 1,
    highlightOn: ["char_name"],
    onAction: (autocompleteApi, rng, value) => {
      editor.selection.setRng(rng);
      editor.insertContent(value);
      autocompleteApi.hide();
    },
    fetch: function (pattern) {
      return new Promise((resolve) => {
        const results = getMatchedFields(pattern).map((char) => {
          return {
            type: "autocompleteitem",
            value: char[1].value,
            text: char[1].text,
          };
        });

        resolve(results);
      });
    },
  });

  editor.ui.registry.addButton("autocompleteButton", {
    text: I18n.t("views.manage_flows.scripts.user_response"),
    tooltip: I18n.t("views.manage_flows.scripts.info_collected_from_lead"),
    icon: "non-breaking",
    onAction: function () {
      const matchedFields = getMatchedFields();

      if (!matchedFields.length) {
        editor.windowManager.alert(I18n.t("views.manage_flows.scripts.no_user_response_block_found"));
        return;
      }

      editor.insertContent("@");
      editor.fire("keypress", { keyCode: "50" });
    },
  });
};
