import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  font-size: 0.875rem;
  display: flex;
  align-items: center;
`;

export const Code = styled.code`
  font-size: 1em;
  font-weight: bold;
`;

