import React from "react";
import { useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

export function withHistoryDirection(Component, routePositions, routePath) {
  return (props) => {
    const { match } = props;

    const history = useHistory();
    const positions = history.entries.map(e => routePositions[e.pathname] || 0);
    let direction = 0;

    if (positions.length < 2) {
      direction = 0;
    } else {
      const lastTransition = positions.slice(positions.length-2, positions.length)
      direction = lastTransition[1]-lastTransition[0];
    }

    const className = direction > 0? "step-forwards" : "step-backwards";

    return <div className={`content ${routePath === '/segment' ? 'content-clean' : ''}`}>
      <CSSTransition in={match != null} timeout={800} classNames={className} unmountOnExit>
        <div className="step">
          <Component {...props} />
        </div>
      </CSSTransition>
    </div>;
  };
}
