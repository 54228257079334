import React from "react";

import swal from "sweetalert";
import axios from "@/lib/axios";

const GoogleLogout = ({
  enabled = true,
  onSuccessCallback = () => {},
  className = "btn-outline-danger w-100",
  text = I18n.t("views.users.edit.google_calendar.enabled.logout.title")
}) => {
  const handleDisconnect = async () => {
    if (!enabled) return;

    const confirmDisconnect = await swal({
      title: I18n.t("shared.common_words.warning"),
      text: I18n.t("views.users.edit.google_calendar.enabled.logout.description"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
      buttons: {
        cancel: { text: I18n.t("shared.actions.cancel"), visible: true },
        confirm: { text: I18n.t("shared.actions.ok"), visible: true },
      },
    });

    if (confirmDisconnect) {
      axios.post('/representatives/disconnect')
        .then(() => {
          swal("", I18n.t("views.users.edit.google_calendar.enabled.account.disconnected"), "success");
          onSuccessCallback();
        })
        .catch(() => {
          swal("", I18n.t("views.users.edit.google_calendar.enabled.account.disconnected_error"), "warning");
        });
    }
  };

  return (
    <button
      className={`btn ${className}`}
      disabled={!enabled}
      type="button"
      onClick={handleDisconnect}
    >
      <i className="ti ti-unlink me-2" />
      {text}
    </button>
  );
}

export default GoogleLogout;
