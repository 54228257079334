import React from "react";

const SkeletonModalLoader = () => {
  return (
    <div className="row w-100">
      <div className="card placeholder-wave mb-3">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-4">
              <div className="placeholder col-12"></div>
            </div>
            <div className="col-6">
              <div className="placeholder col-12"></div>
            </div>
            <div className="col text-end">
              <div className="placeholder col-12"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonModalLoader;
