import styled, { keyframes } from 'styled-components';

export const Card = styled.div`
  background: white;
  border-radius: .25rem;
`;

const loading = keyframes`
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.8;
  }
`;

export const Title = styled.div`
  background-color: #E2E2E2;
  max-width: 15%;
  height: 20px;

  animation: ${loading} 1.5s infinite linear;
`;

export const Content = styled.div`
  background-color: #E2E2E2;
  max-width: 100%;
  height: 200px;
  margin-top: 20px;

  animation: ${loading} 1.5s infinite linear;
`;

export const Description = styled.div`
  background-color: #E2E2E2;
  max-width: 100%;
  height: 20px;
  margin-top: 10px;

  animation: ${loading} 1.5s infinite linear;
`;
